import {
  DEFAULT_LANGUAGE,
  setCountryFromCookie,
  setLanguageToCookie,
} from '@wr/web-shared';
import { getSendCountryCodeFromLocale, toTitleCase } from '@wr/web-ui';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';

import { DEFAULT_LOCATION } from '@/constants';
import { AppContext } from '@/context';

import { getInitialGeoLocationRegion } from './header.utils';

export const useHeaderHooks = () => {
  const { regions, messages, locationCountryCookie } = useContext(AppContext);
  const router = useRouter();

  const { locale, locales } = useRouter();

  const initialGeoLocationRegion = getInitialGeoLocationRegion(
    locale,
    locales,
    locationCountryCookie,
  );

  const filteredLocales = locales?.filter(l => l !== DEFAULT_LANGUAGE);

  const [region, setRegion] = useState(initialGeoLocationRegion);

  const accessor = (region || locale) as string;

  const countryNames = new Intl.DisplayNames([accessor], {
    type: 'region',
  });

  const occurencyBySendCountry = filteredLocales?.reduce<
    Record<string, number>
  >((acc, region) => {
    const sendCountry = getSendCountryCodeFromLocale(region);
    acc[sendCountry] = (acc[sendCountry] ?? 0) + 1;
    return acc;
  }, {});

  const getLanguageNames = (locale: string) =>
    new Intl.DisplayNames([locale], {
      type: 'language',
    });

  // returns structured data for the select dropdown
  // the label is the country name and a language in brackets if there is a sub-locale
  // structure example: { value: 'en-US', label: 'United States (English)', geoData: ... }
  const getRegionLabel = (region: string) => {
    const countryCode = getSendCountryCodeFromLocale(region);
    const countryLabel = countryNames.of(countryCode.toUpperCase());
    const languageCode = region.split('-')[0];
    const languageLabel =
      occurencyBySendCountry && occurencyBySendCountry[countryCode] > 1
        ? ` (${toTitleCase(
            getLanguageNames(region).of(languageCode) as string,
          )})`
        : '';

    return `${countryLabel}${languageLabel}`;
  };

  //helper data
  const getGeoData = (region: string) => ({
    location: region.split('-')[1] || region.split('-')[0],
    language: region.includes('-') ? region.split('-')[0] : null,
  });

  const regionDropDownItems = regions
    .filter(region => region !== DEFAULT_LANGUAGE)
    .map(region => ({
      value: region,
      label: getRegionLabel(region),
      geoData: getGeoData(region),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const defaultOption = {
    value: DEFAULT_LANGUAGE,
    label: messages?.international,
    geoData: {
      location: DEFAULT_LOCATION,
      language: DEFAULT_LANGUAGE,
    },
  };

  const fullGeoDropDownOptions = [defaultOption, ...regionDropDownItems];

  const updateRegion = (locale: string) => {
    const geoDataFromLocale = fullGeoDropDownOptions.find(
      item => item.value === locale,
    )?.geoData;
    if (region === locale || !geoDataFromLocale) return;
    setCountryFromCookie(geoDataFromLocale?.location?.toUpperCase() || '');
    if (geoDataFromLocale?.language) {
      setLanguageToCookie(geoDataFromLocale?.language);
    }

    if (region && region !== locale) {
      if (window && window.OneTrust) {
        window.OneTrust.changeLanguage(region);
      }
    }
    router.push(router.asPath, router.asPath, { locale: locale });
  };

  const onRegionChange = (selectedRegion: string) => {
    setRegion(selectedRegion);
    updateRegion(selectedRegion);
  };

  return {
    region,
    onRegionChange,
    fullGeoDropDownOptions,
  };
};
