import { Typography } from '@mui/material';
import { colors, Container, LineChart } from '@wr/web-ui';
import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@/components/button';
import { useCalculator } from '@/hooks';
import {
  CurrencyPair,
  DateRange,
} from '@/lib/rest/currency-converter/historical-rates';
import { interpolate } from '@/utils';

import { useCexHistoricalRates } from './cex-line-chart.hook';
import { useStyles } from './cex-line-chart.styles';
import { CexLineChartProps, FilterOption } from './cex-line-chart.types';

export const CexLineChart: React.FC<CexLineChartProps> = ({
  sys,
  name,
  titleTemplate,
  chartFilterOptions,
}) => {
  const classes = useStyles();
  const {
    sendCountry,
    receiveCountry,
    selectedPayoutMethodId,
  } = useCalculator();

  const [selectedFilter, setSelectedFilter] = useState(
    chartFilterOptions?.items.find(option => option?.setDefault)?.filterOption,
  );

  const currencyPair: CurrencyPair = useMemo(
    () => [sendCountry?.currency || '', receiveCountry?.currency || ''],
    [sendCountry?.currency, receiveCountry?.currency],
  );

  const {
    historicalRates,
    error: historicalRatesError,
  } = useCexHistoricalRates(
    currencyPair,
    selectedPayoutMethodId,
    selectedFilter as DateRange,
  );

  /**
   * Interpolate the title template to include selected currencies
   */
  const title = useMemo(() => {
    const values = {
      sendCurrency: sendCountry?.currencyName ?? '',
      receiveCurrency: receiveCountry?.currencyName ?? '',
    };
    return {
      text: interpolate(titleTemplate, values),
      html: interpolate(titleTemplate, values, value => `<em>${value}</em>`),
    };
  }, [receiveCountry?.currencyName, sendCountry?.currencyName, titleTemplate]);

  /**
   * Parse the data into a format expected by the chart
   */
  const dataset = useMemo(() => {
    // first sort the data
    const sortedData = [...historicalRates].sort((a, b) => {
      const aDate = new Date(a.date).getTime();
      const bDate = new Date(b.date).getTime();
      return aDate - bDate;
    });
    // Then parse the data
    return sortedData.reduce(
      (entries: { labels: string[]; datasets: number[] }, entry) => {
        return {
          ...entries,
          labels: [...entries.labels, `${entry.date}`],
          datasets: [...entries.datasets, entry.customerRate],
        };
      },
      { labels: [], datasets: [] },
    );
  }, [historicalRates]);

  /**
   * Handle filtering
   */
  const onSelectFilter = useCallback((option: FilterOption) => {
    setSelectedFilter(option.filterOption);
  }, []);

  if (!historicalRates?.length || historicalRatesError) {
    return null;
  }

  return (
    <Container
      id={name || ''}
      component="section"
      className={classes.section}
      data-testid={name}
    >
      <div className={classes.chartWrapper}>
        <Typography
          variant="h2"
          title={title.text}
          dangerouslySetInnerHTML={{ __html: title.html }}
          className={classes.title}
        />

        <div className={classes.filterWrapper}>
          {chartFilterOptions?.items.map(option => {
            return (
              <Button
                key={option.name}
                className={`${classes.filterOption} ${
                  option.filterOption === selectedFilter ? 'selected' : ''
                }`}
                onClick={() => onSelectFilter(option)}
              >
                {option.label}
              </Button>
            );
          })}
        </div>

        <LineChart
          lineType="curved"
          yAxisPosition="right"
          id={`cex-line-chart-${sys.id}`}
          className={classes.lineChart}
          labels={dataset.labels}
          datasets={[
            {
              data: dataset.datasets,
              color: colors.purpleNormal,
            },
          ]}
        />
      </div>
    </Container>
  );
};
