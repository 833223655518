import { Grid, Typography } from '@mui/material';
import React from 'react';

import { Container as UIContainer } from '../container';
import { Image } from '../image';
import useStyles from './icon-grid.styles';
import { IconGridProps } from './icon-grid.types';

export function IconGrid({
  name,
  header,
  iconGridItems,
  backgroundColor = 'transparent',
  secondBackgroundColor,
  containerProps,
}: IconGridProps): React.ReactElement | null {
  const classes = useStyles({ backgroundColor, secondBackgroundColor });

  return (
    <section
      id={name}
      data-testid={'icon-grid-section'}
      className={classes.section}
      {...containerProps}
    >
      <UIContainer>
        {header && (
          <Typography
            component="p"
            variant="h2"
            color="primary.contrastText"
            className={classes.heading}
            data-testid="icon-grid-heading"
          >
            {header}
          </Typography>
        )}
        <Grid
          data-testid="icon-grid-container"
          container
          justifyContent="center"
          spacing={10}
          className={classes.iconGrid}
        >
          {iconGridItems.map(iconGridItem => {
            return (
              <Grid
                key={iconGridItem.name}
                data-testid="icon-grid-item"
                item
                xs={12}
                md={4}
                {...iconGridItem?.containerProps}
              >
                {iconGridItem.image && (
                  <Image
                    {...iconGridItem.image}
                    className={classes.itemImage}
                  />
                )}
                <Typography
                  component="p"
                  variant="h3"
                  color="primary.contrastText"
                  className={classes.itemHeading}
                  data-testid="icon-grid-item-heading"
                >
                  {iconGridItem.header}
                </Typography>
                <Typography
                  color="primary.contrastText"
                  variant="body1"
                  data-testid="icon-grid-item-description"
                >
                  {iconGridItem.description}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </UIContainer>
    </section>
  );
}
