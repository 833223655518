import { Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { useStyles } from './cex-currency-code.styles';
import { CexCurrencyCodeProps } from './cex-currency-code.types';

export const CexCurrencyCode: React.FC<CexCurrencyCodeProps> = ({
  currencyCode,
  sendCurrencyCode,
}) => {
  const classes = useStyles();

  const content = useMemo(() => {
    if (!sendCurrencyCode) return currencyCode;
    return `${sendCurrencyCode} to ${currencyCode}`;
  }, [currencyCode, sendCurrencyCode]);

  return (
    <Typography
      variant="body2"
      data-testid="cex-currency-code"
      className={classes.currencyCode}
    >
      {content}
    </Typography>
  );
};
