import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListIcon from '@mui/icons-material/List';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Container, Image, Search } from '@wr/web-ui';
import React, { useState } from 'react';

import { Anchor } from '@/components';
import { useResultList } from '@/utils/search-dialog';

import { Button } from '../button';
import { useStyles } from './faq-hero.styles';
import { FaqHeroProps, LinksComponentProps } from './faq-hero.types';

const LinksComponent: React.FC<LinksComponentProps> = props => {
  const { classes, links = [] } = props;
  return (
    <>
      {links.map(link => (
        <Anchor
          url={link.url}
          key={link.sys.id}
          data-testid={`faq-hero-link-${link.sys.id}`}
          underline="none"
        >
          <Tab
            key={link.sys.id}
            classes={{ root: classes.linkLabelContainer }}
            label={
              <>
                <Image {...link.image} className={classes.linkImage} />
                {!link?.image?.url && (
                  <div className="link-image-placeholder" />
                )}
                <Typography className={classes.linkLabel} component="span">
                  {link.label}
                </Typography>
              </>
            }
            data-testid={`tab-${link.sys.id}`}
          />
        </Anchor>
      ))}
    </>
  );
};

export const FaqHero: React.FC<FaqHeroProps> = props => {
  const classes = useStyles(props);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { heading, heroImage, search, links, name, menuTitle } = props;
  const { resultList, setResultList, loadList } = useResultList();

  return (
    <section className={classes.section} data-testid={`faq-hero-${name}`}>
      <Container
        className={`${classes.sectionContainer} ${classes.sectionPadding}`}
      >
        <Typography variant="h1" className={classes.heroHeading}>
          {heading}
        </Typography>

        <Image {...heroImage} className={classes.heroImage} />

        {!!search && (
          <div className={classes.heroSearchBarWrapper}>
            <Search
              options={resultList}
              onInputChange={loadList}
              placeholder={search.inputLabel || ''}
              resultListLabel={search.resultListLabel}
              id={`${search.name}-autocomplete`}
              className={classes.heroSearchBar}
              filterOptions={setResultList}
              LinkComponent={Anchor}
            />
          </div>
        )}
      </Container>

      <Container
        className={`${classes.linksContainer} ${classes.sectionPadding}`}
      >
        <div className={classes.mobileOnly}>
          <Button
            className={classes.menuToggleBtn}
            onClick={() => setIsDialogOpen(true)}
          >
            <ListIcon />
            <Typography component="span">{menuTitle}</Typography>
            <KeyboardArrowDownIcon />
          </Button>

          <Dialog className={classes.dialog} open={isDialogOpen}>
            <DialogTitle>
              <button onClick={() => setIsDialogOpen(false)}>
                <CloseIcon />
              </button>
            </DialogTitle>
            <DialogContent>
              <LinksComponent classes={classes} links={links.items} />
            </DialogContent>
          </Dialog>
        </div>
        <div className={classes.desktopOnly}>
          <Tabs className={classes.tabsWrapper} value={0}>
            <LinksComponent classes={classes} links={links.items} />
          </Tabs>
        </div>
      </Container>
    </section>
  );
};
