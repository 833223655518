/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ApolloClient,
  createBFFClient,
  DEFAULT_LANGUAGE,
} from '@wr/web-shared';
import { v4 as uuidv4 } from 'uuid';

import { logger } from '@/utils';

const clientList: Record<string, ApolloClient> = {};

declare const global: {
  bffclientList: Record<string, ApolloClient>;
};
global.bffclientList = {};

function getApolloClientCacheSize(apolloClient: ApolloClient) {
  const cache = apolloClient.extract();
  const jsonString = JSON.stringify(cache);
  const sizeInBytes = new Blob([jsonString]).size;
  return sizeInBytes;
}

export function getApolloCacheSizeClientsWithoutToken() {
  let cacheSize = 0;
  Object.values(global.bffclientList).forEach(client => {
    cacheSize += getApolloClientCacheSize(client);
  });
  return cacheSize;
}

export const createServerSideBFFClientWithToken = async ({
  locale,
  token,
}: {
  locale?: string;
  token?: string;
}): Promise<ApolloClient> => {
  const client = createBFFClient({
    url: process.env.INTERNAL_BFF_GQL_URI!,
    logger,
    headers: {
      'Accept-Language': locale || DEFAULT_LANGUAGE,
      'X-WR-Platform': 'Web',
      'X-WR-RequestID': uuidv4(),
      ...(token ? { Authorization: token } : {}),
    },
  });
  client.clearStore();

  return client;
};

export const createServerSideBFFClient = async ({
  locale,
}: {
  locale?: string;
}): Promise<ApolloClient> => {
  if (locale && !global.bffclientList[locale]) {
    global.bffclientList[locale] = createBFFClient({
      url: process.env.INTERNAL_BFF_GQL_URI!,
      logger,
      headers: {
        'Accept-Language': locale || DEFAULT_LANGUAGE,
        'X-WR-Platform': 'Web',
        'X-WR-RequestID': uuidv4(),
      },
    });
    global.bffclientList[locale].clearStore();
  }

  return global.bffclientList[locale || DEFAULT_LANGUAGE];
};

export const createClientSideBFFClient = ({
  locale,
}: {
  locale?: string;
}): ApolloClient => {
  if (locale && !clientList[locale]) {
    clientList[locale] = createBFFClient({
      url: process.env.NEXT_PUBLIC_BFF_GQL_URI!,
      logger,
      headers: {
        'Accept-Language': locale || DEFAULT_LANGUAGE,
        'X-WR-Platform': 'Web',
        'X-WR-RequestID': uuidv4(),
      },
    });
  }
  return clientList[locale || DEFAULT_LANGUAGE];
};
