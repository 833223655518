import { Box, Button, Typography } from '@mui/material';
import {
  Container,
  InformationDialog as UIInformationDialog,
  isValidEmail,
} from '@wr/web-ui';
import React, { useMemo, useState } from 'react';

import { RichTextContentRenderer } from '@/components/rich-text-content-renderer';
import { interpolate } from '@/utils';

import { useRateAlertFields } from './cex-rate-alerts.hooks';
import { useStyles } from './cex-rate-alerts.styles';
import { CexRateAlertsProps } from './cex-rate-alerts.types';
import { CexRateAlertsFields } from './cex-rate-alerts-fields';

export const CexRateAlerts = ({
  name,
  titleTemplate,
  description,
  message,
  buttonTitle,
  textFieldPlaceholder,
  receiveMethodLabel,
  informationDialog,
}: CexRateAlertsProps) => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  const {
    email,
    selectedSendCountry,
    selectedReceiveCountry,
    selectedPayoutMethod,
  } = useRateAlertFields();

  const onClose = (): void => {
    setShowDialog(false);
  };

  const handleSubmit = () => {
    setShowDialog(true);
  };

  const title = useMemo(() => {
    return interpolate(
      titleTemplate,
      {
        sendCurrency: selectedSendCountry?.currencyName ?? '',
        receiveCurrency: selectedReceiveCountry?.currencyName ?? '',
      },
      value => `<em>${value}</em>`,
    );
  }, [
    selectedSendCountry?.currencyName,
    selectedReceiveCountry?.currencyName,
    titleTemplate,
  ]);

  return (
    <Container
      component="section"
      data-testid="cex-rate-alerts"
      className={classes.section}
      id={name || ''}
    >
      <Box className={classes.container}>
        <Typography
          variant="h2"
          dangerouslySetInnerHTML={{ __html: title }}
          className={classes.title}
          data-testid="title"
        />
        <Typography
          data-testid="description"
          className={classes.description}
          variant="body2"
          paragraph
        >
          {description}
        </Typography>
        <CexRateAlertsFields
          textFieldPlaceholder={textFieldPlaceholder}
          receiveMethodLabel={receiveMethodLabel}
        />
        <Typography
          data-testid="message"
          className={classes.message}
          variant="body2"
          paragraph
        >
          {message}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          data-testid="cta-button"
          className={classes.ctaButton}
          disabled={!email || !selectedPayoutMethod || !isValidEmail(email)}
          onClick={handleSubmit}
          size="medium"
        >
          {buttonTitle}
        </Button>
      </Box>
      {showDialog && (
        <UIInformationDialog
          isOpen={showDialog}
          onClose={onClose}
          RichTextComponent={RichTextContentRenderer}
          title={interpolate(informationDialog.title, { email })}
          name={informationDialog.name}
          content={informationDialog.content}
        />
      )}
    </Container>
  );
};
