import clsx from 'clsx';
import React from 'react';

import useStyles from './table.styles';
import { TableProps } from './table.types';

export const Table: React.FC<TableProps> = ({
  testId,
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <table className={clsx(classes.table, className)} data-testid={testId}>
      {children}
    </table>
  );
};
