import { ElementType, ReactNode } from 'react';

import * as components from '@/components';
import { PageProps } from '@/types';
import { ModuleItem } from '@/utils';

import { Module } from './contentful';

export const resolveComponents = (
  modules: Record<string, unknown>[],
  propsOverrides?: Record<string, Record<string, unknown>>,
  header?: PageProps['header'],
): ReactNode[] =>
  modules.map((module, index) => {
    const [moduleName, moduleData] = Object.entries(module)[0] || [];

    const Component = (components as Record<string, unknown>)[
      moduleName
    ] as ElementType;
    const overridenProps = {
      ...(moduleData as Record<string, unknown>),
      ...(propsOverrides?.[moduleName] || {}),
    };

    if (Component) {
      return (
        <Component
          key={(moduleData as Module)?.sys?.id}
          {...overridenProps}
          isSectionOnTop={index === 0 && header?.headerPosition === 'absolute'}
        />
      );
    } else {
      return null;
    }
  });

export const hasModule = (modules: ModuleItem[], moduleId: string): boolean =>
  modules.some(module => Object.keys(module).includes(moduleId));
