import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

import { ButtonsProps } from './buttons.types';

export const useStyles = makeStyles<Theme, Partial<ButtonsProps>>(
  ({ spacing }) => ({
    button: ({ isLite }) => ({
      display: 'block',
      marginTop: spacing(isLite ? 3 : 2),
    }),
    cancelButton: {
      color: colors.suvaGrey,
      fontWeight: 700,
      marginTop: spacing(1),
      display: 'block',
      textAlign: 'center',
    },
  }),
);
