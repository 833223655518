import React from 'react';

interface UserAccountIconProps {
  className?: string;
}

export const UserAccountIcon: React.FC<UserAccountIconProps> = ({
  className,
}) => (
  <svg className={className} width="20" height="23" viewBox="0 0 20 23">
    <defs>
      <path
        id="nmc66h1wta"
        d="M300 30c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5zm-5 7c5.523 0 10 4.477 10 10 0 .552-.448 1-1 1s-1-.448-1-1c0-.087.011-.172.032-.253-.132-4.322-3.677-7.785-8.032-7.785s-7.9 3.463-8.034 7.786c.023.08.034.165.034.252 0 .552-.448 1-1 1s-1-.448-1-1l.004-.28C285.152 41.327 289.57 37 295 37zm0-10c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
      />
    </defs>
    <g fill="inherit" fillRule="evenodd">
      <g transform="translate(-285 -25)">
        <use href="#nmc66h1wta" />
      </g>
    </g>
  </svg>
);
