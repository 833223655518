import { IconGrid as UIIconGrid } from '@wr/web-ui';
import React from 'react';

import { IconGridProps } from '@/components/icon-grid/icon-grid.types';
import { useIntlMessages } from '@/hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

export const IconGrid: React.FC<IconGridProps> = ({
  name,
  sys,
  __typename,
  header,
  reasons,
  backgroundColor,
  secondBackgroundColor,
}) => {
  const intlMessages = useIntlMessages();

  return (
    <UIIconGrid
      name={name || ''}
      header={header || ''}
      backgroundColor={backgroundColor || ''}
      secondBackgroundColor={secondBackgroundColor || undefined}
      iconGridItems={
        reasons?.items.map(item => ({
          ...item,
          description: interpolate(item?.description, intlMessages),
          name: item?.name || '',
          header: interpolate(item?.header, intlMessages),
          image: item?.image || null,
          containerProps: buildContentfulSidekickAttributes(
            item.sys.id,
            item.__typename,
            item?.name,
          ),
        })) || []
      }
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
