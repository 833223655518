import { BLOCKS } from '@contentful/rich-text-types';
import { contentfulSchema } from '@wr/web-shared';
import { Regulator } from '@wr/web-ui';

import { RichTextProps } from '@/components/rich-text-content-renderer';

export const getRegulators = (
  regulators: contentfulSchema.WeAreRegulatedFragment['regulatorsCollection'],
): Regulator<RichTextProps>[] =>
  regulators?.items
    .filter(regulator => regulator?.countriesListCollection?.items.length)
    .map(regulator => {
      return {
        id: regulator?.sys.id || '',
        name:
          regulator?.customRegulatorName ||
          regulator?.countriesListCollection?.items?.[0]?.name ||
          '',
        code:
          regulator?.customFlagCode ||
          regulator?.countriesListCollection?.items?.[0]?.code ||
          '',
        customFlagCode: regulator?.customFlagCode || null,
        content: regulator?.content?.json
          ? {
              ...regulator.content,
              options: {
                [BLOCKS.HEADING_3]: {
                  color: 'inherit',
                  variant: 'body1',
                },
                [BLOCKS.PARAGRAPH]: {
                  variant: 'body2',
                  color: 'inherit',
                  paragraph: false,
                },
              },
            }
          : null,
        countriesList:
          (regulator?.countriesListCollection?.items.map(
            i => i?.code,
          ) as string[]) || [],
      };
    }) || [];
