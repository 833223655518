import { BLOCKS } from '@contentful/rich-text-types';
import { contentfulSchema } from '@wr/web-shared';
import {
  TextSection as UITextSection,
  useImageForBreakpoint,
} from '@wr/web-ui';
import React from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { Asset } from '@/components/text-section/text-section.type';
import { buildContentfulSidekickAttributes } from '@/utils';

export function TextSection({
  sys,
  __typename,
  name,
  content,
  imageCollection,
  isOrderReversed,
  maxWidth,
  backgroundColor,
}: contentfulSchema.TextSectionFragment) {
  const image = useImageForBreakpoint(
    imageCollection?.items[0] as Asset,
    imageCollection?.items[1] as Asset,
    imageCollection?.items[2] as Asset,
  );

  return (
    <UITextSection<RichTextProps | null>
      name={name || undefined}
      image={
        image
          ? {
              ...image,
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'center',
              },
            }
          : null
      }
      content={
        content?.json
          ? ({
              ...content,
              options: {
                [BLOCKS.PARAGRAPH]: {
                  paragraph: true,
                  variant: 'body2',
                },
                [BLOCKS.LIST_ITEM]: {
                  variant: 'body2',
                },
              },
            } as RichTextProps)
          : null
      }
      maxWidth={maxWidth || null}
      backgroundColor={backgroundColor || null}
      isOrderReversed={isOrderReversed || null}
      RichTextComponent={RichTextContentRenderer}
      containerProps={buildContentfulSidekickAttributes(
        sys?.id,
        __typename,
        name,
      )}
    />
  );
}
