import { FilterOptionsState } from '@mui/material';

import { CountryOption } from './country-search.types';

export const replaceAccentCharactersWithStandard = (text: string): string =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const normalizeStr = (str: string): string =>
  replaceAccentCharactersWithStandard(str).trim().toLowerCase();

export const filterOptions = (
  options: CountryOption[],
  { inputValue }: FilterOptionsState<CountryOption>,
): CountryOption[] => {
  const normalizedInputValue = normalizeStr(inputValue);
  return options.filter(
    option =>
      normalizeStr(option.name).includes(normalizedInputValue) ||
      normalizeStr(option.currency).includes(normalizedInputValue),
  );
};
