import { useState } from 'react';

import { InteractiveTable } from '@/components/interactive-table';

import { CurrencyController } from '../currency-controller/currency-controller.component';
import { InteractiveMap } from '../interactive-map/interactive-map.component';
import { useStyles } from './back-to-school.styles';
import { BackToSchoolProps, SelectedCurrency } from './back-to-school.types';

export const BackToSchool: React.FC<BackToSchoolProps> = props => {
  const { countriesCollection } = props;
  const classNames = useStyles();
  const defaultCurrency = countriesCollection?.items?.find(
    country => country?.currency === 'USD',
  );
  const [selectedCurrency, setSelectedCurrency] = useState<SelectedCurrency>({
    currency: defaultCurrency?.currency || '',
    currencySymbol: defaultCurrency?.currencySymbol || '',
    exchangeRate: defaultCurrency?.exchangeRate || 1,
  });

  return (
    <section id="bts-container" className={classNames.backToSchoolContainer}>
      <section className={classNames.w100}>
        <CurrencyController
          {...props}
          handleCurrencyChange={setSelectedCurrency}
        />
        <section className={classNames.sectionContainer}>
          <InteractiveMap {...props} selectedCurrency={selectedCurrency} />
        </section>
        <section className={classNames.sectionContainer}>
          <InteractiveTable {...props} selectedCurrency={selectedCurrency} />
        </section>
      </section>
    </section>
  );
};
