import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { SelectOption } from '@wr/web-ui';
import clsx from 'clsx';
import React from 'react';

import { Dropdown } from '../dropdown';
import { useSelectToggles } from './select.hooks';
import useStyles from './select.styles';
import { CalculatorSelectProps } from './select.types';

export function Select<Option>({
  'aria-labelledby': ariaLabelledBy,
  selectFieldContainerId,
  selectOptionName,
  title,
  placeholder = '',
  onInputClick,
  onSelect,
  value,
  options,
  containerRef,
  disabled,
  optionRenderer,
}: CalculatorSelectProps<Option>): React.ReactElement {
  const classes = useStyles();
  const {
    anchorEl,
    handleClose,
    handleClick,
    handleKeyDown,
    handleDropdownKeyboardNavigation,
    isOpen,
    fieldRef,
  } = useSelectToggles();

  return (
    <>
      <Box
        data-testid={selectFieldContainerId}
        className={clsx({
          [classes.disabled]: disabled,
        })}
      >
        <TextField
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-controls={
            isOpen ? `${selectOptionName}-dropdown-list` : undefined
          }
          variant="outlined"
          ref={fieldRef}
          value={
            options.find(option => option.value === value)?.label || placeholder
          }
          onClick={() => {
            if (!disabled) {
              onInputClick?.();
              handleClick(fieldRef?.current);
            }
          }}
          onKeyDown={handleKeyDown}
          InputProps={{
            inputProps: {
              'aria-labelledby': ariaLabelledBy,
            },
            readOnly: true,
            classes: {
              input: classes.input,
              inputAdornedEnd: clsx({ [classes.inputDisabled]: disabled }),
              notchedOutline: classes.notchedOutline,
              root: classes.root,
            },
            endAdornment: (
              <InputAdornment position="end">
                <ExpandMoreRoundedIcon
                  fontSize="large"
                  color="secondary"
                  data-testid="payout-select-input-img"
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Dropdown
        onClose={handleClose}
        anchorEl={anchorEl}
        isOpen={isOpen}
        width={containerRef?.current?.offsetWidth}
      >
        <List
          className={classes.selectContentWrapper}
          id={`${selectOptionName}-dropdown-list`}
        >
          {title && (
            <Typography variant="h6" component="div" className={classes.title}>
              {title}
            </Typography>
          )}
          {options &&
            options.map((option: SelectOption & Option) => {
              const selected = value === option.value;

              return (
                <ListItem
                  component={Box}
                  key={option.value}
                  onClick={() => {
                    onSelect?.(option.value);
                    handleClose();
                  }}
                  role="option"
                  aria-selected={selected}
                  data-testid={`${option.value}-${selectOptionName}-item`}
                  mb={1}
                  py={1.5}
                  px={1}
                  className={clsx(classes.option, {
                    [classes.selected]: selected,
                  })}
                  tabIndex={0}
                  onKeyDown={handleDropdownKeyboardNavigation(option, onSelect)}
                >
                  {optionRenderer?.(option) || (
                    <Typography
                      variant="body1"
                      color="primary"
                      data-testid={`${selectOptionName}-select-option`}
                    >
                      <strong>{option.label}</strong>
                    </Typography>
                  )}
                </ListItem>
              );
            })}
        </List>
      </Dropdown>
    </>
  );
}
