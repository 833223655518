import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, customComponents }) => ({
    table: {
      'textAlign': 'left',
      'borderSpacing': 'inherit',
      'marginLeft': '-1.5rem',
      'width': `calc(100% + 3rem)`,
      'color': customComponents.table.color,
      'tableLayout': 'fixed',

      [breakpoints.up('sm')]: {
        marginLeft: 0,
        width: '100%',
        tableLayout: 'auto',
      },

      '& tbody tr:nth-of-type(odd)': {
        background: customComponents.table.tbody.backgroundColor,
      },

      '& th': {
        fontWeight: 400,
        fontSize: '1rem',

        [breakpoints.up('sm')]: {
          textAlign: 'left',
        },
      },
      '& th, td': {
        padding: spacing(2.125, 0, 2.125, 2.5),

        [breakpoints.up('sm')]: {
          padding: spacing(2.125, 3.375),
        },
      },
    },
  }),
);

export default useStyles;
