import { NextRouter } from 'next/router';

import { AppContextType } from '@/context';

import { ComparisonTableProps } from './comparison-table.types';

export const getLocalisedRows = (
  rows: ComparisonTableProps['rowsCollection']['items'],
  corridorPayoutMethodsIds: AppContextType['corridorPayoutMethodsIds'],
) => {
  return rows.map(row => {
    return corridorPayoutMethodsIds?.reduce((acc, payoutMethodId) => {
      if (row && payoutMethodId in row) {
        // We are getting few items as generic entry always passes query filter
        const cell = Array.from(row[payoutMethodId]?.items || []).sort(item =>
          item?.receiveCountryList !== null ? -1 : 1,
        )[0];

        acc = {
          ...acc,
          [payoutMethodId]: cell,
        };
      }
      return {
        name: row?.name || '',
        image: {
          image: row?.image,
        },
        ...acc,
      };
    }, {});
  });
};

export const formatComparisonTableDate = (
  publishedAt: ComparisonTableProps['sys']['publishedAt'],
  locale: NextRouter['locale'],
) => {
  if (!publishedAt) return '';
  const date = new Date(publishedAt);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const month = date.toLocaleString(locale, { month: 'long' });
  const year = date.getFullYear().toString();

  return `${hours}:${minutes} ${month} ${year}`;
};
