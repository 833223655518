import { Theme, Typography, useMediaQuery } from '@mui/material';
import {
  Container,
  HiddenWithCSS,
  Image,
  QrCode as UIQrCode,
} from '@wr/web-ui';
import React from 'react';

import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor } from '../anchor';
import { Button } from '../button';
import useStyles from './hero-slot.styles';
import { HeroSlotProps } from './hero-slot.types';
import { getLinkForCurrentOS } from './hero-slot.utils';

const QR_CODE_ID = 'hero-qr-code';

export const HeroSlot: React.FC<HeroSlotProps> = ({
  sys,
  __typename,
  name,
  header,
  subheader,
  buttonTitle,
  loginInText,
  loginInLinkTitle,
  image,
  qrCode,
  qrCodeLabel,
  appStoreLink,
  googlePlayStoreLink,
  loginPageLink,
  downloadAppsPageLink,
  isSectionOnTop,
}) => {
  const classes = useStyles({ isSectionOnTop });
  const isLGUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const buttonLink = getLinkForCurrentOS(
    googlePlayStoreLink,
    appStoreLink,
    downloadAppsPageLink,
  );
  return (
    <section
      id={name || undefined}
      className={classes.section}
      {...buildContentfulSidekickAttributes(sys.id, __typename, name)}
    >
      <Container>
        <div className={classes.container}>
          <div className={classes.leftColumn}>
            <div className={classes.leftColumnContainer}>
              <Typography
                className={classes.header}
                variant="h3"
                component="h1"
                color="primary.contrastText"
              >
                {header}
              </Typography>
              <Typography
                className={classes.subheader}
                color="primary.contrastText"
                variant="body1"
                component="h3"
              >
                {subheader}
              </Typography>
              <HiddenWithCSS mdDown>
                <div className={classes.textMeAppContainer}>
                  {qrCodeLabel && (
                    <Typography
                      className={classes.mb2}
                      color="primary.contrastText"
                      variant="h5"
                    >
                      {qrCodeLabel}
                    </Typography>
                  )}
                  {qrCode && (
                    <>
                      <UIQrCode
                        url={qrCode.url}
                        width={qrCode.width}
                        height={qrCode.height}
                        title={qrCode.title}
                        alt={qrCode.alt}
                        description=""
                        classes={{
                          qrCodeImageContainer: classes.imageContainer,
                        }}
                        elementId={QR_CODE_ID}
                      />
                    </>
                  )}
                </div>
              </HiddenWithCSS>
            </div>
          </div>
          <div className={classes.rightColumn}>
            {image && (
              <Image
                {...image}
                fill={!isLGUp}
                style={
                  isLGUp
                    ? {}
                    : {
                        objectFit: 'cover',
                        objectPosition: 'top left',
                      }
                }
              />
            )}
          </div>
        </div>
        <HiddenWithCSS lgUp>
          <Button
            {...buttonLink}
            className={classes.mb2}
            variant="contained"
            color="secondary"
            fullWidth
          >
            {buttonTitle || ''}
          </Button>
          <Typography
            variant="body1"
            color="primary.contrastText"
            align="center"
          >
            <strong>{loginInText}</strong>
            <Anchor
              className={classes.link}
              {...loginPageLink}
              color="secondary"
            >
              {loginInLinkTitle}
            </Anchor>
          </Typography>
        </HiddenWithCSS>
      </Container>
    </section>
  );
};
