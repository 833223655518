import { bffSchema, COOKIES } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import {
  CALCULATOR_QUERY_PARAMS,
  DEFAULT_LOCATION,
  GENERIC_CLP_SLUG,
} from '@/constants';
import { getCountryPagesSlugs } from '@/services';
import { getContentfulCountries } from '@/services/contentful/country/country.service';
import {
  getCalculatorReceiveCountryCodeBySendCountryCode,
  getReceiveCountryCodeBySlug,
  isCountryCodeValid,
} from '@/services/contentful/country/country.utils';
import { getSingleValueFromQueryParam } from '@/utils';
import { getSendCountryCodeFromLocale } from '@/utils/intl';

/** Returns query variables: page type, slug and corridor countries
 * to fetch correct Page and any associated data with that corridor
 *
 * /en - slug = [], returns root home page
 * /en/gb - slug = [gb], returns localized home page, if doesn't exist redirects to root
 * /en/philippines - slug = [philippines] - check if slug is matches slugified country name and has unique page, returns it
 * /en/poland - slug = [poland] - as above, has no unique page, returns generic country page
 * /en/faq/foo - slug = [faq, foo] returns any non home/country/error page by slug, otherwise returns 404
 */
export const getContentfulPageQueryVariables = async ({
  region,
  query,
  cookies,
  contentfulCountries,
  sendCountriesSorted,
  resolvedUrl,
}: Pick<GetServerSidePropsContext, 'query' | 'resolvedUrl'> &
  Pick<GetServerSidePropsContext['req'], 'cookies'> & {
    region: string;
    contentfulCountries: Awaited<ReturnType<typeof getContentfulCountries>>;
  } & bffSchema.GetSendCountriesQuery) => {
  const localizationCountry = getSendCountryCodeFromLocale(region);

  const sendCountryCode =
    localizationCountry ||
    (isCountryCodeValid(
      sendCountriesSorted.map(country => country.code as string),
      cookies?.[COOKIES.LOCATION_COUNTRY],
    ) &&
      cookies?.[COOKIES.LOCATION_COUNTRY]) ||
    (isCountryCodeValid(
      sendCountriesSorted.map(country => country.code as string),
      cookies?.[COOKIES.SELECT_FROM],
    ) &&
      cookies?.[COOKIES.SELECT_FROM]) ||
    DEFAULT_LOCATION;

  const receiveCountryCode =
    getSingleValueFromQueryParam(query?.[CALCULATOR_QUERY_PARAMS.SELECT_TO]) ||
    cookies?.[COOKIES.SELECT_TO] ||
    getCalculatorReceiveCountryCodeBySendCountryCode(
      contentfulCountries,
      sendCountryCode,
    ) ||
    'ph';

  const [currentUrl] = resolvedUrl.split('?');
  const resolvedSlug = currentUrl
    .replace('/', '')
    .split('/')
    .filter(param => !!param);

  // If no slug is resolved return Home Page
  if (!resolvedSlug.length) {
    return {
      resolvedSlug: null,
      sendCountryCode: sendCountryCode.toLowerCase(),
      receiveCountryCode: receiveCountryCode.toLowerCase(),
    };
  }

  const countryCode =
    resolvedSlug.length === 1
      ? getReceiveCountryCodeBySlug(contentfulCountries, resolvedSlug[0])
      : null;

  if (countryCode) {
    // Get all possible localized country pages slugs
    const countryPagesSlugs = await getCountryPagesSlugs({
      region,
    });

    // Render generic country page in case there is no localized page for slug
    const isGenericPage = !countryPagesSlugs?.includes(resolvedSlug[0]);

    return {
      resolvedSlug: isGenericPage ? GENERIC_CLP_SLUG : resolvedSlug[0],
      sendCountryCode: sendCountryCode.toLowerCase(),
      receiveCountryCode: countryCode.toLowerCase(),
    };
  }

  return {
    resolvedSlug: resolvedSlug.join('/'),
    sendCountryCode: sendCountryCode.toLowerCase(),
    receiveCountryCode: receiveCountryCode.toLowerCase(),
  };
};
