import { EVENT, pushToDataLayer } from '@wr/web-shared';

export const pushToDataLayerWithDropdown = (
  dropdownName: string,
  interactionName: string,
  dropdownValue?: string,
) => {
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    dropdownName,
    interactionName,
    dropdownValue: dropdownValue?.toLowerCase(),
  });
};
