import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const greenDarkColor = {
  color: colors.greenDark,
};

const blackColor = {
  color: colors.black,
};

const boldFontWeight = {
  fontWeight: 700,
};

export const useStyles = makeStyles<Theme>({
  transferTime: {
    ...greenDarkColor,
  },
  instantFee: {
    ...greenDarkColor,
    ...boldFontWeight,
  },
  fee: {
    ...blackColor,
  },
  feeAmount: {
    ...blackColor,
    ...boldFontWeight,
  },
});
