import { ApolloClient, bffSchema } from '@wr/web-shared';

import { ICountryData } from '@/components/calculator/src/types';
import { logger } from '@/utils';

export const getPayoutMethods = async ({
  sendCountry,
  receiveCountry,
  bffClient,
}: {
  sendCountry: ICountryData;
  receiveCountry: ICountryData;
} & { bffClient: ApolloClient }): Promise<
  NonNullable<bffSchema.PayoutMethodsQuery>['payOutMethods']
> => {
  try {
    const { data } = await bffClient.query<bffSchema.PayoutMethodsQuery>({
      query: bffSchema.PayoutMethods,
      variables: {
        sendCountry: sendCountry.countryCode,
        sendCurrency: sendCountry.currency,
        receiveCountry: receiveCountry.countryCode,
        receiveCurrency: receiveCountry.currency,
      },
    });

    return data.payOutMethods || [];
  } catch (error) {
    logger.error(error, `PayoutMethods query request failed`);

    try {
      const { data } = await bffClient.query<bffSchema.PayoutMethodsQuery>({
        query: bffSchema.PayoutMethods,
        variables: {
          sendCountry: sendCountry.countryCode,
          sendCurrency: sendCountry.currency,
          receiveCountry: receiveCountry.countryCode,
          receiveCurrency: receiveCountry.currency,
        },
        fetchPolicy: 'cache-only',
      });
      return data.payOutMethods || [];
    } catch (error) {
      return [];
    }
  }
};
