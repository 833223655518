import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { useSmartLink } from '@wr/web-ui';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { SMART_LINK_URL } from '@/constants';
import { useIntlMessages } from '@/hooks';
import { interpolate } from '@/utils';

import { getLinkHref, getRelAttribute } from '../link/link.utils';
import { AnchorProps } from './anchor.types';
// valid link variants
// link variants are inherited from typography https://mui.com/material-ui/api/link/
const TypographyVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
  'srOnly',
  'inherit',
];

export const Anchor: FC<AnchorProps> = ({
  children,
  id,
  'url': urlTxt,
  openInNewTab,
  color,
  align,
  underline,
  name,
  rel,
  className,
  classes,
  onClick,
  TypographyClasses,
  'data-testid': dataTestId,
  ariaLabel,
  isInternal = true,
  noFollow,
  variant,
}) => {
  const router = useRouter();
  const intlMessages = useIntlMessages();
  const smartLink = useSmartLink(urlTxt || '', SMART_LINK_URL);
  const url = interpolate(urlTxt, intlMessages, value => value.toLowerCase());
  const href = getLinkHref(url, router.locale);

  const props = {
    id,
    'rel': getRelAttribute(noFollow || false, openInNewTab || false, url, rel),
    'name': name || undefined,
    'aria-label': ariaLabel || undefined,
    className,
    classes,
    onClick,
    'variant': (variant as MuiLinkProps['variant']) || undefined,
    'target': openInNewTab ? '_blank' : '_self',
    'data-testid': dataTestId,
    TypographyClasses,
    'color': color || undefined,
    'align': (align as MuiLinkProps['align']) || undefined,
    'underline': (underline as MuiLinkProps['underline']) || undefined,
  };

  // we do this because the variant prop that is passed here is meant for buttons
  // but since it is also applied to the anchor, it throws errors, breaking formatting
  if (props.variant && !TypographyVariants.includes(props.variant)) {
    props.variant = 'inherit';
  }

  if (isInternal || isInternal === null) {
    return (
      <NextLink {...props} href={smartLink ?? href} passHref legacyBehavior>
        <MuiLink {...props}>{children}</MuiLink>
      </NextLink>
    );
  }

  return (
    <MuiLink {...props} href={smartLink ?? href}>
      {children}
    </MuiLink>
  );
};
