import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  currencySection: {
    backgroundColor: colors.purpleDark,
    color: colors.white,
  },
  currencyFlexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  currencyFlexBoxInner: {
    padding: '2px',
  },
  currencyButton: {
    padding: spacing(0.75, 1),
  },
  currencySelectBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '1px',
    marginBottom: '1px',
    [breakpoints.up('md')]: {
      flexWrap: 'no-wrap',
    },
  },
  currencyItemLabel: {
    'display': 'block',
    'padding': spacing(0.5, 1),
    'cursor': 'pointer',
    'transition': '.2s color,.2s background',
    'color': colors.white,
    'fontSize': '0.8rem',
    '&:hover': {
      backgroundColor: colors.white,
      opacity: 0.4,
      color: colors.purpleDark,
    },
  },
  currencyItemInput: {
    'position': 'absolute',
    'height': '0px',
    'width': '0px',
    'overflow': 'hidden',
    'clip': 'rect(1px, 1px, 1px, 1px)',
    'whiteSpace': 'nowrap',
    '&:checked+label': {
      backgroundColor: colors.white,
      color: colors.purpleDark,
    },
  },
}));

export default useStyles;
