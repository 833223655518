import ChevronRight from '@mui/icons-material/ChevronRight';
import { Grid, Typography } from '@mui/material';

import { CountryFlag } from '../../country-flag-image';
import useStyles from './country-item.styles';
import { CountryItemProps } from './country-item.types';

export function CountryItem<Button>({
  ButtonComponent,
  link,
  isCondensed,
}: CountryItemProps<Button>) {
  const classes = useStyles();

  // Made for SEO purpose for hidden links
  if (isCondensed) {
    return (
      <Grid component="div" key={link.code} item xs={12} sm={6} md={4}>
        <ButtonComponent data-testid={`${link.name}-button`} {...link}>
          {link.name}
        </ButtonComponent>
      </Grid>
    );
  }

  return (
    <Grid component="div" key={link.code} item xs={12} sm={6} md={4}>
      <ButtonComponent
        data-testid={`${link.name}-button`}
        className={classes.countryLink}
        {...link}
      >
        <CountryFlag code={link.code} type="circle" className={classes.flag} />
        <Typography variant="body2">{link.name}</Typography>
        <ChevronRight className={classes.arrowRight} fontSize="large" />
      </ButtonComponent>
    </Grid>
  );
}
