import { contentfulSchema, COOKIES } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import { contentfulClient, logger } from '@/utils';

import { parseStickyBanners } from './sticky-banners.utils';

export const getStickyBanners = async ({
  locale,
  analyticsPageType,
  preview = false,
  sendCountryCode,
  receiveCountryCode,
  resolvedUrl = '',
  query = {},
  cookies = {},
}: Partial<
  Pick<
    GetServerSidePropsContext,
    'locale' | 'preview' | 'resolvedUrl' | 'query'
  >
> & {
  analyticsPageType: string | null;
  sendCountryCode: string;
  receiveCountryCode: string;
  cookies?: GetServerSidePropsContext['req']['cookies'];
}) => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.StickyBannersQuery>({
      query: contentfulSchema.StickyBanners,
      variables: {
        locale,
        preview,
        analyticsPageType,
        sendCountryCode,
        receiveCountryCode,
        marketingChannel: resolvedUrl,
        visitorTypes: [
          cookies[COOKIES.VISITOR_TYPE_LONG],
          cookies[COOKIES.VISITOR_TYPE_SHORT],
        ],
        cookies: Object.entries(cookies).map(
          ([key, value]) => `${key}=${value}`,
        ),
        queryParams: Object.entries(query).map(
          ([key, value]) => `${key}=${value}`,
        ),
      },
    });

    return parseStickyBanners({
      banners: data?.stickyBannerCollection?.items,
      receiveCountryCode,
      sendCountryCode,
    });
  } catch (error) {
    logger.error(error, `StickyBanner query request failed`);

    return [];
  }
};
