import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing, customComponents }) => ({
  'exchangeRateContainer': {
    paddingLeft: spacing(2.5),
  },
  'contentWrapper': {
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'position': 'relative',
    'padding': spacing(0, 2),
    'borderLeft': `2px solid ${customComponents.calculator.countryField.backgroundColor}`,
    'minHeight': '76px',
    'gap': spacing(0.75),

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      backgroundColor: customComponents.calculator.countryField.backgroundColor,
      width: spacing(1),
      height: spacing(1),
      borderRadius: '50%',
      left: spacing(-0.625),
      marginTop: spacing(-0.5),
      top: '50%',
    },
  },
  '@keyframes fadeInFromRight': {
    from: {
      opacity: 0,
      transform: 'translateX(11px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  'specialRateChip': {
    display: 'inline-flex',
    borderRadius: spacing(1),
    backgroundColor: colors.lightCyan,
    padding: spacing(0.5, 1),
    width: 'fit-content',
    animation: '$fadeInFromRight 0.4s ease-out',
    marginBottom: spacing(0.5),
  },
  'specialRateText': {
    fontSize: spacing(1.5),
    fontWeight: 600,
  },
  'contentContainer': {
    display: 'flex',
    alignItems: 'baseline',
    gap: spacing(0.75),
  },
  'exchangeRateText': {
    fontWeight: 700,
    fontSize: spacing(1.75),
    color: customComponents.calculator.exchangeRate.textColor,
    letterSpacing: 0.15,
  },
  'blendedRate': {
    fontSize: spacing(1.7),
    color: colors.grey,
    marginTop: -6,
  },
  'exchangeRateAnimationContainer': {
    animation: '$fadeInFromRight 0.2s ease-out',
  },
}));
