import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useCallback } from 'react';

import { useStyles } from './search.styles';
import { SearchOption, SearchProps } from './search.types';

export function Search<L>({
  id,
  inputLabel,
  placeholder,
  options,
  onInputChange,
  filterOptions,
  LinkComponent,
  linkProps = {} as L,
  resultListLabel,
  loading,
  className,
  inputValue,
  onChange,
  onEnter,
}: SearchProps<L>): React.ReactElement {
  const classes = useStyles();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const searchValue = (event.target as HTMLInputElement).value.trim();
        onEnter && onEnter(searchValue);
      }
    },
    [onEnter],
  );

  return (
    <Autocomplete
      id={id}
      data-testid={id}
      className={className}
      classes={{ option: classes.option }}
      loading={loading}
      freeSolo
      fullWidth
      disablePortal
      disableCloseOnSelect
      inputValue={inputValue}
      options={options}
      getOptionLabel={option => (option as SearchOption)?.label ?? option}
      onChange={onChange}
      renderGroup={params => [
        params.group ? (
          <p key={params.key} className={classes.listLabel}>
            {params.group}
          </p>
        ) : null,
        params.children,
      ]}
      groupBy={() => resultListLabel || ''}
      onInputChange={onInputChange}
      renderOption={(props, option) =>
        option.url ? (
          <li {...props}>
            <LinkComponent
              {...linkProps}
              className={classes.link}
              url={option.url}
            >
              {option.label}
            </LinkComponent>
          </li>
        ) : (
          <li {...props}>{option.label}</li>
        )
      }
      filterOptions={filterOptions}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={inputLabel}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.inputIcon} />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress size={24} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
}
