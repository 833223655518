import { contentfulSchema } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import { contentfulClient, logger } from '@/utils';

export const getContentfulFeatureFlags = async ({
  preview,
}: Pick<
  GetServerSidePropsContext,
  'preview'
>): Promise<contentfulSchema.FeatureFlagsQuery> => {
  try {
    const {
      data,
    } = await contentfulClient.query<contentfulSchema.FeatureFlagsQuery>({
      query: contentfulSchema.FeatureFlags,
      variables: {
        preview,
      },
    });

    return data;
  } catch (error) {
    logger.error(error, `getContentfulFeatureFlags request failed`);

    throw {
      name: 'FeatureFlagsQuery',
      message: error,
    };
  }
};
