import { Hidden, PopperProps } from '@mui/material';
import { DropdownDesktop, DropdownMobile } from '@wr/web-ui';
import React from 'react';

export interface DropdownProps {
  isOpen: boolean;
  width?: number;
  id?: string;
  showCloseIcon?: boolean;
  anchorEl: PopperProps['anchorEl'];
  onClose: (reason?: string) => void;
}

export const CurrencySelectDropdown: React.FC<DropdownProps> = ({
  id,
  width,
  isOpen,
  anchorEl,
  children,
  showCloseIcon,
  onClose,
}) => (
  <>
    <Hidden mdUp>
      <DropdownMobile
        id={id}
        isOpen={isOpen}
        onClose={(_, reason) => onClose(reason)}
        showCloseIcon={showCloseIcon}
      >
        {children}
      </DropdownMobile>
    </Hidden>
    <Hidden mdDown>
      <DropdownDesktop
        id={id}
        anchorEl={anchorEl}
        isOpen={isOpen}
        onClose={() => onClose()}
        width={width}
      >
        {children}
      </DropdownDesktop>
    </Hidden>
  </>
);
