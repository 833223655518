import { useLazyQuery } from '@apollo/client';
import { ANALYTICS_PAGE_TYPE, contentfulSchema } from '@wr/web-shared';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { contentfulClient } from '../contentful-client';
import { SearchDialogProps } from './search-dialog.types';
import { filterPagesByTagOrName } from './search-dialog.utils';

export const useResultList = () => {
  const router = useRouter();
  const [list, setList] = useState<SearchDialogProps<unknown>['options']>([]);
  const [
    loadFaqArticles,
    { called, loading, data: faqArticles },
  ] = useLazyQuery<contentfulSchema.PagesWithTagsQuery>(
    contentfulSchema.PagesWithTags,
    {
      client: contentfulClient,
    },
  );

  const setResultList = (
    _: unknown,
    { inputValue }: { inputValue: string },
  ) => {
    return filterPagesByTagOrName(
      faqArticles?.pageCollection?.items || [],
      inputValue,
    );
  };

  const loadList = () => {
    if (!called) {
      loadFaqArticles({
        variables: {
          locale: router.locale,
          analyticsPageType: ANALYTICS_PAGE_TYPE.FAQ,
        },
        onCompleted: faqArticles => {
          setList(
            filterPagesByTagOrName(faqArticles?.pageCollection?.items || []),
          );
        },
      });
    }
  };

  return { resultList: list, setResultList, loadList, loading };
};
