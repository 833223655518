export const CWV_METRIC_NAME = {
  LARGEST_CONTENTFUL_PAINT: 'LCP',
  CUMULATIVE_LAYOUT_SHIFT: 'CLS',
  FIRST_INPUT_DELAY: 'FID',
  FIRST_CONTENTFUL_PAINT: 'FCP',
  TIME_TO_FIRST_BYTE: 'TTFB',
};

export const CWV_RATING = {
  GOOD: 'good',
  NEEDS_IMPROVEMENT: 'needs-improvement',
  POOR: 'poor',
};

export const WEB_VITAL_METRICS = 'web-vital';
