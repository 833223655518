import { contentfulSchema } from '@wr/web-shared';
import { HowDoesItWork as UIHowDoesItWork } from '@wr/web-ui';
import { FC, useContext } from 'react';

import { AppContext } from '@/context';
import { useIntlMessages } from '@/hooks/intl.hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

export const HowDoesItWork: FC<contentfulSchema.HowDoesItWorkFragment> = ({
  name,
  __typename,
  sys,
  animationSteps,
  title,
}) => {
  const intlMessages = useIntlMessages();
  const { messages } = useContext(AppContext);

  return (
    <UIHowDoesItWork
      title={interpolate(title, intlMessages)}
      name={name || ''}
      animationSteps={(animationSteps?.items as contentfulSchema.HowItWorksStepRelaunch[]).map(
        item => ({
          ...item,
          id: item.sys.id,
          header: interpolate(item?.header, intlMessages),
          name: item.name || '',
          description: interpolate(item?.description, intlMessages),
          image: {
            ...item?.image,
            alt: item.image?.title,
            width: 2400,
            height: 800,
          },
        }),
      )}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name || undefined,
      )}
      messages={{
        previous: messages?.previous,
        next: messages?.next,
      }}
    />
  );
};
