import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  drawer: {
    borderTopLeftRadius: theme.shape.borderRadius * 4,
    borderTopRightRadius: theme.shape.borderRadius * 4,
    overflowY: 'inherit',
  },
  paperAnchorBottom: {
    maxHeight: 'calc(100% - 3rem)',
  },
  closeButton: {
    position: 'absolute',
    bottom: '102%',
    right: '2%',
    color: theme.customComponents.dropdownMobile.closeButton.color,
    fontSize: '1.5rem',
  },
}));

export default useStyles;
