import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, getBackground } from '@wr/web-ui';

import { HeroSlotProps } from './hero-slot.types';

const useStyles = makeStyles<Theme, Partial<HeroSlotProps>>(
  ({ spacing, shape, breakpoints, typography }: Theme) => ({
    section: ({ isSectionOnTop }) => ({
      background: getBackground(colors.purpleNormal, colors.purpleDark),
      // lower by header height - 88px, and additional 16px
      paddingTop: spacing(isSectionOnTop ? 13 : 2),
      paddingBottom: spacing(3),

      [breakpoints.up('sm')]: {
        // lower by header height - 112px and additional 24px
        paddingTop: spacing(isSectionOnTop ? 17 : 3),
        paddingBottom: spacing(4),
      },
      [breakpoints.up('lg')]: {
        // lower by header height - 112px and additional 64px
        paddingTop: spacing(isSectionOnTop ? 22 : 8),
        paddingBottom: spacing(8),
        flexDirection: 'row',
      },
    }),
    container: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '18rem',
      margin: 'auto',

      [breakpoints.up('lg')]: {
        maxWidth: 'initial',
        flexDirection: 'row',
      },
    },
    leftColumn: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      marginBottom: spacing(2),

      [breakpoints.up('lg')]: {
        justifyContent: 'start',
        marginBottom: 0,
      },
    },
    leftColumnContainer: {
      [breakpoints.up('lg')]: {
        maxWidth: '32.25rem',
      },
    },
    rightColumn: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      position: 'relative',
      minHeight: '14.75rem',
      width: 'inherit',
      overflow: 'hidden',
      marginBottom: spacing(2),

      [breakpoints.up('lg')]: {
        justifyContent: 'right',
        marginBottom: 0,
      },
    },
    header: {
      marginBottom: spacing(1),

      [breakpoints.up('lg')]: {
        marginBottom: spacing(2),
        ...typography.h1,
      },
    },
    subheader: {
      marginBottom: spacing(2),

      [breakpoints.up('lg')]: {
        marginBottom: spacing(3),
        ...typography.body2,
      },
    },
    textMeAppContainer: {
      borderRadius: shape.borderRadius * 2,
      paddingTop: spacing(4),
    },
    mb2: {
      marginBottom: spacing(2),
    },
    link: {
      marginLeft: spacing(1),
    },
    imageContainer: {
      'width': '10rem',
      'height': '10rem',
      'padding': spacing(1),
      'background': '#fff',

      '& img, & canvas': {
        width: '100%',
        height: 'auto',
      },
    },
  }),
);

export default useStyles;
