import {
  CalculationErrorMessage,
  getCalculationErrorMessages,
} from '@wr/web-ui';
import { useContext, useEffect, useMemo } from 'react';

import { AppContext, CalculatorContext, pushErrorToDataLayer } from '@/context';

import { CalculatorProps } from './cex-calculator.types';

export const useErrorMessages = ({
  errors,
  senderPayoutMethodUnavailableMessage,
  receiverPayoutMethodUnavailableMessage,
  unavailableCorridorErrorMessage,
  airtimeTopupQuoteAvailableInApp,
  generalErrorMessage,
}: CalculatorProps & {
  errors: CalculationErrorMessage[];
}) => {
  const { intl } = useContext(AppContext);
  const [state] = useContext(CalculatorContext);

  const errorMessages = useMemo(() => {
    return getCalculationErrorMessages({
      errors,
      calculationType: state.calculationType,
      payoutMethodId: state.payoutMethodId,
      payoutMethodName: state.payoutMethodId
        ? intl.payoutMethodsLabelsById[state.payoutMethodId]
        : undefined,
      receiveCountryName: state.receiveCountry?.name,
      sendCountryName: state.sendCountry?.name,
      senderPayoutMethodUnavailableMessage,
      receiverPayoutMethodUnavailableMessage,
      unavailableCorridorErrorMessage,
      airtimeTopupQuoteAvailableInApp,
      generalErrorMessage,
    });
  }, [
    errors,
    generalErrorMessage,
    intl.payoutMethodsLabelsById,
    state.payoutMethodId,
    receiverPayoutMethodUnavailableMessage,
    senderPayoutMethodUnavailableMessage,
    airtimeTopupQuoteAvailableInApp,
    state.calculationType,
    state.receiveCountry?.name,
    state.sendCountry?.name,
    unavailableCorridorErrorMessage,
  ]);

  /**
   * GA event for the error message
   */
  useEffect(() => {
    if (errorMessages.length) {
      errorMessages.map(errorMessage => {
        pushErrorToDataLayer(errorMessage);
      });
    }
  }, [errorMessages]);

  return errorMessages;
};
