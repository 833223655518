import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import React from 'react';

import { VideoPlayProps } from '../video.types';
import { useStyles } from './play-button.component.styles';

const PlayButton = ({ isContained }: VideoPlayProps): React.ReactElement => {
  const classes = useStyles();

  if (isContained) {
    return (
      <div className={classes.containedContainer}>
        <div className={classes.containedCircle} />
        <PlayArrowRoundedIcon className={classes.containedIcon} />
        <div className={classes.ontainedCircleAnimation} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.circle} />
      <PlayArrowRoundedIcon className={classes.icon} />
      <div className={classes.circleAnimation} />
    </div>
  );
};

export default PlayButton;
