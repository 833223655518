import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';

import { AppContext } from '@/context';
import { useCalculator } from '@/hooks';
import { CexCurrencyFragment, getCurrencyInformationBySlug } from '@/services';

import { CexCurrencyInformationProps } from './cex-currency-information.types';

export const useCurrencyInformation = ({
  sendCurrencyCode,
  receiveCurrencyCode,
}: CexCurrencyInformationProps) => {
  const router = useRouter();
  const { currencies } = useContext(AppContext);
  const { sendCountry, receiveCountry } = useCalculator();

  const [currencyData, setCurrencyData] = useState<CexCurrencyFragment[]>([]);

  const currencySlugs = useMemo(() => {
    const currentSendCurrencyCode = (
      sendCurrencyCode ??
      sendCountry?.currency ??
      ''
    )?.toLowerCase();

    const currentReceiveCurrencyCode = (
      receiveCurrencyCode ??
      receiveCountry?.currency ??
      ''
    )?.toLowerCase();

    const currencyCodes = [
      currentSendCurrencyCode,
      currentReceiveCurrencyCode,
    ].filter(currencyCode => !!currencyCode);

    return currencies
      .filter(currency => {
        return currencyCodes.includes(currency.currencyCode.toLowerCase());
      })
      .map(currency => currency.pageSlug)
      .filter(slug => !!slug);
  }, [
    currencies,
    receiveCountry?.currency,
    receiveCurrencyCode,
    sendCountry?.currency,
    sendCurrencyCode,
  ]);

  useEffect(() => {
    if (currencySlugs.length) {
      Promise.all(
        currencySlugs.map(slug => {
          return getCurrencyInformationBySlug({
            slug,
            locale: router.locale,
          });
        }),
      ).then(data => {
        return setCurrencyData(
          data.filter(
            (currency): currency is CexCurrencyFragment => currency !== null,
          ),
        );
      });
    }
  }, [currencySlugs, router.locale]);

  return currencyData;
};
