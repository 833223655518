import clsx from 'clsx';
import React from 'react';

import { useStyles } from './beat-loader.styles';
import { BeatLoaderProps } from './beat-loader.types';

export const BeatLoader: React.FC<BeatLoaderProps> = ({
  className,
  size = 0.5,
  color,
}) => {
  const classes = useStyles({ size, color });

  return (
    <div data-testid="loader" className={clsx(classes.loader, className)}>
      <div className={clsx(classes.circle, classes.circle1)} />
      <div className={clsx(classes.circle, classes.circle2)} />
      <div className={clsx(classes.circle, classes.circle3)} />
    </div>
  );
};
