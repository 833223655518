import { isAndroid } from './android';
import { isIOS } from './ios';
import { MOBILE_OS, OSRecognizer } from './os.types';

export const isClient = (): boolean =>
  Boolean(typeof window !== 'undefined' && window.document);

export const recognizeMobileOS = (isSSR = false): MOBILE_OS | undefined => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isSSR || window.MSStream) {
    return undefined;
  }

  const getOSName = (
    recognizedOS: MOBILE_OS | undefined,
    OSName: MOBILE_OS,
  ) => {
    const osRecognizerPerOS: { [k in MOBILE_OS]: OSRecognizer } = {
      Android: isAndroid,
      iOS: isIOS,
    };

    const isOSRecognized = osRecognizerPerOS[OSName](window);

    return recognizedOS || (isOSRecognized && OSName) || undefined;
  };

  const allOSes = Object.values(MOBILE_OS);

  return allOSes.reduce<MOBILE_OS | undefined>(getOSName, undefined);
};
