import { useEffect, useState } from 'react';
import Slider from 'react-slick';

import {
  HowItWorksRelaunchStep,
  UseStepSelection,
} from './how-does-it-work.types';

export const useStepSelection = (): UseStepSelection => {
  const [selectedId, onSelectionChange] = useState('');

  return { selectedId, onSelectionChange };
};

export function useStepUpdate(
  steps: HowItWorksRelaunchStep[],
  carouselRef: React.RefObject<Slider>,
  goToCarouselSlide: (
    carouselRef: React.RefObject<Slider>,
    slideNumber: number,
  ) => void,
  onSelectionChange: React.Dispatch<React.SetStateAction<string>>,
): void {
  useEffect(() => {
    if (steps?.length > 0) {
      onSelectionChange(steps[0].id);
      goToCarouselSlide(carouselRef, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);
}
