import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, important } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tableContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: theme.spacing(1),
    gap: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  tableParent: {
    width: '100%',
    borderRadius: theme.spacing(1),
    border: `1px solid ${colors.greyLight}`,
    borderSpacing: 'revert',

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0),
    },
  },
  tableTitle: {
    '& > *': {
      paddingRight: theme.spacing(1),
    },
  },
  tableRow: {
    'display': 'flex',
    'padding': theme.spacing(0.5, 3),
    'borderBottom': `1px solid ${colors.greyLight}`,
    'background': important(colors.white),
    'justifyContent': 'space-between',

    '& .MuiTableCell-root': {
      background: colors.white,
      padding: ' 4px !important',
      borderBottom: 'none',
    },

    '& .MuiTableCell-root > p': {
      fontWeight: 500,
    },

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  tableBody: {
    'background': colors.white,
    'padding': ' 4px !important',

    '& > :not(:last-child)': {
      borderRight: 'none',
    },
    '& tr:nth-of-type(odd)': {
      background: colors.white,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  leftAlignedCell: {
    textAlign: 'left',
    fontWeight: 500,
    color: colors.purpleNormal,
    paddingLeft: `${theme.spacing(3)} !important`,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '16px !important',
    },
  },
  rightAlignedCell: {
    textAlign: 'right',
    fontWeight: 500,
    color: colors.black,
    paddingRight: `${theme.spacing(3)} !important`,

    [theme.breakpoints.down('sm')]: {
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  amountCell: {
    textAlign: 'left',
    paddingLeft: `${theme.spacing(3)} !important`,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
    },
    ...sharedCellStyles,
  },
  rateCell: {
    textAlign: 'right',
    paddingRight: `${theme.spacing(3)} !important`,

    [theme.breakpoints.down('sm')]: {
      paddingRight: `${theme.spacing(2)} !important`,
    },
    ...sharedCellStyles,
  },
  underline: {
    textDecoration: 'underline',
  },
}));

const sharedCellStyles = {
  fontSize: '16px',
  fontWeight: 700,
};
