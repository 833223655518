import { ButtonProps } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { ExtendedButtonProps } from '@wr/web-ui';

import { pushNavigationClickToDataLayer } from '@/components/header/header.utils';

import { HeaderTheme } from '../header.types';
import { isDarkTheme } from '../header.utils';
import { GetValidLinksProps } from './header-nav.types';

export const getValidLinks = (links: GetValidLinksProps): ButtonProps[] => {
  if (!links) return [];
  const validLinks = links.filter(
    (link): link is contentfulSchema.Link => link !== null,
  );
  return validLinks as ButtonProps[];
};

export const getLinkFormatter = (
  headerTheme: HeaderTheme,
  isSMUp: boolean,
  linkProps?: ExtendedButtonProps,
) => {
  return ({
    name,
    label,
    url,
    variant,
    color: linkColor,
    gaTrackName,
    isInternal,
    __typename,
  }: contentfulSchema.LinkFragment) => {
    const color = isDarkTheme(headerTheme) ? 'secondary' : 'primary';
    const isButton = variant === 'contained' || variant === 'outlined';
    const size = isButton && isSMUp ? 'medium' : 'small';
    return {
      ...linkProps,
      'data-testid': `${__typename}-${name || ''}`,
      'color': isButton ? linkColor : color,
      url,
      variant,
      size,
      'onClick': pushNavigationClickToDataLayer(gaTrackName),
      isInternal,
      'children': label,
      name,
    } as ExtendedButtonProps;
  };
};
