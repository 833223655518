import { formatDate } from '@/utils';

import { DateRange, HistoricalRate } from './types';

export const calculateAverageRates = (
  data: HistoricalRate[],
): HistoricalRate[] => {
  const groupedByDate = data.reduce((acc, entry) => {
    if (!acc[entry.date]) {
      acc[entry.date] = {
        rates: [],
        times: [],
      };
    }
    acc[entry.date].rates.push(Number(entry.customerRate));
    acc[entry.date].times.push(entry.time);
    return acc;
  }, {} as Record<string, { rates: number[]; times: string[] }>);

  const averagedRates = Object.entries(groupedByDate).map(
    ([date, { rates, times }]) => {
      const sum = rates.reduce((a, b) => a + b, 0);
      const average = sum / rates.length;
      const earliestTime = times.sort()[0];

      return {
        date,
        time: earliestTime,
        customerRate: Number(average.toFixed(2)),
      };
    },
  );

  return averagedRates;
};

export const getDateRange = (
  range: DateRange,
): {
  startDate: string;
  endDate: string;
} => {
  const endDate = new Date();
  let startDate: Date;

  switch (range) {
    case DateRange.Weekly:
      startDate = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case DateRange.Monthly:
      startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      break;
    default:
      throw new Error('Unsupported range type');
  }

  return {
    startDate: formatDate(startDate.toISOString()),
    endDate: formatDate(endDate.toISOString()),
  };
};
