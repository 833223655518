import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  slider: {
    '&.slick-slider, & .slick-list': {
      position: 'relative',
      userSelect: 'none',
    },
    '&.slick-slider': {
      display: 'block',
    },
    '& .slick-list': {
      position: 'relative',
      overflow: 'visible',
      display: 'block',
      margin: theme.spacing(-1),

      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(-1.5),
      },
    },
    '& .slick-slide': {
      'minHeight': '1px',
      'padding': theme.spacing(1),

      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(1.5),
      },
      '& > div': {
        height: '100%',
      },
    },
    '& .slick-track': {
      display: 'flex',
      alignItems: 'stretch',
    },
  },
}));
