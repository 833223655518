import dynamic from 'next/dynamic';
import React from 'react';

import { PDFViewerProps } from './pdf-viewer.types';

const PDFViewer = dynamic(() => import('./pdf-viewer.component'), {
  ssr: false,
});

export const LazyPDFViewer: React.FC<PDFViewerProps> = props => {
  return <PDFViewer {...props} />;
};
