import { useEffect, useState } from 'react';

import { QrCodeProps } from './qr-code.types';

export const QrCode: React.FC<QrCodeProps> = ({
  url,
  width,
  height,
  title,
  alt,
  description,
  classes,
  elementId,
}) => {
  const [intervalLimit, setIntervalLimit] = useState(10);

  useEffect(() => {
    if (intervalLimit <= 0) return;

    const timeout = setTimeout(() => {
      // if there is a hero QR code in the page, lets replace it
      if (window?.AF_SMART_SCRIPT?.displayQrCode) {
        // clear the current qr code
        const element = document.getElementById(elementId);
        if (element) {
          element.innerHTML = '';
          // display the new qr code
          window.AF_SMART_SCRIPT.displayQrCode(elementId);
          return;
        }
      }

      // if the qr code is not available, we will try again
      // every second until the interval limit is reached
      setIntervalLimit(intervalLimit - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [elementId, intervalLimit]);

  return (
    <>
      {url && (
        <div
          data-testid="qr-code-container"
          className={classes?.qrCodeContainer}
        >
          <div id={elementId} className={classes?.qrCodeImageContainer}>
            <img
              title={title || 'QR code for downloading the mobile app'}
              alt={alt || 'App download QR code'}
              width={width ? width + 'px' : '100%'}
              height={height ? height + 'px' : 'auto'}
              src={url}
              data-testid="qr-code-img"
              aria-describedby="qr-code-description"
            />
          </div>

          {description && (
            <p
              title="QR code description"
              className={classes?.qrCodeDescription}
              data-testid="qr-code-description"
            >
              {description}
            </p>
          )}
        </div>
      )}
    </>
  );
};
