import { CountryData } from '../../types';
import { INITIAL_APP_CONTEXT_SHARED } from './app.constants';
import { AppContextTypeShared } from './app.types';

export const intlMock: AppContextTypeShared['intl'] = {
  payoutMethodsLabelsById: {
    ATP: 'Airtime Top up',
    BNK: 'Bank Transfer',
    CSH: 'Cash pickup',
    DTD: 'Door to door',
  },
  countryArticlesAndPrepositions: {
    US: {
      name: 'United States',
      article: 'the',
      prepositionFrom: 'from the United States',
      prepositionTo: 'to the United States',
      prepositionIn: 'in the United States',
    },
    NG: {
      name: 'Nigeria',
      prepositionFrom: 'from Nigeria',
      prepositionTo: 'to Nigeria',
      prepositionIn: 'in Nigeria',
    },
    PH: {
      name: 'Philippines',
      prepositionFrom: 'from Philippines',
      prepositionTo: 'to Philippines',
      prepositionIn: 'in the Philippines',
    },
    PL: {
      name: 'Poland',
      prepositionFrom: 'from Poland',
      prepositionTo: 'to Poland',
      prepositionIn: 'in Poland',
    },
  },
  referralScheme: {
    reward: {
      amount: 100,
      currency: 'PLN',
    },
    qualifyingConditions: {
      minimalTransfer: {
        amount: 600,
        currency: 'PLN',
      },
    },
  },
};

export const messagesMock = {
  readMore: 'Read more',
  readLess: 'Read less',
  captchaPxLabel:
    'This is an added security measure to keep your information safe. Click and hold to help us verify you.',
  previous: 'Previous',
  next: 'Next',
  chooseYourRegion: 'Choose your Region',
  countries: 'Countries',
  international: 'International',
  continue: 'Continue',
  cancel: 'Cancel',
  close: 'Close',
  geoLocationBannerContent:
    'Select region or country to see information specific to your location.',
  updated: 'Updated',
  all: 'All',
  autocompleteLabel: 'Search by keyword',
  noResults: 'No results',
  lifeabroad: 'Life Abroad',
  community: 'Community',
  education: 'Education',
  finance: 'Finance',
  products: 'Product',
};

export const mockSendCountry: CountryData = {
  countryCode: 'US',
  currency: 'USD',
  currencyName: 'US Dollar',
  name: 'United States',
  dialCode: '1',
  validReceiveCountriesIso2: ['ke', 'ug', 'tz', 'ng'],
  receive: false,
  send: true,
  iso2: 'us',
};

export const mockReceiveCountry: CountryData = {
  countryCode: 'NG',
  currency: 'NGN',
  currencyName: 'Nigerian naira',
  name: 'Nigeria',
  dialCode: '234',
  validReceiveCountriesIso2: ['gh', 'ng', 'sn'],
  receive: true,
  send: false,
  iso2: 'ng',
};

export const mockPayoutMethod = {
  code: 'BNK',
  payOutTimeEstimate: 'TBC',
  name: '',
  feeEstimate: {
    value: {
      amount: 7,
      currency: 'EUR',
    },
    type: 'Preview',
  },
  correspondents: [
    {
      id: '255',
      name: 'Metrobank',
      payOutTime: '⚡ Within minutes',
    },
  ],
};

export const mockedRegions = [
  'en',
  'en-gb',
  'en-us',
  'en-my',
  'en-nz',
  'en-ca',
  'en-se',
  'en-au',
  'da-dk',
  'de',
  'es',
  'es-us',
  'fr',
  'fr-ca',
  'nl',
];

export const appContextSharedMock = {
  locale: INITIAL_APP_CONTEXT_SHARED.locale,
  analyticsPageType: INITIAL_APP_CONTEXT_SHARED.analyticsPageType,
  sendCountries: [mockSendCountry],
  receiveCountries: [mockReceiveCountry],
  receiveCountry: mockReceiveCountry,
  sendCountry: mockSendCountry,
  messages: messagesMock,
  regions: mockedRegions,
  intl: intlMock,
};
