import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(({ typography, spacing, breakpoints }) => ({
  text: {
    fontFamily: typography.fontFamily,
    lineHeight: 1.25,
    fontSize: '1rem',
  },
  value: {
    fontWeight: typography.fontWeightMedium,
    textDecoration: 'underline',
    color: colors.greenDark,
    marginRight: spacing(0.5),
  },
  openButtonIcon: {
    color: colors.greenDark,
  },
  openButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  popupContainer: {
    padding: spacing(2),
    marginTop: spacing(1),

    [breakpoints.up('md')]: {
      marginTop: spacing(2),
    },
    background: colors.creamNormal,
    maxWidth: '16.825rem',
    borderRadius: '0.9375rem',
    boxShadow: '1px 2px 5px 2px rgba(0, 0, 0, 0.15)',
  },
  popupText: {
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.3,
    color: colors.black,
  },
  closeButton: {
    padding: '0',
    border: 'none',
    background: 'none',
    marginBottom: spacing(0.875),
    cursor: 'pointer',
    color: colors.greenDark,
  },
  closeButtonIcon: {
    fontSize: '1.608rem',
  },
}));

export default useStyles;
