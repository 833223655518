import {
  FeatureFlagCollection,
  FeatureFlagDictionary,
} from './feature-flag.types';

export function mapFeatureFlags(featureFlagCollection: FeatureFlagCollection) {
  return featureFlagCollection.items.reduce((featureFlagsDictionary, flag) => {
    featureFlagsDictionary[flag.name] = flag.value;
    return featureFlagsDictionary;
  }, {} as FeatureFlagDictionary);
}
