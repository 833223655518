import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
  ({ spacing, breakpoints, shape }) => ({
    calculator: {
      position: 'relative',
      padding: spacing(2),

      [breakpoints.up('sm')]: {
        padding: spacing(4),
      },
    },
    rounded: {
      borderRadius: shape.borderRadius * 4,
    },
    roundedBottom: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: shape.borderRadius * 4,
      borderBottomRightRadius: shape.borderRadius * 4,
    },
  }),
);
