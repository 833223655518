import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(theme => ({
  exchangeRateWrapper: {
    'position': 'relative',
    'margin': theme.spacing(-2, 0, 0, 2.5),
    'padding': theme.spacing(1.5, 2),
    'borderLeft': `solid 1px ${theme.palette.primary.dark}`,
    'display': 'flex',
    'flexWrap': 'wrap',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      borderRadius: '50%',
      left: 0,
      top: 0,
      bottom: 0,
      margin: 'auto 0',
      transform: 'translateX(calc(-50% - 0.5px))',
      height: '0.5rem',
      width: '0.5rem',
      backgroundColor: theme.palette.primary.dark,
    },
  },
  promoLabel: {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: colors.lightCyan,
    padding: theme.spacing(0.5, 1),
    fontSize: '0.75rem',
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  exchangeRate: {
    fontSize: '0.75rem',
    flexBasis: '100%',
  },
  crossedExchangeRate: {
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
