import { Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { Anchor } from '@/components';
import { getCurrencyCorridorSlug } from '@/services';

import { CexCountryFlag } from '../cex-country-flag';
import { CexCurrencyCode } from '../cex-currency-code';
import { useStyles } from './cex-currency-card.styles';
import { CexCurrencyCardProps } from './cex-currency-card.types';

export const CexCurrencyCard: React.FC<CexCurrencyCardProps> = ({
  pageSlug,
  countryCode,
  currencyCode,
  currencyName,
  sendCountryCode,
  sendCurrencyCode,
}) => {
  const classes = useStyles();

  const url = useMemo(
    () =>
      getCurrencyCorridorSlug({
        receiveCurrencyCode: currencyCode,
        sendCurrencyCode: sendCurrencyCode || '',
        cexPageSlug: pageSlug,
      }),
    [currencyCode, pageSlug, sendCurrencyCode],
  );

  return (
    <Anchor className={classes.linkWrapper} url={url}>
      <div
        className={classes.card}
        data-testid={`cex-currency-card-${currencyCode}`}
      >
        <CexCountryFlag
          countryCode={countryCode}
          sendCountryCode={sendCountryCode}
        />
        <CexCurrencyCode
          currencyCode={currencyCode}
          sendCurrencyCode={sendCurrencyCode}
        />
        <Typography
          component="div"
          className={classes.currencyName}
          data-testid="cex-currency-card-name"
          paragraph
        >
          {currencyName}
        </Typography>
      </div>
    </Anchor>
  );
};
