import { fallbackLocales } from '@/constants';

export const getLocalePart = (locale: string) => {
  if (locale.indexOf('-') !== -1) {
    return locale.substring(0, locale.indexOf('-'));
  } else {
    return locale;
  }
};

export const getMatchedLocale = (locale: string) => {
  const matchedLocale = fallbackLocales.find(fallbackLocale => {
    return getLocalePart(locale) === fallbackLocale;
  });

  const result = matchedLocale || 'en';

  return result;
};
