import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { getBackground } from '../../utils';
import { FullWidthBannerProps } from './full-width-banner.types';

export const useStyles = makeStyles<
  Theme,
  Partial<FullWidthBannerProps<unknown, unknown>>
>(theme => ({
  section: ({
    backgroundImage,
    backgroundColor,
    secondBackgroundColor,
    text,
    textColor,
    textAlign,
  }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    textAlign: (textAlign as React.CSSProperties['textAlign']) || 'center',
    padding: theme.spacing(4, 0),
    flexDirection:
      theme.customComponents.fullWidthBanner.section.breakpointSm.flexDirection,

    ...(text ? {} : { aspectRatio: '3.29218106996' }),
    ...(backgroundImage
      ? {
          backgroundColor: 'transparent',
        }
      : {
          background: getBackground(backgroundColor, secondBackgroundColor),
        }),
    ...(textColor ? { color: textColor } : {}),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0),
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection:
        theme.customComponents.fullWidthBanner.section.breakpointMdUp
          .flexDirection,
      padding: theme.spacing(8, 0),
    },
  }),
  container: ({ contentWidth }) => ({
    width: contentWidth === 'narrow' ? 'unset' : '100%',
    paddingLeft:
      theme.customComponents.fullWidthBanner.container.breakpointSm.paddingLeft,
    paddingRight:
      theme.customComponents.fullWidthBanner.container.breakpointSm
        .paddingRight,

    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  }),
  borderContainer: ({ borderColor, secondBorderColor }) => ({
    position: 'relative',
    padding: theme.customComponents.fullWidthBanner.borderContainer.padding,
    borderWidth: 4,
    borderStyle: 'solid',
    ...(borderColor && secondBorderColor
      ? {
          borderImage: `${getBackground(borderColor, secondBorderColor)} 1`,
        }
      : {
          borderColor: borderColor || secondBorderColor || undefined,
        }),
  }),
  image: {
    marginBottom: theme.spacing(2),
  },
  link: {
    display: 'inline-block',
    ...theme.typography.subtitle1,
    marginTop: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(5),
    },
  },
}));
