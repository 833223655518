import { Typography } from '@mui/material';
import { pushToDataLayer } from '@wr/web-shared';
import { Button, Container, Image } from '@wr/web-ui';
import React, { useCallback, useState } from 'react';

import { useStyles } from './faq-feedback-module.styles';
import { FaqFeedbackModuleProps } from './faq-feedback-module.types';

export const FaqFeedbackModule: React.FC<FaqFeedbackModuleProps> = props => {
  const classes = useStyles();
  const { title, postFeedbackMessage, feedbackOptions, analyticsTagId } = props;
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleFeedback = useCallback(
    (feedbackOptionAnalyticsTag?: string) => {
      if (!!analyticsTagId && !!feedbackOptionAnalyticsTag) {
        pushToDataLayer({
          event: analyticsTagId,
          interactionName: feedbackOptionAnalyticsTag,
        });
      }
      setSubmitted(true);
    },
    [analyticsTagId],
  );

  return (
    <section data-testid="faq-feedback-module" className={classes.container}>
      <Container>
        <Typography variant="h4">{title}</Typography>

        {submitted ? (
          <Typography
            component="p"
            data-testid="faq-feedback-post-message"
            className={classes.postFeedbackMessage}
            paragraph
          >
            {postFeedbackMessage || 'Thanks for your feedback!'}
          </Typography>
        ) : (
          <div className={classes.feedbackOptionsWrapper}>
            {feedbackOptions?.items.map(option => (
              <Button
                key={option.name}
                data-testid={`faq-feedback-option-${option.name}`}
                className={classes.feedbackOption}
                onClick={() => handleFeedback(option.analyticsTagId as string)}
                variant="outlined"
              >
                <Image
                  className={classes.feedbackOptionIcon}
                  {...option.icon}
                />
                <Typography className={classes.feedbackOptionLabel}>
                  {option.label}
                </Typography>
              </Button>
            ))}
          </div>
        )}
      </Container>
    </section>
  );
};
