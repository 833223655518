export const isTextAlign = (
  s: string | undefined,
): s is React.CSSProperties['textAlign'] => {
  const possibleGlobalValues = [
    '-moz-initial',
    'inherit',
    'initial',
    'revert',
    'unset',
  ];
  const possibleTextAlignValues = [
    'center',
    'end',
    'justify',
    'left',
    'match-parent',
    'right',
    'start',
  ];
  return (
    (s && [...possibleGlobalValues, ...possibleTextAlignValues].includes(s)) ||
    false
  );
};

export const validateTextAlign = (
  s: string | undefined,
): React.CSSProperties['textAlign'] | undefined =>
  isTextAlign(s) ? s : undefined;

export const getBackground = (
  backgroundColor?: string | null,
  secondBackgroundColor?: string | null,
): string => {
  if (backgroundColor && secondBackgroundColor) {
    return `linear-gradient(90deg, ${backgroundColor}, ${secondBackgroundColor})`;
  }

  return backgroundColor || secondBackgroundColor || 'transparent';
};

export const getFlexAlignBasedOnTextAlign = (textAlign: string | undefined) => {
  switch (textAlign) {
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};

export const important = (cssValue: number | string): string => {
  return `${cssValue} !important`;
};
