export type OSRecognizerNavigator = {
  userAgent: string;
  platform?: string;
  maxTouchPoints?: number;
};

export type OSRecognizerDocument = {
  ontouchend?: typeof window.document.ontouchend;
};

export type RecognizeMobileOSWindow = {
  MSStream?: unknown;
  navigator: OSRecognizerNavigator;
  document: OSRecognizerDocument;
};

export type OSRecognizer = (window: RecognizeMobileOSWindow) => boolean;

export enum MOBILE_OS {
  Android = 'Android',
  iOS = 'iOS',
}
