import { MOBILE_OS, recognizeMobileOS } from '@wr/web-ui';

export function getLinkForCurrentOS<LinkGenericType>(
  androidLink: LinkGenericType,
  iOSLink: LinkGenericType,
  desktopLink: LinkGenericType,
): LinkGenericType {
  // eslint-disable-next-line react/display-name
  switch (recognizeMobileOS(typeof window === 'undefined')) {
    case MOBILE_OS.Android:
      return androidLink;
    case MOBILE_OS.iOS:
      return iOSLink;
    default:
      return desktopLink;
  }
}
