import { Observable } from '@apollo/client';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import httpStatus from 'http-status';
import Cookies from 'js-cookie';
import { Logger } from 'pino';

import {
  COOKIES,
  DEFAULT_LANGUAGE,
  LOGOUT_REDIRECT_FAIL,
  PERIMETER_X_SHOW_CAPTCHA_EVENT,
  UNAUTHORIZED_ERROR_MESSAGE,
  UNAUTHORIZED_EXCEPTION,
  UNAUTHORIZED_SERVER_SIDE_ERROR_MESSAGE,
  UNAUTHORIZED_WITH_COOKIE_ERROR_MESSAGE,
} from '../constants';
import { PerimeterXCaptchaData } from '../types/perimeter-x';
import { isClient, maskSensitiveData } from '../utils';
import { PerimeterXBlockStrategy } from './shared.types';

const LOGOUT_PATH = `/web-processes/api/logout`;

type PerimeterXNetworkError = {
  statusCode: number;
  result?: PerimeterXCaptchaData;
};

/**
 * handles the most common errors in case of connection issues
 * we don't want trigger pagerduty alerts for those but still want to show error message to user
 *
 * "Load failed" - ios all browsers and mac os safari
 * "Failed to fetch" - the rest of browsers
 *
 * the issue here is that when user leaves page on ios and mac os safari during running request
 * we have the same "Load failed" error and we can not distinguish that from real connection issue
 * and unfortunately that might be reported by monitoring tools on another page
 */
function isConnectionIssue({ name, message }: Error) {
  return (
    name === 'TypeError' &&
    (message === 'Load failed' || message === 'Failed to fetch')
  );
}

function handleUnauthorizedError(
  graphQLErrors: GraphQLErrors | undefined,
  logger: Logger,
  logData: Record<string, unknown>,
) {
  const clientSide = isClient();

  if (graphQLErrors?.[0]?.extensions?.type !== UNAUTHORIZED_EXCEPTION) {
    return;
  }

  if (!clientSide) {
    logger.warn(logData, UNAUTHORIZED_SERVER_SIDE_ERROR_MESSAGE);
    return;
  }

  const authCookie = Cookies.get(COOKIES.Authorization);
  if (authCookie) {
    logger.warn(
      { ...logData, authCookie },
      UNAUTHORIZED_WITH_COOKIE_ERROR_MESSAGE,
    );
    return;
  }

  const locale = document.location.pathname.split('/')[1] || DEFAULT_LANGUAGE;
  const returnUrl = `${document.location.pathname}${document.location.search}`;
  logger.warn(logData, UNAUTHORIZED_ERROR_MESSAGE);
  fetch(`${LOGOUT_PATH}?ReturnUrl=${encodeURIComponent(returnUrl)}`, {
    headers: {
      'Accept-Language': `${locale}`,
    },
    redirect: 'manual',
  })
    .then(res => {
      if (res.url) {
        document.location.assign(res.url);
      } else {
        throw new Error(LOGOUT_REDIRECT_FAIL);
      }
    })
    .catch(err => {
      logger.error(err, LOGOUT_REDIRECT_FAIL);
    });

  return true; // ignore error
}

function handlePerimeterXError(
  perimeterXBlockStrategy: PerimeterXBlockStrategy,
  networkError: NetworkError | undefined,
  logger: Logger,
  logData: Record<string, unknown>,
) {
  const clientSide = isClient();
  const perimeterXError = networkError as PerimeterXNetworkError | undefined;
  if (
    perimeterXError?.statusCode === httpStatus.FORBIDDEN &&
    perimeterXError?.result?.appId
  ) {
    if (clientSide) {
      window.dispatchEvent(
        new CustomEvent(PERIMETER_X_SHOW_CAPTCHA_EVENT, {
          detail: {
            ...perimeterXError.result,
            perimeterXBlockStrategy,
          },
        }),
      );

      logger.warn(logData, `[Client: PerimeterX block]: ${networkError}`);
      if (perimeterXBlockStrategy === 'IgnorePXError') {
        return true; // ignore error - user may bypass captcha
      }
    } else {
      logger.error(logData, `[Server: PerimeterX block]: ${networkError}`);
    }
  }
}

export const defaultErrorLink = (
  logger: Logger,
  perimeterXBlockStrategy: PerimeterXBlockStrategy = 'ThrowAndReloadPage',
) =>
  onError(({ operation, graphQLErrors, networkError }) => {
    if (operation.operationName === 'AccountDetails') return;

    const { operationName, variables } = operation;
    const logData = {
      operationName,
      variables: maskSensitiveData(variables),
      graphQLErrors,
      networkError,
    };

    const ignoreAuthError = handleUnauthorizedError(
      graphQLErrors,
      logger,
      logData,
    );
    if (ignoreAuthError) return Observable.of();

    const ignorePXError = handlePerimeterXError(
      perimeterXBlockStrategy,
      networkError,
      logger,
      logData,
    );
    if (ignorePXError) return Observable.of();

    if (networkError) {
      if (isConnectionIssue(networkError)) {
        logger.warn(logData, `[Network error]: ${networkError}`);
      } else {
        logger.error(logData, `[Network error]: ${networkError}`);
      }
    } else {
      logger.error(logData, 'GraphQL error');
    }
  });
