import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import { getSendCountryCodeFromLocale } from '@wr/web-ui';

import globeIcon from '../../../../static/icons/globe.svg';
import { CountryFlag } from '../../country-flag-image';
import { useMenuToggler } from '../header.hooks';
import { useStyles } from './header-select.styles';
import { HeaderSelectProps } from './header-select.types';

export const HeaderSelect: React.FC<HeaderSelectProps> = ({
  isMobile,
  name,
  color,
  value,
  label,
  onOpen,
  onChange,
  options,
  className,
  headerVariant,
  renderCountryDropdownOptions,
  startIcon,
}) => {
  const classes = useStyles({ headerVariant });
  const selectedOption = options?.find(o => o.value === value);

  const { isOpen, anchorEl, handleOpen, handleClose } = useMenuToggler();

  const id = name + (isMobile ? '-mobile' : '');
  const commonProps = {
    'aria-controls': id,
    'aria-haspopup': true,
    'aria-expanded': isOpen ? true : undefined,
    'data-testid': id,
  };
  const endIcon = isOpen ? (
    <KeyboardArrowUpIcon
      className={isMobile ? classes.endIconMobile : classes.endIcon}
    />
  ) : (
    <KeyboardArrowDownIcon
      className={isMobile ? classes.endIconMobile : classes.endIcon}
    />
  );
  const getCountryCode = (value: string) =>
    getSendCountryCodeFromLocale(value) ?? value;

  return (
    <>
      {isMobile ? (
        <ListItem
          {...commonProps}
          button
          onClick={e => {
            onOpen?.();
            handleOpen(e);
          }}
        >
          {startIcon}
          {label}
          {selectedOption?.label}
          {endIcon}
        </ListItem>
      ) : (
        <Button
          {...commonProps}
          variant="text"
          className={className ?? ''}
          size="small"
          endIcon={endIcon}
          color={color}
          onClick={e => {
            onOpen?.();
            handleOpen(e);
          }}
        >
          <span className={classes.selectButtonText}>
            {label}
            {selectedOption?.label}
          </span>
        </Button>
      )}
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          square: true,
          style: {
            maxHeight: 260,
          },
        }}
        MenuListProps={{
          disablePadding: true,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
      >
        {renderCountryDropdownOptions
          ? options.map(option => (
              //specific dropdown list for the region selector
              <div key={`menu-option-${option.value}`}>
                <MenuItem
                  onClick={() => {
                    onChange?.(option.value);
                    handleClose();
                  }}
                  data-testid={option.value}
                  selected={option.value === value}
                  value={option.value}
                >
                  <ListItemIcon>
                    {/* render the international option */}
                    {option.value === DEFAULT_LANGUAGE ? (
                      <img alt="" src={globeIcon} className={classes.flag} />
                    ) : (
                      <CountryFlag
                        code={getCountryCode(option.value)}
                        className={classes.flag}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText>{option.label}</ListItemText>
                </MenuItem>
                {option.value === DEFAULT_LANGUAGE && (
                  <Divider
                    aria-hidden="true"
                    component="li"
                    className={classes.regionDivider}
                  />
                )}
              </div>
            ))
          : //basic vanilla dropdown
            options.map(option => (
              <MenuItem
                onClick={() => {
                  onChange?.(option.value);
                  handleClose();
                }}
                selected={option.value === value}
                value={option.value}
                key={`menu-option-${option.value}`}
              >
                {option.label}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};
