import { AppBarProps, Theme, useMediaQuery } from '@mui/material';
import {
  contentfulSchema,
  DEFAULT_LANGUAGE,
  DROPDOWN,
  getCountryFromCookie,
  getLanguageNameAndCode,
  LanguageCode,
  setCountryFromCookie,
  setLanguageToCookie,
} from '@wr/web-shared';
import {
  Header as UIHeader,
  HeaderVariant,
  ImageProps,
  isDarkTheme,
} from '@wr/web-ui';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useMemo } from 'react';

import { DEFAULT_LOCATION } from '@/constants';
import { AppContext } from '@/context';
import {
  useUpdateBlogSelectionAndLocale,
  useUpdateLanguageSelection,
  useUpdateLoyaltyCountry,
} from '@/hooks';
import { getLanguageFromLocale } from '@/utils/intl';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';
import { useHeaderHooks } from './header.hooks';
import { HeaderProps } from './header.types';
import {
  getAllValidTopLevelLinks,
  pushNavigationClickToDataLayer,
} from './header.utils';

const defaultLogoImageProps: Partial<ImageProps> = {
  loading: 'eager',
  priority: true,
};

export const Header: React.FC<HeaderProps> = ({
  menuLabel,
  logoDark,
  logoDarkMobile,
  logoLight,
  logoLightMobile,
  headerTheme,
  headerPosition,
  linkProps,
  languageFromCookie,
  blogTags,
  isReferAFriend,
  loyaltyCountries,
  hideRegionSelector,
  ...headerItems
}) => {
  const loyaltyCountryDefault =
    getCountryFromCookie()?.toUpperCase() ?? DEFAULT_LOCATION.toUpperCase();
  const { region, fullGeoDropDownOptions, onRegionChange } = useHeaderHooks();
  const { accountDetails, isAuthenticated, regions, messages } = useContext(
    AppContext,
  );
  const router = useRouter();

  const { locale = DEFAULT_LANGUAGE, query, reload } = useRouter();
  const actualSendCountry = query.selectfrom as string;
  const isSMUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const headerVariant = (headerTheme || 'dark') as HeaderVariant;
  const position = (headerPosition || 'absolute') as AppBarProps['position'];

  const { onLoyaltyCountryChange } = useUpdateLoyaltyCountry(
    loyaltyCountries,
    DEFAULT_LOCATION,
    setCountryFromCookie,
    reload,
  );

  const { onLanguageChange, onLanguageOpen } = useUpdateLanguageSelection(
    setLanguageToCookie,
    reload,
  );

  const all = messages?.all?.toLowerCase();
  const { onBlogCategorySelect } = useUpdateBlogSelectionAndLocale(router, all);

  const color = isDarkTheme(headerVariant) ? 'secondary' : 'primary';

  // Logo props
  // Changed isInternal to false for making hard refresh every time logo is clicked.
  const logo = {
    link: {
      underline: 'none',
      color: 'inherit',
      url: `/${DEFAULT_LANGUAGE}/`,
      isInternal: false,
      ariaLabel: isDarkTheme(headerVariant) ? logoDark?.alt : logoLight?.alt,
    },
    logoDark: {
      logo: logoDark
        ? {
            ...logoDark,
            ...defaultLogoImageProps,
          }
        : null,
      logoMobile: logoDarkMobile
        ? {
            ...logoDarkMobile,
            ...defaultLogoImageProps,
          }
        : null,
    },
    logoLight: {
      logo: logoLight
        ? {
            ...logoLight,
            ...defaultLogoImageProps,
          }
        : null,
      logoMobile: logoLightMobile
        ? {
            ...logoLightMobile,
            ...defaultLogoImageProps,
          }
        : null,
    },
  };

  // User Menu props
  const welcomeMessage = accountDetails?.firstName
    ? `${headerItems?.welcome || ''} ${accountDetails.firstName}`
    : headerItems?.welcome || '';

  const userDropdownLinks: AnchorProps[] =
    headerItems.userDropdownMenuItemsCollection?.items
      .filter((item): item is AnchorProps => item !== null)
      .map(({ gaTrackName, name, label, ...rest }) => ({
        ...rest,
        'data-testid': name ? 'user-dropdown-link-' + name : undefined,
        'variant': undefined,
        'color': 'textPrimary',
        'underline': 'none',
        'children': label,
        'onClick': pushNavigationClickToDataLayer(
          gaTrackName,
          DROPDOWN.MY_ACCOUNT,
        ),
      })) || [];

  /**
   * Parse link data for display in web-ui
   */
  const parseLinkData = useCallback(
    ({
      name,
      label,
      url,
      variant,
      color: linkColor,
      gaTrackName,
      isInternal,
      __typename,
    }: contentfulSchema.LinkFragment) => {
      const isButton = variant === 'contained' || variant === 'outlined';
      const size = isButton && isSMUp ? 'medium' : 'small';

      return {
        ...linkProps,
        'data-testid': `${__typename}-${name || ''}`,
        'color': isButton ? linkColor : color,
        url,
        variant,
        size,
        'onClick': pushNavigationClickToDataLayer(gaTrackName),
        isInternal,
        'children': label,
        name,
      } as ButtonProps;
    },
    [color, isSMUp, linkProps],
  );

  // Nav bar links mapping
  const allValidTopLevelLinks: ButtonProps[] = getAllValidTopLevelLinks(
    headerItems,
    isAuthenticated,
  ).map(parseLinkData);

  const languages = useMemo(
    () =>
      languageFromCookie !== undefined
        ? [...new Set(regions.map(getLanguageFromLocale))]
            .sort((a, b) => a?.localeCompare(b || '') || -1)
            .map(item => getLanguageNameAndCode(item as LanguageCode))
        : undefined,
    [regions, languageFromCookie],
  );

  const submenuItems = useMemo(() => {
    if (!headerItems?.submenuItems?.length) {
      return undefined;
    }
    return headerItems.submenuItems
      .filter((link): link is contentfulSchema.Link => link !== null)
      .map(parseLinkData);
  }, [headerItems?.submenuItems, parseLinkData]);

  return (
    <UIHeader<AnchorProps, ButtonProps>
      isAuthenticated={isAuthenticated}
      headerVariant={headerVariant}
      welcomeMessage={welcomeMessage}
      myAccount={headerItems.myAccount || ''}
      menuLabel={menuLabel || ''}
      position={position}
      links={allValidTopLevelLinks}
      logo={logo}
      userDropdownLinks={userDropdownLinks}
      ButtonComponent={Button}
      AnchorComponent={Anchor}
      onLanguageOpen={onLanguageOpen}
      onLanguageChange={onLanguageChange}
      languages={languages}
      language={languageFromCookie || DEFAULT_LANGUAGE}
      actualSendCountry={actualSendCountry}
      onBlogCategorySelect={onBlogCategorySelect}
      blogTags={blogTags}
      allBlogTag={messages?.all}
      submenuItems={submenuItems}
      router={router}
      isReferAFriend={isReferAFriend}
      referAFriendSendFromLabel={messages?.referAFriendSendFromLabel}
      loyaltyCountryDefault={loyaltyCountryDefault}
      loyaltyCountries={loyaltyCountries}
      onLoyaltyCountryChange={onLoyaltyCountryChange}
      locale={locale}
      region={region}
      onRegionChange={onRegionChange}
      regionDropdownOptions={fullGeoDropDownOptions}
      locationLangLabel={messages?.locationLangLabel}
      hideRegionSelector={hideRegionSelector}
    />
  );
};
