import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(theme => ({
  countryFieldContainer: {
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: '1fr auto',
    borderRadius: theme.customComponents.calculator.countryField.borderRadius,
    border: `2px solid ${theme.customComponents.calculator.countryField.backgroundColor}`,
    backgroundColor:
      theme.customComponents.calculator.countryField.backgroundColor,
    alignItems: 'stretch',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1.25, 1.5),
    backgroundColor: colors.white,
    minWidth: theme.spacing(31),
    width: '100%',

    [theme.breakpoints.down('md')]: {
      minWidth: 'fit-content',
    },
  },
  inputLabel: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.customComponents.calculator.countryField.labelColor,
    lineHeight: 1.2,
  },
  inputBaseProps: {
    padding: 0,
    fontWeight: 700,
    color: theme.customComponents.calculator.countryField.inputColor,
    fontSize: theme.spacing(3.4),
    height: theme.spacing(3.9),
    lineHeight: 1,
  },
  selectContainer: {
    backgroundColor:
      theme.customComponents.calculator.countryField.backgroundColor,
  },
  inputSelectText: {
    color: theme.customComponents.calculator.countryField.selectColor,
  },
  selectIcon: {
    color: theme.customComponents.calculator.countryField.selectColor,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 1.25),
    paddingLeft: theme.spacing(1.875),
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    fontSize: theme.spacing(1.75),
    color: theme.customComponents.calculator.countryField.inputColor,
    textTransform: 'uppercase',
    lineHeight: 1,
  },
  icon: {
    fontSize: theme.spacing(3),
    cursor: 'pointer',
  },
  loader: {
    marginTop: theme.spacing(0.75),
  },
}));
