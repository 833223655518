import { Box } from '@mui/material';
import React from 'react';

import { PX_ELEMENT_ID } from './perimeter-x.constants';
import { usePerimeterX } from './perimeter-x.hooks';
import useStyles from './perimeter-x.styles';
import { PerimeterXProps } from './perimeter-x.types';

export const PerimeterX: React.FC<PerimeterXProps> = ({
  captchaPxLabel,
  onSuccess,
}) => {
  const classes = useStyles();

  usePerimeterX({ onSuccess });

  return (
    <Box
      id={PX_ELEMENT_ID}
      data-testid={PX_ELEMENT_ID}
      className={classes.pxElement}
      hidden
    >
      <h2>{captchaPxLabel}</h2>
    </Box>
  );
};
