import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  input: {
    width: '100%',
  },
  currencySelectWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gap: spacing(1),
  },
  currencyContainer: {
    position: 'relative',
  },
  currencySelectContainer: {
    backgroundColor: 'transparent',
    border: `1px solid ${colors.purpleNormal}`,
    borderRadius: spacing(0.5),
  },
  currencySelectIcon: {
    color: colors.purpleNormal,
  },
  equalSign: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 1),
  },
  receiveMethodWrapper: {
    margin: spacing(3, 0),
  },
  receiveMethodSelect: {
    height: '56px',
  },
}));
