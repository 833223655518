import { Container, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Image } from '../image';
import { useStyles } from './app-banner.styles';
import { AppBannerProps } from './app-banner.types';

export function AppBanner<Link>({
  name,
  bannerTitle,
  bannerDescription,
  playStoreIconLink,
  appStoreIconLink,
  image,
  LinkComponent,
  containerProps,
}: PropsWithChildren<AppBannerProps<Link>>): React.ReactElement {
  const classes = useStyles();

  return (
    <section className={classes.appBannerWrapper} id={name} {...containerProps}>
      <Container maxWidth="md" className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.text}>
            <Typography
              component="h2"
              variant="h2"
              data-testid="app-banner-title"
              gutterBottom
            >
              {bannerTitle}
            </Typography>
            <Typography
              variant="body2"
              data-testid="app-banner-description"
              className={classes.description}
            >
              {bannerDescription}
            </Typography>
            {/*
             // We need to render both and hide whichever we need to through CSS,
            // otherwise it breakes the style in NextJS due to the DOM differences
            // betwee server and client side.
            */}
            <div className={classes.links}>
              {appStoreIconLink && (
                <LinkComponent
                  {...appStoreIconLink}
                  key="app-banner-app-store-link"
                  className={classes.iconLink}
                />
              )}
              {playStoreIconLink && (
                <LinkComponent
                  {...playStoreIconLink}
                  key="app-banner-play-store-link"
                  className={classes.iconLink}
                />
              )}
            </div>
          </div>
          {image && (
            <div
              className={classes.imageWrapper}
              data-testid="app-banner-image"
            >
              <Image {...image} className={classes.image} />
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}
