import { Typography } from '@mui/material';
import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import {
  capitalizeFirstLetter,
  getSendCountryCodeFromLocale,
} from '@wr/web-ui';
import React from 'react';

import globeIcon from '../../../../static/icons/globe.svg';
import { CountryFlag } from '../../country-flag-image';
import { RegionDropdownProps } from '../region-dropdown.types';
import {
  getLanguageNames,
  getRegionDropdownOptions,
} from '../region-dropdown.utils';
import { useStyles } from './region-dropdown-item.styles';

function RegionDropdownGlobalItemComponent(
  { internationalLabel }: Pick<RegionDropdownProps, 'internationalLabel'>,
  ref: React.ForwardedRef<HTMLImageElement>,
) {
  const classes = useStyles();

  return (
    <>
      <img alt="" src={globeIcon} className={classes.flag} ref={ref} />
      <Typography component="span" variant="body2">
        {internationalLabel}
      </Typography>
      <Typography
        component="span"
        variant="body2"
        className={classes.secondaryLabel}
      >
        {getLanguageNames(DEFAULT_LANGUAGE).of(DEFAULT_LANGUAGE)}
      </Typography>
    </>
  );
}

RegionDropdownGlobalItemComponent.displayName = 'RegionDropdownGlobalItem';

export const RegionDropdownGlobalItem = React.forwardRef(
  RegionDropdownGlobalItemComponent,
);

type RegionDropdownItemComponentProps = {
  option: ReturnType<typeof getRegionDropdownOptions>[0];
};

function RegionDropdownItemComponent(
  { option }: RegionDropdownItemComponentProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) {
  const classes = useStyles();

  return (
    <>
      <CountryFlag
        className={classes.flag}
        code={getSendCountryCodeFromLocale(option.value)}
      />
      <Typography component="span" variant="body2" ref={ref}>
        {option.label}
      </Typography>
      {option?.secondaryLabel && (
        <Typography
          component="span"
          variant="body2"
          className={classes.secondaryLabel}
        >
          {capitalizeFirstLetter(
            getLanguageNames(option.secondaryLabel).of(option.secondaryLabel) ||
              '',
          )}
        </Typography>
      )}
    </>
  );
}

RegionDropdownItemComponent.displayName = 'RegionDropdownItem';

export const RegionDropdownItem = React.forwardRef(
  RegionDropdownItemComponent,
) as (
  props: RegionDropdownItemComponentProps & {
    ref?: React.ForwardedRef<HTMLSpanElement>;
  },
) => ReturnType<typeof RegionDropdownItemComponent>;
