import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors, getBackground, important, ThemeBreakpoints } from '@wr/web-ui';

import { FaqHeroProps } from './faq-hero.types';

type HeroStyleProps = Pick<
  FaqHeroProps,
  'primaryBackgroundColor' | 'secondaryBackgroundColor' | 'isSectionOnTop'
>;

export const useStyles = makeStyles<Theme, HeroStyleProps>(
  ({ spacing, breakpoints, typography }) => ({
    section: ({ primaryBackgroundColor, secondaryBackgroundColor }) => ({
      position: 'relative',
      background: getBackground(
        primaryBackgroundColor,
        secondaryBackgroundColor,
      ),
    }),
    sectionPadding: {
      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
      },
      [breakpoints.down(ThemeBreakpoints.xs)]: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
    },
    sectionContainer: ({ isSectionOnTop }) => ({
      display: 'grid',
      alignItems: 'flex-end',
      gridTemplateRows: 'auto auto',
      gridTemplateColumns: '1fr auto',
      marginBottom: spacing(3.75),
      paddingTop: spacing(isSectionOnTop ? 24 : 10),
      gap: spacing(0, 3),
      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        gap: spacing(0, 2),
        paddingTop: spacing(isSectionOnTop ? 17.75 : 10),
      },
      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        gap: spacing(3.75, 2),
        paddingTop: spacing(isSectionOnTop ? 15 : 10),
        marginBottom: spacing(3),
      },
    }),
    heroHeading: {
      gridRow: 1,
      gridColumn: 1,
      fontSize: spacing(10),
      color: colors.white,
      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        fontSize: spacing(6),
      },
      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        fontSize: spacing(4.5),
      },
    },
    heroImage: {
      height: 'auto',
      objectFit: 'contain',
      gridRow: '1/-1',
      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        width: spacing(20),
      },
      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        width: spacing(12.5),
        gridRow: 1,
        gridColumn: 2,
      },
    },
    heroSearchBarWrapper: {
      paddingTop: spacing(2),
      paddingBottom: spacing(4.25),
      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        paddingBottom: spacing(2),
      },
      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        gridRow: 2,
        gridColumn: '1/-1',
        paddingBottom: spacing(0),
      },
    },
    heroSearchBar: {
      '& .MuiInputBase-root': {
        borderRadius: 0,
        padding: important(spacing(1.75, 1.5)),
        background: colors.white,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        outline: 0,
        border: 0,
      },
      '& .MuiInputBase-input': {
        height: spacing(3),
        padding: important(0),
      },
    },
    linksContainer: {
      paddingBottom: spacing(1),
    },
    linkLabelContainer: {
      opacity: 1,
      flexDirection: 'row',
      textTransform: 'none',
      color: colors.purpleExtraLight,
      padding: spacing(1.5, 1),
      marginRight: spacing(3),
    },
    linkLabel: {
      fontSize: spacing(2.5),
    },
    menuToggleBtn: {
      'gap': spacing(1),
      'color': colors.white,
      'padding': spacing(0, 0),
      '& .MuiTypography-root': {
        fontSize: important(spacing(2.5)),
        fontWeight: typography.fontWeightBold,
      },
      '& .MuiSvgIcon-root': {
        fontSize: spacing(3),
      },
      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        padding: spacing(1.5, 0),
      },
    },
    tabsWrapper: {
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTabs-flexContainer .MuiLink-root .MuiTab-root img': {
        display: 'none',
      },
    },
    mobileOnly: {
      display: 'none',
      [breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    desktopOnly: {
      display: 'block',
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    dialog: {
      '& .MuiDialog-paper': {
        width: '100%',
      },
      '& .MuiDialogTitle-root': {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 0,
      },
      '& .MuiDialogTitle-root button': {
        outline: 0,
        background: 'transparent',
        border: 0,
      },
      '& .MuiDialogContent-root .MuiLink-root': {
        display: 'block',
        width: '100%',
      },
      '& .MuiDialogContent-root .MuiLink-root .MuiTab-root': {
        width: '100%',
        padding: spacing(1, 0),
        color: colors.purpleNormal,
        justifyContent: 'flex-start',
        textAlign: 'left',
      },
      '& .MuiDialogContent-root .MuiLink-root .MuiTab-root img': {
        width: spacing(3.5),
        height: spacing(3.5),
        marginRight: spacing(1),
      },
      '& .MuiDialogContent-root .MuiLink-root .MuiTab-root .link-image-placeholder': {
        width: spacing(3.5),
        height: spacing(3.5),
        marginRight: spacing(1),
      },
      '& .MuiDialogContent-root .MuiLink-root .MuiTab-root .MuiTypography-root': {
        fontSize: spacing(2.5),
        fontWeight: typography.fontWeightBold,
      },
    },
  }),
);
