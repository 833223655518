import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, important } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  container: {
    padding: spacing(3, 2),
    backgroundColor: colors.white,
    borderRadius: spacing(1),
    width: '100%',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: spacing(0, 0, 1.5),
  },
  corridorLink: {
    'textDecoration': important('none'),
    'color': important(colors.purpleNormal),

    '& .MuiTypography-root': {
      fontWeight: 500,
      textDecoration: important('underline'),
    },

    '& .MuiTypography-paragraph': {
      width: spacing(12),
      color: important(colors.black),
      textDecoration: important('none'),
      textAlign: 'right',
      margin: important(0),
    },

    '&:hover > div': {
      backgroundColor: alpha(colors.greyLight, 0.5),
    },
  },
  corridorRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.greyLight}`,
    padding: spacing(1.5, 1),
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
  },
}));
