import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';

import useStyles from './faq-module-item.styles';
import { FAQModuleItemProps } from './faq-module-item.types';

export function FAQModuleItem<RichText>({
  name,
  title,
  description,
  isExpanded,
  onShowClick,
  onHideClick,
  RichTextComponent,
  containerProps,
}: FAQModuleItemProps<RichText>): React.ReactElement {
  const classes = useStyles();

  const expandIcon = isExpanded ? (
    <RemoveIcon className={classes.expandIcon} />
  ) : (
    <AddIcon className={classes.expandIcon} />
  );

  return (
    <Accordion
      id={name}
      data-testid={name}
      classes={{
        rounded: classes.borderRounding,
      }}
      elevation={0}
      expanded={isExpanded}
    >
      <AccordionSummary
        data-testid="accordion-summary"
        onClick={() => (isExpanded ? onHideClick(name) : onShowClick(name))}
        expandIcon={expandIcon}
        {...containerProps}
      >
        <Typography
          component="h3"
          variant="body1"
          color="textSecondary"
          align="left"
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.expandedMenu}
        data-testid={`${name}-accordion-content`}
      >
        {description && <RichTextComponent {...description} />}
      </AccordionDetails>
    </Accordion>
  );
}
