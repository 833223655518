import { Box, Typography } from '@mui/material';
import React from 'react';

import { CalculatorLoader } from '../../calculator-loader';
import { ExchangeRateTextProps } from './exchange-rate-text.types';

export const ExchangeRateText: React.FC<ExchangeRateTextProps> = ({
  exchangeRateText,
  isFetching,
  customColor,
  crossedOut,
  isTop,
}) => {
  return (
    <Box
      display="flex"
      color={customColor ? customColor : isTop ? 'white' : 'primary.dark'}
      justifyContent={isTop ? 'center' : 'left'}
      mb={isTop ? 0 : 2}
    >
      <Typography
        data-testid="exchange-rate-text"
        component="span"
        variant={isTop ? 'h4' : 'body1'}
      >
        {crossedOut ? (
          <s>{exchangeRateText}</s>
        ) : isTop ? (
          <strong>{exchangeRateText}</strong>
        ) : (
          exchangeRateText
        )}
      </Typography>
      {isFetching && (
        <Box
          display="flex"
          alignItems="center"
          marginLeft={1}
          data-testid="exchange-loader"
        >
          <CalculatorLoader size={isTop ? 5 : 4} />
        </Box>
      )}
    </Box>
  );
};
