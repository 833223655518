import { GetIntlMessagesConfig } from './messages';

export const getCurrenciesCodesAndName = (
  country:
    | GetIntlMessagesConfig['sendCountry']
    | GetIntlMessagesConfig['receiveCountry'],
  countries:
    | GetIntlMessagesConfig['sendCountries']
    | GetIntlMessagesConfig['receiveCountries'],
): [currenciesCodes: string[], currenciesNames: string[]] => {
  // countries return duplicated objects because country can have multiple currencies
  const _countries = countries.filter(_country => {
    return _country.countryCode === country.countryCode;
  });

  const currenciesCodes = _countries.map(_country => _country.currency);
  const currenciesNames = _countries.map(_country => _country.currencyName);

  return [currenciesCodes, currenciesNames];
};
