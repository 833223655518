import { contentfulSchema } from '@wr/web-shared';
import { ListOfCountries as UIListOfCountries } from '@wr/web-ui';
import React, { useContext } from 'react';

import { AppContext } from '@/context';
import { useIntlMessages } from '@/hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

import { Button, ButtonProps } from '../button';

export const ListOfCountries: React.FC<contentfulSchema.ListOfCountriesFragment> = ({
  sys,
  __typename,
  name,
  header,
  subheader,
  countriesCollection,
  buttonLabelHiddenList,
  buttonLabelExpandedList,
}) => {
  const { countriesByCode, receiveCountries = [] } = useContext(AppContext);
  const intlMessages = useIntlMessages();

  const receiveCountriesByCode = Object.fromEntries(
    receiveCountries.map(receiveCountry => {
      return [receiveCountry.countryCode, receiveCountry.name];
    }),
  );

  return (
    <UIListOfCountries<ButtonProps>
      name={name || undefined}
      header={interpolate(header, intlMessages)}
      subheader={interpolate(subheader, intlMessages)}
      buttonLabelExpandedList={buttonLabelExpandedList || null}
      buttonLabelHiddenList={buttonLabelHiddenList || null}
      allCountries={
        Object.entries(receiveCountriesByCode).map(
          ([countryCode, countryName]) => ({
            code: countryCode.toLowerCase(),
            name: countryName,
            variant: 'contained',
            url: countriesByCode[countryCode]?.slug,
            fullWidth: true,
            color: 'primary',
          }),
        ) || []
      }
      visibleCountriesCodes={
        countriesCollection?.items.map(c => c?.code || '') || []
      }
      ButtonComponent={Button}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
