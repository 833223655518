import { BLOCKS } from '@contentful/rich-text-types';
import { Theme, useMediaQuery } from '@mui/material';
import { Container, Search } from '@wr/web-ui';
import React from 'react';

import { Anchor, CexCurrencyGrid } from '@/components';
import { RichTextContentRenderer } from '@/components/rich-text-content-renderer';

import { useSearchFilter } from './cex-search.hook';
import { useStyles } from './cex-search.styles';
import { CexSearchProps } from './cex-search.types';

export const CexSearch = ({
  name,
  titleTemplate,
  searchInputLabel,
  searchResultListLabel,
  showCurrencyGrid,
  sendCurrencyCode,
  sendCountryCode,
  corridors,
}: CexSearchProps) => {
  const classes = useStyles();

  const {
    onKeyDown,
    onInputChange,
    onFilterOptions,
    searchResults,
    currencies,
  } = useSearchFilter({ sendCountryCode, corridors });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Container
      component="section"
      data-testid="cex-search-container"
      className={classes.container}
      id={name || ''}
    >
      {titleTemplate && (
        <RichTextContentRenderer
          json={titleTemplate.json}
          options={{
            [BLOCKS.HEADING_1]: {
              fontSize: isMobile ? '2rem' : '2.5rem',
            },
            [BLOCKS.PARAGRAPH]: {
              variant: 'body2',
              fontSize: isMobile ? '1rem' : '1.25rem',
            },
          }}
        />
      )}

      <div
        className={classes.searchBarWrapper}
        data-testid="cex-search-wrapper"
      >
        <Search
          options={searchResults}
          id={`${name}-autocomplete`}
          placeholder={searchInputLabel || 'Search'}
          resultListLabel={searchResultListLabel}
          className={classes.heroSearchBar}
          onInputChange={onInputChange}
          filterOptions={onFilterOptions}
          onEnter={onKeyDown}
          LinkComponent={Anchor}
        />
      </div>

      {showCurrencyGrid && (
        <CexCurrencyGrid
          {...{
            currencies,
            sendCurrencyCode,
            sendCountryCode,
          }}
        />
      )}
    </Container>
  );
};
