import { useMutation } from '@apollo/client';
import { Alert, Paper } from '@mui/material';
import { bffSchema } from '@wr/web-shared';
import { SelectOption } from '@wr/web-ui';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useReducer, useState } from 'react';

import { Anchor } from '@/components/anchor';
import { calculatorReducer } from '@/components/calculator/component/calculator.reducer';
import {
  CalculatorProps,
  DefaultItem,
} from '@/components/calculator/component/calculator.types';
import { ExchangeRateTop } from '@/components/calculator/src/components/exchange-rate/exchange-rate-top';
import { PayoutDetails } from '@/components/calculator/src/composition/payout-details';
import { TopUpAmount } from '@/components/calculator/src/composition/top-up-amount';
import { isPositiveFloat } from '@/components/calculator/src/reducer/utils';
import { CALCULATOR_QUERY_PARAMS } from '@/constants';
import { AppContext } from '@/context';
import { getSingleValueFromQueryParam } from '@/utils';

import { Buttons } from '../src/components/buttons';
import { PhoneInputInfo } from '../src/components/phone-input-info';
import Exchange from '../src/composition/exchange';
import { PayoutCorrespondentSelect } from '../src/composition/payout-correspondent-select';
import { PayoutMethod } from '../src/composition/payout-method';
import PerimeterX from '../src/composition/perimeterx';
import { PhoneValidator } from '../src/composition/phone-validator';
import { CalculatorContext, calculatorInitialState } from '../src/context';
import { PayoutMethodsEnum } from '../src/types';
import { useDataLayerErrorPush } from './calculator.hooks';
import { useStyles } from './calculator.styles';
import {
  getGenericCalculationErrorMessages,
  setDataLayerForCalculation,
} from './calculator.utils';

export const Calculator: React.FC<CalculatorProps> = ({
  isLite,
  feeLabel,
  instantDiscountLabel,
  sendLabel,
  loginLink,
  signUpLink,
  receiveLabel,
  partnersLabel,
  totalToPayLabel,
  topUpAmountsLabel,
  topUpAmountsTitle,
  transferTimeLabel,
  exchangeRateLabel,
  payoutMethodsLabel,
  calculatorPageSlug,
  generalErrorMessage,
  phoneValidatorLabel,
  promotionalTermsLink,
  exchangeRatePromoLabel,
  topUpAmountsPlaceholder,
  phoneInputInfoPopupText,
  partnersListDefaultValue,
  partnerSelectErrorMessage,
  phoneValidatorPlaceholder,
  countriesSearchPlaceholder,
  contentfulPayoutMethodsList,
  phoneInputInfoMoreInfoLabel,
  phoneNumberInputErrorMessage,
  topUpAmountsSelectErrorMessage,
  phoneNumberInvalidErrorMessage,
  unavailableCorridorErrorMessage,
  senderPayoutMethodUnavailableMessage,
  receiverPayoutMethodUnavailableMessage,
  topUpAmountsSelectRetrievalErrorMessage,
  phReceiveBankTransferMessage,
}) => {
  const router = useRouter();
  const classes = useStyles();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [payInMethod, setPayInMethod] = useState<
    | {
        id: string;
        payInMethodCalculations: [];
      }[]
    | []
  >([]);

  const [error, setError] = useState<string>('');
  const {
    messages,
    payoutMethods,
    receiveCountry,
    receiveCountries,
    payoutMethodAlerts,
    calculatorSendCountry,
    selectedPayoutMethodId: initialPayoutMethodId,
    intl,
  } = useContext(AppContext);

  const initialReducer = () => {
    const initialSendAmount = getSingleValueFromQueryParam(
      router.query?.[CALCULATOR_QUERY_PARAMS.AMOUNT_FROM],
    );

    const validatedSendAmount =
      initialSendAmount && isPositiveFloat(initialSendAmount)
        ? initialSendAmount
        : calculatorInitialState.sendFromValue;

    return {
      ...calculatorInitialState,
      sendCountry: calculatorSendCountry,
      receiveCountry,
      receiveCountries,
      payoutMethods,
      payoutMethodAlerts,
      sendFromValue: validatedSendAmount,
      selectedPayoutMethodId: initialPayoutMethodId,
    };
  };
  const [state, dispatch] = useReducer(calculatorReducer, initialReducer());

  const getDefaultCorrespondentForSelectedPayoutMethod = (
    code: string,
  ): string => {
    if (!payoutMethods) {
      return '';
    }
    const selectedPayoutMethod = payoutMethods.find(
      item => item?.code === code,
    );
    if (!selectedPayoutMethod) {
      return '';
    }
    const correspondentId = selectedPayoutMethod.correspondents?.[0]?.id;
    return correspondentId ?? '';
  };

  useEffect(() => {
    dispatch({
      type: 'CREATE_CALCULATION_PENDING',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      state.sendCountry?.countryCode !== calculatorSendCountry?.countryCode ||
      state.receiveCountry?.countryCode !== receiveCountry?.countryCode ||
      state.receiveCountry?.currency !== receiveCountry?.currency
    ) {
      dispatch({
        type: 'REINITIALIZE',
        payload: {
          sendCountry: calculatorSendCountry,
          receiveCountries,
          payoutMethods,
          receiveCountry,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    receiveCountry?.currency,
    receiveCountry?.countryCode,
    calculatorSendCountry?.currency,
    calculatorSendCountry?.countryCode,
  ]);

  const [
    createCalculation,
    calculationResult,
  ] = useMutation<bffSchema.CreateCalculationMutation>(
    bffSchema.CreateCalculation,
    {
      onCompleted: result => {
        if (
          result.createCalculation.calculation?.send &&
          result.createCalculation.calculation?.receive
        ) {
          const sendFromValue = Number(
            result.createCalculation.calculation.send.amount,
          ).toFixed(2);
          const sendToValue = Number(
            result.createCalculation.calculation.receive.amount,
          ).toFixed(2);
          const feeValue = Number(
            result.createCalculation.calculation.informativeSummary.fee.value
              .amount,
          ).toFixed(2);
          const totalToPay = Number(
            result.createCalculation.calculation.informativeSummary.totalToPay
              .amount,
          ).toFixed(2);

          setDataLayerForCalculation({
            isLite,
            selectedPayoutMethodId: state.selectedPayoutMethodId,
            exchangeRate: result.createCalculation.calculation.exchangeRate,
            sendFromValue,
            state,
          });

          dispatch({
            type: 'CREATE_CALCULATION_SUCCESS',
            payload: {
              sendFromValue,
              sendToValue,
              feeValue,
              totalToPay,
            },
          });
        }
        if (result.createCalculation.errors?.length) {
          dispatch({
            type: 'CREATE_CALCULATION_ERROR',
            payload: {
              errors: result.createCalculation.errors,
            },
          });
        }
      },
      onError: error => {
        if (error) {
          dispatch({
            type: 'CREATE_CALCULATION_ERROR',
            payload: {
              errors: [],
            },
          });
        }
      },
    },
  );

  const [
    createProductsCalculation,
    resultCreateProductsCalculation,
  ] = useMutation<bffSchema.CreateProductsCalculationMutation>(
    bffSchema.CreateProductsCalculation,
    {
      onCompleted: ({ createProductsCalculation }) => {
        if (createProductsCalculation?.errors?.[0]?.message) {
          setError(createProductsCalculation?.errors?.[0]?.message);
        }
        const itemOptions =
          createProductsCalculation?.calculation?.productsCalculations?.map(
            productCalculation => ({
              label: productCalculation?.product?.name ?? '',
              value: productCalculation?.product?.id ?? '',
            }),
          ) ?? [];

        const itemPayInMethodCalculations =
          createProductsCalculation?.calculation?.productsCalculations?.map(
            productCalculation => ({
              id: productCalculation?.product?.id || '',
              payInMethodCalculations:
                productCalculation?.payInMethodCalculations || [],
            }),
          ) ?? [];

        setPayInMethod(() => [
          ...(((itemPayInMethodCalculations as unknown) as []) as {
            id: string;
            payInMethodCalculations: [];
          }[]),
        ]);
        setOptions(() => [...itemOptions]);
      },
      onError: error => {
        setError(error?.message);
        dispatch({
          type: 'CREATE_AIRTIME_CALCULATION_ERROR',
          payload: {
            errors: [],
          },
        });
      },
    },
  );

  useEffect(() => {
    const hashValueFromUrl = router.asPath.split('#')[1];
    const hash = hashValueFromUrl ? `#${hashValueFromUrl}` : undefined;

    router.replace(
      {
        pathname: router.pathname,
        hash,
        query: {
          ...router.query,
          [CALCULATOR_QUERY_PARAMS.AMOUNT_FROM]: state.sendFromValue,
          [CALCULATOR_QUERY_PARAMS.SELECT_FROM]: state.sendCountry?.countryCode?.toLowerCase?.(),
          [CALCULATOR_QUERY_PARAMS.CURRENCY_FROM]: state.sendCountry?.currency?.toLowerCase?.(),
          [CALCULATOR_QUERY_PARAMS.SELECT_TO]: state.receiveCountry?.countryCode?.toLowerCase?.(),
          [CALCULATOR_QUERY_PARAMS.CURRENCY_TO]: state.receiveCountry?.currency?.toLowerCase?.(),
          ...(state.selectedPayoutMethodId
            ? {
                [CALCULATOR_QUERY_PARAMS.TRANSFER]: state.selectedPayoutMethodId.toLowerCase(),
              }
            : {}),
        },
      },
      undefined,
      {
        shallow: true,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selectedPayoutMethodId,
    state.sendFromValue,
    state.sendCountry?.countryCode,
    state.sendCountry?.currency,
    state.receiveCountry?.countryCode,
    state.receiveCountry?.currency,
  ]);

  useEffect(() => {
    if (state.lastAction === 'CREATE_CALCULATION_PENDING') {
      if (
        state.selectedPayoutMethodId &&
        state.payoutMethods.length &&
        state.sendFromValue &&
        state.sendToValue &&
        state.sendCountry?.countryCode &&
        state.sendCountry?.currency &&
        state.receiveCountry?.countryCode &&
        state.receiveCountry?.currency
      ) {
        if (!isAirtime) {
          createCalculation({
            variables: {
              amount:
                state.calculationType === bffSchema.CalculationType.Send
                  ? parseFloat(state.sendFromValue)
                  : parseFloat(state.sendToValue),
              type: state.calculationType,
              sendCountryCode: state.sendCountry?.countryCode,
              sendCurrencyCode: state.sendCountry?.currency,
              receiveCountryCode: state.receiveCountry?.countryCode,
              receiveCurrencyCode: state.receiveCountry?.currency,
              payOutMethodCode: state.selectedPayoutMethodId,
              correspondentId: state.correspondentId,
            },
          });
        }
      } else {
        dispatch({
          type: 'CREATE_CALCULATION_ERROR',
          payload: {
            errors: [],
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction]);

  useEffect(() => {
    if (isAirtime && state.lastAction === 'VALIDATE_PHONE_NUMBER_SUCCESS') {
      createProductsCalculation({
        variables: {
          productsCalculationInput: {
            mobileNumber: !state.receiveCountry?.dialCode
              ? state.mobileNumber
              : `+${state.receiveCountry.dialCode}${state.mobileNumber}`,
            send: {
              country: state.sendCountry?.countryCode,
              currency: state.sendCountry?.currency,
            },
            receive: {
              country: state.receiveCountry?.countryCode,
              currency: state.receiveCountry?.currency,
            },
            payOutMethodCode: state.selectedPayoutMethodId,
            correspondentId:
              state.correspondentId ??
              getDefaultCorrespondentForSelectedPayoutMethod(
                state.selectedPayoutMethodId || '',
              ),
          },
        },
      });
    }

    if (
      state.lastAction === 'CREATE_AIRTIME_CALCULATION_PENDING' &&
      !isLite &&
      isAirtime &&
      state.isPhoneNumberValid &&
      state.mobileNumber &&
      state.topUpUpDenominationId &&
      state.sendCountry?.countryCode &&
      state.sendCountry?.currency &&
      state.receiveCountry?.countryCode
    ) {
      const selectedItem = payInMethod?.find(
        (item: { id: string }) => item.id === state.topUpUpDenominationId,
      );

      const defaultItem = selectedItem?.payInMethodCalculations.find(
        (row: { isDefault: boolean }) => row.isDefault,
      );

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { fee, totalToPay } = (defaultItem! as DefaultItem) || {};

      const feeValue = Number((fee?.amount as number) ?? 0).toFixed(2);

      const toPay = Number(totalToPay?.amount as number).toFixed(2);

      dispatch({
        type: 'CREATE_AIRTIME_CALCULATION_SUCCESS',
        payload: {
          sendFromValue: state.sendFromValue,
          sendToValue: state.sendToValue,
          feeValue,
          totalToPay: toPay,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction]);

  const genericErrorMessages = getGenericCalculationErrorMessages(state, {
    receiveCountries,
    calculationResult,
    generalErrorMessage,
    selectedPayoutMethodId: state.selectedPayoutMethodId,
    payoutMethodsLabelsById: intl.payoutMethodsLabelsById,
    unavailableCorridorErrorMessage,
    senderPayoutMethodUnavailableMessage,
    receiverPayoutMethodUnavailableMessage,
  });

  useDataLayerErrorPush(isLite, state.errors, genericErrorMessages);

  const isAirtime =
    state.selectedPayoutMethodId === PayoutMethodsEnum.AIRTIME_TOP_UP;
  const isAlipay = state.selectedPayoutMethodId === PayoutMethodsEnum.ALIPAY;
  const isPromo =
    calculationResult.data?.createCalculation?.calculation?.informativeSummary?.appliedPromotions?.includes(
      bffSchema.AppliedPromotions.Cbp,
    ) || false;

  return (
    <CalculatorContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {isLite && !isAirtime && (
        <ExchangeRateTop
          isFetching={state.isLoading || calculationResult.loading}
          fromCurrency={state.sendCountry?.currency ?? ''}
          toCurrency={state.receiveCountry?.currency ?? ''}
          exchangeRate={
            calculationResult.data?.createCalculation?.calculation?.exchangeRate
          }
          exchangeRateLabel={exchangeRateLabel || ''}
          exchangeRatePromoLabel={exchangeRatePromoLabel || ''}
          isPromo={isPromo}
        />
      )}
      <Paper
        data-testid="calculator"
        elevation={0}
        classes={{
          root: classes.calculator,
          rounded:
            !isLite || (isLite && isAirtime)
              ? classes.rounded
              : classes.roundedBottom,
        }}
      >
        <PerimeterX captchaPxLabel={messages?.captchaPxLabel || undefined} />
        <Exchange
          isFetching={calculationResult.loading}
          exchangeRate={
            calculationResult.data?.createCalculation?.calculation?.exchangeRate
          }
          receiveLabel={receiveLabel}
          sendLabel={sendLabel}
          countriesSearchPlaceholder={countriesSearchPlaceholder}
          isLite={isLite}
          exchangeRatePromoLabel={exchangeRatePromoLabel || ''}
          isPromo={isPromo}
        />
        <PayoutMethod
          isLite={isLite}
          isFetching={calculationResult.loading}
          contentfulPayoutMethodsList={contentfulPayoutMethodsList}
          payoutMethodsLabel={payoutMethodsLabel}
          phReceiveBankTransferMessage={phReceiveBankTransferMessage}
        />

        {!isLite && partnersLabel && !isAirtime && !isAlipay && (
          <PayoutCorrespondentSelect
            contentfulPayoutMethodsList={contentfulPayoutMethodsList}
            partnersLabel={partnersLabel}
            partnersListDefaultValue={partnersListDefaultValue}
            partnerSelectErrorMessage={partnerSelectErrorMessage}
          />
        )}
        {!isLite && isAirtime && (
          <>
            <PhoneValidator
              phoneValidatorLabel={phoneValidatorLabel}
              phoneValidatorPlaceholder={phoneValidatorPlaceholder}
              phoneNumberInvalidErrorMessage={phoneNumberInvalidErrorMessage}
            />
            <PhoneInputInfo
              phoneInputInfoMoreInfoLabel={phoneInputInfoMoreInfoLabel}
              phoneInputInfoPopupText={phoneInputInfoPopupText}
            />
            <TopUpAmount
              options={options}
              error={error}
              topUpAmountsLabel={topUpAmountsLabel}
              topUpAmountsTitle={topUpAmountsTitle}
              topUpAmountsPlaceholder={topUpAmountsPlaceholder}
              topUpAmountsSelectErrorMessage={topUpAmountsSelectErrorMessage}
              topUpAmountsSelectRetrievalErrorMessage={
                topUpAmountsSelectRetrievalErrorMessage
              }
            />
          </>
        )}

        {genericErrorMessages?.map(errorMessage => (
          <Alert
            severity="error"
            icon={false}
            key={errorMessage}
            data-testid="generic-error"
          >
            {errorMessage}
          </Alert>
        ))}
        {!isLite &&
          isAirtime &&
          !state.mobileNumber &&
          phoneNumberInputErrorMessage && (
            <Alert
              severity="error"
              icon={false}
              data-testid="phone-number-error"
            >
              {phoneNumberInputErrorMessage}
            </Alert>
          )}
        {!isLite && (
          <PayoutDetails
            isLoading={calculationResult.loading}
            feeLabel={feeLabel}
            instantDiscountLabel={instantDiscountLabel}
            transferTimeLabel={transferTimeLabel}
            totalToPayLabel={totalToPayLabel}
          />
        )}

        {isPromo && !isAirtime && (
          <Anchor {...promotionalTermsLink}>
            {promotionalTermsLink?.label}
          </Anchor>
        )}

        <Buttons
          isLite={isLite}
          loginLink={loginLink}
          signUpLink={signUpLink}
          calculatorPageSlug={calculatorPageSlug}
          calculationResult={calculationResult}
          resultCreateProductsCalculation={resultCreateProductsCalculation}
        />
      </Paper>
    </CalculatorContext.Provider>
  );
};
