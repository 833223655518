import ExpandMoreIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Accordion, Container as UIContainer, Image } from '@wr/web-ui';
import React from 'react';

import { Anchor } from '@/components';

import { FaqPopularQuestions } from '../faq-popular-questions';
import { useStyles } from './faq-topics-module.styles';
import { FaqTopicsModuleProps } from './faq-topics-module.types';

export const FaqTopicsModule: React.FC<FaqTopicsModuleProps> = ({
  title,
  backgroundColor,
  popularQuestions,
  topics,
}) => {
  const classes = useStyles({ backgroundColor });
  const [expandedPanel, setExpandedPanel] = React.useState<string>();

  const handleAccordionChange = (index: number) => {
    setExpandedPanel(prev =>
      prev === `panel_${index}` ? undefined : `panel_${index}`,
    );
  };

  return (
    <section className={classes.sectionWrapper} data-testid="faq-topics-module">
      <UIContainer className={classes.gridWrapper}>
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>

        <div className={classes.questionsWrapper}>
          {popularQuestions && <FaqPopularQuestions {...popularQuestions} />}
        </div>

        <div className={classes.topicsWrapper}>
          {topics.items.map((topic, index) => (
            <Accordion
              className={classes.accordion}
              data-testid={`faq-topics-module-item-${topic.sys.id}`}
              expanded={expandedPanel === `panel_${index}`}
              onChange={() => handleAccordionChange(index)}
              key={topic.sys.id}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Image
                  {...topic.icon}
                  className={classes.accordionSummaryAvatar}
                />
                <div>
                  <Typography
                    variant="h3"
                    className={classes.accordionSummaryTitle}
                  >
                    {topic.title}
                  </Typography>
                  <Typography
                    className={classes.accordionSummaryDescription}
                    paragraph
                  >
                    {topic.description}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {topic.topicLinks.items.map(link => (
                  <div key={link.sys.id}>
                    <Anchor
                      url={link.slug}
                      data-testid={`faq-topics-module-item-link-${link.sys.id}`}
                      className={classes.accordionDetailsLink}
                      underline="none"
                    >
                      <span className={classes.accordionDetailsLinkText}>
                        {link.name}
                      </span>
                      <ArrowRightAltIcon />
                    </Anchor>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </UIContainer>
    </section>
  );
};
