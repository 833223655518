import { useEffect, useState } from 'react';

import { CalculatorContextType } from '@/context';
import {
  CurrencyPair,
  DateRange,
  getDateRange,
  HistoricalRate,
} from '@/lib/rest/currency-converter/historical-rates';
import { fetchHistoricalRates } from '@/services';

export const useCexHistoricalRates = (
  currencyPair: CurrencyPair,
  selectedPayoutMethodId: CalculatorContextType['payoutMethodId'],
  range: DateRange,
) => {
  const [historicalRates, setHistoricalRates] = useState<HistoricalRate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const { startDate, endDate } = getDateRange(range);

    const fetchRates = async () => {
      try {
        setLoading(true);
        const data = await fetchHistoricalRates(
          currencyPair,
          selectedPayoutMethodId,
          startDate,
          endDate,
        );

        setHistoricalRates(data);
        setError(null);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : 'An unknown error occurred',
        );
      } finally {
        setLoading(false);
      }
    };

    if (currencyPair[0] && currencyPair[1]) {
      fetchRates();
    }
  }, [currencyPair, range, selectedPayoutMethodId]);

  return {
    historicalRates,
    loading,
    error,
  };
};
