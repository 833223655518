import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(({ spacing, customComponents }) => ({
  inputBase: {
    'backgroundColor': customComponents.countrySearch.inputBase.backgroundColor,
    'borderRadius': '0.25rem',
    'border': `1px solid ${customComponents.countrySearch.inputBase.border}`,
    'boxSizing': 'border-box',
    'fontSize': '1.25rem',

    '&:focus-within': {
      outline: 'none',
      boxShadow: `0 0 0 4px ${customComponents.countrySearch.inputBase.boxShadow}`,
    },
  },
  input: {
    '&::placeholder': {
      fontSize: '1rem',
    },
  },
  option: {
    'padding': spacing(1.5, 2.375),
    '&[data-focus="true"]': {
      backgroundColor: customComponents.countrySearch.option.backgroundColor,
    },
  },
  countryRowContainer: {
    display: 'block',
  },
  button: {
    marginTop: spacing(4),
  },
  icon: {
    fontSize: '2rem',
    cursor: 'pointer',
  },
}));

export default useStyles;
