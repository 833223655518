import { Grid, TextField } from '@mui/material';
import { DROPDOWN, INTERACTION_NAME } from '@wr/web-shared';
import {
  CountryData,
  ExchangeRateCalculationType,
  OnCalculatorCountryChangeEvent,
  Select,
} from '@wr/web-ui';
import React, { useMemo } from 'react';

import { useRateAlertFields } from '../cex-rate-alerts.hooks';
import { CexRateAlertsFieldsProps } from '../cex-rate-alerts.types';
import { pushToDataLayerWithDropdown } from '../cex-rate-alerts.utils';
import { CurrencySelect } from './cex-rate-alerts-fields.currency.select';
import { useStyles } from './cex-rate-alerts-fields.styles';

export const CexRateAlertsFields = ({
  textFieldPlaceholder,
  receiveMethodLabel,
}: CexRateAlertsFieldsProps) => {
  const classes = useStyles();

  const {
    email,
    setEmail,
    selectedPayoutMethod,
    setSelectedPayoutMethod,
    selectedSendCountry,
    selectedReceiveCountry,
    setSelectedSendCountry,
    setSelectedReceiveCountry,
    receiveCountries,
    sendCountries,
    payoutMethods,
  } = useRateAlertFields();

  const handleCurrencyChange = ({
    country,
    calculationType,
  }: OnCalculatorCountryChangeEvent) => {
    if (calculationType === ExchangeRateCalculationType.Send) {
      setSelectedSendCountry(country as CountryData);
    } else if (calculationType === ExchangeRateCalculationType.Receive) {
      setSelectedReceiveCountry(country as CountryData);
    }

    pushToDataLayerWithDropdown(
      calculationType === ExchangeRateCalculationType.Send
        ? DROPDOWN.SEND_FROM_COUNTRY
        : DROPDOWN.SEND_TO_COUNTRY,
      INTERACTION_NAME.DROPDOWN_SELECTION,
      country.countryCode,
    );
  };

  const onDropdownOpen = (dropdownName: DROPDOWN) => {
    pushToDataLayerWithDropdown(dropdownName, INTERACTION_NAME.DROPDOWN_OPEN);
  };

  const currencySelectClasses = useMemo(
    () => ({
      selectContainer: classes.currencySelectContainer,
      selectIcon: classes.currencySelectIcon,
    }),
    [classes.currencySelectContainer, classes.currencySelectIcon],
  );

  return (
    <Grid alignItems="center">
      <div className={classes.currencySelectWrapper}>
        <CurrencySelect
          id="cex-rates-alerts-send"
          selectedCountry={selectedSendCountry}
          onDropdownOpen={() => onDropdownOpen(DROPDOWN.SEND_FROM_COUNTRY)}
          calculationType={ExchangeRateCalculationType.Send}
          autocompletePlaceholder="Search Send Currency"
          onCountryChange={handleCurrencyChange}
          classes={currencySelectClasses}
          countries={sendCountries}
        />
        <div className={classes.equalSign}>=</div>
        <CurrencySelect
          id="cex-rates-alerts-receive"
          selectedCountry={selectedReceiveCountry}
          onDropdownOpen={() => onDropdownOpen(DROPDOWN.SEND_TO_COUNTRY)}
          calculationType={ExchangeRateCalculationType.Receive}
          autocompletePlaceholder="Search Receive Currency"
          onCountryChange={handleCurrencyChange}
          classes={currencySelectClasses}
          countries={receiveCountries}
        />
      </div>

      <div className={classes.receiveMethodWrapper}>
        <Select
          name={receiveMethodLabel || ''}
          value={selectedPayoutMethod || ''}
          data-testid="receive-method"
          options={(payoutMethods || []).map(payoutMethod => ({
            value: payoutMethod.code,
            label: payoutMethod.name,
          }))}
          label={receiveMethodLabel || ''}
          onChange={(dropdownValue): void =>
            setSelectedPayoutMethod(dropdownValue)
          }
          forwardedProps={{
            displayEmpty: true,
            renderValue: () => <span>{selectedPayoutMethod}</span>,
            fullWidth: true,
            color: 'primary',
            variant: 'outlined',
          }}
          className={classes.receiveMethodSelect}
        />
      </div>

      <TextField
        type="email"
        label={textFieldPlaceholder}
        aria-haspopup="listbox"
        aria-controls={undefined}
        variant="outlined"
        value={email}
        onChange={e => setEmail(e.target.value)}
        className={classes.input}
        InputProps={{
          inputProps: {
            'aria-labelledby': 'ariaLabelledBy',
          },
        }}
      />
    </Grid>
  );
};
