import { EVENT, INTERACTION_NAME, pushToDataLayer } from '@wr/web-shared';
import { NextRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { fallbackLocales } from '@/constants';
import { getMatchedLocale } from '@/utils';

export const useUpdateBlogSelectionAndLocale = (
  router: NextRouter,
  all?: string,
) => {
  const BLOG_PATH = '/blog' as const;

  const hasFallbackLocales = fallbackLocales
    .map((fallbackLocale: string) => router.locale === fallbackLocale)
    .includes(true);

  useEffect(() => {
    if (!hasFallbackLocales && router.pathname.includes(BLOG_PATH)) {
      router.push(router.asPath, undefined, {
        locale: getMatchedLocale(router.locale as string),
      });
    }
  }, [router, BLOG_PATH, hasFallbackLocales]);

  const onBlogCategorySelect = useCallback(
    (newCategory: string): void => {
      const data = {
        event: EVENT.VISITOR_INTERACTION,
        interactionName: INTERACTION_NAME.HEADER_BLOG_CATEGORY_CLICK,
        categoryClicked: newCategory,
      };

      pushToDataLayer(data);

      const pathname =
        newCategory !== all ? `${BLOG_PATH}/${newCategory}` : BLOG_PATH;

      if (!hasFallbackLocales) {
        router.push(
          router.asPath,
          {
            pathname,
          },
          {
            locale: getMatchedLocale(router.locale as string),
          },
        );
      } else {
        router.push({
          pathname,
        });
      }
    },
    [router, all, hasFallbackLocales],
  );

  return {
    onBlogCategorySelect,
  };
};
