import { GetServerSidePropsContext, GetStaticPropsContext } from 'next/types';

export const getLanguageFromLocale = (
  locale: GetServerSidePropsContext['locale'] | GetStaticPropsContext['locale'],
): string | undefined => locale?.split('-')[0];

/**
 * Returns the country code from the locale
 * if there is no country code set, it returns undefined
 * @param locale
 */
export const getSendCountryCodeFromLocale = (
  locale: GetServerSidePropsContext['locale'] | GetStaticPropsContext['locale'],
): string | undefined => {
  return locale?.split('-')[1];
};
