import { Box, Typography } from '@mui/material';
import React from 'react';

import { getExchangeRateText } from '../exchange-rate.utils';
import { ExchangeRateText } from '../exchange-rate-text';
import useStyles from './exchange-rate-top.styles';
import { ExchangeRateTopProps } from './exchange-rate-top.types';

export const ExchangeRateTop: React.FC<ExchangeRateTopProps> = ({
  isFetching,
  exchangeRate,
  exchangeRateLabel,
  exchangeRatePromoLabel,
  fromCurrency,
  toCurrency,
  isPromo,
}) => {
  const classes = useStyles();
  const exchangeRateText = getExchangeRateText(
    exchangeRate,
    isFetching,
    fromCurrency,
    toCurrency,
  );
  const exchangeRateWithPromoText = getExchangeRateText(
    exchangeRate,
    isFetching,
    fromCurrency,
    toCurrency,
    true,
  );

  return isPromo ? (
    <Box
      padding={2}
      className={classes.exchangeRatePromotionalWrapper}
      color="black"
      textAlign="center"
      data-testid="exchange-rate-with-promotion"
    >
      <Typography
        variant="h5"
        gutterBottom
        data-testid="exchange-rate-promo-label"
      >
        {exchangeRatePromoLabel}
      </Typography>
      <ExchangeRateText
        exchangeRateText={exchangeRateWithPromoText}
        isFetching={isFetching}
        customColor="black"
        crossedOut
        isTop
      />
      <ExchangeRateText
        exchangeRateText={exchangeRateText}
        customColor="#813FD6"
        isFetching={isFetching}
        isTop
      />
    </Box>
  ) : (
    <Box
      padding={2}
      className={classes.exchangeRateWrapper}
      color="white"
      textAlign="center"
    >
      <Typography gutterBottom>{exchangeRateLabel}</Typography>
      <ExchangeRateText
        exchangeRateText={exchangeRateText}
        isFetching={isFetching}
        isTop
      />
    </Box>
  );
};
