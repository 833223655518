import { ANALYTICS_PAGE_TYPE, DEFAULT_LANGUAGE } from '@wr/web-shared';
import { NextRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { PageProps } from '@/types';

import {
  PAGE_LOAD_EVENT,
  VIRTUAL_PAGE_VIEW_EVENT,
} from './gtm-page-load.constants';
import {
  getAnalyticsPageName,
  getLastTransferDate,
} from './gtm-page-load.utils';

export const useGtmPageLoadVariables = ({
  sendCountry,
  receiveCountry,
  countriesByCode,
  analyticsPageName,
  analyticsPageType,
  locale,
  accountDetails,
}: Partial<
  Pick<
    PageProps,
    | 'sendCountry'
    | 'receiveCountry'
    | 'countriesByCode'
    | 'analyticsPageName'
    | 'analyticsPageType'
    | 'accountDetails'
  > & {
    locale: NextRouter['locale'];
  }
>): void => {
  const isFirstRun = useRef(true);
  const isCountryPage =
    analyticsPageType === ANALYTICS_PAGE_TYPE.Country &&
    sendCountry?.countryCode &&
    receiveCountry?.countryCode;

  const dataLayerPush = (): void => {
    (window.dataLayer || []).push({
      event: isFirstRun.current ? PAGE_LOAD_EVENT : VIRTUAL_PAGE_VIEW_EVENT,
      senderCountry: countriesByCode?.[
        `${sendCountry?.countryCode}`
      ]?.name?.toLowerCase(),
      senderCountryIso: sendCountry?.countryCode,
      visitorStatus: accountDetails ? 'logged in' : 'not logged in',
      pageLanguage: locale
        ? new Intl.DisplayNames([DEFAULT_LANGUAGE], {
            type: 'language',
          })
            .of(locale)
            ?.toLowerCase()
        : locale,
      pageType: analyticsPageType,
      // TODO: fix when all CLP's are migrated to web-cms
      // in cms pageName is created separately for generic page by appending slug to '/country'
      // and for unique with already defined country name e.g. '/country/austria
      pageName: getAnalyticsPageName(
        analyticsPageName,
        isCountryPage
          ? countriesByCode?.[receiveCountry.countryCode]?.name?.toLowerCase()
          : undefined,
      ),

      ...(isCountryPage
        ? {
            destinationCountryIso: receiveCountry?.countryCode,
            destinationCountry: countriesByCode?.[
              receiveCountry.countryCode
            ]?.name?.toLowerCase(),
            countryCorridorIso: `${sendCountry?.countryCode}-${receiveCountry?.countryCode}`.toUpperCase(),
          }
        : {}),

      ...(accountDetails
        ? {
            customerId: accountDetails?.reference || '',
            numTransfers: accountDetails?.statistics?.transactionsCount || '',
            gender: accountDetails?.gender || '',
            lastTransferDate: getLastTransferDate(accountDetails),
          }
        : {}),
    });
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    dataLayerPush();

    if (isFirstRun.current) {
      isFirstRun.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsPageName, analyticsPageType]);
};
