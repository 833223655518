import { Typography } from '@mui/material';
import React from 'react';

import { Image } from '../image';
import { useStyles } from './promotional-banner-contextual.styles';
import { PromotionalBannerContextualProps } from './promotional-banner-contextual.types';

export const PromotionalBannerContextual = <RichText, Anchor, Button>({
  name,
  backgroundColor,
  description,
  disclaimer,
  button,
  link,
  promoIcon,
  image,
  RichTextComponent,
  AnchorComponent,
  ButtonComponent,
  containerProps,
}: PromotionalBannerContextualProps<
  RichText,
  Anchor,
  Button
>): React.ReactElement => {
  const classes = useStyles({
    backgroundColor,
  });

  return (
    <section
      id={name}
      data-testid="promotional-banner-contextual"
      className={classes.root}
      {...containerProps}
    >
      <div className={classes.content}>
        {promoIcon && <Image {...promoIcon} />}
        {description && <RichTextComponent {...description} />}
        {button && <ButtonComponent {...button} className={classes.button} />}
        {link && <AnchorComponent {...link} className={classes.link} />}
        {disclaimer && (
          <Typography className={classes.disclaimer}>{disclaimer}</Typography>
        )}
      </div>
      <div className={classes.image}>{image && <Image {...image} />}</div>
    </section>
  );
};
