import { Grid, Typography } from '@mui/material';
import React from 'react';

import { Container, CountrySelect } from '..';
import { useSpacing } from './we-are-regulated.hooks';
import { useStyles } from './we-are-regulated.styles';
import { WeAreRegulatedProps } from './we-are-regulated.types';

export function WeAreRegulated<RichText>({
  name,
  title,
  description,
  regulators,
  countrySelectProps,
  containerProps,
  RichTextComponent,
}: WeAreRegulatedProps<RichText>): React.ReactElement {
  const classes = useStyles();
  const spacing = useSpacing();

  const selectedRegulator = regulators?.find(
    ({ code }) => code === countrySelectProps.selectedCountry?.code,
  );

  return (
    <section
      id={name}
      className={classes.section}
      data-testid="regulatory-section"
      {...containerProps}
    >
      <Container>
        <Grid
          component="div"
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={spacing}
        >
          <Grid item xs={12} md={6}>
            <Typography
              align="center"
              component="h2"
              data-testid="regulatory-heading"
              variant="h2"
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              component="p"
              align="center"
              data-testid="regulatory-description"
              variant="body1"
              className={classes.description}
            >
              {description}
            </Typography>
            <Grid item xs={12} md={8}>
              <CountrySelect
                {...countrySelectProps}
                forwardedProps={{
                  classes: {
                    icon: classes.selectIcon,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            {selectedRegulator?.content && (
              <div className={classes.regulatoryBody}>
                <div
                  className={classes.regulatoryBodyContent}
                  data-testid="regulatory-body-content"
                >
                  <RichTextComponent {...selectedRegulator.content} />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
