import { CircularProgress } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { useEffect, useState } from 'react';

import { contentfulClient } from '@/utils';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';
import { ModuleItemProps, QueryData } from './embedded-table-entry.types';

export const EmbeddedTableEntry: React.FC<ModuleItemProps> = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [entryData, setEntryData] = useState(props);

  /**
   * Fetch Data if none is provided
   */
  useEffect(() => {
    async function getData() {
      const { data } = await contentfulClient.query<QueryData>({
        query: contentfulSchema.EmbeddedTableEntryQuery,
        variables: {
          id: props.sys.id,
          locale: 'en',
        },
      });
      setEntryData(data.EmbeddedTableEntry);
      setIsLoading(false);
    }
    if (!!props.sys.id && !props.description) getData();
  }, [props.description, props.sys.id]);

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  if (!entryData?.description) {
    return null;
  }

  return (
    <RichTextContentRenderer
      json={entryData.description.json}
      links={entryData.description.links as RichTextProps['links']}
    />
  );
};
