export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AccountActivationResult: ['AccountActivated', 'AccountActivationFailed'],
    BusinessError: [],
    BusinessErrorsList: [],
    CalculationError: ['GenericCalculationError', 'ValidationCalculationError'],
    CompleteMFAResponse: [
      'AccountLockedResponse',
      'MFACodeExpiredResponse',
      'MFACodeInvalidResponse',
      'MFASuccessResponse',
    ],
    CreateCalculationError: [
      'GenericCalculationError',
      'ValidationCalculationError',
    ],
    CreateUserFxRateAlertResult: [
      'CreateUserFxRateAlertFailed',
      'UserFxRateAlert',
    ],
    CreateUserFxRateAlertResultV2: [
      'CreateUserFxRateAlertFailed',
      'CreateUserFxRateAlertSucceeded',
    ],
    CustomerComplianceResponse: [
      'CustomerComplianceFailed',
      'CustomerComplianceSucceed',
    ],
    PasswordResetLinkValidationResult: [
      'PasswordResetLinkValidated',
      'PasswordResetLinkValidationFailed',
    ],
    PaymentResult: [
      'PaymentFailedResult',
      'PaymentResultRequires3DS',
      'PaymentSuccessfulResult',
    ],
    PhoneNumberValidationResult: [
      'PhoneNumberValidated',
      'PhoneNumberValidationFailed',
    ],
    RecipientCreationResult: ['RecipientCreated', 'RecipientCreationFailed'],
    RecipientDeletionResult: ['RecipientDeleted', 'RecipientDeletionFailed'],
    RecipientUpdateResult: ['RecipientUpdateFailed', 'RecipientUpdated'],
    ReferralCodeResult: ['ReferAFriendValidationFailed', 'ReferralCodeDetails'],
    ReferralCodeUsageValidationResult: [
      'ReferAFriendValidationFailed',
      'ReferralCodeUsageValidated',
    ],
    SenderUpdateResult: ['SenderUpdateFailed', 'SenderUpdated'],
    SessionData: ['AdyenSessionData'],
    SignInV2Response: [
      'AccountDisabledResponse',
      'AccountLockedResponse',
      'InvalidCredentialsResponse',
      'MFARequiredResponse',
      'SignInSuccessResponse',
    ],
    SignUpResponse: [
      'AccountAlreadyExistsResponse',
      'InvalidCredentialsResponse',
      'SignUpSuccessResponse',
    ],
    SocialSignInResponse: [
      'AccountDisabledResponse',
      'AccountLockedResponse',
      'InvalidCredentialsResponse',
      'MFARequiredResponse',
      'RegistrationRequiredResponse',
      'SignInSuccessResponse',
    ],
    ThreeDSConfig: ['AdyenConfig'],
    TransferCancellationResult: [
      'TransferCancellationFailure',
      'TransferCancellationSuccess',
    ],
    UserFxRateAlertV2: ['ScheduledRateAlert', 'TargetRateAlert'],
    UserPreferredLanguageSetResult: [
      'UserPreferredLanguageSetFailure',
      'UserPreferredLanguageSetSuccess',
    ],
  },
};
export default result;
