import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';

import { TextInterpolator } from '../intl';
import { useStyles } from './rich-text-content-renderer.styles';
import { RichTextProps } from './rich-text-content-renderer.types';
import {
  EmbeddedAssetRenderer,
  EmbeddedEntryBlockRenderer,
  EmbeddedEntryInlineRenderer,
  EntryHyperLinkRenderer,
  HeadingRenderer,
  HyperLinkRenderer,
  ListItemRenderer,
  OrderedListRenderer,
  ParagraphRenderer,
  TableCellRenderer,
  TableHeaderCellRenderer,
  TableRenderer,
  TableRowRenderer,
  UnorderedListRenderer,
} from './rich-text-content-renderer.utils';

export const RichTextContentRenderer: React.FC<RichTextProps> = ({
  json,
  links,
  options = {},
}) => {
  const classes = useStyles();

  const component = documentToReactComponents(json, {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: EmbeddedEntryInlineRenderer({
        links,
        options,
        classes,
      }),
      [INLINES.HYPERLINK]: HyperLinkRenderer({ options }),
      [INLINES.ENTRY_HYPERLINK]: EntryHyperLinkRenderer({ links, options }),

      [BLOCKS.EMBEDDED_ASSET]: EmbeddedAssetRenderer({
        links,
        options,
        classes,
      }),
      [BLOCKS.EMBEDDED_ENTRY]: EmbeddedEntryBlockRenderer({ links, options }),
      [BLOCKS.PARAGRAPH]: ParagraphRenderer({ options }),
      [BLOCKS.HEADING_1]: HeadingRenderer({ options }),
      [BLOCKS.HEADING_2]: HeadingRenderer({ options }),
      [BLOCKS.HEADING_3]: HeadingRenderer({ options }),
      [BLOCKS.HEADING_4]: HeadingRenderer({ options }),
      [BLOCKS.HEADING_5]: HeadingRenderer({ options }),
      [BLOCKS.HEADING_6]: HeadingRenderer({ options }),
      [BLOCKS.LIST_ITEM]: ListItemRenderer({ options }),
      [BLOCKS.UL_LIST]: UnorderedListRenderer({ options }),
      [BLOCKS.OL_LIST]: OrderedListRenderer({ options }),
      [BLOCKS.TABLE]: TableRenderer({ classes }),
      [BLOCKS.TABLE_ROW]: TableRowRenderer,
      [BLOCKS.TABLE_CELL]: TableCellRenderer({ classes }),
      [BLOCKS.TABLE_HEADER_CELL]: TableHeaderCellRenderer({ classes }),
    },
    renderMark: {
      superscript: text => <sup>{text}</sup>,
    },
    renderText: text => <TextInterpolator text={text} />,
  });

  return <>{component}</>;
};

export default RichTextContentRenderer;
