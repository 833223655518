import clsx from 'clsx';
import React from 'react';
import Slider from 'react-slick';

import { Arrow } from './arrow';
import { useStyles } from './carousel.styles';
import { CarouselProps } from './carousel.types';

export const Carousel: React.FC<CarouselProps> = React.forwardRef<
  Slider,
  CarouselProps
>(({ settings = {}, arrowProps = {}, children }, ref) => {
  const classes = useStyles();
  const defaultSettings = {
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: false,
    cssEase: 'linear',
    nextArrow: <Arrow right arrowProps={arrowProps} />,
    prevArrow: <Arrow left arrowProps={arrowProps} />,
    ...settings,
    className: clsx(classes.slider, settings.className),
  };

  return (
    <Slider ref={ref} {...defaultSettings}>
      {children}
    </Slider>
  );
});

Carousel.displayName = 'Carousel';
