import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  ANALYTICS_PAGE_TYPE,
  contentfulSchema,
  DEFAULT_LANGUAGE,
} from '@wr/web-shared';
import { NextRouter } from 'next/router';

import { QueryParams, REGION_CODES, TRAILING_SLASH } from '@/constants';
import { AppContextType } from '@/context';
import { getRegionCode } from '@/services/contentful/region-code/region-code.service';
import { getSendCountryCodeFromLocale } from '@/utils/intl';

import { PageMetadataProps, RegionCodeKeys } from './page-metadata.types';

export const getLanguageAlternates = ({
  activeCorridors,
  originUri,
  pathname,
  noIndex,
  regions,
  analyticsPageType,
}: Pick<
  PageMetadataProps,
  'activeCorridors' | 'originUri' | 'pathname' | 'noIndex'
> &
  Pick<AppContextType, 'regions' | 'analyticsPageType'>): Array<{
  href: string;
  hreflang: string;
}> => {
  const isHomepage = analyticsPageType === ANALYTICS_PAGE_TYPE.Homepage;
  const isBlogPage = analyticsPageType === ANALYTICS_PAGE_TYPE.Blog;
  const isCurrencyConverterPage =
    analyticsPageType === ANALYTICS_PAGE_TYPE.CurrencyConverter;

  // If a page only exists in one language - no Hreflang tag to be generated
  const isSingleLanguage = !isHomepage && (!regions || regions.length === 1);

  if (pathname === '404' || noIndex || isSingleLanguage) {
    return [];
  }

  return regions
    .filter(region => {
      const sendCountry = getSendCountryCodeFromLocale(region)?.toUpperCase();
      if (activeCorridors && sendCountry)
        return activeCorridors.includes(sendCountry);
      if (!activeCorridors) return true;
      return false;
    })
    .map(region => {
      if (
        (!isBlogPage || !isCurrencyConverterPage) &&
        region === DEFAULT_LANGUAGE
      ) {
        return;
      }

      const href = `${originUri}/${region}${pathname}`;

      if (isBlogPage || isCurrencyConverterPage) {
        return {
          href,
          hreflang: region,
        };
      }

      return {
        href,
        hreflang: region.includes('-') ? region : `${region}-${region}`,
      };
    })
    .filter((hrefLang): hrefLang is {
      href: string;
      hreflang: string;
    } => Boolean(hrefLang))
    .concat({
      href: `${originUri}/${DEFAULT_LANGUAGE}${pathname}`,
      hreflang: 'x-default',
    });
};

export const getCanonicalUrl = ({
  originUri,
  locale,
  query,
  pathname,
}: Pick<NextRouter, 'locale' | 'query' | 'pathname'> &
  Pick<PageMetadataProps, 'originUri'>) => {
  if (pathname === '404') {
    return null;
  }
  // Get the value of the "page" parameter if it's present
  const pageParam = query?.page ? `${[QueryParams.page]}=${query?.page}` : '';

  // Create the canonical URL by combining the current path and the "page" parameter (if present)
  return `${originUri}/${locale}${pathname}${
    pageParam ? `?${pageParam}` : ''
  }`.replace(TRAILING_SLASH, '');
};

export const getOrganizationSchemaMarkup = (
  organizationSchema: PageMetadataProps['organizationSchema'],
) => {
  if (!organizationSchema) return null;

  return {
    '@type': 'Organization',
    '@context': 'https://schema.org',
    'logo': organizationSchema?.image?.url || '',
    'sameAs':
      organizationSchema?.sameAsCollection?.items
        .filter((i): i is { value: string } => typeof i?.value === 'string')
        .map(i => i?.value) || [],
    'name': organizationSchema?.name || '',
    'description': organizationSchema?.description,
    'url': organizationSchema?.url || '',
    'email': organizationSchema?.email,
    'contactPoint': organizationSchema?.contactPointCollection?.items.map(
      contactPoint => ({
        telephone: contactPoint?.telephone,
        contactType: contactPoint?.contactType,
        areaServed: contactPoint?.areaServed,
      }),
    ),
    'address': {
      streetAddress: organizationSchema?.streetAddress,
      addressLocality: organizationSchema?.addressLocality,
      postalCode: organizationSchema?.postalCode,
      addressCountry: organizationSchema?.addressCountry,
    },
  };
};

export const getFaqPageSchemaMarkup = (
  modules: PageMetadataProps['modules'],
) => {
  const filteredModules = modules?.filter(
    modules => 'FaqModuleItem' in modules,
  );

  if (!filteredModules?.length) return;

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': filteredModules?.map(module => {
      const faqModuleItem = module?.FaqModuleItem as contentfulSchema.FaqModuleItem;

      if (faqModuleItem.description) {
        return {
          '@type': 'Question',
          'name': faqModuleItem.title,
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': documentToHtmlString(faqModuleItem.description.json),
          },
        };
      }
    }),
  };
};

export const getNewsArticleSchemaMarkup = (
  newsArticleSchema: PageMetadataProps['newsArticleSchema'],
) => {
  if (!newsArticleSchema) return null;

  const {
    sys,
    name,
    author,
    banner,
    publishedDate,
    updatedDate,
  } = newsArticleSchema;
  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    'headline': name,
    'image': [banner?.url],
    'datePublished': publishedDate || sys.firstPublishedAt,
    'dateModified': updatedDate || sys.publishedAt,
    'author': [
      {
        '@type': 'Person',
        'name': `${author?.firstName} ${author?.lastName}`,
        'url': author?.image?.url,
      },
    ],
  };
};

export function getBreadcrumbsSchemaMarkup({
  breadcrumbs,
  locale,
  originUri,
}: Pick<PageMetadataProps, 'breadcrumbs' | 'originUri'> &
  Pick<NextRouter, 'locale'>) {
  if (!breadcrumbs?.length) return null;

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': breadcrumbs.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'name': breadcrumb?.name,
      ...(index < breadcrumbs.length - 1
        ? { item: originUri + '/' + locale + '/' + (breadcrumb?.slug ?? '') }
        : {}),
    })),
  };
}

export const removeDashAndConvertToCamelCase = (str: string): string | null => {
  if (!str) return null;

  return str.replace(/(-.)/g, val => val[1].toUpperCase());
};

export const getActiveRegionCode = (
  regionCode: ReturnType<typeof getRegionCode>[],
  locale: string,
): string | null | undefined =>
  regionCode.map((codes: ReturnType<typeof getRegionCode>) => {
    for (const key in codes) {
      if (key === removeDashAndConvertToCamelCase(locale)) {
        if (codes[key as RegionCodeKeys]) {
          return REGION_CODES[key as RegionCodeKeys];
        }
      }
    }
  })[0];
