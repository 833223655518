import { Block, BLOCKS, Mark } from '@contentful/rich-text-types';

import { RichTextProps } from '@/components/rich-text-content-renderer';

type TextNodes = {
  type: BLOCKS;
  value: string;
  marks?: Mark[];
}[];

export function convertTextToJsonNode(nodes: TextNodes) {
  return {
    json: {
      data: {},
      nodeType: BLOCKS.DOCUMENT,
      content: nodes.map(node => {
        return {
          nodeType: node.type,
          content: [
            {
              value: node.value,
              marks: node?.marks ?? [],
              nodeType: 'text',
              data: {},
            },
          ],
          data: {},
        } as Block;
      }),
    } as RichTextProps['json'],
    links: {} as RichTextProps['links'],
  };
}
