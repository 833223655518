import { bffSchema, COOKIES } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import { ICountryData } from '@/components/calculator/src/types';
import { DEFAULT_LOCATION } from '@/constants';
import { AccountDetailsQueryResult } from '@/services';
import { getSendCountries } from '@/services/bff/send-countries';
import { isCountryCodeValid } from '@/services/contentful/country/country.utils';
import { getSendCountryCodeFromLocale } from '@/utils/intl';

export const mapCountryData = (
  countryData: bffSchema.Country,
): ICountryData => ({
  name: countryData.name || '',
  currencyName: countryData.currency?.name || '',
  currency: String(countryData.currency?.code || ''),
  countryCode: String(countryData.code || ''),
  dialCode: String(countryData.dialCode),
  iso2: '',
});

export const getSendCountryCode = ({
  region,
  cookies,
  accountDetails,
  sendCountriesSorted,
}: Pick<GetServerSidePropsContext['req'], 'cookies'> &
  Awaited<ReturnType<typeof getSendCountries>> & {
    accountDetails: AccountDetailsQueryResult;
    region: string;
  }): string => {
  const localizationCountry = getSendCountryCodeFromLocale(region);

  return (
    (accountDetails?.country?.code as string) ||
    localizationCountry ||
    (isCountryCodeValid(
      sendCountriesSorted.map(country => country.code as string),
      cookies?.[COOKIES.LOCATION_COUNTRY],
    ) &&
      cookies?.[COOKIES.LOCATION_COUNTRY]) ||
    (isCountryCodeValid(
      sendCountriesSorted.map(country => country.code as string),
      cookies?.[COOKIES.SELECT_FROM],
    ) &&
      cookies?.[COOKIES.SELECT_FROM]) ||
    DEFAULT_LOCATION
  );
};
