import pino, { LogEvent } from 'pino';

import { getLoggerSessionFields } from './session-properties';

const getPinoConfig = (app: string) => ({
  formatters: {
    level(label: string, _number: number) {
      return { level: label.toUpperCase() };
    },
  },
  browser: {
    transmit: {
      level: 'warn',
      send(level: string, logEvent: LogEvent) {
        const endpoint = process.env.NEXT_PUBLIC_WEB_LOGGER_URL;
        if (typeof window !== 'undefined' && endpoint) {
          navigator.sendBeacon(
            endpoint,
            JSON.stringify({
              ...getLoggerSessionFields(),
              level: level.toUpperCase(),
              app,
              url: window.location.href,
              messages: logEvent.messages,
            }),
          );
        }
      },
    },
  },
});

export const createLogger = (app: string) => {
  const pinoConfig = getPinoConfig(app);

  return pino(pinoConfig);
};
