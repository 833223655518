import { Theme, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';

import { ButtonProps } from '../../button';
import { isDarkTheme } from '../header.utils';
import { HeaderNavBlogSelect } from '../header-nav-blog-select/header-nav-blog-select.component';
import { HeaderNavSelect } from '../header-nav-select/header-nav-select.component';
import { HeaderNavProps } from './header-nav.types';
import { getLinkFormatter, getValidLinks } from './header-nav.utils';
import { Nav, NavButton, NavLi, NavUl } from './styled';

export const HeaderNav: React.FC<HeaderNavProps> = ({
  blogTags,
  color,
  menuItems,
  headerTheme,
  linkProps,
}) => {
  const { query } = useRouter();
  const actualSendCountry = query.selectfrom as string;
  const isSMUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const validLinks: ButtonProps[] = getValidLinks(menuItems);

  const formatLinks = getLinkFormatter(headerTheme, isSMUp, linkProps);
  const links = validLinks.map(formatLinks) || [];
  const canNavigateToBlog = ['es', 'fr', 'gb'].includes(actualSendCountry);

  return (
    <Nav>
      <NavUl>
        {blogTags && (
          <HeaderNavBlogSelect {...{ color, blogTags, headerTheme }} />
        )}

        {links.map((link, index) => {
          const key = link?.name ?? index;
          const isDropdown = link.variant === 'LinkGroup';
          const blogLink = link?.url === 'blog';

          if (isDropdown) {
            return (
              <NavLi {...{ key }}>
                <HeaderNavSelect {...{ color, headerTheme, link }} />
              </NavLi>
            );
          } else if (!blogLink) {
            return (
              <NavLi {...{ key }}>
                <NavButton
                  isHeaderDarkTheme={isDarkTheme(headerTheme)}
                  {...link}
                />
              </NavLi>
            );
          } else {
            if (canNavigateToBlog) {
              return (
                <NavLi {...{ key }}>
                  <NavButton
                    isHeaderDarkTheme={isDarkTheme(headerTheme)}
                    {...link}
                  />
                </NavLi>
              );
            }
          }
          return null;
        })}
      </NavUl>
    </Nav>
  );
};
