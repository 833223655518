import { contentfulSchema } from '@wr/web-shared';

export const parseStickyBanners = ({
  banners = [],
  sendCountryCode,
  receiveCountryCode,
}: {
  sendCountryCode: string;
  receiveCountryCode: string;
  banners?: (contentfulSchema.StickyBannerFragment | null)[];
}) => {
  return banners
    .filter(
      (item): item is contentfulSchema.StickyBannerFragment => item !== null,
    )
    .filter(
      item =>
        item?.sendCountries?.items?.some(
          country =>
            country?.code?.toLowerCase?.() === sendCountryCode.toLowerCase(),
        ) ||
        item?.receiveCountries?.items?.some(
          country =>
            country?.code?.toLowerCase?.() === receiveCountryCode.toLowerCase(),
        ),
    )
    .map(banner => ({
      __typename: banner.__typename || 'StickyBanner',
      sys: { id: banner.sys.id },
      name: banner?.name ?? '',
      textColor: banner?.textColor ?? '',
      backgroundColor: banner?.backgroundColor ?? '',
      image: (banner?.image as contentfulSchema.Asset) ?? undefined,
      description:
        (banner?.description as contentfulSchema.StickyBannerDescription) ??
        undefined,
    }));
};
