import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background:
      theme.customComponents.promotionalBanner.container.backgroundColor,
    color: theme.customComponents.promotionalBanner.container.color,
    minHeight: '16rem',
    padding: theme.spacing(3.5, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7.5, 4.5),
    },
  },
  description: {
    'maxWidth': '35rem',
    'textAlign': 'center',
    'zIndex': theme.zIndex.mobileStepper,
    [theme.breakpoints.up('md')]: {
      maxWidth: '50rem',
    },
    '& h2': {
      'letterSpacing': '0.0925rem',
      'fontWeight': 600,
      'margin': theme.spacing(0, 0, 1),
      'lineHeight': 1.2,
      'fontSize': '2.85rem',
      'backgroundColor':
        theme.customComponents.promotionalBanner.description.h2.backgroundColor,
      'backgroundImage': `-webkit-linear-gradient(left, ${theme.customComponents.promotionalBanner.description.h2.backgroundImage.left} 0%, ${theme.customComponents.promotionalBanner.description.h2.backgroundImage.right} 100%)`,
      'backgroundSize': '100%',
      '-webkitBackgroundClip': 'text',
      '-mozBackgroundClip': 'text',
      '-webkitTextFillColor': 'transparent',
      '-mozTextFillColor': 'transparent',

      [theme.breakpoints.up('md')]: {
        fontSize: '5.5rem',
      },
    },
    '& p': {
      'fontSize': '1rem',
      'fontWeight': theme.typography.fontWeightMedium,
      'margin': 0,
      'color': theme.customComponents.promotionalBanner.description.p.color,

      '&:first-of-type': {
        margin: theme.spacing(0, 0, 0.625),
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
      '& b ': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}));

export default useStyles;
