import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  root: {},
  select: {
    color: theme.customComponents.select.select.color,
  },
  icon: {
    // the order in the class property does not matter in real and only way to override
    // the styles from MUI you need to make a more accurate selector
    '$root &:not($disabled)': {
      color: theme.customComponents.select.icon.color,
    },
    '$root:hover &:not($disabled)': {
      color: theme.customComponents.select.icon.hover.color,
    },
  },
  disabled: {
    'color': theme.customComponents.select.disabled.color,
    '$icon:hover &': {
      color: theme.customComponents.select.disabled.hover.color,
    },
  },
}));

export default useStyles;
