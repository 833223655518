import { Toolbar } from '@mui/material';
import { DEFAULT_LANGUAGE as defaultLanguage } from '@wr/web-shared';
import { Logo } from '@wr/web-ui';

import { Anchor } from '../anchor';
import { HeaderProps, HeaderTheme } from './header.types';
import { getHeaderPosition, isDarkTheme } from './header.utils';
import { HeaderLanguageSelect } from './header-language-select/header-language-select.component';
import { getHeaderLogo } from './header-logo/header-logo.utils';
import { HeaderNav } from './header-nav/header-nav.component';
import { HeaderRegionSelect } from './header-region-select/header-region-select.component';
import { HeaderAppBar } from './styled/header-appbar.component';
import { HeaderContainer } from './styled/header-container.component';

export const Header: React.FC<HeaderProps> = ({
  blogTags,
  headerTheme: _headerTheme,
  headerPosition,
  hideRegionSelector,
  languageFromCookie,
  linkProps,
  logoDark,
  logoDarkMobile,
  logoLight,
  logoLightMobile,
  notLoggedInMenuItemsCollection,
  submenuItems,
}) => {
  const headerTheme = (_headerTheme ?? 'dark') as HeaderTheme;
  const color = isDarkTheme(headerTheme) ? 'secondary' : 'primary';
  const menuItems = notLoggedInMenuItemsCollection?.items;

  const logo = getHeaderLogo({
    defaultLanguage,
    headerTheme,
    logoDark,
    logoDarkMobile,
    logoLight,
    logoLightMobile,
  });

  return (
    <HeaderAppBar
      position={getHeaderPosition(headerPosition)}
      isHeaderDarkTheme={isDarkTheme(headerTheme)}
      hasSubMenuItems={!!submenuItems?.length}
      data-test-id="header-group"
    >
      <HeaderContainer>
        <Toolbar disableGutters>
          <Anchor {...logo.link} data-testid={`${headerTheme}-logo`}>
            <Logo {...logo} />
          </Anchor>
          <HeaderLanguageSelect
            {...{ color, headerTheme, languageFromCookie }}
          />
          {!hideRegionSelector && (
            <HeaderRegionSelect {...{ color, headerTheme }} />
          )}
          <HeaderNav
            {...{ blogTags, color, linkProps, menuItems, headerTheme }}
          />
        </Toolbar>
      </HeaderContainer>
    </HeaderAppBar>
  );
};
