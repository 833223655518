import { ImageLoaderProps } from './image.types';

export const getAssetPrefix = () => {
  const prefixPath =
    process.env.NEXT_PUBLIC_ASSETS_PATH || process.env.ASSET_PREFIX || '';
  return prefixPath.trim().replace(' ', '');
};

export const imageAssetLoader = ({ src, width, quality }: ImageLoaderProps) => {
  const assetPrefixPath = getAssetPrefix();
  return `${assetPrefixPath}/_next/image?url=${encodeURIComponent(
    src,
  )}&w=${width}&q=${quality || 75}`;
};

export const contentfulImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps) => {
  const [url, initialParams] = src.split('?');
  const params = new URLSearchParams(initialParams || {});
  params.set('q', (quality || 75).toString());
  if (!src.includes('.svg')) params.set('fm', 'avif');
  params.set('w', width.toString());
  return `${url}?${params.toString()}`;
};
