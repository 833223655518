import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, typography, customComponents }) => ({
    section: {
      backgroundColor: customComponents.correspondents.section.backgroundColor,
      textAlign: 'center',
      padding: spacing(2, 0),

      [breakpoints.up('sm')]: {
        padding: spacing(3, 0),
      },
      [breakpoints.up('md')]: {
        padding: spacing(9, 0),
      },
      [breakpoints.up('lg')]: {
        padding: spacing(8, 0),
      },
      [breakpoints.up('xl')]: {
        padding: spacing(10, 0),
      },
    },
    heading: {
      marginBottom: spacing(1),

      [breakpoints.up('sm')]: {
        ...typography.h1,
      },

      [breakpoints.up('md')]: {
        marginBottom: spacing(2),
      },
    },
    subheading: {
      marginBottom: spacing(3),

      [breakpoints.up('sm')]: {
        ...typography.subtitle1,
      },
      [breakpoints.up('md')]: {
        marginBottom: spacing(5),
      },
      [breakpoints.up('lg')]: {
        marginBottom: spacing(4),
      },
    },
    tabs: {
      justifyContent: 'center',
      marginBottom: spacing(2),

      [breakpoints.up('lg')]: {
        marginBottom: spacing(3),
      },
    },
    scroller: {
      flexGrow: 0,
    },
    tabPanel: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: spacing(3, 1),

      [breakpoints.up('md')]: {
        gap: spacing(3, 4),
      },

      [breakpoints.up('lg')]: {
        gap: spacing(3, 6),
      },
    },
    scrollButton: {
      fontSize: '2rem',
    },
    scrollButtonDisabled: {
      opacity: 0,
    },
  }),
);
