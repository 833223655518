import React from 'react';

import { Image } from '../image';
import { useStyles } from './correspondent.styles';
import { CorrespondentProps } from './correspondent.types';

export function Correspondent<L>({
  link,
  logo,
  name,
  LinkComponent,
}: CorrespondentProps<L>): React.ReactElement {
  const classes = useStyles();

  if (link) {
    return (
      // TODO: CLP is not follow intended lowercase, need to be rewrited to Coutry model
      <LinkComponent className={classes.correspondent} name={name} {...link}>
        <div className={classes.imageWrapper}>
          <Image
            {...logo}
            className={classes.image}
            objectFit="contain"
            layout="fill"
          />
        </div>
      </LinkComponent>
    );
  }
  return (
    <div className={classes.correspondent}>
      <div className={classes.imageWrapper}>
        <Image
          {...logo}
          className={classes.image}
          objectFit="contain"
          layout="fill"
        />
      </div>
    </div>
  );
}
