import { Paper, Typography } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import React from 'react';

import { Container } from '../container';
import { Image } from '../image';
import useStyles from './step-by-step.styles';
import { StepByStepProps } from './step-by-step.types';

export function StepByStep<Link, RichText>({
  header,
  name,
  steps,
  link,
  RichTextComponent,
  LinkComponent,
  containerProps,
}: StepByStepProps<Link, RichText>): React.ReactElement {
  const classes = useStyles();

  return (
    <section data-testid={name} className={classes.section} {...containerProps}>
      <Container>
        <Typography className={classes.heading} variant="h2">
          {header}
        </Typography>
        <ol className={classes.stepsContainer}>
          {steps.map((step, index) => {
            return (
              <Paper
                key={step.name}
                component="li"
                data-testid={step.name}
                elevation={0}
                className={classes.listItem}
                {...step?.containerProps}
              >
                <Hidden mdDown>
                  {step.imageProps && (
                    <Image className={classes.image} {...step.imageProps} />
                  )}
                </Hidden>
                <Typography
                  component="h3"
                  variant="h6"
                  className={classes.stepHeading}
                >
                  <Typography
                    variant="inherit"
                    className={classes.numeration}
                    component="span"
                  >
                    {`${index + 1}.`}
                  </Typography>
                  <Typography variant="inherit" component="span">
                    {step.header}
                  </Typography>
                </Typography>

                {step?.description && RichTextComponent && (
                  <div className={classes.description}>
                    <RichTextComponent {...step.description} />
                  </div>
                )}
              </Paper>
            );
          })}
        </ol>
        {link && LinkComponent && (
          <LinkComponent {...link} className={classes.link} />
        )}
      </Container>
    </section>
  );
}
