import {
  AppContextProviderShared,
  appContextSharedMock,
  mockPayoutMethod,
  mockReceiveCountry,
  mockSendCountry,
} from '@wr/web-ui';

import {
  AppContextProvider,
  AppContextType,
  MockedCalculatorProvider,
  MockProviderProps,
} from '@/context';
import { mockRichTextContent } from '@/test-helpers/data-interfaces-mocks';

import { INITIAL_APP_CONTEXT } from './app.constants';

export const mockedAccountDetails = {
  id: '10523452',
  firstName: 'John',
  reference: '10523452',
  gender: 'male',
  language: 'en',
  email: {
    email: '1234@test.com',
  },
  country: {
    name: 'Poland',
    code: 'PL',
    dialCode: null,
    currency: {
      code: 'PLN',
      name: 'Polish Zloty',
    },
  },
  statistics: {
    lastTransactionDate: '1589977830',
    transactionsCount: 1,
  },
  isGreenfieldUser: false,
};

export const mockSendCurrency = {
  countryName: mockSendCountry.name,
  countryCode: mockSendCountry.countryCode,
  pageSlug: `/cex/currencies/${mockSendCountry.currency}`,
  currencyCode: mockSendCountry.currency,
  currencyName: mockSendCountry.currencyName,
  description: mockRichTextContent,
  name: mockSendCountry.currencyDisplay,
  sys: { id: 'sEgPFdExAQidPxu5nvZ-1' },
};

export const mockReceiveCurrency = {
  countryName: mockReceiveCountry.name,
  countryCode: mockReceiveCountry.countryCode,
  pageSlug: `/cex/currencies/${mockReceiveCountry.currency}`,
  currencyCode: mockReceiveCountry.currency,
  currencyName: mockReceiveCountry.currencyName,
  description: mockRichTextContent,
  name: mockReceiveCountry.currencyDisplay,
  sys: { id: 'sEgPFdExAQidPxu5nvZ-2' },
};

export const mockCorridor = {
  sendCurrency: mockSendCurrency,
  receiveCurrency: mockReceiveCurrency,
  pageSlug: `/cex/currencies/${mockSendCurrency.currencyCode}-${mockReceiveCurrency.currencyCode}`.toLowerCase(),
};

export const appContextMock: AppContextType = {
  ...INITIAL_APP_CONTEXT,
  ...appContextSharedMock,
  accountDetails: mockedAccountDetails,
  corridorPayoutMethodsIds: ['CSH', 'BNK', 'DTD'],
  calculatorSendCountry: appContextSharedMock.sendCountry,
  calculatorSendAmount: 0,
  selectedPayoutMethodId: mockPayoutMethod.code,
  payoutMethods: [mockPayoutMethod],
  currencies: [mockSendCurrency, mockReceiveCurrency],
  corridors: [],
};

export const MockedProvider: React.FC<MockProviderProps> = ({
  children,
  withCalculator = true,
  ...props
}) => {
  return (
    <AppContextProviderShared {...{ ...appContextSharedMock, ...props }}>
      <AppContextProvider {...{ ...appContextMock, ...props }} appName="web-wr">
        {!withCalculator ? (
          children
        ) : (
          <MockedCalculatorProvider>{children}</MockedCalculatorProvider>
        )}
      </AppContextProvider>
    </AppContextProviderShared>
  );
};
