import { ButtonProps } from '@mui/material';
import { ExtendedButtonProps, HeaderSelect } from '@wr/web-ui';

import { HeaderTheme } from '../header.types';

type HeaderNavSelectProps = {
  color?: ButtonProps['color'];
  headerTheme: HeaderTheme;
  link: ExtendedButtonProps;
};

export const HeaderNavSelect: React.FC<HeaderNavSelectProps> = ({
  color,
  headerTheme,
  link,
}) => {
  return (
    <HeaderSelect
      color={color}
      name={link?.name ?? ''}
      label={link?.label ?? ''}
      onChange={(e: string) => link?.onSelectDropdown?.(e)}
      data-testid={`header-link-group-${link.name}`}
      options={link?.options ?? []}
      onOpen={() => {}}
      value=""
      headerVariant={headerTheme}
    />
  );
};
