import { AppContextProviderShared, PerimeterXCaptchaModal } from '@wr/web-ui';
import React, { useMemo } from 'react';

import { PageMetadataComponent, RelatedPages } from '@/components';
import { TAG_LINKS } from '@/constants';
import { AppContextProvider } from '@/context';
import { BranchWebSDK } from '@/plugins';
import { logger, resolveComponents } from '@/utils';

import { Layout } from './layout.component';
import { DefaultPageLayoutProps } from './layout.types';

export const DefaultPageLayout: React.FC<DefaultPageLayoutProps> = ({
  __typename,
  accountDetails,
  activeCorridors,
  analyticsPageName,
  analyticsPageType,
  breadcrumbs,
  calculatorSendAmount,
  calculatorSendCountry,
  correspondentLocations,
  correspondentLogos,
  corridorPayoutMethodsIds,
  countriesByCode,
  countryArticlesAndPrepositions,
  description,
  deviceType,
  footer,
  geoCookie,
  header,
  isWebView,
  locationCountryCookie,
  messages,
  modules,
  name,
  noIndex = false,
  organizationSchema,
  originUri,
  osName,
  pathname,
  payoutMethodAlerts,
  payoutMethods,
  payoutMethodsLabelsById,
  preview,
  receiveCountries,
  receiveCountry,
  referralCode,
  regions = [],
  selectedPayoutMethodId,
  sendCountries,
  sendCountry,
  sys,
  title,
  relatedPagesCollection,
  languageFromCookie,
  regionCode,
  pageLinksWithRegionsAndSlugs,
  blogTags,
  currencies,
  corridors,
  children,
}) => {
  const propsOverrides = {
    Correspondents: {
      correspondentLogos,
    },
    CorrespondentLocations: {
      correspondentLocations,
    },
  };

  const resolvedComponents = children
    ? children
    : resolveComponents(modules || [], propsOverrides, header);

  // Contentful data from PayoutMethod and Country content type entries
  const intlContextValue = useMemo(
    () => ({
      countryArticlesAndPrepositions,
      payoutMethodsLabelsById,
    }),
    [countryArticlesAndPrepositions, payoutMethodsLabelsById],
  );

  return (
    <>
      <BranchWebSDK />
      <AppContextProviderShared
        analyticsPageName={analyticsPageName}
        analyticsPageType={analyticsPageType}
        deviceType={deviceType}
        isWebView={isWebView}
        messages={messages}
        osName={osName}
        receiveCountries={receiveCountries}
        receiveCountry={receiveCountry}
        regions={regions as string[]}
        sendCountries={sendCountries}
        sendCountry={sendCountry}
        intl={intlContextValue}
        isAuthenticated={!!accountDetails}
        originUri={originUri || 'https://localhost:3000'}
        appName="web-wr"
      >
        <AppContextProvider
          accountDetails={accountDetails}
          calculatorSendAmount={calculatorSendAmount}
          calculatorSendCountry={calculatorSendCountry}
          corridorPayoutMethodsIds={corridorPayoutMethodsIds}
          countriesByCode={countriesByCode}
          payoutMethodAlerts={payoutMethodAlerts}
          payoutMethods={payoutMethods}
          referralCode={referralCode}
          selectedPayoutMethodId={selectedPayoutMethodId}
          currencies={currencies}
          corridors={corridors}
        >
          <PageMetadataComponent
            breadcrumbs={breadcrumbs}
            title={title}
            description={description}
            originUri={originUri}
            pathname={pathname || ''}
            noIndex={noIndex}
            organizationSchema={organizationSchema}
            activeCorridors={activeCorridors}
            newsArticleSchema={null}
            modules={modules}
            tagLinks={TAG_LINKS}
            regionCode={regionCode}
            pageLinksWithRegionsAndSlugs={pageLinksWithRegionsAndSlugs}
          />

          <Layout
            __typename={__typename}
            breadcrumbs={breadcrumbs}
            footer={{ ...footer }}
            geoCookie={geoCookie}
            header={isWebView ? null : header}
            locationCountryCookie={locationCountryCookie}
            messages={messages}
            name={name}
            preview={preview}
            sys={sys}
            languageFromCookie={languageFromCookie}
            blogTags={blogTags}
          >
            {resolvedComponents}
            <RelatedPages relatedPagesCollection={relatedPagesCollection} />
          </Layout>
          <PerimeterXCaptchaModal
            label={messages?.captchaPxLabel || ''}
            logger={logger}
          />
        </AppContextProvider>
      </AppContextProviderShared>
    </>
  );
};
