import { bffSchema, ECOMMERCE_TYPE, INTERACTION_NAME } from '@wr/web-shared';
import {
  CountryData,
  ExchangeRate,
  ExchangeRateCalculationType,
} from '@wr/web-ui';
import React, { PropsWithChildren } from 'react';

import { AppContextType } from '@/context';

export type CalculatorContextType = Pick<
  AppContextType,
  'sendCountry' | 'receiveCountry'
> & {
  sendAmount: number;
  receiveAmount: number;
  calculationAmount: number;
  calculationType: ExchangeRateCalculationType;
  payoutMethodId: AppContextType['selectedPayoutMethodId'];
  payoutMethods: PayoutMethod[];
  exchangeRate?: ExchangeRate;
  isLoading: boolean;
};

export type CalculatorActionTypes = {
  setSendAmount: (amount: number) => void;
  setReceiveAmount: (amount: number) => void;
  setCalculationAmount: (amount: number) => void;
  setCalculationType: (type: ExchangeRateCalculationType) => void;
  setSendCountry: (country: CountryData) => void;
  setReceiveCountry: (country: CountryData) => void;
  setPayoutMethodId: (id: string | null) => void;
  setPayoutMethods: (payoutMethods: PayoutMethod[]) => void;
  setExchangeRate: (data: ExchangeRate) => void;
  setIsLoading: (loading: boolean) => void;
};

export type CalculatorContextProviderType = React.FC<{
  children: React.ReactNode | React.ReactNode[];
}>;

export type MockedCalculatorProviderProps = PropsWithChildren<{
  state?: Partial<CalculatorContextType>;
  dispatch?: Partial<CalculatorActionTypes>;
}>;

export type CalculationErrors = bffSchema.CreateCalculationMutation['createCalculation']['errors'];

export type PayoutMethod = {
  code: string;
  name: string;
  payOutTimeEstimate?: string;
  defaultCorrespondentId?: string;
  correspondents?: Array<{
    id: string;
    name?: string;
    payOutTime?: string;
  }>;
};

export type CalculationVariables = Record<
  keyof Omit<bffSchema.CreateCalculationMutationVariables, 'amount' | 'type'>,
  string
> & {
  amount: number;
  type: bffSchema.CalculationType;
};

export type PushCalculationToDataLayerProps = {
  state: CalculatorContextType;
  ecommerceType: ECOMMERCE_TYPE;
  interactionName: INTERACTION_NAME;
  payoutMethod?: PayoutMethod | null;
};

export type PayoutMethodsQueryVariables = {
  sendCountry: string;
  receiveCountry: string;
  receiveCurrency: string;
};

export type Calculation = {
  payOutMethod: string;
  sendAmount: number;
  receiveAmount: number;
  fee: number;
  totalToPay: number;
  crossedOutRate: number;
  conversionRate: number;
  hasPromo: boolean;
};
