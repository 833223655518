import { OSRecognizer } from './os.types';

export const isIOS: OSRecognizer = ({
  navigator: { userAgent, platform, maxTouchPoints },
  document,
}) => {
  const matchesPlatform = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(platform || '');

  const matchesUserAgent = /iPad|iPhone|iPod/i.test(userAgent);

  // https://stackoverflow.com/a/9039885
  const isIOS13plus1 = userAgent.includes('Mac') && 'ontouchend' in document;

  // https://stackoverflow.com/a/58064481
  const isIOS13plus2 =
    platform === 'MacIntel' && maxTouchPoints && maxTouchPoints > 1;

  return (
    matchesPlatform || matchesUserAgent || isIOS13plus1 || isIOS13plus2 || false
  );
};
