import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  tooltip: {
    maxWidth: '13.25rem',
    backgroundColor: theme.customComponents.tooltip.backgroundColor,
    border: `1px solid ${theme.customComponents.tooltip.borderColor}`,
    boxShadow: `0 -9px 24px 0 ${alpha(
      theme.customComponents.tooltip.boxShadowColor,
      0.1,
    )}`,
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: 1.5,
    color: theme.customComponents.tooltip.color,
    padding: theme.spacing(1.5, 2),
  },
  arrow: {
    color: theme.customComponents.tooltip.arrow.color,
  },
  tooltipPlacementTop: {
    margin: theme.spacing(1, 0),
  },
  tooltipPlacementRight: {
    margin: theme.spacing(0, 1),
  },
  tooltipPlacementBottom: {
    margin: theme.spacing(1, 0),
  },
  tooltipPlacementLeft: {
    margin: theme.spacing(0, 1),
  },
}));
