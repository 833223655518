import { Box, InputBase, Typography } from '@mui/material';
import { BeatLoader, CurrencySelect } from '@wr/web-ui';
import * as React from 'react';

import { CALCULATOR_CONSTANTS } from '../calculator.constants';
import { useStyles } from './country-field.styles';
import { CountryFieldProps } from './country-field.types';
import { getInputValue } from './country-field.utils';

export const CalculatorCountryField: React.FC<CountryFieldProps> = ({
  id,
  loading,
  selectedCountry,
  inputLabel,
  countries,
  inputValue,
  onValueChange,
  onCountryChange,
  onDropdownOpen,
  onSearchCompleted,
  autocompletePlaceholder,
  isCurrentCalculation,
  calculationType,
  context,
}) => {
  const classes = useStyles();
  const rootRef = React.useRef<HTMLDivElement>(null);
  const searchCountryInputEl = React.useRef<HTMLElement | null>(null);

  const showLoadingIndicator = React.useMemo(() => {
    return !!loading && !isCurrentCalculation;
  }, [isCurrentCalculation, loading]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): void | boolean => {
    if (e.key === CALCULATOR_CONSTANTS.ENTER_KEY_INPUT) {
      (e.target as HTMLElement).blur();
    }
  };

  const handleAmountChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const amount = Number(getInputValue(event.currentTarget.value) || 0);
      onValueChange(amount, calculationType);
    },
    [calculationType, onValueChange],
  );

  return (
    <div
      data-testid={id}
      className={classes.countryFieldContainer}
      ref={rootRef}
    >
      <Box className={classes.inputContainer}>
        <Typography className={classes.inputLabel}>{inputLabel}</Typography>
        {showLoadingIndicator ? (
          <BeatLoader size={5.05} className={classes.loader} />
        ) : (
          <InputBase
            data-testid={`${id}-input`}
            inputProps={{
              'className': classes.inputBaseProps,
              'aria-label': 'pricing-calculator-input-label',
              'inputMode': 'numeric',
              'type': 'decimal',
              'pattern': '[0-9]*',
              'autoComplete': 'off',
            }}
            onChange={handleAmountChange}
            onKeyDown={handleKeyDown}
            inputRef={searchCountryInputEl}
            value={inputValue}
            placeholder="_"
          />
        )}
      </Box>
      <CurrencySelect
        {...{
          countries,
          calculationType,
          onCountryChange,
          autocompletePlaceholder,
          selectedCountry,
          onSearchCompleted,
          onDropdownOpen: () => onDropdownOpen(calculationType),
          loading,
          context,
          classes,
        }}
        id={id}
        inputRef={searchCountryInputEl}
        rootRef={rootRef}
      />
    </div>
  );
};
