import { isValidVisitorTypeCookie } from '@wr/web-shared';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import { Anchor } from '@/components/anchor';
import { Button } from '@/components/button';
import { setDataLayerForSubmit } from '@/components/calculator/component/calculator.utils';
import {
  isFullCalculatorEnabledOnLandingPage,
  setDataLayerForCancel,
} from '@/components/calculator/core/core.utils';
import { ButtonsProps } from '@/components/calculator/src/components/buttons/buttons.types';
import { CALCULATOR_QUERY_PARAMS } from '@/constants';
import { AppContext } from '@/context';
import { getLanguageFromLocale } from '@/utils/intl';

import { CalculatorContext } from '../../context';
import { PayoutMethodsEnum } from '../../types';
import { useStyles } from './buttons.styles';

export const Buttons: React.FC<ButtonsProps> = ({
  isLite,
  loginLink,
  signUpLink,
  calculationResult,
  calculatorPageSlug,
  resultCreateProductsCalculation,
}) => {
  const router = useRouter();
  const classes = useStyles({ isLite });
  const { state } = useContext(CalculatorContext);
  const locale = getLanguageFromLocale(router.locale);
  const isLandingPage = isFullCalculatorEnabledOnLandingPage();
  const { isAuthenticated, selectedPayoutMethodId, messages } = useContext(
    AppContext,
  );
  const isAirtime = selectedPayoutMethodId === PayoutMethodsEnum.AIRTIME_TOP_UP;

  const exchangeRate = isAirtime
    ? undefined
    : calculationResult.data?.createCalculation?.calculation?.exchangeRate;

  const onButtonSubmit = (): void => {
    if (!selectedPayoutMethodId) {
      return;
    }

    setDataLayerForSubmit({
      isLite,
      state,
      selectedPayoutMethodId,
      exchangeRate,
    });

    if (isLite) {
      router.push({
        pathname: calculatorPageSlug,
        query: {
          ...router.query,
          [CALCULATOR_QUERY_PARAMS.FROM_LITE]: `${isLite}`,
        },
      });
    } else {
      const id = isAirtime
        ? resultCreateProductsCalculation?.data?.createProductsCalculation
            ?.calculation?.id
        : calculationResult?.data?.createCalculation?.calculation?.id;

      const productIdAirtime = state.topUpUpDenominationId ?? '';

      if (!id) {
        return;
      }

      const query = {
        [CALCULATOR_QUERY_PARAMS.PAY_OUT_METHOD]: selectedPayoutMethodId,
        [CALCULATOR_QUERY_PARAMS.PRODUCT_ID_AIRTIME]: productIdAirtime,
        ...(!isAirtime && {
          [CALCULATOR_QUERY_PARAMS.CALCULATION_VERSION]: '3',
        }),
      };

      const path = `${window.location.origin}/${getLanguageFromLocale(
        router.locale,
      )}`;
      const redirectPath = `calculation/${id}?${new URLSearchParams(
        query,
      ).toString()}`;

      if (!isAuthenticated) {
        const redirectHref =
          (isValidVisitorTypeCookie(['prospect'])
            ? signUpLink?.url
            : loginLink?.url) + `?ReturnUrl=/${locale}/${redirectPath}`;

        window.location.replace(`${path}/${redirectHref}`);
      } else {
        window.location.replace(`${path}/${redirectPath}`);
      }
    }
  };

  const isLoading = state.isLoading || calculationResult.loading;
  const isCalculationLoading = isAirtime
    ? resultCreateProductsCalculation.loading
    : calculationResult.loading;
  const hasErrors = Boolean(state.errors.length || calculationResult.error);
  const hasMissingFields =
    (!isLite &&
      isAirtime &&
      (!state.isPhoneNumberValid || !state.topUpUpDenominationId)) ||
    (!isLite && !isAirtime && !state.correspondentId);

  const isDisabled =
    !selectedPayoutMethodId ||
    isLoading ||
    hasErrors ||
    !state.payoutMethods.length ||
    isCalculationLoading ||
    hasMissingFields;

  return (
    <>
      <Button
        data-testid="calculator-submit-button"
        variant="contained"
        color="primary"
        onClick={onButtonSubmit}
        fullWidth
        className={classes.button}
        disabled={isDisabled}
      >
        {messages?.continue}
      </Button>
      {(isLandingPage ? false : !isLite) && (
        <Anchor
          data-testid="calculator-cancel-button"
          className={classes.cancelButton}
          isInternal={true}
          onClick={setDataLayerForCancel}
          //  Logo redirects to /en within Link
          url="/"
          rel="noopener"
        >
          {messages?.cancel}
        </Anchor>
      )}
    </>
  );
};
