import {
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
  VARIABLE,
} from '@wr/web-shared';

import { CalculatorProps } from '../component/calculator.types';
import {
  PAYOUT_METHOD_SELECTOR,
  RECEIVE_COUNTRY_SELECTOR,
  SEND_COUNTRY_SELECTOR,
} from './core.constants';

export const getCalculationCorridor = (
  sendCountryCode: string,
  receiveCountryCode: string,
): string =>
  `${sendCountryCode.toUpperCase()}-${receiveCountryCode.toUpperCase()}`;

export const getCalculationCorridorWithCurrencies = (
  sendCountryCode: string,
  sendCurrency: string,
  receiveCountryCode: string,
  receiveCurrency: string,
): string =>
  `${sendCountryCode.toUpperCase()}/${receiveCountryCode.toUpperCase()}/${sendCurrency.toUpperCase()}/${receiveCurrency.toUpperCase()}`;

export const getPayoutMethodError = (
  message: string,
  payoutMethodName: string,
  sendCountry: string,
  receiveCountry: string,
): string =>
  message
    .replace(PAYOUT_METHOD_SELECTOR, payoutMethodName)
    .replace(SEND_COUNTRY_SELECTOR, sendCountry)
    .replace(RECEIVE_COUNTRY_SELECTOR, receiveCountry);

export const setDataLayerForCancel = (): void => {
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: INTERACTION_NAME.CALC_CANCEL_CLICK,
  });
};

export const setDataLayerForDropdownOpen = (
  name: string,
  isLite: CalculatorProps['isLite'],
): void => {
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: isLite
      ? INTERACTION_NAME.LITE_CALC_OPEN_DROPDOWN
      : INTERACTION_NAME.CALC_OPEN_DROPDOWN,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_NAME
      : VARIABLE.CALC_DROPDOWN_NAME]: name,
  });
};

export const setDataLayerForDropdownSearch = (
  value: string,
  name: string,
  isLite: CalculatorProps['isLite'],
): void => {
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: isLite
      ? INTERACTION_NAME.LITE_CALC_SEARCH_DROPDOWN
      : INTERACTION_NAME.CALC_SEARCH_DROPDOWN,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_NAME
      : VARIABLE.CALC_DROPDOWN_NAME]: name,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_SEARCH_VALUE
      : VARIABLE.CALC_DROPDOWN_SEARCH_VALUE]: value,
  });
};

export const setDataLayerForDropdownSelection = (
  value: string,
  name: string,
  isLite: CalculatorProps['isLite'],
): void => {
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: isLite
      ? INTERACTION_NAME.LITE_CALC_SELECT_DROPDOWN
      : INTERACTION_NAME.CALC_SELECT_DROPDOWN,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_NAME
      : VARIABLE.CALC_DROPDOWN_NAME]: name,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_VALUE
      : VARIABLE.CALC_DROPDOWN_VALUE]: value,
  });
};

export const setDataLayerError = (
  isLite: CalculatorProps['isLite'],
  errorMessage: string,
): void => {
  const dataLayerProps = {
    event: EVENT.VISITOR_INTERACTION,
    interactionName: isLite
      ? INTERACTION_NAME.LITE_CALC_ERROR
      : INTERACTION_NAME.CALC_ERROR,
    [isLite ? VARIABLE.LITE_CALC_MESSAGE : VARIABLE.CALC_MESSAGE]: errorMessage,
  };

  pushToDataLayer(dataLayerProps);
};

// we had optimizely edge a/b test here
// in future we can repeat it again in optimizely web
export const isFullCalculatorEnabledOnLandingPage = (): boolean => false;
