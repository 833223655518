import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  button: {
    'color': theme.customComponents.carousel.button.color,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
