import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(theme => ({
  exchangeRateWrapper: {
    background: `linear-gradient(90deg, ${colors.purpleNormal} 0%, ${colors.purpleDark} 100%)`,
    borderTopLeftRadius: theme.shape.borderRadius * 4,
    borderTopRightRadius: theme.shape.borderRadius * 4,
  },
  exchangeRatePromotionalWrapper: {
    background: colors.lightCyan,
    borderTopLeftRadius: theme.shape.borderRadius * 4,
    borderTopRightRadius: theme.shape.borderRadius * 4,
  },
}));

export default useStyles;
