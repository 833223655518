/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
  CustomWindow,
  PerimeterXCaptchaData,
  PerimeterXStyles,
} from '@wr/web-shared';
import type { Logger } from 'pino';

import { executeWithRetry, isDev } from '..';

declare const window: CustomWindow;

export const PERIMETER_X_SHOW_CAPTCHA_EVENT = 'PERIMETER_X_SHOW_CAPTCHA_EVENT';
export const PERIMETER_X_CAPTCHA_PLACEHOLDER_ELEMENT_ID = 'px-captcha';
const PERIMETER_X_SCRIPT_LOAD_ATTEMPTS = 5;

async function loadScript(url: string) {
  return new Promise<void>((resolve, reject) => {
    const element = document.createElement('script');
    element.src = url;
    element.type = 'text/javascript';
    element.async = true;
    element.onload = () => resolve();
    element.onerror = () => reject();
    document.head.appendChild(element);
  });
}

/**
 * perimeterX captcha will be loaded to #px-captcha element, it should be in html
 * for example see ui/src/components/perimeter-x-captcha-modal/perimeter-x-captcha-modal.component.tsx
 * @param arg.captchaData response data on blocked by perimeterX request
 * @param arg.onCaptchaSuccess is called when user successfully bypass captcha
 * @param arg.styles captcha styles
 */
export async function loadScriptAndInitPerimeterXCaptcha({
  captchaData,
  onCaptchaSuccess,
  styles,
  logger,
}: {
  captchaData: PerimeterXCaptchaData;
  onCaptchaSuccess: () => void;
  styles: PerimeterXStyles;
  logger: Logger;
}) {
  const scriptUrlPrefixForDev = isDev()
    ? process.env.NEXT_PUBLIC_STAGING_URL // to display captcha locally we need to take the script from staging
    : '';
  window._pxAppId = captchaData.appId;
  window._pxJsClientSrc = captchaData.jsClientSrc
    ? scriptUrlPrefixForDev + captchaData.jsClientSrc
    : process.env.NEXT_PUBLIC_PERIMETER_X_MAIN_SCRIPT_URL!;
  window._pxFirstPartyEnabled = captchaData.firstPartyEnabled;
  window._pxVid = captchaData.vid;
  window._pxUuid = captchaData.uuid;
  window._pxHostUrl = captchaData.hostUrl;
  window[`_${captchaData.appId}`] = styles;
  window._pxOnCaptchaSuccess = (isValid: boolean): void => {
    if (isValid) {
      onCaptchaSuccess();
    }
  };

  const blockScriptUrl = captchaData.blockScript
    ? scriptUrlPrefixForDev + captchaData.blockScript
    : process.env.NEXT_PUBLIC_PERIMETER_X_CAPTCHA_SCRIPT_URL!;

  try {
    // retry - sometimes script returns 404, it may be test env issue
    // PS we don't know from which WR app we load the script
    await executeWithRetry(
      () => loadScript(blockScriptUrl),
      PERIMETER_X_SCRIPT_LOAD_ATTEMPTS,
      attempt =>
        logger.warn(`Failed attempt ${attempt} to load perimeterX script`),
    );
  } catch (e) {
    logger.error(
      e,
      `Failed to load perimeterX captcha script from ${PERIMETER_X_SCRIPT_LOAD_ATTEMPTS} attempts`,
    );
    throw e;
  }
}
