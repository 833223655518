export const LOGOUT_REDIRECT_FAIL = 'Logout redirect fail';
export const UNAUTHORIZED_EXCEPTION = 'UnauthorizedException';
export const INCORRECT_OTP_EXCEPTION = 'IncorrectOtpException';
export const OTP_TOO_OFTEN_GENERATED_SMS_CODE_EXCEPTION =
  'OtpTooOftenGeneratedException';
export const UNAUTHORIZED_WITH_COOKIE_ERROR_MESSAGE =
  'Unauthorized access with cookie';
export const UNAUTHORIZED_SERVER_SIDE_ERROR_MESSAGE =
  'Server side unauthorized access';
export const UNAUTHORIZED_ERROR_MESSAGE =
  'Unauthorized access, redirect to login';
export const LOGIN_PATH = 'account/login';
export const LOGOUT_PATH = 'account/logout';
