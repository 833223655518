import { useRef, useState } from 'react';

import {
  ENTER_KEY_INPUT,
  ESC_CODE,
  SPACE_CODE,
  SPACE_CODE_OLD,
} from '../../../core/core.constants';
import { UseSelectToggles } from './select.types';

export const useSelectToggles = <Option>(): UseSelectToggles<Option> => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fieldRef = useRef(null);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (ref: null | HTMLElement): void => {
    setAnchorEl(ref);
  };

  const isOpen = Boolean(anchorEl);

  const handleDropdownKeyboardNavigation: UseSelectToggles<Option>['handleDropdownKeyboardNavigation'] = (
    option,
    callback,
  ) => event => {
    switch (event.key) {
      case SPACE_CODE:
      case SPACE_CODE_OLD:
      case ENTER_KEY_INPUT: {
        event.preventDefault();
        callback?.(option.value);
        handleClose();
        break;
      }
      case ESC_CODE: {
        if (isOpen) {
          handleClose();
        }
        break;
      }
      default:
        break;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case ESC_CODE: {
        if (isOpen) {
          handleClose();
        }
        break;
      }
      case SPACE_CODE_OLD:
      case SPACE_CODE: {
        if (!isOpen) {
          event.preventDefault();
          handleClick(fieldRef.current);
        }
        break;
      }
      default:
        break;
    }
  };

  return {
    anchorEl,
    handleClose,
    handleClick,
    handleKeyDown,
    handleDropdownKeyboardNavigation,
    fieldRef,
    isOpen,
  };
};
