import {
  CurrencySelect as UICurrencySelect,
  CurrencySelectProps,
} from '@wr/web-ui';
import React, { FC, useContext } from 'react';

import { AppContext } from '@/context';

import { useStyles } from './cex-rate-alerts-fields.styles';

export const CurrencySelect: FC<
  Omit<CurrencySelectProps, 'context' | 'rootRef'>
> = props => {
  const classes = useStyles();
  const { osName } = useContext(AppContext);
  const rootRef = React.useRef<HTMLDivElement>(null);
  return (
    <div className={classes.currencyContainer} ref={rootRef}>
      <UICurrencySelect {...props} context={{ osName }} rootRef={rootRef} />
    </div>
  );
};
