import { BLOCKS } from '@contentful/rich-text-types';
import { TypographyTypeMap } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';
import { BodyText as UIBodyText } from '@wr/web-ui';
import React, { FC } from 'react';

import { getRichTextHeadingOptions } from '@/components/rich-text-content-renderer/rich-text-content-renderer.utils';
import { buildContentfulSidekickAttributes } from '@/utils';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';

export const BodyText: FC<contentfulSchema.BodyTextFragment> = ({
  sys,
  __typename,
  name,
  bodyTextHeading,
  bodyTextContent,
  bodyTextContentTwo,
  headingAlign,
  contentAlign,
  isPageSection,
  listIndent,
  paragraphIndent,
}) => {
  const options = {
    ...getRichTextHeadingOptions({
      align: (contentAlign as TypographyTypeMap['props']['align']) || undefined,
    }),
    [BLOCKS.PARAGRAPH]: {
      align: (contentAlign as TypographyTypeMap['props']['align']) || undefined,
      paragraph: true,
      variant: 'body2',
    },
    [BLOCKS.LIST_ITEM]: {
      variant: 'body2',
    },
  };

  return (
    <UIBodyText<RichTextProps>
      name={name || ''}
      bodyTextHeading={
        bodyTextHeading?.json
          ? {
              ...bodyTextHeading,
              options: {
                ...getRichTextHeadingOptions({
                  align:
                    (headingAlign as TypographyTypeMap['props']['align']) ||
                    undefined,
                }),
              },
            }
          : null
      }
      bodyTextContent={
        bodyTextContent?.json
          ? ({
              ...bodyTextContent,
              options,
            } as RichTextProps)
          : null
      }
      bodyTextContentTwo={
        bodyTextContentTwo?.json
          ? ({
              ...bodyTextContentTwo,
              options,
            } as RichTextProps)
          : null
      }
      RichTextComponent={RichTextContentRenderer}
      listIndent={listIndent || undefined}
      paragraphIndent={paragraphIndent || undefined}
      contentAlign={contentAlign || undefined}
      isPageSection={isPageSection || undefined}
      classNameMap={{}}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
