import { CalculatorContextType } from '@/context';
import {
  CurrencyPair,
  HistoricalRate,
} from '@/lib/rest/currency-converter/historical-rates';
import { logger } from '@/utils';

export const fetchHistoricalRates = async (
  currencyPair: CurrencyPair,
  selectedPayoutMethodId: CalculatorContextType['payoutMethodId'],
  startDate: string,
  endDate: string,
): Promise<HistoricalRate[]> => {
  try {
    const response = await fetch('/api/currency-converter/historical-rates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        currencyPair,
        selectedPayoutMethodId,
        startDate,
        endDate,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch historical rates');
    }

    const data = await response.json();
    return data.data.rates;
  } catch (error) {
    logger.error(error, `fetchHistoricalRates request failed`);
    throw error;
  }
};
