import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  section: {
    backgroundColor: theme.customComponents.reviews.section.backgroundColor,
    padding: theme.spacing(7, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10, 0),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(13, 0),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(20, 0),
    },
  },
  carouselText: {
    marginBottom: theme.spacing(2),
  },
  trustpilotLogo: {
    height: '2.5rem',
    width: 'auto',
  },
  ratingIcon: {
    width: '2rem',
    height: '2rem',
  },
  reviewsTotalScore: {
    marginLeft: theme.spacing(1),
  },
  totalReviewsWrapper: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  heading: {
    'marginBottom': theme.spacing(1),
    'minHeight': '2rem',
    'display': 'flex',
    'alignItems': 'center',

    '&:after': {
      content: "''",
      display: 'block',
      width: '0.125rem',
      height: '2rem',
      backgroundColor: theme.customComponents.reviews.heading.backgroundColor,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  ratingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    margin: theme.spacing(0, 0, 1, 0),
  },
  trustpilotLink: {
    display: 'inline-block',
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));
