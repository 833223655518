import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(
  ({ spacing, breakpoints, customComponents }) => {
    return {
      section: {
        backgroundColor:
          customComponents.listOfCountries.section.backgroundColor,
        textAlign: 'center',
        padding: spacing(3, 0),

        [breakpoints.up('sm')]: {
          padding: spacing(4, 0),
        },

        [breakpoints.up('md')]: {
          padding: spacing(7, 0),
        },

        [breakpoints.up('lg')]: {
          padding: spacing(9, 0),
        },
      },
      heading: {
        marginBottom: spacing(3),
        color: customComponents.listOfCountries.heading.color,

        [breakpoints.up('md')]: {
          marginBottom: spacing(5),
        },
      },
      subheading: {
        marginBottom: spacing(2),
        color: customComponents.listOfCountries.subheader.color,
      },
      grid: {
        marginBottom: spacing(0.5),
      },
      expandButton: {
        marginTop: spacing(2.5),
      },
    };
  },
);

export default useStyles;
