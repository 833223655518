import { BLOCKS } from '@contentful/rich-text-types';
import { Theme, useMediaQuery } from '@mui/material';
import { Hero as UIHero } from '@wr/web-ui';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { CexCalculator } from '@/components';
import { Calculator } from '@/components/calculator';
import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { getRichTextHeadingOptions } from '@/components/rich-text-content-renderer/rich-text-content-renderer.utils';
import { SendToCountryModule } from '@/components/send-to-country-module';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';
import { useStyles } from './hero.styles';
import { HeroProps } from './hero.types';

export const Hero: React.FC<HeroProps> = ({
  sys,
  name,
  __typename,
  rightModule,
  headingRichText,
  subHeadingRichText,
  link,
  backgroundImage,
  backgroundImageMobile,
  trustpilotMessage,
  trustpilotLogo,
  trustpilotLink,
  calculatorPage,
  isSectionOnTop,
  backgroundColor,
  secondBackgroundColor,
  contentAlignment,
  hideDescriptionOnMobile,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const calculatorPageSlug = calculatorPage?.slug;
  const isSMDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const image = isSMDown
    ? backgroundImageMobile || backgroundImage
    : backgroundImage;

  const fullCalculatorUrl = 'full-calculator' + router.asPath.substring(1);

  const getRightColumnComponent = (): React.ReactNode => {
    switch (rightModule?.__typename) {
      case 'Calculator': {
        const CalculatorComponent =
          rightModule.version === 'v2' ? CexCalculator : Calculator;
        return (
          <CalculatorComponent
            {...rightModule}
            calculatorPageSlug={calculatorPageSlug}
          />
        );
      }
      case 'SendToCountryModule':
        return <SendToCountryModule {...rightModule} />;
      default:
        return null;
    }
  };

  const hasRightColumn = Boolean(rightModule);

  return (
    <UIHero<AnchorProps, ButtonProps, RichTextProps>
      name={name || undefined}
      heading={
        headingRichText?.json
          ? {
              ...headingRichText,
              options: {
                ...getRichTextHeadingOptions({
                  color: 'primary.contrastText',
                  className: clsx(classes.heading, {
                    [classes.textCenter]: hasRightColumn,
                    [classes.fullWidthHeading]: !hasRightColumn,
                  }),
                }),
              },
            }
          : null
      }
      subheading={
        subHeadingRichText?.json
          ? {
              ...subHeadingRichText,
              options: {
                [BLOCKS.HEADING_5]: {
                  color: 'primary.contrastText',
                  variant: 'body2',
                },
                [BLOCKS.HEADING_6]: {
                  color: 'primary.contrastText',
                  variant: 'body1',
                  className: classes.heading6,
                },
                [BLOCKS.PARAGRAPH]: {
                  variant: 'h4',
                  color: 'primary.contrastText',
                  className: clsx(classes.subheading, {
                    [classes.textCenter]: hasRightColumn,
                    [classes.fullWidthSubHeading]: !hasRightColumn,
                  }),
                },
              },
            }
          : null
      }
      button={
        link?.label
          ? { ...link, children: link.label, url: fullCalculatorUrl }
          : null
      }
      backgroundImage={
        image
          ? {
              ...image,
              loading: 'eager',
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'top left',
              },
            }
          : null
      }
      trustpilotMessage={trustpilotMessage || null}
      trustpilotLogo={trustpilotLogo || null}
      trustpilotLink={
        trustpilotLink?.label
          ? {
              ...trustpilotLink,
              children: trustpilotLink.label || undefined,
              underline: 'none',
              color: trustpilotLink.color || undefined,
            }
          : null
      }
      hideDescriptionOnMobile={hideDescriptionOnMobile || false}
      trustpilotWidgetUrl={null}
      AnchorComponent={Anchor}
      ButtonComponent={Button}
      RichTextComponent={RichTextContentRenderer}
      isSectionOnTop={isSectionOnTop}
      backgroundColor={backgroundColor || null}
      secondBackgroundColor={secondBackgroundColor || null}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
      contentAlignment={contentAlignment || 'top'}
    >
      {getRightColumnComponent()}
    </UIHero>
  );
};
