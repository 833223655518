import { Paper, Typography } from '@mui/material';
import React, { useState } from 'react';

import { Container } from '..';
import { FAQModuleItem } from '../faq-module-item';
import { Image } from '../image';
import useStyles from './faq-module.styles';
import { FAQModuleProps } from './faq-module.types';

export const FAQModule = <Link, RichText>({
  name,
  title,
  subheading,
  image,
  ctaButton,
  questions,
  LinkComponent,
  RichTextComponent,
  containerProps,
}: FAQModuleProps<Link, RichText>): React.ReactElement => {
  const classes = useStyles();
  const [expandedQuestion, setExpandedQuestion] = useState<string | null>(null);

  return (
    <section id={name} className={classes.section} {...containerProps}>
      <Container>
        <Typography
          color="primary.contrastText"
          component="h2"
          variant="h2"
          className={classes.header}
        >
          {title}
        </Typography>
        <Typography color="primary.contrastText" className={classes.subheading}>
          {subheading}
        </Typography>
        {image && (
          <div className={classes.imageWrapper}>
            <Image {...image} />
          </div>
        )}
        <Paper
          elevation={0}
          data-testid={'faq-module-accordion-container'}
          classes={{
            root: classes.questions,
            rounded: classes.borderRounding,
          }}
        >
          {questions.map(item => (
            <FAQModuleItem
              key={item.name}
              name={item.name}
              title={item.title}
              description={item.description}
              onShowClick={id => setExpandedQuestion(id)}
              onHideClick={() => setExpandedQuestion(null)}
              isExpanded={item.name === expandedQuestion}
              RichTextComponent={RichTextComponent}
              containerProps={item?.containerProps}
            />
          ))}
        </Paper>
        {ctaButton && (
          <LinkComponent
            {...ctaButton}
            className={classes.ctaButton}
            data-testid={'faq-button'}
          />
        )}
      </Container>
    </section>
  );
};
