import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { BeatLoaderStylesProps } from './beat-loader.types';

export const useStyles = makeStyles<Theme, BeatLoaderStylesProps>(_theme => ({
  'loader': {
    fontSize: props => `${props.size}px`,
    position: 'relative',
    width: '9.75em',
    height: '5em',
  },
  'circle': {
    background: '#0E0D5D',
    position: 'absolute',
    width: '2.5em',
    height: '2.5em',
    top: `${5 / 2 - 2.5 / 2}em`,
    animationFillMode: 'both',
    animation: '$load7 1.8s infinite ease-in-out',
    borderRadius: '50%',
    transform: 'scale(0)',
  },
  'circle1': {
    left: 0,
    animationDelay: '-0.32s',
    opacity: 0.5,
  },
  'circle2': {
    left: `${2.5 + 1}em`,
  },
  'circle3': {
    left: `${2.5 * 2 + 1 * 2}em`,
    animationDelay: '0.32s',
    opacity: 0.5,
  },
  '@keyframes load7': {
    '0%, 100%': {
      transform: 'scale(0)',
    },
    '50%': {
      transform: 'scale(1)',
    },
  },
}));
