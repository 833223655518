import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(({ palette, spacing, shape }) => ({
  accordion: {
    backgroundColor: colors.greyLight,
  },
  errorIcon: {
    fontSize: '1.25rem',
    marginRight: spacing(1.5),
  },
  borderRounding: {
    'borderRadius': shape.borderRadius,
    '&:first-child': {
      borderTopLeftRadius: shape.borderRadius,
      borderTopRightRadius: shape.borderRadius,
    },

    '&:last-child': {
      borderBottomLeftRadius: shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
  },
  summary: {
    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
  expanded: {
    marginTop: spacing(0),
    marginBottom: spacing(0),
  },
  content: {
    '&.Mui-expanded': {
      marginTop: spacing(1.5),
      marginBottom: spacing(1.5),
    },
  },
  expandIcon: {
    fontSize: '1.5rem',
    color: palette.text.primary,
  },
  text: {
    fontSize: '0.9375rem',
    lineHeight: 1.47,
    fontWeight: 400,
    color: palette.text.secondary,
    margin: 0,
  },
  expandedMenu: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${alpha(colors.darkGray, 0.26)}`,
    paddingTop: spacing(2.5),
    paddingBottom: spacing(2.5),
  },
}));

export default useStyles;
