import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import {
  AppContext,
  CalculatorContext,
  CalculatorContextProviderType,
} from '@/context';
import { useGtmPageLoadVariables } from '@/hooks';

import { INITIAL_CALCULATOR_CONTEXT } from './calculator.constants';

export const CalculatorContextProvider: CalculatorContextProviderType = ({
  children,
}) => {
  const router = useRouter();
  const appContext = useContext(AppContext);

  const [isLoading, setIsLoading] = React.useState(true);

  const [sendAmount, setSendAmount] = React.useState(
    appContext.calculatorSendAmount ||
      INITIAL_CALCULATOR_CONTEXT.calculationAmount,
  );
  const [receiveAmount, setReceiveAmount] = React.useState(0);

  const [calculationAmount, setCalculationAmount] = React.useState(
    appContext.calculatorSendAmount ||
      INITIAL_CALCULATOR_CONTEXT.calculationAmount,
  );
  const [calculationType, setCalculationType] = React.useState(
    INITIAL_CALCULATOR_CONTEXT.calculationType,
  );

  const [sendCountry, setSendCountry] = React.useState(
    appContext.calculatorSendCountry,
  );
  const [receiveCountry, setReceiveCountry] = React.useState(
    appContext.receiveCountry,
  );

  const [payoutMethodId, setPayoutMethodId] = React.useState(
    appContext.selectedPayoutMethodId,
  );

  const [payoutMethods, setPayoutMethods] = React.useState(
    INITIAL_CALCULATOR_CONTEXT.payoutMethods,
  );

  const [exchangeRate, setExchangeRate] = React.useState(
    INITIAL_CALCULATOR_CONTEXT.exchangeRate,
  );

  useGtmPageLoadVariables({
    sendCountry: sendCountry || undefined,
    receiveCountry: receiveCountry || undefined,
    analyticsPageType: appContext.analyticsPageType,
    analyticsPageName: appContext.analyticsPageName,
    countriesByCode: appContext.countriesByCode,
    accountDetails: appContext.accountDetails,
    locale: router.locale,
  });

  return (
    <CalculatorContext.Provider
      value={[
        {
          sendAmount,
          receiveAmount,
          calculationType,
          calculationAmount,
          sendCountry,
          receiveCountry,
          payoutMethodId,
          payoutMethods,
          exchangeRate,
          isLoading,
        },
        {
          setSendAmount,
          setReceiveAmount,
          setCalculationAmount,
          setCalculationType,
          setSendCountry,
          setReceiveCountry,
          setPayoutMethodId,
          setPayoutMethods,
          setExchangeRate,
          setIsLoading,
        },
      ]}
    >
      {children}
    </CalculatorContext.Provider>
  );
};
