import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(theme => ({
  countryOnly: {
    cursor: 'pointer',
    caretColor: 'transparent',
    textOverflow: 'ellipsis',
  },
  disableArrows: {
    '-webkit-appearance': 'textfield',
    '-moz-appearance': 'textfield',
    'appearance': 'textfield',

    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
  rootFocused: {
    boxShadow: `0 0 0 4px ${colors.purpleLight}`,
  },
  errorField: {
    marginBottom: theme.spacing(0.5),
  },
  exchangeInputRoot: {
    paddingRight: theme.spacing(0.75),
    lineHeight: 1.125,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  exchangeInput: {
    fontSize: '2rem',
    padding: 0,
    height: '2.25rem',
  },
  flag: {
    alignSelf: 'center',
    height: '1.5rem',
    width: '1.5rem',
  },
  currency: {
    backgroundColor: theme.palette.primary.dark,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
  },
  currencySelect: {
    cursor: 'pointer',
  },
  loader: {
    marginTop: theme.spacing(0.75),
  },
  amountContainer: {
    border: `2px solid ${theme.palette.primary.dark}`,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.common.white,
  },
}));

export default useStyles;
