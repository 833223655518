import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { UserMenuProps } from './user-menu.types';

export const useStyles = makeStyles<
  Theme,
  Pick<UserMenuProps<unknown>, 'color'>
>(({ spacing, customComponents }) => ({
  itemsWrapper: {
    padding: spacing(2.5),
    width: '300px',
  },
  menuItem: {
    borderTop: `1px solid ${customComponents.header.menuItem.borderTopColor}`,
  },
  icon: {
    fill: 'currentColor',
  },
}));
