import React, { FC } from 'react';

import { useIntlMessages } from '@/hooks/intl.hooks';
import { interpolate } from '@/utils';

import { IntlProps as TextInterpolatorProps } from './intl.types';

export const TextInterpolator: FC<TextInterpolatorProps> = ({ text }) => {
  const messages = useIntlMessages();
  return <>{interpolate(text, messages)}</>;
};
