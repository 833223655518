import { Paper, Typography } from '@mui/material';
import React from 'react';

import { CountryFlag } from '../country-flag-image';
import { Image } from '../image';
import { StyledRating } from '../styled-rating';
import { useStyles } from './review.styles';
import { ReviewProps } from './review.types';

export function Review({
  reviewsNumberLabel,
  starIcon,
  avatarIcon,
  review,
}: ReviewProps): React.ReactElement {
  const classes = useStyles();
  const authorCountryCode = review.countryCode?.toLowerCase();

  return (
    <Paper
      classes={{
        root: classes.review,
        rounded: classes.rounded,
      }}
      elevation={0}
      data-testid="review"
    >
      <div className={classes.reviewer}>
        {!!avatarIcon && (
          <Image className={classes.avatarIcon} {...avatarIcon} />
        )}
        <div className={classes.reviewerData}>
          <Typography
            variant="body1"
            className={classes.reviewerName}
            data-testid="reviewer-name"
          >
            {review.reviewer}
          </Typography>
          {review.reviewsNumberText && reviewsNumberLabel && (
            <Typography variant="body1">
              {review.reviewsNumberText} {reviewsNumberLabel}
            </Typography>
          )}
        </div>
      </div>
      {review.stars && (
        <StyledRating
          value={review.stars}
          icon={
            (starIcon && (
              <Image className={classes.ratingIcon} {...starIcon} />
            )) ||
            undefined
          }
          data-testid={`${review.name}-rating`}
        />
      )}
      <Typography
        component="q"
        variant="body1"
        display="block"
        className={classes.comment}
        data-testid="review-comment"
      >
        {review.comment}
      </Typography>

      <div className={classes.footer} data-testid="review-footer">
        {authorCountryCode && (
          <CountryFlag
            type="circle"
            className={classes.countryFlag}
            code={authorCountryCode}
          />
        )}
        {review.timeDuration && (
          <Typography variant="body1">{review.timeDuration}</Typography>
        )}
      </div>
    </Paper>
  );
}
