import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(({ spacing, shape, customComponents }) => ({
  expandIcon: {
    fontSize: '2rem',
    color: customComponents.faqModuleItem.expandIcon.color,
  },
  borderRounding: {
    '&:first-child': {
      borderTopLeftRadius: shape.borderRadius * 2,
      borderTopRightRadius: shape.borderRadius * 2,
    },

    '&:last-child': {
      borderBottomLeftRadius: shape.borderRadius * 2,
      borderBottomRightRadius: shape.borderRadius * 2,
    },
  },
  expandedMenu: {
    backgroundColor:
      customComponents.faqModuleItem.expandedMenu.backgroundColor,
    borderLeftColor:
      customComponents.faqModuleItem.expandedMenu.borderLeftColor,
    borderLeftWidth: shape.borderRadius,
    borderLeftStyle: 'solid',
    padding: spacing(2, 2, 1, 2),
    textAlign: 'justify',
  },
}));

export default useStyles;
