import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing, typography }) => ({
  container: {
    paddingTop: spacing(5),
    paddingBottom: spacing(5),
  },
  postFeedbackMessage: {
    marginTop: spacing(1.5),
    marginBottom: 0,
  },
  feedbackOptionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacing(1.5),
    gap: spacing(3),
  },
  feedbackOption: {
    gap: spacing(1),
    padding: `${spacing(1.25)} ${spacing(1)}`,
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  feedbackOptionIcon: {
    width: spacing(2.5),
    height: spacing(2.5),
    objectFit: 'contain',
  },
  feedbackOptionLabel: {
    fontWeight: typography.fontWeightBold,
  },
}));
