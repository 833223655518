import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import {
  getFlexAlignBasedOnTextAlign,
  important,
  validateTextAlign,
} from '../../utils/styles/styles';
import { BodyTextWrapperProps } from './body-text.types';

type StyleProps = Pick<
  BodyTextWrapperProps,
  'isPageSection' | 'contentAlign' | 'listIndent' | 'paragraphIndent'
>;

const useStyles = makeStyles<Theme, StyleProps>(({ spacing, breakpoints }) => ({
  bodyTextSection: ({ isPageSection }) => ({
    paddingTop: !isPageSection ? important(spacing(0)) : spacing(3),
    paddingBottom: !isPageSection ? important(spacing(0)) : spacing(3),

    [breakpoints.down('sm')]: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },
  }),
  content: ({ contentAlign, listIndent, paragraphIndent }) => ({
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: getFlexAlignBasedOnTextAlign(contentAlign),

    ...(contentAlign
      ? {
          textAlign: validateTextAlign(contentAlign),
        }
      : {}),
    ...(listIndent
      ? {
          '& ul, & ol': {
            paddingInlineStart: `${listIndent || '16'}px`,
          },
        }
      : {}),
    ...(paragraphIndent ? { textIndent: `${paragraphIndent}px` } : {}),
  }),

  columnsContainer: {
    '&&': {
      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,

    [breakpoints.up('md')]: {
      '&:first-child': {
        marginRight: spacing(2.5),
      },
    },
  },
}));

export default useStyles;
