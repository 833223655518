import { Tooltip, Typography } from '@mui/material';
import { Image } from '@wr/web-ui';
import clsx from 'clsx';

import {
  BackToSchoolCountry,
  formatCurrencyValue,
} from '@/components/back-to-school';
import { RichTextContentRenderer } from '@/components/rich-text-content-renderer';

import useStyles from './interactive-map.styles';
import { InteractiveMapProps } from './interactive-map.types';

export const InteractiveMap: React.FC<InteractiveMapProps> = ({
  mapSectionHeading,
  mapSectionImage,
  countriesCollection,
  mapSectionMessage,
  totalCostPerChildLabel,
  totalCostPerHouseholdLabel,
  selectedCurrency,
  averageHouseholdIncomeLabel,
}) => {
  const classNames = useStyles({
    totalCostPerChildLabel: totalCostPerChildLabel ?? '',
  })();

  const isMapVisible = Boolean(mapSectionImage?.url);
  const hasCountries = Array.isArray(countriesCollection?.items);

  const CountryTooltipContent = (
    country: BackToSchoolCountry,
  ): React.ReactElement => (
    <section className={clsx(classNames.tooltip)}>
      <section
        className={clsx(classNames.tooltipHeader, classNames.tooltipFormatting)}
      >
        <section className={clsx(classNames.flexCenterStart)}>
          <section
            className={clsx(
              classNames.countryFlag,
              classNames.tooltipHeaderCountryFlag,
            )}
          >
            <Image {...country.countryFlag} />
          </section>
          <Typography variant="subtitle2">{country?.country}</Typography>
        </section>
      </section>
      <section className={clsx(classNames.countryWrapper)}>
        {country?.totalChildCost && (
          <section className={clsx(classNames.countryContainer)}>
            <section className={clsx(classNames.flexStart)}>
              <section
                className={clsx(classNames.marker, classNames.bgPerChild)}
              />
              <Typography variant="body1">{totalCostPerChildLabel}</Typography>
            </section>
            <section
              className={clsx(
                classNames.tooltipValue,
                classNames.clPerChild,
                classNames.flexTextCenter,
              )}
            >
              <Typography variant="body1">
                {formatCurrencyValue(
                  country.totalChildCost,
                  selectedCurrency.exchangeRate,
                  selectedCurrency.currencySymbol,
                )}
              </Typography>
            </section>
          </section>
        )}

        <section className={clsx(classNames.countryContainer)}>
          <section className={clsx(classNames.flexStart)}>
            <section
              className={clsx(classNames.marker, classNames.bgPerHousehold)}
            />
            <section>
              <Typography variant="body1">
                {totalCostPerHouseholdLabel}
              </Typography>
              {country?.childrenRatio && (
                <Typography variant="body1">{`(for ${country?.childrenRatio} children)`}</Typography>
              )}
            </section>
          </section>
          <section
            className={clsx(
              classNames.tooltipValue,
              classNames.clPerHousehold,
              classNames.flexTextCenter,
            )}
          >
            <Typography variant="body1">
              {formatCurrencyValue(
                country?.totalHouseholdCost ? country.totalHouseholdCost : 0,
                selectedCurrency.exchangeRate,
                selectedCurrency.currencySymbol,
              )}
            </Typography>
          </section>
        </section>
        <section className={clsx(classNames.flexBetween)}>
          <section className={clsx(classNames.flexStart)}>
            <section className={clsx(classNames.marker)} />
            <Typography variant="body1">
              {averageHouseholdIncomeLabel}
            </Typography>
          </section>
          <section
            className={clsx(
              classNames.tooltipValue,
              classNames.clIncome,
              classNames.flexTextCenter,
            )}
          >
            <Typography variant="body1">
              {formatCurrencyValue(
                country?.householdIncome ? country.householdIncome : 0,
                selectedCurrency?.exchangeRate,
                selectedCurrency?.currencySymbol,
              )}
            </Typography>
          </section>
        </section>
      </section>
    </section>
  );

  return (
    <section
      className={clsx(classNames.interactiveMapContainer, classNames.section)}
      data-testid="interactive-map"
    >
      <section className={classNames.headingAndMessage}>
        <Typography className={classNames.sectionContentHeader} variant="h4">
          {mapSectionHeading}
        </Typography>
        {mapSectionMessage?.json && (
          <section className={classNames.pt2}>
            <RichTextContentRenderer json={mapSectionMessage.json} />
          </section>
        )}
      </section>
      <section className={classNames.totalCostPerChild}>
        {totalCostPerChildLabel && (
          <section className={classNames.totalCostPerChildLbl}>
            <section className={classNames.marker} />
            <Typography variant="body1">{`${totalCostPerChildLabel} (${selectedCurrency?.currencySymbol})`}</Typography>
          </section>
        )}
        {totalCostPerHouseholdLabel && (
          <section className={classNames.totalCostPerChildLbl}>
            <section className={classNames.marker} />
            <Typography variant="body1">{`${totalCostPerHouseholdLabel} (${selectedCurrency?.currencySymbol})`}</Typography>
          </section>
        )}
      </section>
      <section className={classNames.mapImageWrapper}>
        <section className={classNames.mapImageContainer}>
          {isMapVisible && (
            <>
              <img
                src={mapSectionImage?.url ?? ''}
                width="100%"
                alt="World map"
                data-testid="world-map"
              />
              {hasCountries && (
                <section data-testid="total-cost-map-wrapper">
                  {countriesCollection?.items.map(country => (
                    <Tooltip
                      key={country?.name}
                      id={`map-tooltip-${country?.country}`}
                      title={CountryTooltipContent(country)}
                      classes={{
                        tooltip: classNames.tooltipWrapper,
                        arrow: classNames.arrowWrapper,
                      }}
                      enterTouchDelay={50}
                      leaveTouchDelay={5000}
                    >
                      <section
                        className={classNames.mapItem}
                        style={{
                          top: `${country?.flagYPosition}%`,
                          left: `${country?.flagXPosition}%`,
                          position: 'absolute',
                        }}
                      >
                        <div
                          className={classNames.mapCircle}
                          aria-expanded="false"
                          style={
                            {
                              '--perHousehold': 250,
                              '--circleRatio': `${country?.circleRatio}%`,
                            } as React.CSSProperties
                          }
                        >
                          <section className={classNames.mapCircleCountry}>
                            <Typography variant="caption">
                              {country?.country}
                            </Typography>
                          </section>
                        </div>
                      </section>
                    </Tooltip>
                  ))}
                </section>
              )}
            </>
          )}
        </section>
      </section>
    </section>
  );
};
