import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, IconButton } from '@mui/material';
import React from 'react';

import { useStyles } from './cex-calculator.styles';
import { CalculatorErrorProps } from './cex-calculator.types';

export const CexCalculatorError: React.FC<CalculatorErrorProps> = ({
  errors,
  severity,
}) => {
  const classes = useStyles();
  const [currentError, setCurrentError] = React.useState<string | null>(null);
  const [uniqueErrors, setUniqueErrors] = React.useState<string[]>([]);

  React.useEffect(() => {
    const normalizedErrors = Array.from(
      new Set(errors.map(error => error.trim().toLowerCase())),
    ).map(error =>
      errors.find(
        originalError => originalError.trim().toLowerCase() === error,
      ),
    ) as string[];

    setUniqueErrors(normalizedErrors);
    setCurrentError(normalizedErrors.shift() || null);
  }, [errors]);

  const handleClose = () => {
    setCurrentError(uniqueErrors.shift() || null);
    setUniqueErrors([...uniqueErrors]);
  };

  return (
    <Box className={classes.errorsWrapper}>
      {currentError && (
        <Alert
          data-testid="generic-error"
          className={classes.errorAnimateIn}
          severity={severity}
          icon={false}
          action={
            (errors.length > 1 && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )) ||
            undefined
          }
        >
          {currentError}
        </Alert>
      )}
    </Box>
  );
};
