import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { HeaderProps } from '../header.types';
import { isDarkTheme } from '../header.utils';

export const useStyles = makeStyles<
  Theme,
  Pick<HeaderProps<unknown, unknown>, 'headerVariant'>
>(theme => ({
  endIcon: ({ headerVariant }) => ({
    marginLeft: 'auto',
    color: isDarkTheme(headerVariant)
      ? theme.customComponents.header?.selectButtonText?.dark?.color
      : theme.customComponents.header?.selectButtonText?.color,
  }),
  endIconMobile: {
    color: theme.customComponents.header?.selectButtonText?.color,
  },
  selectButtonText: ({ headerVariant }) => ({
    'color': isDarkTheme(headerVariant)
      ? theme.customComponents.header?.selectButtonText?.dark?.color
      : theme.customComponents.header?.selectButtonText?.color,
    'textDecoration':
      theme.customComponents.header?.selectButtonText?.textDecoration,
    'fontSize': theme.customComponents.header?.selectButtonText?.fontSize,

    '&:hover': {
      color: theme.customComponents.header?.selectButtonText?.hover.color,
      textDecoration:
        theme.customComponents.header?.selectButtonText?.hover.textDecoration,
    },
  }),
  flag: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: theme.spacing(3),
  },
  regionDivider: {
    height: 'auto',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    backgroundColor: theme.customComponents.search?.option?.borderTopColor,
    color: theme.customComponents.search?.option?.borderTopColor,
  },
}));
