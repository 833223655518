import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  paper: {
    maxHeight: '20rem',
  },
  menuItem: {
    padding: 0,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
    textDecoration: 'none',
    color: 'inherit',
  },
  notchedOutline: {
    '&&&': {
      borderColor:
        theme.customComponents.regionDropdown.notchedOutline.borderColor,
    },
  },
}));
