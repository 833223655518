import {
  ApolloClient as OriginalApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import ApolloLinkTimeout from 'apollo-link-timeout';
import Cookies from 'js-cookie';

import { COOKIES } from '../constants';
import { timedFetch } from '../timed-functions';
import { bffIntrospectionResult } from '../types/worldremit';
import { isDev } from '../utils';
import { defaultErrorLink } from './default-error-link';
import { ApolloClient, ApolloConfig } from './shared.types';

const DEFAULT_HEADERS = { accept: 'application/json' };

const authorizationLink = (config: ApolloConfig) => {
  const { url, headers = {} } = config;
  const httpLink = createHttpLink({
    fetch: process.env.NODE_ENV === 'development' ? timedFetch : fetch,
    uri: url,
    credentials: 'include',
  });

  const authLink = setContext((_, { graphQlHeaders }) => {
    if (isDev()) {
      const authCookie = Cookies.get(COOKIES.Authorization);
      if (authCookie) {
        headers.Authorization = authCookie;
      }
    }
    return {
      headers: {
        ...DEFAULT_HEADERS,
        ...graphQlHeaders,
        ...headers,
      },
    };
  });
  return authLink.concat(httpLink);
};

const timeoutLink = new ApolloLinkTimeout(4 * 10000);

export const createApolloClientLink = (config: ApolloConfig) => {
  const { logger } = config;

  const errorLink = defaultErrorLink(logger, config.perimeterXBlockStrategy);
  const authLink = authorizationLink(config);

  return from([timeoutLink, errorLink, authLink]);
};

export const createBFFClient = (config: ApolloConfig): ApolloClient => {
  const { logger } = config;

  const errorLink = defaultErrorLink(logger, config.perimeterXBlockStrategy);
  const authLink = authorizationLink(config);

  return new OriginalApolloClient({
    link: from([timeoutLink, errorLink, authLink]),
    cache: new InMemoryCache({
      possibleTypes: bffIntrospectionResult.possibleTypes,
    }),
    ssrMode: typeof window === 'undefined',
    defaultOptions: config.defaultOptions,
  });
};
