import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, important } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(theme => ({
  section: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    borderRadius: '8px',
    border: `1px solid ${colors.silverGrey}`,
    padding: theme.spacing(3),
  },
  title: {
    'marginBottom': theme.spacing(1),

    '& em': {
      color: colors.purpleNormal,
      fontStyle: important('normal'),
      textDecoration: 'underline',
    },
  },
  description: {
    margin: theme.spacing(3, 0),
  },
  message: {
    margin: theme.spacing(2, 0),
  },
  ctaButton: {
    backgroundColor: colors.purpleNormal,
    color: colors.white,
  },
}));
