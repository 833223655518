import { contentfulSchema } from '@wr/web-shared';
import React from 'react';

import { CexCurrencyCard } from '@/components';
import { parseCexCurrency } from '@/services';

export const CexCurrency: React.FC<contentfulSchema.CexCurrencyFragment> = props => {
  const currencyInformation = parseCexCurrency(props);
  return <CexCurrencyCard {...currencyInformation} />;
};
