import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles<Theme>(theme => ({
  countrySearchWrapper: {
    'padding': theme.spacing(7, 2),
    'maxWidth': '100%',
    'backgroundColor': theme.palette.background.paper,
    'borderRadius': 15,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7, 5),
      maxWidth: '26.75rem',
    },
    // &&& - top up specificity to override the mui styles
    '&&& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: '1rem',
    },
  },
}));
