import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { getBackground } from '../../utils';
import { HeroBaseProps } from './hero.types';

export function parseContentAlignment(
  alignment?: HeroBaseProps['contentAlignment'],
) {
  switch (alignment) {
    case 'top':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'bottom':
      return 'flex-end';
    default:
      return 'flex-start';
  }
}

export const useStyles = makeStyles<Theme, Partial<HeroBaseProps>>(theme => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    columnGap: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  heroSection: ({ backgroundColor, secondBackgroundColor }) => {
    const background = getBackground(backgroundColor, secondBackgroundColor);
    return {
      position: 'relative',
      ...(background ? { background } : {}),
    };
  },
  heroBackgroundMobile: {
    position: 'unset',
    padding: theme.spacing(8, 0, 2),

    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(6, 0, 2),
    },

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 0, 2),
    },

    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: theme.spacing(3, 0),
    },
  },
  descriptionContainer: ({ hideDescriptionOnMobile }) => ({
    [theme.breakpoints.down('sm')]: {
      display: hideDescriptionOnMobile ? 'none' : 'unset',
    },
  }),
  heroBackgroundRow: ({
    isSectionOnTop,
    contentAlignment,
    contentFlexDirection,
  }) => ({
    display: 'grid',
    flexDirection: contentFlexDirection,
    gridTemplateColumns: contentFlexDirection === 'row' ? '1fr auto' : '1fr',
    gap: contentFlexDirection === 'row' ? theme.spacing(4) : theme.spacing(2),
    paddingTop: theme.spacing(isSectionOnTop ? 21 : 10),
    placeItems:
      contentFlexDirection === 'row'
        ? parseContentAlignment(contentAlignment)
        : 'center',
    paddingBottom: theme.spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.spacing(200),
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),

    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(isSectionOnTop ? 21 : 6),
    },

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(0),
      gridTemplateColumns: '1fr',
      placeItems: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(isSectionOnTop ? 17.5 : 2),
    },
  }),
  heroRightColumn: ({ contentFlexDirection }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: contentFlexDirection === 'row' ? theme.spacing(10) : 0,
    maxWidth: contentFlexDirection === 'row' ? theme.spacing(53.5) : 'none',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
      paddingTop: contentFlexDirection === 'row' ? theme.spacing(6) : 0,
    },

    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  }),
  heroBackground: ({ isSectionOnTop }) => ({
    maxHeight: '48rem',
    display: 'flex',
    alignItems: 'end',
    paddingTop: theme.spacing(isSectionOnTop ? 21 : 10),
    paddingBottom: theme.spacing(10),

    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(isSectionOnTop ? 21 : 6),
    },

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(isSectionOnTop ? 17.5 : 4),
    },
  }),
  heroContent: ({ isSectionOnTop }) => ({
    position: 'relative',
    paddingTop: theme.spacing(isSectionOnTop ? 10 : 0),
    gap: theme.spacing(10),

    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(1),
      paddingTop: theme.spacing(isSectionOnTop ? 6 : 0),
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(isSectionOnTop ? 4 : 0),
    },

    [theme.breakpoints.up('md')]: {
      alignItems: 'end',
      flexDirection: 'row',
    },
  }),
  heroContentCenter: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
      textAlign: 'left',
    },
  },
  heroTrustpilot: {
    marginTop: theme.spacing(2),
    textAlign: 'left',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  trustpilotLogo: {
    height: '3rem',
    marginBottom: theme.spacing(2),
    display: 'inline-block',

    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  trustpilotWidget: {
    marginTop: theme.spacing(3),
    textAlign: 'left',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  heroTrustpilotReviews: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    borderLeft: '1px solid white',

    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  foregroundImageContainer: {
    '& img': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
      },
    },
  },
  appStoreLinksContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(2, 2),
    alignItems: 'center',
    marginTop: theme.spacing(6),

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
