import clsx from 'clsx';
import React from 'react';

import { useStyles } from './social-links.styles';
import { SocialLinksProps } from './social-links.types';

export const SocialLinks = <Anchor extends { url?: string | null } | null>({
  socialIconsLinks,
  id,
  className,
  AnchorComponent,
}: SocialLinksProps<Anchor>): React.ReactElement => {
  const classes = useStyles();

  return (
    <ul
      className={clsx(classes.socialLinksList, className)}
      id={id}
      data-testid="social-links"
    >
      {socialIconsLinks.map(iconLink => (
        <li key={iconLink?.url}>
          <AnchorComponent
            {...iconLink}
            className={classes.socialLinkItemLink}
          />
        </li>
      ))}
    </ul>
  );
};
