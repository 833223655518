import { BLOCKS } from '@contentful/rich-text-types';
import { TypographyTypeMap } from '@mui/material';
import { contentfulSchema } from '@wr/web-shared';

import { getRichTextHeadingOptions } from '@/components/rich-text-content-renderer/rich-text-content-renderer.utils';

import { RichTextContentRenderer } from '../rich-text-content-renderer/rich-text-content-renderer.component';

export const TextWithColor: React.FC<
  contentfulSchema.TextWithColorFragment & {
    className?: string;
  }
> = ({ className, text, inline = true, color, backgroundColor, align }) => {
  if (text === null || text === undefined) {
    return null;
  }

  const commonOptions = {
    align: (align as TypographyTypeMap['props']['align']) || undefined,
    display:
      (inline
        ? 'inline'
        : ('block' as TypographyTypeMap['props']['display'])) || undefined,
  };

  const richTextContent = {
    ...text,
    options: {
      ...getRichTextHeadingOptions(commonOptions),
      [BLOCKS.PARAGRAPH]: commonOptions,
    },
  };

  return (
    <div
      className={className}
      style={{
        backgroundColor: backgroundColor || undefined,
        color: color || undefined,
      }}
    >
      <RichTextContentRenderer {...richTextContent} />
    </div>
  );
};
