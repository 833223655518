import { darken, lighten, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { isDarkTheme } from '../header';
import { AppDownloadDropdownProps } from './app-download-dropdown.types';

export const useStyles = makeStyles<
  Theme,
  Pick<AppDownloadDropdownProps, 'headerVariant'>
>(theme => ({
  appDownloadBtn: ({ headerVariant }) => ({
    'borderRadius': '100px',
    'backgroundColor': isDarkTheme(headerVariant)
      ? theme.customComponents.appDownloadDropdown?.dark?.backgroundColor
      : theme.customComponents.appDownloadDropdown?.backgroundColor,
    'color': isDarkTheme(headerVariant)
      ? theme.customComponents.appDownloadDropdown?.dark?.color
      : theme.customComponents.appDownloadDropdown?.color,
    '&:hover, &:focus-visible, &:active': {
      boxShadow: `0 0 0 4px ${
        isDarkTheme(headerVariant)
          ? theme.customComponents.appDownloadDropdown?.dark?.shadowColor
          : theme.customComponents.appDownloadDropdown?.shadowColor
      }`,
      backgroundColor: isDarkTheme(headerVariant)
        ? lighten(
            theme.customComponents.appDownloadDropdown?.dark?.backgroundColor ??
              '',
            0.2,
          )
        : darken(
            theme.customComponents.appDownloadDropdown?.backgroundColor ?? '',
            0.2,
          ),
      color: isDarkTheme(headerVariant)
        ? theme.customComponents.appDownloadDropdown?.dark?.color
        : theme.customComponents.appDownloadDropdown?.color,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  }),
  qrCodeContainer: {
    'boxShadow': '7px 7px 7px rgba(0, 0, 0, 0.1)',
    'backgroundColor': '#fff',
    'maxWidth': '180px',
    'borderRadius': '10px',
    'padding': theme.spacing(2),
    'marginTop': '10px',

    '& img, & canvas': {
      width: '100%',
      height: 'auto',
    },
  },
}));
