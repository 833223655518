import React from 'react';

import { Image } from '../../image';
import { useStyles } from './logo.styles';
import { LogoProps } from './logo.types';

export function Logo({
  logo,
  logoMobile,
}: LogoProps): React.ReactElement | null {
  const classes = useStyles();

  const desktopLogo = logo && <Image className={classes.logo} {...logo} />;

  const mobileLogo = logoMobile && (
    <Image className={classes.logoMobile} {...logoMobile} />
  );

  return logoMobile ? (
    <>
      {desktopLogo}
      {mobileLogo}
    </>
  ) : (
    desktopLogo
  );
}
