import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
  ({ spacing, breakpoints, customComponents }) => ({
    container: {
      background: customComponents.notFound.container.backgroundColor,
    },
    content: {
      padding: spacing(2, 0),
      [breakpoints.up('sm')]: {
        padding: spacing(4, 1),
      },
      [breakpoints.up('md')]: {
        padding: spacing(10, 1),
      },
      [breakpoints.up('lg')]: {
        padding: spacing(7, 11),
      },
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      // This won't work in CMS if we remove the `px` for some reason
      gap: spacing(3),
      justifyContent: 'flex-start',
      marginTop: spacing(4),
    },
  }),
);
