import { useEffect } from 'react';

import { CalculatorProps } from '@/components/calculator/component/calculator.types';
import {
  getGenericCalculationErrorMessages,
  getValidationCalculationErrorMessages,
} from '@/components/calculator/component/calculator.utils';

import { setDataLayerError } from '../core/core.utils';
import { CalculatorState } from '../src/types';

export const useDataLayerErrorPush = (
  isLite: CalculatorProps['isLite'],
  errors: CalculatorState['errors'],
  genericErrors: ReturnType<typeof getGenericCalculationErrorMessages>,
): void => {
  useEffect(() => {
    const validationErrors = getValidationCalculationErrorMessages(errors);

    if (genericErrors.length) {
      setDataLayerError(isLite, genericErrors[0]);
    } else if (validationErrors.length) {
      setDataLayerError(isLite, validationErrors[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
};
