import { ANALYTICS_PAGE_TYPE } from '@wr/web-shared';

import { AppContextTypeShared } from './app.types';

export const INITIAL_APP_CONTEXT_SHARED: AppContextTypeShared = {
  appName: null,
  isAuthenticated: false,
  isWebView: false,
  originUri: '',
  osName: '',
  deviceType: '',
  messages: {},
  regions: [],
  sendCountry: null,
  receiveCountry: null,
  sendCountries: [],
  receiveCountries: [],
  analyticsPageName: '',
  analyticsPageType: ANALYTICS_PAGE_TYPE.Homepage,
  locale: 'en',
  intl: {
    payoutMethodsLabelsById: {},
    countryArticlesAndPrepositions: {},
  },
};
