import { Container as MuiContainer, ContainerProps } from '@mui/material';
import React, { ElementType } from 'react';

import { useStyles } from './container.styles';

export const Container: React.FC<ContainerProps<ElementType>> = ({
  children,
  className,
  component = 'div',
  maxWidth = 'xl',
  ...props
}) => {
  const classes = useStyles();

  return children ? (
    <MuiContainer
      className={className}
      component={component}
      maxWidth={maxWidth}
      classes={classes}
      {...props}
    >
      {children}
    </MuiContainer>
  ) : null;
};
