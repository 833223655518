export const CONTENTFUL_GQL_BASE_URL =
  process.env.CONTENTFUL_GQL_BASE_URL || 'https://graphql.contentful.com';
export const CONTENTFUL_LOCALES = process.env.CONTENTFUL_LOCALES?.split(
  ' ',
) || [
  'en',
  'en-gb',
  'en-us',
  'en-my',
  'en-nz',
  'en-ca',
  'en-se',
  'en-au',
  'da-dk',
  'de',
  'es',
  'es-us',
  'fr',
  'fr-ca',
  'nl',
];

// WorldRemit
export const CONTENTFUL_ENVIRONMENT =
  process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT;
export const CONTENTFUL_SPACE_ID = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
export const CONTENTFUL_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
export const CONTENTFUL_PREVIEW_ACCESS_TOKEN =
  process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
export const CONTENTFUL_GQL_CONTENT_URL = `${CONTENTFUL_GQL_BASE_URL}/content/v1/spaces/${CONTENTFUL_SPACE_ID}/environments/${CONTENTFUL_ENVIRONMENT}`;
export const CONTENTFUL_PREVIEW_ACCESS_HEADER = {
  context: {
    headers: {
      authorization: `Bearer ${CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
    },
  },
};

// Sendwave
export const CONTENTFUL_SENDWAVE_ENVIRONMENT =
  process.env.NEXT_PUBLIC_CONTENTFUL_SENDWAVE_ENVIRONMENT;
export const CONTENTFUL_SENDWAVE_SPACE_ID =
  process.env.NEXT_PUBLIC_CONTENTFUL_SENDWAVE_SPACE_ID;
export const CONTENTFUL_SENDWAVE_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_SENDWAVE_ACCESS_TOKEN;
export const CONTENTFUL_SENDWAVE_PREVIEW_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_SENDWAVE_PREVIEW_ACCESS_TOKEN;
export const CONTENTFUL_SENDWAVE_GQL_CONTENT_URL = `${CONTENTFUL_GQL_BASE_URL}/content/v1/spaces/${CONTENTFUL_SENDWAVE_SPACE_ID}/environments/${CONTENTFUL_SENDWAVE_ENVIRONMENT}`;
export const CONTENTFUL_SENDWAVE_PREVIEW_ACCESS_HEADER = {
  context: {
    headers: {
      authorization: `Bearer ${CONTENTFUL_SENDWAVE_PREVIEW_ACCESS_TOKEN}`,
    },
  },
};

export const WEB_PROCESSES = 'web-processes';
export const WEB_CMS = 'web-cms';
export const WEB_CMS_SENDWAVE = 'web-cms-sw';
