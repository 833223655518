import { contentfulSchema } from '@wr/web-shared';

import { SearchDialogProps } from './search-dialog.types';

const convertToComparableString = (str: string | null = '') =>
  str?.replace(/ /g, '').toLowerCase() || '';

export const filterPagesByTagOrName = (
  pages: NonNullable<
    contentfulSchema.PagesWithTagsQuery['pageCollection']
  >['items'],
  inputValue?: string,
): SearchDialogProps<unknown>['options'] => {
  const value = convertToComparableString(inputValue);
  return pages
    .filter(
      page =>
        convertToComparableString(page?.name).includes(value) ||
        page?.contentfulMetadata.tags.some(tag =>
          convertToComparableString(tag?.name).includes(value),
        ),
    )
    .map(result => ({
      url: result?.slug || '',
      label: result?.name || '',
    }));
};
