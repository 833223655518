import MuiButton from '@mui/material/Button';
import React from 'react';

import { ButtonProps } from './button.types';

// eslint-disable-next-line react/display-name
export const Button: React.FC<ButtonProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(({ isExternal, navigate, href, onClick, ...props }, ref) => {
  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void | boolean => {
    if (!isExternal && href && navigate) {
      // MuiButton doesn't handle external prop, so we handle internal navigation by using gatsby navigate
      e.preventDefault();
      navigate(href);
    } else {
      onClick?.(e);
    }
  };

  return <MuiButton {...props} ref={ref} href={href} onClick={handleOnClick} />;
});
