import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  flag: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: theme.spacing(3),
  },
  secondaryLabel: {
    marginLeft: theme.spacing(1),
    color: alpha(theme.palette.text.primary, 0.4),
  },
}));
