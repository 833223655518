import { v4 } from 'uuid';

export const readSessionId = () => {
  const idInStorage = window.sessionStorage.getItem('sessionId');

  if (idInStorage === null) {
    const generatedId = v4();

    window.sessionStorage.setItem('sessionId', generatedId);

    return generatedId;
  }

  return idInStorage;
};

export const riskifiedTracking = () => {
  if (window.location.hostname === 'localhost') {
    return false;
  }

  const storeDomain = window.location.hostname;
  const sessionId = readSessionId();
  const protocol =
    document.location.protocol === 'https:' ? 'https://' : 'http://';
  const url = `${protocol}beacon.riskified.com?shop=${storeDomain}&sid=${sessionId}`;
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = url;
  document.head.appendChild(s);

  return true;
};
