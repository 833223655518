import { useContext, useEffect } from 'react';

import { CalculatorContext } from '@/components/calculator/src/context';

import { PERIMETERX_SUCCESS_EVENT_NAME } from '../../services/perimeterx/perimeterx.constants';

export const usePerimeterX = (): void => {
  const { dispatch } = useContext(CalculatorContext);

  useEffect(() => {
    const onSuccessfulPerimeterX = (): void => {
      dispatch({
        type: 'SET_PERIMETER_X_SOLVED',
        payload: {
          isPerimeterXSolved: true,
        },
      });
    };

    window.addEventListener(
      PERIMETERX_SUCCESS_EVENT_NAME,
      onSuccessfulPerimeterX,
    );

    return () => {
      window.removeEventListener(
        PERIMETERX_SUCCESS_EVENT_NAME,
        onSuccessfulPerimeterX,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
