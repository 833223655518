import { Theme, useMediaQuery } from '@mui/material';

export function useImageForBreakpoint<T>(
  xsUp?: T,
  smUp?: T,
  mdUp?: T,
  lgUp?: T,
  xlUp?: T,
): T {
  const isXSUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const isSMUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isMDUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isLGUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isXLUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  if (isXLUp) {
    return (xlUp || lgUp || mdUp || smUp || xsUp) as T;
  }

  if (isLGUp) {
    return (lgUp || mdUp || smUp || xsUp) as T;
  }

  if (isMDUp) {
    return (mdUp || smUp || xsUp) as T;
  }

  if (isSMUp) {
    return (smUp || xsUp) as T;
  }
  if (isXSUp) {
    return xsUp as T;
  }
  return (xlUp || lgUp || mdUp || smUp || xsUp) as T;
}
