import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Button } from '../button';
import { Image } from '../image';
import { useStyles } from './stepper.styles';
import { StepperProps } from './stepper.types';
import { getTwoDigitNumber } from './stepper.utils';

export function Stepper({
  steps,
  selectedStep,
  onChange,
}: StepperProps): React.ReactElement {
  const classes = useStyles();

  const handleClick = (id: string) => (): void => {
    if (onChange) {
      onChange(id);
    }
  };

  const selected = steps.find(step => step.id === selectedStep);

  return (
    <div className={classes.mobile}>
      {steps.map((step, index) => {
        const isSelected = step.id === selectedStep;

        return (
          <Button
            key={step.id}
            data-is-selected={isSelected}
            onClick={handleClick(step.id)}
            onKeyDown={handleClick(step.id)}
            classes={{
              root: clsx(classes.numberMobile, classes.margin, {
                [classes.selected]: isSelected,
              }),
              focusVisible: '',
            }}
          >
            {getTwoDigitNumber(index + 1)}
          </Button>
        );
      })}
      {selected && (
        <div className={classes.stepTextMobile}>
          <Typography className={classes.selectedStepHeader}>
            {selected.header}
          </Typography>

          <div
            data-testid={`${selected.name}-image`}
            className={classes.selectedBackgroundImage}
          >
            <Image {...selected.image} layout="responsive" />
          </div>
        </div>
      )}
    </div>
  );
}
