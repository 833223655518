const LOGGER_STORAGE_KEY = 'logger_storage';

export const getLoggerSessionFields = () =>
  JSON.parse(sessionStorage.getItem(LOGGER_STORAGE_KEY) as string) || {};

/** add addtional properties to the browser session to send with each error log */
export const addToLoggerSession = (fieldName: string, fieldValue: unknown) => {
  if (!fieldValue) return;

  const newSessionProperties = {
    ...getLoggerSessionFields(),
    [fieldName]: fieldValue,
  };
  sessionStorage.setItem(
    LOGGER_STORAGE_KEY,
    JSON.stringify(newSessionProperties),
  );
};

/** same as addToLoggerSession, but only if the property with that name does not exist */
export const addToLoggerSessionUnlessExists = (
  fieldName: string,
  fieldValue: unknown,
) => {
  const storageValue = getLoggerSessionFields();
  if (!storageValue[fieldName]) {
    return addToLoggerSession(fieldName, fieldValue);
  }
  return storageValue;
};

export const removeFromLoggerSession = (fieldName: string) => {
  const storageValue = getLoggerSessionFields();
  delete storageValue[fieldName];
  sessionStorage.setItem(LOGGER_STORAGE_KEY, JSON.stringify(storageValue));
};

export const clearLoggerSession = () =>
  sessionStorage.removeItem(LOGGER_STORAGE_KEY);
