import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, important } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.greyLight,
    minWidth: spacing(16.25),
    height: 'inherit',
  },
  selectContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    justifyContent: 'space-between',
    padding: spacing(2.5, 1.5),
    cursor: 'pointer',
    gap: spacing(1),
    height: 'inherit',
    width: '100%',
    border: important(0),
    outline: important(0),
  },
  selectContentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
  },
  countryFlag: {
    width: important(spacing(3)),
    height: important(spacing(3)),
  },
  inputSelectText: {
    fontWeight: 700,
    fontSize: spacing(2.5),
    lineHeight: 1,
  },
  selectIcon: {
    width: important(spacing(3)),
    height: important(spacing(3)),
  },
  dropdownWrapper: {
    background: colors.white,
  },
}));
