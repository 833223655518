import {
  PHONE_INPUT_BLACKLIST_KEY_CODES,
  PHONE_INPUT_BLACKLIST_KEYS,
  PHONE_INPUT_BLACKLIST_PAST_KEYS,
} from '@/components/calculator/core/core.constants';

export const preventTypingAdditionalKeysInPhoneInput = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  // Prevent characters that are not numbers ("e", ".", "+", "-", "ArrowUp", "ArrowDown")
  // but still are accepted by number input field
  let isBlackListedKey;

  if (e.key !== undefined) {
    isBlackListedKey = PHONE_INPUT_BLACKLIST_KEYS.includes(e.key);
  } else if (e.keyCode !== undefined) {
    isBlackListedKey = PHONE_INPUT_BLACKLIST_KEY_CODES.includes(e.keyCode);
  }
  return isBlackListedKey && e.preventDefault();
};

export const preventPastingSpecificValuesInPhoneInput = (
  e: React.ClipboardEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
  >,
) => {
  // Prevent to past characters that are not numbers ("e", ".", "+", "-")
  // but still are accepted by number input field
  const pastedData = e?.clipboardData?.getData('Text');

  const isDisabledValue =
    pastedData &&
    PHONE_INPUT_BLACKLIST_PAST_KEYS.some(key => pastedData.includes(key));

  if (isDisabledValue) {
    e.preventDefault();
  }
};

export const preventWheelInPhoneInput = (e: React.WheelEvent<HTMLElement>) =>
  (e.target as HTMLElement).blur();
