import { InputAdornment } from '@mui/material';
import { ReactNode } from 'react';

import { CountrySelectOption } from '..';
import { CountryFlag } from '../country-flag-image';
import { Select } from '../select';
import { useStyles } from './country-select.styles';
import { CountrySelectProps } from './country-select.types';
import { mapCountryToOption, valueAccessor } from './country-select.utils';

export function CountrySelect({
  name: namePrefix,
  label,
  countries,
  selectedCountry,
  defaultValue,
  onChange,
  onOpen,
  className,
  forwardedProps,
  countryFlagType = 'rectangle',
  isDisabled,
  showFlag = true,
}: CountrySelectProps) {
  const classes = useStyles({ countryFlagType });

  const options = [
    ...(defaultValue ? [{ label: defaultValue, value: '' }] : []),
    ...countries.map(mapCountryToOption),
  ];

  const findCountryByValue = (value: string): CountrySelectOption | undefined =>
    countries.find(country => value === valueAccessor(country));

  const CountryField = (value: unknown): ReactNode => {
    if (selectedCountry && valueAccessor(selectedCountry) === value) {
      const CountryName = () => <span>{selectedCountry.name}</span>;

      return (
        <div className={classes.field}>
          {showFlag ? (
            <>
              <InputAdornment position="start">
                <CountryFlag
                  type={countryFlagType}
                  className={classes.flag}
                  code={selectedCountry.customFlagCode || selectedCountry.code}
                />
              </InputAdornment>
              <CountryName />
            </>
          ) : (
            <CountryName />
          )}
        </div>
      );
    }

    return defaultValue;
  };

  return (
    <Select
      name={`${namePrefix}-country-select`}
      value={selectedCountry ? valueAccessor(selectedCountry) : ''}
      data-testid="country-select"
      options={options}
      label={label}
      onOpen={onOpen}
      onChange={(dropdownValue): void =>
        onChange(findCountryByValue(dropdownValue))
      }
      isDisabled={isDisabled}
      forwardedProps={{
        displayEmpty: true,
        defaultValue,
        renderValue: CountryField,
        fullWidth: true,
        classes: {
          select: className,
        },
        color: 'primary',
        variant: 'outlined',
        ...forwardedProps,
      }}
    />
  );
}
