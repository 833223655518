import { contentfulSchema } from '@wr/web-shared';
import { CountrySearch as UICountrySearch } from '@wr/web-ui';
import React from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';

import { useStyles } from './send-to-country-module.styles';

export const SendToCountryModule: React.FC<contentfulSchema.SendToCountryModuleFragment> = ({
  name,
  description,
  searchPlaceholder,
  continueButtonText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.countrySearchWrapper}>
      <UICountrySearch<RichTextProps>
        name={name || ''}
        description={description?.json ? description : null}
        // TODO: implement SendToCountryModule in web-cms
        countries={[]}
        onSubmit={() => {}}
        placeholder={searchPlaceholder || undefined}
        buttonText={continueButtonText || ''}
        RichTextComponent={RichTextContentRenderer}
      />
    </div>
  );
};
