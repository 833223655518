import { Typography } from '@mui/material';
import React from 'react';

import { CARDS_CAROUSEL_SETTINGS } from '../../constants';
import { Carousel, Container, Review, StyledRating } from '..';
import { NextArrow, PrevArrow } from '../carousel/cards-arrows';
import { Image } from '../image';
import { useStyles } from './reviews.styles';
import { ReviewsProps } from './reviews.types';

export function Reviews<L>({
  reviews,
  reviewsNumberLabel,
  starIcon,
  avatarIcon,
  name,
  heading,
  carouselText,
  reviewsTotalScore,
  hideTrustpilotHeader,
  trustpilotLink,
  trustpilotLogo,
  messages,
  LinkComponent,
  containerProps,
}: ReviewsProps<L>): React.ReactElement | null {
  const classes = useStyles();

  return reviews?.length ? (
    <section id={name} className={classes.section} {...containerProps}>
      <Container>
        {!hideTrustpilotHeader && trustpilotLink && trustpilotLogo && (
          <LinkComponent
            {...trustpilotLink}
            className={classes.trustpilotLink}
            data-testid={'trustpilot-link-image'}
          >
            <Image {...trustpilotLogo} className={classes.trustpilotLogo} />
          </LinkComponent>
        )}
        <div className={classes.totalReviewsWrapper}>
          <Typography variant="subtitle1" className={classes.heading}>
            {heading}
          </Typography>
          {reviewsTotalScore && (
            <div
              className={classes.ratingWrapper}
              data-testid="total-reviews-wrapper"
            >
              <StyledRating
                value={reviewsTotalScore}
                icon={
                  starIcon ? (
                    <Image {...starIcon} className={classes.ratingIcon} />
                  ) : null
                }
              />
              <Typography
                variant="subtitle1"
                className={classes.reviewsTotalScore}
              >
                {reviewsTotalScore}
              </Typography>
            </div>
          )}
        </div>
        {carouselText && (
          <Typography variant="body2" className={classes.carouselText}>
            {carouselText}
          </Typography>
        )}
        <Carousel
          settings={{
            ...CARDS_CAROUSEL_SETTINGS,
            nextArrow: <NextArrow label={messages?.next || ''} />,
            prevArrow: <PrevArrow label={messages?.previous || ''} />,
          }}
        >
          {reviews.map(review => {
            return (
              <Review
                review={review}
                key={review?.name}
                reviewsNumberLabel={reviewsNumberLabel}
                starIcon={starIcon}
                avatarIcon={avatarIcon}
              />
            );
          })}
        </Carousel>
      </Container>
    </section>
  ) : null;
}
