import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(({ palette, spacing, breakpoints }) => ({
  section: {
    overflow: 'hidden',
    paddingLeft: '16px',
    paddingRight: '16px',
    [breakpoints.up('xs')]: {
      paddingTop: '32px',
    },
    [breakpoints.up('md')]: {
      paddingTop: '64px',
    },
  },
  sectionContentWrapper: {
    maxWidth: '900px',
    textAlign: 'center',
    margin: 'auto',
  },
  sectionContentHeader: {
    color: colors.purpleNormal,
    fontSize: '1.125rem',
    lineHeight: 2.11,
    fontWeight: 500,
    [breakpoints.up('md')]: {
      lineHeight: 1.07,
      fontSize: '1.75rem',
    },
  },
  categoryWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    maxWidth: '1200px',
  },
  barsWrapper: {
    margin: 'auto',
    maxWidth: '1400px',
    paddingTop: '48px',
    position: 'relative',
    [breakpoints.up('xs')]: {
      paddingLeft: '0px',
      paddingRight: '10px',
    },
    [breakpoints.up('md')]: {
      paddingLeft: '320px',
      paddingRight: '160px',
    },
  },
  barsLabelWrapper: {
    display: 'flex',
    paddingBottom: '16px',
  },
  barsGrid: {
    position: 'relative',
    height: '2rem',
  },
  barsGridItem: {
    'position': 'absolute',
    'height': '2rem',
    '&:before': {
      position: 'absolute',
      top: '100%',
      height: '9999px',
      borderLeft: '1px solid rgba(0,0,0,.2)',
      content: '""',
    },
    [breakpoints.down('sm')]: {
      '&:before': {
        borderLeft: '1px solid rgba(0,0,0,.1)',
      },
    },
  },
  barsRow: {
    position: 'relative',
    cursor: 'pointer',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    [breakpoints.down('sm')]: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  barValue: {
    position: 'absolute',
    top: '90px',
    right: '-50px',
    width: '40px',
    transform: 'translateY(-70px)',
    [breakpoints.down('sm')]: {
      position: 'relative',
      left: '5px',
      top: '5px',
      transform: 'none',
    },
  },
  barCountry: {
    position: 'absolute',
    left: '-25%',
    top: '50%',
    transform: 'translateY(-70%)',
    [breakpoints.down('sm')]: {
      position: 'relative',
      left: 'auto',
      top: '0%',
      transform: 'none',
    },
  },
  barCountryContentWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  barCountryFlag: {
    'width': '3rem',
    'height': '3rem',
    'fontSize': '3.5rem',
    'marginRight': '8px',
    'position': 'relative',
    'transform': 'translateY(-30%)',
    '& img': {
      position: 'absolute',
      top: '-200%',
      left: '-200%',
      width: '500%',
      maxWidth: '9999px',
      display: 'block',
      pointerEvents: 'none',
    },
    [breakpoints.down('sm')]: {
      width: '1.5rem',
      transform: 'translateY(-45%)',
    },
  },
  barCountryLabel: {
    [breakpoints.up('xs')]: {
      paddingTop: '20px',
    },
    [breakpoints.up('md')]: {
      paddingTop: '24px',
    },
  },
  barScale: {
    position: 'relative',
    maxHeight: '2rem',
  },
  barFill: {
    display: 'flex',
    position: 'relative',
    transition: '.5s filter',
  },
  barPercent: {
    display: 'flex',
    position: 'absolute',
    color: colors.white,
    width: '100%',
    left: 0,
    top: 0,
  },
  barPercentValue: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  barItem: {
    position: 'relative',
    flexGrow: 1,
    color: colors.white,
    height: '2rem',
  },
  barTooltipPercent: {
    paddingLeft: spacing(0.5),
    marginLeft: spacing(0.5),
    borderLeft: '1px solid currentColor',
    width: 'calc(4ch + .5rem)',
  },
  barTooltip: {
    transform: 'translateX(-50%)',
    backgroundColor: palette.common.white,
    border: `1px solid ${colors.whiteSmokeGrey} 1rem`,
    boxShadow: `0 -9px 24px 0 ${alpha(colors.resolutionBlue, 0.1)}`,
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: 1.5,
    color: palette.text.primary,
    width: '300px',
    maxWidth: '80vw',
    borderRadius: '1rem',
    zIndex: 40,
    [breakpoints.down('sm')]: {
      transform: 'none',
      maxWidth: 'calc(80vw - 4rem)',
      fontSize: '0.5rem',
    },
  },
  barTooltipHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.creamNormal,
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    padding: '12px',
  },
  barTooltipCountryWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  barTooltipCountryFlag: {
    'position': 'relative',
    'marginRight': spacing(1),
    'width': '1.8rem',
    'height': '1.8rem',
    '& img': {
      top: '-440%',
    },
  },
  barTooltipCategory: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '8px',
  },
  barTooltipCategoryWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  barTooltipCategoryValue: {
    display: 'flex',
    fontSize: '.8rem',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: '.7rem',
    },
  },
  category: {
    margin: '16px',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    width: 'calc(25% - 2rem)',
    [breakpoints.down('sm')]: {
      width: 'calc(50% - 2rem)',
    },
  },
  categoryImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  categoryTitle: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: colors.white,
    padding: '8px',
    flexGrow: 1,
  },
  tooltipWrapper: {
    padding: 0,
    maxWidth: '0px',
    maxHeight: '0px',
    opacity: 1,
  },
  arrowWrapper: {
    display: 'none',
  },
  marker: {
    display: 'inline-block',
    width: '23px',
    height: '23px',
    borderRadius: '50%',
    verticalAlign: 'middle',
    marginRight: spacing(1),
    flexShrink: 0,
  },
}));

export default useStyles;
