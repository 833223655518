import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  stepTextMobile: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  selectedStepHeader: {
    color: theme.customComponents.stepper.selectedStepHeader.color,
    margin: theme.spacing(2, 0, 3, 0),
    fontWeight: 600,
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(5, 0, 6.25, 0),
      fontSize: '1.25rem',
    },
  },
  numberMobile: {
    'color': theme.customComponents.stepper.numberMobile.color,
    'fontSize': '1.25rem',
    'opacity': 0.5,
    'padding': theme.spacing(0.75, 1.875),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.25, 4),
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      margin: '0 auto',
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      height: '0.25rem',
      backgroundColor:
        theme.customComponents.stepper.numberMobile.backgroundColor,
      [theme.breakpoints.up('md')]: {
        height: '0.375rem',
      },
    },
  },
  margin: {
    '&:nth-child(odd)': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  selected: {
    filter: 'grayscale(0%)',
    opacity: 1,
  },
  mobile: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  selectedBackgroundImage: {
    '& img': {
      'overflow': 'hidden',
      'position': 'relative',
      'width': '100%',
      'height': '16rem',
      'top': 0,
      'left': 0,
      'objectFit': 'cover',
      'objectPosition': 'center center',
      '& > div': {
        position: 'initial !important',
      },

      [theme.breakpoints.up('sm')]: {
        height: '19rem',
      },
      [theme.breakpoints.up('md')]: {
        height: '25.75rem',
      },
      [theme.breakpoints.up('lg')]: {
        height: '32rem',
      },
    },
  },
}));
