import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  correspondent: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.customComponents.correspondent.borderColor}`,
    width: '4.5rem',
    background: theme.customComponents.correspondent.backgroundColor,
    height: '3.5rem',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      width: '11rem',
      height: '5rem',
    },

    [theme.breakpoints.up('md')]: {
      width: '12rem',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));
