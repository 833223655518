import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { contentfulSchema } from '@wr/web-shared';

import { useIntlMessages } from '@/hooks';

import { interpolate } from '../../utils/interpolate';

// https://developers.google.com/search/docs/advanced/structured-data/faqpage
export const getFAQPageSchemaMarkup = (
  questions: contentfulSchema.FaqModuleFragment['questions'],
  intlMessages: ReturnType<typeof useIntlMessages>,
) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity':
      questions?.items.map(question => ({
        '@type': 'Question',
        'name': interpolate(question?.title, intlMessages),
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': question?.description?.json
            ? interpolate(
                documentToPlainTextString(question.description.json),
                intlMessages,
              )
            : '',
        },
      })) || [],
  };
};
