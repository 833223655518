import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { ButtonBase, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';

import { Container, Correspondent } from '..';
import { useStyles } from './correspondents.styles';
import { CorrespondentsProps } from './correspondents.types';

export function Correspondents<L>({
  name,
  title,
  subtitle,
  payoutMethodsTabs,
  selectedPayoutMethodId,
  onPayoutMethodChange,
  containerProps,
  LinkComponent,
}: CorrespondentsProps<L>): React.ReactElement | null {
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: string,
  ): void => {
    onPayoutMethodChange(newValue);
  };

  const classes = useStyles();

  return payoutMethodsTabs.length > 0 ? (
    <section
      id={name}
      data-testid={name}
      className={classes.section}
      {...containerProps}
    >
      <Container>
        <Typography component="h2" variant="h4" className={classes.heading}>
          {title}
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={classes.subheading}
        >
          {subtitle}
        </Typography>

        <Tabs
          value={selectedPayoutMethodId}
          onChange={handleChange}
          selectionFollowsFocus
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons
          data-testid="payout-method-selector"
          classes={{
            root: classes.tabs,
            scroller: classes.scroller,
          }}
          ScrollButtonComponent={props => {
            return (
              <ButtonBase
                className={props.className}
                disabled={props.disabled}
                onClick={props.onClick}
                component="div"
                classes={{
                  root: classes.scrollButton,
                  disabled: classes.scrollButtonDisabled,
                }}
              >
                {props.direction === 'right' ? (
                  <KeyboardArrowRight fontSize="inherit" />
                ) : (
                  <KeyboardArrowLeft fontSize="inherit" />
                )}
              </ButtonBase>
            );
          }}
          allowScrollButtonsMobile
        >
          {payoutMethodsTabs.map(payoutMethodTabItem => {
            if (
              !selectedPayoutMethodId ||
              !payoutMethodTabItem?.correspondents.length
            ) {
              return null;
            }
            const tabProps = {
              'value': payoutMethodTabItem.value,
              'id': `${name}-tab-${payoutMethodTabItem.value}`,
              'data-testid': `${name}-tab-${payoutMethodTabItem.value}`,
              'aria-controls': `${name}-tabpanel-${payoutMethodTabItem.value}`,
            };

            return (
              <Tab
                key={payoutMethodTabItem.value}
                label={
                  <Typography component="h3" variant="h6">
                    {payoutMethodTabItem.label}
                  </Typography>
                }
                {...tabProps}
              />
            );
          })}
        </Tabs>
        {payoutMethodsTabs.map(payoutMethodTabItem => {
          if (!payoutMethodTabItem?.correspondents.length) {
            return null;
          }
          return (
            <div
              role="tabpanel"
              hidden={selectedPayoutMethodId !== payoutMethodTabItem.value}
              key={payoutMethodTabItem.value}
              id={`${name}-tabpanel-${payoutMethodTabItem.value}`}
              aria-labelledby={`${name}-tab-${payoutMethodTabItem.value}`}
            >
              <ul className={classes.tabPanel} data-testid="correspondent-list">
                {payoutMethodTabItem.correspondents.map(
                  ({ name, link, logo, containerProps }) =>
                    logo && (
                      <li key={name} {...containerProps}>
                        <Correspondent<L>
                          link={link}
                          logo={logo}
                          LinkComponent={LinkComponent}
                        />
                      </li>
                    ),
                )}
              </ul>
            </div>
          );
        })}
      </Container>
    </section>
  ) : null;
}
