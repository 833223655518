import * as grpc from '@grpc/grpc-js';

export type GrpcMethod<TRequest, TResponse> = (
  request: TRequest,
  metadata: grpc.Metadata,
  callback: (error: grpc.ServiceError | null, response: TResponse) => void,
) => grpc.ClientUnaryCall;

type GrpcPromiseMethod<TRequest, TResponse> = (
  request: TRequest,
  metadata?: grpc.Metadata,
) => Promise<TResponse>;

export type PromisifyGrpcMethod<T> = T extends GrpcMethod<
  infer TRequest,
  infer TResponse
>
  ? GrpcPromiseMethod<TRequest, TResponse>
  : T;

export type GrpcError = grpc.ServiceError;

export type WrappedClient<T> = {
  [K in keyof T]: PromisifyGrpcMethod<T[K]>;
};

export interface GrpcClientConfig {
  protoFileName: string;
  packageName: string;
  serviceName: string;
  serverAddress: string;
}

export interface ProtoPackage {
  com?: {
    zepz?: {
      pricing?: {
        hcrc?: {
          [key: string]: grpc.ServiceClientConstructor;
        };
      };
    };
  };
}

export interface HistoricalRateRequest {
  pair: {
    baseCurrency: string;
    counterCurrency: string;
  };
  period: {
    start: string;
    end: string;
  };
  resolution: string;
}

export interface HistoricalRate {
  time: string;
  date: string;
  customerRate: number;
}

export interface HistoricalRateResponse {
  historicalRate: HistoricalRate[];
  message: string;
}

export interface HistoricalRateService extends grpc.ServiceClientConstructor {
  getHistoricalRates: GrpcMethod<HistoricalRateRequest, HistoricalRateResponse>;
}

export enum DateRange {
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export type CurrencyPair = [string, string];

export enum HourlyRatesResolutions {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
}
