import { Container } from '@mui/material';

import { Image } from '../image';
import { useStyles } from './full-width-banner.styles';
import { FullWidthBannerProps } from './full-width-banner.types';

export function FullWidthBanner<Link, RichText>({
  name,
  backgroundImage,
  backgroundColor,
  secondBackgroundColor,
  image,
  text,
  link,
  textColor,
  textAlign,
  borderColor,
  secondBorderColor,
  contentWidth,
  LinkComponent,
  RichTextComponent,
  containerProps,
}: FullWidthBannerProps<Link, RichText>) {
  const classes = useStyles({
    backgroundImage,
    backgroundColor,
    secondBackgroundColor,
    textColor,
    textAlign,
    borderColor,
    text,
    secondBorderColor,
    contentWidth,
  });
  const content = (
    <>
      {image && (
        <Image
          {...image}
          className={classes.image}
          data-testid="full-width-banner-image"
        />
      )}
      {text && (
        <RichTextComponent {...text} data-testid="full-width-banner-content" />
      )}
      {link && <LinkComponent {...link} data-testid="full-width-banner-link" />}
    </>
  );

  return (
    <section id={name} className={classes.section} {...containerProps}>
      {backgroundImage && (
        <Image
          {...backgroundImage}
          data-testid="full-width-banner-background-image"
        />
      )}
      {text && (borderColor || secondBorderColor) ? (
        <Container
          maxWidth="xl"
          classes={{
            root: classes.container,
          }}
          data-testid="full-width-banner-container"
        >
          <div
            className={classes.borderContainer}
            data-testid="full-width-banner-border-container"
          >
            {content}
          </div>
        </Container>
      ) : (
        <Container maxWidth="md" data-testid="full-width-banner-container">
          {content}
        </Container>
      )}
    </section>
  );
}
