import {
  APP_NAME,
  contentfulSchema,
  EVENT,
  INTERACTION_NAME,
  LINK_LOCATION,
  LINK_TYPE,
  pushToDataLayer,
} from '@wr/web-shared';
import { AppBanner as UIAppBanner, Image } from '@wr/web-ui';
import { FC, useContext } from 'react';

import { ANDROID, IOS } from '@/constants';
import { AppContext } from '@/context';
import { useIntlMessages } from '@/hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';

export const AppBanner: FC<contentfulSchema.AppBannerFragment> = ({
  sys,
  name,
  appStoreIconLink,
  playStoreIconLink,
  bannerTitle,
  bannerDescription,
  image,
  __typename,
}) => {
  const intlMessages = useIntlMessages();
  const { osName } = useContext(AppContext);

  const shouldRenderPlayStoreLink = osName !== IOS;
  const shouldRenderAppStoreLink = osName !== ANDROID;

  const dataLayerProps = {
    event: EVENT.VISITOR_INTERACTION,
    interactionName: INTERACTION_NAME.DOWNLOAD_APP_CLICK,
    linkType: LINK_TYPE.LINK,
    linkLocation: LINK_LOCATION.BODY,
  };

  const onClickAppStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, appName: APP_NAME.IOS });
  };

  const onClickPlayStoreLink = (): void => {
    pushToDataLayer({ ...dataLayerProps, appName: APP_NAME.ANDROID });
  };

  return (
    <UIAppBanner<AnchorProps>
      name={name || ''}
      bannerTitle={interpolate(bannerTitle, intlMessages)}
      bannerDescription={interpolate(bannerDescription, intlMessages)}
      appStoreIconLink={
        shouldRenderAppStoreLink
          ? {
              ...appStoreIconLink,
              'data-testid': 'app-banner-app-store-link',
              'openInNewTab': true,
              'isInternal': false,
              'onClick': onClickAppStoreLink,
              'children': appStoreIconLink?.image ? (
                <Image {...appStoreIconLink.image} fill={false} />
              ) : null,
            }
          : null
      }
      playStoreIconLink={
        shouldRenderPlayStoreLink
          ? {
              ...playStoreIconLink,
              'data-testid': 'app-banner-play-store-link',
              'openInNewTab': true,
              'isInternal': false,
              'onClick': onClickPlayStoreLink,
              'children': playStoreIconLink?.image ? (
                <Image {...playStoreIconLink.image} fill={false} />
              ) : null,
            }
          : null
      }
      image={image || null}
      LinkComponent={Anchor}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
