import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  backToSchoolContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  w100: {
    width: '100%',
  },
  sectionContainer: {
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
