import { ApolloClient, contentfulSchema } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import { getCorridorsBySendCountry } from '@/services/bff';
import { getSendCountries } from '@/services/bff/send-countries';
import {
  contentfulClient,
  getAllModuleItems,
  logger,
  Module,
  QueryOptions,
} from '@/utils';

import { parseCexCountryToCexCurrency } from '../currency-data';
import {
  CexCurrencyCorridor,
  CexCurrencyCorridorData,
} from './currency-corridors.types';
import { parseCexCorridor } from './currency-corridors.utils';

export async function getCexCorridors(queryArgs: QueryOptions) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyCorridorsListQuery,
      contentfulSchema.CexCurrencyCorridorsListQueryVariables
    >({
      query: contentfulSchema.CexCurrencyCorridorsList,
      variables: {
        preview: queryArgs.preview,
        locale: queryArgs?.locale ?? '',
      },
    });

    const metadata = data?.cexCurrencyCorridorCollection?.items || [];

    const modules = await getAllModuleItems({
      modules: metadata as Module[],
      ...queryArgs,
    });

    return [...modules]
      .map(module => {
        const __typename = Object.keys(module);
        return parseCexCorridor(
          module[__typename[0]] as CexCurrencyCorridorData,
        );
      })
      .sort((a, b) => {
        const aCurrencyCode = a?.receiveCurrency?.currencyCode ?? '';
        const bCurrencyCode = b?.receiveCurrency?.currencyCode ?? '';
        return aCurrencyCode.localeCompare(bCurrencyCode);
      }) as CexCurrencyCorridor[];
  } catch (error) {
    logger.error(error, `CexCurrencyCorridorsList query request failed`);
    return [];
  }
}

export async function getCexCorridorBySendCurrency({
  sendCurrencyCode,
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  sendCurrencyCode: string;
}) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyCorridorBySendCurrencyCodeQuery,
      contentfulSchema.CexCurrencyCorridorBySendCurrencyCodeQueryVariables
    >({
      query: contentfulSchema.CexCurrencyCorridorBySendCurrencyCode,
      variables: {
        sendCurrencyCode,
        preview,
        locale,
      },
    });

    if (data?.cexCurrencyCorridorCollection?.items?.length) {
      return parseCexCorridor(
        data.cexCurrencyCorridorCollection
          .items?.[0] as CexCurrencyCorridorData,
      );
    }

    return null;
  } catch (error) {
    logger.error(error, `CexCurrencyCorridorBySlug query request failed`);
    return null;
  }
}

export async function getCexCorridorBySlug({
  slug,
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  slug: string;
}) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyCorridorBySlugQuery,
      contentfulSchema.CexCurrencyCorridorBySlugQueryVariables
    >({
      query: contentfulSchema.CexCurrencyCorridorBySlug,
      variables: {
        slug,
        preview,
        locale,
      },
    });

    if (data?.cexCurrencyCorridorCollection?.items?.length) {
      return data.cexCurrencyCorridorCollection.items?.[0];
    }

    return null;
  } catch (error) {
    logger.error(error, `CexCurrencyCorridorBySlug query request failed`);
    return null;
  }
}

export async function getCorridorSlugs({
  bffClient,
}: {
  bffClient: ApolloClient;
}): Promise<CexCurrencyCorridor[]> {
  try {
    const currencyCorridors = [];
    const { sendCountriesSorted } = await getSendCountries({ bffClient });

    for (const sendCountry of sendCountriesSorted) {
      if (!sendCountry.code) {
        return [];
      }

      const corridors = await getCorridorsBySendCountry({
        sendCountryCode: sendCountry.code as string,
        bffClient,
      });

      for (const corridor of corridors.corridorsBySendCountrySortedByReceiveCountryName) {
        currencyCorridors.push({
          pageSlug: `currency-converter/${
            sendCountry.currency?.code as string
          }_${sendCountry.code as string}-${corridor.receiveCurrency.code}_${
            corridor.receiveCountry.code
          }`.toLowerCase(),
          sendCurrency: parseCexCountryToCexCurrency(sendCountry),
          receiveCurrency: parseCexCountryToCexCurrency({
            ...corridor.receiveCountry,
            currency: corridor.receiveCurrency,
          }),
        });
      }
    }

    return currencyCorridors;
  } catch (error) {
    logger.error(error, `GetCorridorSlugs request failed`);
    return [];
  }
}
