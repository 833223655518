import { EVENT, INTERACTION_NAME, pushToDataLayer } from '@wr/web-shared';
import { useCallback } from 'react';

export const useUpdateLoyaltyCountry = (
  loyaltyCountries: { value: string; label: string }[] | undefined,
  DEFAULT_LOCATION: string,
  setCountryFromCookie: (country: string) => void,
  reload: () => void,
) => {
  const onLoyaltyCountryChange = useCallback(
    (value: string) => {
      const foundCountryValue =
        (loyaltyCountries ?? [])
          .find(c => c.value.toUpperCase() === value.toUpperCase())
          ?.value.toUpperCase() ?? DEFAULT_LOCATION.toUpperCase();

      const GA_EVENT = {
        event: EVENT.VISITOR_INTERACTION,
        interactionName: INTERACTION_NAME.DROPDOWN_SELECTION,
        dropdownValue: value,
      };
      pushToDataLayer(GA_EVENT);

      setCountryFromCookie(foundCountryValue.toLowerCase());
      reload();
    },
    [loyaltyCountries, DEFAULT_LOCATION, setCountryFromCookie, reload],
  );

  return {
    onLoyaltyCountryChange,
  };
};
