import { ApolloClient, bffSchema } from '@wr/web-shared';

import { PageQueryVariables } from '@/services';
import { logger } from '@/utils';
import { getSendCountryCodeFromLocale } from '@/utils/intl';

export const getCorridorsBySendCountry = async ({
  sendCountryCode,
  bffClient,
}: Pick<PageQueryVariables, 'sendCountryCode'> & {
  bffClient: ApolloClient;
}): Promise<bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryNameQuery> => {
  try {
    const {
      data,
    } = await bffClient.query<bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryNameQuery>(
      {
        query: bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryName,
        variables: {
          sendCountry: sendCountryCode.toUpperCase(),
        },
      },
    );

    return data;
  } catch (error) {
    logger.error(
      error,
      `GetCorridorsBySendCountrySortedByReceiveCountryName query request failed`,
    );

    try {
      const {
        data,
      } = await bffClient.query<bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryNameQuery>(
        {
          query: bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryName,
          variables: {
            sendCountry: sendCountryCode.toUpperCase(),
          },
          fetchPolicy: 'cache-only',
        },
      );

      return data;
    } catch (error) {
      return { corridorsBySendCountrySortedByReceiveCountryName: [] };
    }
  }
};

export const getReceiveCountryCodesBySendCountry = async ({
  sendCountryCode,
  bffClient,
}: {
  bffClient: ApolloClient;
  sendCountryCode: ReturnType<typeof getSendCountryCodeFromLocale>;
}): Promise<string[]> => {
  if (!sendCountryCode) {
    return [];
  }

  try {
    const {
      data,
    } = await bffClient.query<bffSchema.ReceiveCountryCodesBySendCountryQuery>({
      query: bffSchema.ReceiveCountryCodesBySendCountry,
      variables: {
        sendCountry: (sendCountryCode as string).toUpperCase(),
      },
    });

    const result = data.corridorsBySendCountry
      .map(corridor => corridor.receiveCountry.code)
      .filter((code): code is string => typeof code === 'string');

    return result;
  } catch (error) {
    logger.error(
      error,
      `ReceiveCountryCodesBySendCountry query request failed`,
    );

    try {
      const {
        data,
      } = await bffClient.query<bffSchema.ReceiveCountryCodesBySendCountryQuery>(
        {
          query: bffSchema.ReceiveCountryCodesBySendCountry,
          variables: {
            sendCountry: (sendCountryCode as string).toUpperCase(),
          },
          fetchPolicy: 'cache-only',
        },
      );

      const result = data.corridorsBySendCountry
        .map(corridor => corridor.receiveCountry.code)
        .filter((code): code is string => typeof code === 'string');

      return result;
    } catch (error) {
      return [];
    }
  }
};
