import { ExchangeRateCalculationType } from '@wr/web-ui';

import { GetCalculationErrorMessagesProps } from './parse-errors.types';
import { interpolateErrorMessage } from './parse-errors.utils';

export const getCalculationErrorMessages = ({
  errors,
  calculationType,
  receiveCountryName,
  sendCountryName,
  payoutMethodId,
  payoutMethodName,
  generalErrorMessage,
  senderPayoutMethodUnavailableMessage,
  receiverPayoutMethodUnavailableMessage,
  unavailableCorridorErrorMessage,
  airtimeTopupQuoteAvailableInApp,
}: GetCalculationErrorMessagesProps) => {
  // Client Errors
  if (payoutMethodId === 'ATP' && airtimeTopupQuoteAvailableInApp) {
    return [
      interpolateErrorMessage({
        message: airtimeTopupQuoteAvailableInApp,
        receiveCountryName,
        sendCountryName,
        payoutMethodName,
      }),
    ];
  }

  return errors
    .map(error => {
      // Server Errors
      if (error.isGeneric && error.isServerError && payoutMethodName) {
        const genericMessage =
          calculationType === ExchangeRateCalculationType.Send
            ? senderPayoutMethodUnavailableMessage
            : receiverPayoutMethodUnavailableMessage;
        if (genericMessage) {
          return interpolateErrorMessage({
            message: genericMessage,
            receiveCountryName,
            sendCountryName,
            payoutMethodName,
          });
        }
      }

      if (error.isGeneric && error.isClientError) {
        if (payoutMethodName && senderPayoutMethodUnavailableMessage) {
          return interpolateErrorMessage({
            message: senderPayoutMethodUnavailableMessage,
            receiveCountryName,
            sendCountryName,
            payoutMethodName,
          });
        }

        if (!payoutMethodName && unavailableCorridorErrorMessage) {
          return interpolateErrorMessage({
            message: unavailableCorridorErrorMessage,
            receiveCountryName,
            sendCountryName,
          });
        }
      }

      // General Errors
      if (error.isGeneralError) {
        return generalErrorMessage || error.message;
      }

      return error.message;
    })
    .filter((message): message is string => typeof message === 'string');
};
