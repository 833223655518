import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  appBannerWrapper: {
    backgroundColor: theme.customComponents.appBanner.backgroundColor,
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10),
    },

    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 5),
    },

    [theme.breakpoints.up('xl')]: {
      padding: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8, 5),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  text: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      textAlign: 'left',
    },
  },
  imageWrapper: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(5),
    maxWidth: 320,
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 400,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 460,
    },
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  description: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2, 2),
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start',
    },
  },
  iconLink: {
    display: 'flex',
  },
}));
