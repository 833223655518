import { ApolloClient, bffSchema } from '@wr/web-shared';

import { logger } from '@/utils';

export const getReferralScheme = async ({
  sendCountryCode,
  bffClient,
}: {
  sendCountryCode: string;
  bffClient: ApolloClient;
}): Promise<bffSchema.GetReferralSchemeQuery | null> => {
  try {
    const { data } = await bffClient.query<bffSchema.GetReferralSchemeQuery>({
      query: bffSchema.GetReferralScheme,
      variables: {
        sendCountry: sendCountryCode.toUpperCase(),
      },
    });

    return data;
  } catch (error) {
    logger.error(error, `GetReferralScheme query request failed`);
    return null;
  }
};
