import ArrowLeft from '@mui/icons-material/ChevronLeft';
import ArrowRight from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import React from 'react';

import { Button } from './../../button';
import { useStyles } from './arrow.styles';
import { ArrowProps } from './arrow.types';

export const Arrow: React.FC<ArrowProps> = ({
  onClick,
  className,
  left,
  right,
  arrowProps,
}) => {
  const classes = useStyles();

  const getAriaLabel = () => {
    if (left && arrowProps?.prev) {
      return {
        'aria-label': arrowProps.prev,
        'data-testid': 'carousel-button-prev',
      };
    }
    if (right && arrowProps?.next) {
      return {
        'aria-label': arrowProps.next,
        'data-testid': 'carousel-button-next',
      };
    }

    return {};
  };

  return (
    <Button
      onClick={onClick}
      classes={{
        root: clsx(classes.button, className, arrowProps.className),
      }}
      {...getAriaLabel()}
    >
      {left && (arrowProps.LeftIcon ? <arrowProps.LeftIcon /> : <ArrowLeft />)}
      {right &&
        (arrowProps.RightIcon ? <arrowProps.RightIcon /> : <ArrowRight />)}
    </Button>
  );
};

export default Arrow;
