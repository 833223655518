import { ImageProps, isDarkTheme } from '@wr/web-ui';

import { LogoImageProps } from './header-logo.types';

const defaultLogoImageProps: Partial<ImageProps> = {
  loading: 'eager',
  priority: true,
};

export const getHeaderLogo = ({
  defaultLanguage,
  headerTheme,
  logoDark,
  logoDarkMobile,
  logoLight,
  logoLightMobile,
}: LogoImageProps) => {
  let ariaLabel = logoLight?.alt;
  const logo = {
    ...logoLight,
    ...defaultLogoImageProps,
  };
  const logoMobile = {
    ...logoLightMobile,
    ...defaultLogoImageProps,
  };

  //dark theme overrides

  if (isDarkTheme(headerTheme)) {
    ariaLabel = logoDark?.alt;
    Object.assign(logo, logoDark);
    Object.assign(logoMobile, logoDarkMobile);
  }

  return {
    link: {
      underline: 'none',
      color: 'inherit',
      url: `/${defaultLanguage}/`,
      isInternal: false,
      ariaLabel,
    },
    logo,
    logoMobile,
  };
};
