import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CardProps } from '../..';

export const useStyles = makeStyles<
  Theme,
  Partial<CardProps<unknown, unknown>>
>(theme => ({
  card: ({ backgroundImage, backgroundColor }) => ({
    ...(backgroundImage
      ? {}
      : {
          backgroundColor:
            backgroundColor || theme.customComponents.card.backgroundColor,
        }),
    position: 'relative',
    border: theme.customComponents.card.border,
  }),
  chip: {
    position: 'relative',
    alignSelf: 'end',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.customComponents.card.chip.backgroundColor,
    marginBottom: 'auto',
  },
  rounded: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  textContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 'inherit',
    marginTop: 'auto',
    padding: theme.spacing(2),
    color: theme.customComponents.card.textContainer.color,

    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(3),
    },
  },
  overlay: {
    'height': 'auto',
    'background': alpha(theme.customComponents.card.overlay.background, 0.4),
    'borderBottomLeftRadius': 'inherit',
    'borderBottomRightRadius': 'inherit',

    '& .MuiButton-root': {
      '&:hover': {
        background: theme.customComponents.card.overlay.button.background,
        borderColor: theme.customComponents.card.overlay.button.borderColor,
        color: theme.customComponents.card.overlay.button.color,
      },
    },
  },
  backgroundImage: {
    borderRadius: 'inherit',
  },
  cardLink: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 'inherit',
  },
  description: {
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    color: theme.customComponents.card.description.color,

    [theme.breakpoints.up('xl')]: {
      ...theme.typography.body2,
    },
  },
  ctaButton: {
    backgroundColor: theme.customComponents.card.ctaButton.backgroundColor,
    color: theme.customComponents.card.ctaButton.color,
    border: theme.customComponents.card.ctaButton.border,
  },
  title: {
    color: theme.customComponents.card.title.color,
  },
}));
