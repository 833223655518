import { useMutation } from '@apollo/client';
import { bffSchema } from '@wr/web-shared';
import { CalculationErrorMessage } from '@wr/web-ui';
import { useCallback, useState } from 'react';

import { CalculationErrors } from '@/context';

import { Calculation, CalculationVariables } from './calculator.types';

export const useCalculatorExchangeRate = () => {
  const [errors, setErrors] = useState<CalculationErrorMessage[]>([]);

  /**
   * Parse the bff errors into a common format
   */
  const parseErrors = useCallback((errors: CalculationErrors) => {
    return errors.map(error => {
      if (error.__typename === 'GenericCalculationError') {
        return {
          isGeneric: true,
          isClientError:
            error.genericType ===
            bffSchema.GenericCalculationErrorType.ClientError,
          isServerError:
            error.genericType ===
            bffSchema.GenericCalculationErrorType.ServerError,
          isGeneralError:
            error.genericType ===
            bffSchema.GenericCalculationErrorType.UnknownError,
          message: error.message,
        };
      } else {
        return {
          isGeneric: false,
          isGeneralError: error.__typename !== 'ValidationCalculationError',
          isServerError: false,
          isClientError: false,
          message: error.message,
        };
      }
    });
  }, []);

  /**
   * Parse the result
   */
  const parseCalculationResult = useCallback(
    (result?: bffSchema.CreateCalculationMutation | null) => {
      if (
        result?.createCalculation.calculation?.send &&
        result?.createCalculation.calculation?.receive
      ) {
        const { calculation } = result.createCalculation;

        return {
          sendAmount: Number(calculation?.send?.amount ?? 0),
          receiveAmount: Number(calculation?.receive?.amount ?? 0),
          fee: Number(calculation?.informativeSummary?.fee?.value?.amount ?? 0),
          totalToPay: Number(
            calculation?.informativeSummary?.totalToPay?.amount ?? 0,
          ),
          crossedOutRate: Number(
            calculation?.exchangeRate?.crossedOutValue ?? 0,
          ),
          conversionRate: Number(calculation?.exchangeRate?.value ?? 0),
          hasPromo: !!calculation?.informativeSummary?.appliedPromotions?.includes(
            bffSchema.AppliedPromotions.Cbp,
          ),
        };
      }
    },
    [],
  );

  const [createCalculation] = useMutation<
    bffSchema.CreateCalculationMutation,
    CalculationVariables
  >(bffSchema.CreateCalculation, {
    onCompleted: result => {
      setErrors(parseErrors(result?.createCalculation?.errors ?? []));
    },
    onError: error => {
      setErrors(errors =>
        errors.concat({
          isGeneric: false,
          isGeneralError: true,
          isServerError: false,
          isClientError: false,
          message: error.message,
        }),
      );
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateExchangeRate = useCallback(
    async (
      variables: CalculationVariables,
    ): Promise<Calculation | undefined> => {
      if (
        variables.amount &&
        variables.sendCountryCode &&
        variables.sendCurrencyCode &&
        variables.receiveCountryCode &&
        variables.receiveCurrencyCode
      ) {
        if (variables.payOutMethodCode === 'ATP') {
          setErrors([
            {
              isGeneric: false,
              isClientError: true,
              isServerError: false,
              isGeneralError: false,
              message: 'Airtime Topup is not supported',
            },
          ]);
          return undefined;
        }
        setErrors([]);
        const result = await createCalculation({ variables });
        const calculation = parseCalculationResult(result.data);

        return calculation
          ? {
              ...calculation,
              payOutMethod: variables.payOutMethodCode,
            }
          : undefined;
      }
    },
    [createCalculation, parseCalculationResult],
  );

  return {
    calculateExchangeRate,
    errors,
  };
};
