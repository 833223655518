import { useLazyQuery } from '@apollo/client';
import { bffSchema } from '@wr/web-shared';
import { useContext, useEffect } from 'react';

import { CalculatorContext } from '@/components/calculator/src/context';

export const useValidatePhoneNumber = () => {
  const { state, dispatch } = useContext(CalculatorContext);

  const [
    validatePhoneNumber,
    { loading, data, error },
  ] = useLazyQuery<bffSchema.ValidatePhoneNumberQuery>(
    bffSchema.ValidatePhoneNumber,
    {
      onCompleted: result => {
        dispatch({
          type: 'VALIDATE_PHONE_NUMBER_SUCCESS',
          payload: {
            isPhoneNumberValid: Boolean(
              result.validatePhoneNumber.__typename === 'PhoneNumberValidated',
            ),
          },
        });
      },
      onError: () => {
        dispatch({
          type: 'VALIDATE_PHONE_NUMBER_ERROR',
        });
      },
    },
  );

  useEffect(() => {
    if (
      state.sendCountry?.countryCode &&
      state.receiveCountry?.countryCode &&
      state.mobileNumber
    ) {
      validatePhoneNumber({
        variables: {
          sendCountryCode: state?.sendCountry?.countryCode,
          receiveCountryCode: state.receiveCountry.countryCode,
          mobileNumber: state.mobileNumber,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.sendCountry?.countryCode,
    state.sendCountry?.currency,
    state.receiveCountry?.countryCode,
    state.receiveCountry?.currency,
    state.mobileNumber,
  ]);

  return { loading, data, error };
};
