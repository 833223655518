import { ApolloClient, bffSchema } from '@wr/web-shared';

import { logger } from '@/utils';

export const getAccountDetails = async (
  bffClient: ApolloClient,
  token?: string,
) => {
  if (!token) {
    return null;
  }

  try {
    const {
      data: { accountDetails },
    } = await bffClient.query<bffSchema.CmsAccountDetailsQuery>({
      query: bffSchema.CmsAccountDetails,
    });

    return accountDetails;
  } catch (error) {
    logger.error(error, `CmsAccountDetailsQuery request failed`);

    return null;
  }
};
