import React from 'react';

import { Image, ImageProps } from '../image';
import { CountryFlagProps } from './country-flag-image.types';
import { getFlagSvgSrc } from './country-flag-image.utils';

export const CountryFlag: React.FC<CountryFlagProps> = ({
  code,
  type = 'circle',
  className,
}) => {
  const { svgIcon, assetPrefixPath } = getFlagSvgSrc(code.toLowerCase(), type);

  const isRectangle = type === 'rectangle';

  const width = isRectangle ? 34 : 32;
  const height = isRectangle ? 23 : 32;

  const props = ({
    'url': svgIcon,
    'title': `${code} country flag`,
    'data-testid': `country-flag-${code}`.toLowerCase(),
    'alt': `${code} flag`.toLowerCase(),
    assetPrefixPath,
    className,
    width,
    height,
  } as unknown) as ImageProps;

  return <Image {...props} />;
};
