import {
  CorrespondentsProps as UICorrespondentsProps,
  IntlContextValue,
} from '@wr/web-ui';

import { AnchorProps } from '@/components/anchor';
import { AppContextType } from '@/context';
import { buildContentfulSidekickAttributes } from '@/utils';

import { CorrespondentsProps } from './correspondents.types';

export const getPayoutMethodsTabs = (
  payoutMethods: AppContextType['payoutMethods'],
  payoutMethodsLabelsById: IntlContextValue['payoutMethodsLabelsById'],
  correspondentLogos: CorrespondentsProps['correspondentLogos'],
) => {
  return payoutMethods
    ?.map(payoutMethod => {
      const correspondents = payoutMethod?.correspondents?.flatMap(
        correspondent => {
          return correspondentLogos
            ?.filter(correspondentLogo => {
              return correspondentLogo?.id === correspondent?.id;
            })
            .map(correspondentLogo => {
              if (correspondentLogo) {
                return {
                  logo: correspondentLogo.logo,
                  name: correspondentLogo.name,
                  link:
                    correspondentLogo.link?.__typename === 'Page' &&
                    correspondentLogo.link?.slug
                      ? {
                          url: correspondentLogo.link.slug,
                          ['data-testid']: correspondentLogo.id,
                          isInternal: false,
                        }
                      : null,
                  containerProps: buildContentfulSidekickAttributes(
                    correspondentLogo.sys.id,
                    correspondentLogo.__typename,
                    correspondentLogo.name || undefined,
                  ),
                };
              }
            });
        },
      );

      if (payoutMethod?.code && correspondents?.length) {
        return {
          value: payoutMethod.code,
          label: payoutMethodsLabelsById[payoutMethod.code],
          correspondents,
        };
      }
    })
    .filter(Boolean) as UICorrespondentsProps<AnchorProps>['payoutMethodsTabs'];
};
