import { Alert } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { SelectOption } from '@wr/web-ui';
import React, { FC, useContext, useRef } from 'react';

import { CalculatorProps } from '@/components/calculator/component/calculator.types';
import { CORRESPONDENT_DROPDOWN_NAME } from '@/components/calculator/core/core.constants';
import { setDataLayerForDropdownOpen } from '@/components/calculator/core/core.utils';
import { Select } from '@/components/calculator/src/components/select';

import { CalculatorContext } from '../../context';

export const TopUpAmount: FC<
  Pick<
    CalculatorProps,
    | 'error'
    | 'options'
    | 'topUpAmountsLabel'
    | 'topUpAmountsTitle'
    | 'topUpAmountsPlaceholder'
    | 'topUpAmountsSelectErrorMessage'
    | 'topUpAmountsSelectRetrievalErrorMessage'
  >
> = ({
  error,
  options,
  topUpAmountsLabel,
  topUpAmountsTitle,
  topUpAmountsPlaceholder,
  topUpAmountsSelectErrorMessage,
  topUpAmountsSelectRetrievalErrorMessage,
}) => {
  const containerRef = useRef(null);
  const { state, dispatch } = useContext(CalculatorContext);

  const onValueChange = (value: string): void => {
    if (value && value !== state.topUpUpDenominationId) {
      dispatch({
        type: 'SET_TOP_UP_DENOMINATION_ID',
        payload: {
          topUpUpDenominationId: value,
        },
      });
      dispatch({
        type: 'CREATE_AIRTIME_CALCULATION_PENDING',
      });
    }
  };

  return (
    <div ref={containerRef}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        data-testid="topup-amount-container"
        mb={2}
      >
        <Box flex={1}>
          <Typography
            variant="body1"
            component="span"
            data-testid="topup-amount-label"
          >
            {topUpAmountsLabel}
          </Typography>
        </Box>
        <Box flex={1} display="flex" justifyContent="end">
          <Select
            aria-labelledby="topup-amount-label"
            containerRef={containerRef}
            value={state.topUpUpDenominationId}
            title={topUpAmountsTitle || ''}
            options={
              options?.sort((a, b) =>
                (a.label as string).localeCompare(b.label as string, 'EN', {
                  numeric: true,
                }),
              ) as SelectOption[]
            }
            placeholder={topUpAmountsPlaceholder || undefined}
            disabled={!options?.length || !state.isPhoneNumberValid}
            onSelect={onValueChange}
            onInputClick={() =>
              setDataLayerForDropdownOpen(CORRESPONDENT_DROPDOWN_NAME, false)
            }
          />
        </Box>
      </Box>
      {!error &&
        state.isPhoneNumberValid &&
        !state.topUpUpDenominationId &&
        topUpAmountsSelectErrorMessage && (
          <Alert
            severity="error"
            icon={false}
            data-testid="top-up-amount-error"
          >
            {topUpAmountsSelectErrorMessage}
          </Alert>
        )}
      {error && topUpAmountsSelectRetrievalErrorMessage && (
        <Alert
          severity="error"
          icon={false}
          data-testid="top-up-amount-retrieval-error"
        >
          {topUpAmountsSelectRetrievalErrorMessage}
        </Alert>
      )}
    </div>
  );
};
