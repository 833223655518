import { PromotionalBanner as UIPromotionalBanner } from '@wr/web-ui';
import React from 'react';

import { useIsBannerHidden } from '@/hooks';

import { RichTextContentRenderer } from '../rich-text-content-renderer';
import { PromotionalBannerProps } from './promotional-banner.types';

export const PromotionalBanner: React.FC<PromotionalBannerProps> = ({
  applicability: { visitorTypes, showForCorridors },
  addToInitialHtml,
  ...uiPromotionalBannerProps
}) => {
  const shouldNotRenderBanner = useIsBannerHidden(
    visitorTypes,
    showForCorridors,
    addToInitialHtml,
  );

  if (shouldNotRenderBanner) {
    return null;
  }

  return (
    <UIPromotionalBanner
      {...uiPromotionalBannerProps}
      RichTextComponent={RichTextContentRenderer}
    />
  );
};
