import Alert from '@mui/lab/Alert';
import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef } from 'react';

import { getInitialSelectedCorrespondent } from '@/components/calculator/component/calculator.utils';
import { CORRESPONDENT_DROPDOWN_NAME } from '@/components/calculator/core/core.constants';
import {
  setDataLayerForDropdownOpen,
  setDataLayerForDropdownSelection,
} from '@/components/calculator/core/core.utils';

import { Select } from '../../components/select';
import { CalculatorContext } from '../../context';
import { PayoutCorrespondentSelectProps } from './payout-correspondent-select.types';

export const PayoutCorrespondentSelect: React.FC<PayoutCorrespondentSelectProps> = ({
  isLite,
  contentfulPayoutMethodsList,
  partnersListDefaultValue,
  partnersLabel,
  partnerSelectErrorMessage,
}) => {
  const containerRef = useRef(null);
  const { state, dispatch } = useContext(CalculatorContext);
  const { selectedPayoutMethodId } = state;
  // used to diff the payoutMethods array in useEffect
  const payoutMethodsString = JSON.stringify(state.payoutMethods);

  useEffect(() => {
    if (!isLite) {
      const initialCorrespondent = getInitialSelectedCorrespondent(
        state.payoutMethods,
        state.selectedPayoutMethodId,
      );

      if (initialCorrespondent) {
        dispatch({
          type: 'SET_CORRESPONDENT_ID',
          payload: {
            correspondentId: initialCorrespondent,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutMethodsString, state.selectedPayoutMethodId, isLite]);

  useEffect(() => {
    if (state.correspondentId) {
      dispatch({
        type: 'CREATE_CALCULATION_PENDING',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.correspondentId]);

  const handleCorrespondentChange = (correspondentId: string): void => {
    if (correspondentId !== state.correspondentId) {
      dispatch({
        type: 'SET_CORRESPONDENT_ID',
        payload: {
          correspondentId,
        },
      });
      setDataLayerForDropdownSelection(
        correspondentId,
        CORRESPONDENT_DROPDOWN_NAME,
        isLite,
      );
    }
  };

  const selectedPayoutMethod = state.payoutMethods.find(
    payoutMethod => payoutMethod?.code === selectedPayoutMethodId,
  );

  const contentfulPayoutMethod = contentfulPayoutMethodsList?.items.find(
    contentfulPayoutMethod =>
      contentfulPayoutMethod?.payoutMethodId === selectedPayoutMethodId,
  );

  const correspondentOptions = selectedPayoutMethod?.correspondents?.map(
    correspondent => ({
      value: correspondent?.id || '',
      label: correspondent?.name || '',
    }),
  );

  const label = `${contentfulPayoutMethod?.name} ${partnersLabel}`;

  return selectedPayoutMethodId && correspondentOptions ? (
    <div ref={containerRef}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        data-testid="correspondent-container"
        mt={2}
        mb={1}
      >
        <Box flex={1}>
          <Typography
            variant="body1"
            component="span"
            data-testid="payout-methods-label"
          >
            {label}
          </Typography>
        </Box>
        <Box flex={1} display="flex" justifyContent="end">
          <Select
            aria-labelledby="correspondent-label"
            containerRef={containerRef}
            selectFieldContainerId="correspondent-select"
            selectOptionName="correspondent"
            value={state.correspondentId}
            title={label}
            disabled={state.isLoading}
            options={correspondentOptions}
            placeholder={partnersListDefaultValue || undefined}
            onSelect={handleCorrespondentChange}
            onInputClick={() =>
              setDataLayerForDropdownOpen(CORRESPONDENT_DROPDOWN_NAME, isLite)
            }
          />
        </Box>
      </Box>
      {!state.correspondentId && partnerSelectErrorMessage && (
        <Alert severity="error" icon={false}>
          {partnerSelectErrorMessage}
        </Alert>
      )}
    </div>
  ) : null;
};
