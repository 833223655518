import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors, important } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  section: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
  },
  chartWrapper: {
    padding: spacing(3, 4),
    borderRadius: spacing(1),
    border: `1px solid ${colors.greyLight}`,

    [breakpoints.down('sm')]: {
      padding: spacing(2, 2),
    },
  },
  title: {
    [breakpoints.down('sm')]: {
      fontSize: `1.8rem`,
    },
    '& em': {
      color: colors.purpleNormal,
      fontStyle: important('normal'),
      textDecoration: 'underline',
    },
  },
  lineChart: {
    marginTop: spacing(2),
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1.5, 0),
    gap: spacing(1),
  },
  filterOption: {
    'fontWeight': 700,
    'color': colors.purpleNormal,
    'borderRadius': spacing(0.5),
    'padding': spacing(1.5, 2),

    [breakpoints.down('md')]: {
      fontSize: '0.85rem',
      padding: spacing(1, 2),
    },

    '&.selected': {
      color: colors.greyNormal,
      backgroundColor: colors.greyLight,
    },
  },
}));
