import { Regulator } from '@wr/web-ui';

import { RichTextProps } from '@/components/rich-text-content-renderer';
import { DEFAULT_REGULATOR_COUNTRY_CODE } from '@/constants/regulators';
import { AppContextType } from '@/context';

import { CountrySelectOption } from '../../types/data-interfaces';

export const getPreselectedRegulator = (
  regulators: Regulator<RichTextProps>[],
  sendCountry: AppContextType['sendCountry'],
): CountrySelectOption => {
  return (
    regulators.find(({ countriesList }) => {
      const includesSendCountry = countriesList.includes(
        sendCountry?.countryCode?.toLowerCase?.() ?? '',
      );
      const includesDefaultCountry = countriesList.includes(
        DEFAULT_REGULATOR_COUNTRY_CODE,
      );
      return includesSendCountry || includesDefaultCountry;
    }) || regulators[0]
  );
};
