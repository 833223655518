import {
  bffSchema,
  setCountryFromCookie,
  setCountryToCookie,
} from '@wr/web-shared';

import { CalculatorState } from '../src/types';
import { CalculatorReducerActionTypes } from './calculator.actions';

export const calculatorReducer = (
  store: CalculatorState,
  action: CalculatorReducerActionTypes,
): CalculatorState => {
  switch (action.type) {
    case 'REINITIALIZE': {
      const {
        sendCountry,
        receiveCountries,
        payoutMethods,
        receiveCountry,
      } = action.payload;
      return {
        ...store,
        sendCountry,
        receiveCountries,
        payoutMethods,
        receiveCountry,
        lastAction: 'CREATE_CALCULATION_PENDING',
      };
    }

    case 'SET_SEND_AMOUNT': {
      const { sendFromValue } = action.payload;

      return {
        ...store,
        sendFromValue,
        errors: [],
        calculationType: bffSchema.CalculationType.Send,
        lastAction: action.type,
      };
    }

    case 'SET_SEND_COUNTRY': {
      const { sendCountry } = action.payload;
      setCountryFromCookie(sendCountry?.countryCode ?? '');

      return {
        ...store,
        sendCountry,
        isPhoneNumberValid: undefined,
        topUpUpDenominationId: undefined,
        correspondentId: undefined,
        errors: [],
        lastAction: action.type,
      };
    }

    case 'SET_RECEIVE_AMOUNT': {
      const { sendToValue } = action.payload;

      return {
        ...store,
        sendToValue,
        errors: [],
        calculationType: bffSchema.CalculationType.Receive,
        lastAction: action.type,
      };
    }

    case 'SET_RECEIVE_COUNTRY': {
      const { receiveCountry } = action.payload;
      setCountryToCookie(receiveCountry?.countryCode ?? '');

      return {
        ...store,
        receiveCountry,
        isPhoneNumberValid: undefined,
        topUpUpDenominationId: undefined,
        correspondentId: undefined,
        errors: [],
        lastAction: action.type,
      };
    }

    case 'GET_CORRIDORS_BY_SEND_COUNTRY_SUCCESS': {
      return {
        ...store,
        errors: [],
        lastAction: action.type,
        receiveCountries: action.payload.receiveCountries,
      };
    }

    case 'GET_CORRIDORS_BY_SEND_COUNTRY_ERROR': {
      return {
        ...store,
        errors: [],
        isLoading: false,
        lastAction: action.type,
        receiveCountries: [],
        payoutMethods: [],
      };
    }

    case 'SET_CORRESPONDENT_ID': {
      const { correspondentId } = action.payload;

      return {
        ...store,
        correspondentId,
        errors: [],
        lastAction: action.type,
      };
    }

    case 'GET_PAYOUT_METHODS_SUCCESS': {
      const { payoutMethods } = action.payload;

      return {
        ...store,
        errors: [],
        correspondentId: undefined,
        payoutMethods,
        isLoading: false,
        lastAction: action.type,
      };
    }

    case 'GET_PAYOUT_METHODS_ERROR': {
      return {
        ...store,
        payoutMethods: [],
        isLoading: false,
        lastAction: action.type,
      };
    }

    case 'GET_PAYOUT_METHODS_ALERTS_SUCCESS': {
      const { payoutMethodAlerts } = action.payload;

      return {
        ...store,
        errors: [],
        payoutMethodAlerts,
        isLoading: false,
        lastAction: action.type,
      };
    }

    case 'SET_SELECTED_PAYOUT_METHOD_ID': {
      return {
        ...store,
        feeValue: undefined,
        totalToPay: undefined,
        correspondentId: undefined,
        errors: [],
        selectedPayoutMethodId: action.payload.selectedPayoutMethodId,
        lastAction: action.type,
      };
    }

    case 'CREATE_CALCULATION_SUCCESS':
    case 'CREATE_AIRTIME_CALCULATION_SUCCESS': {
      const {
        sendFromValue,
        sendToValue,
        feeValue,
        totalToPay,
      } = action.payload;

      return {
        ...store,
        sendFromValue,
        sendToValue,
        feeValue,
        totalToPay,
        errors: [],
        isLoading: false,
        lastAction: action.type,
      };
    }

    case 'CREATE_CALCULATION_ERROR':
    case 'CREATE_AIRTIME_CALCULATION_ERROR': {
      const { errors } = action.payload;

      return {
        ...store,
        errors,
        isLoading: false,
        lastAction: action.type,
      };
    }

    case 'AIRTIME_TOP_UP_DENOMINATIONS_SUCCESS': {
      return {
        ...store,
        errors: [],
        isLoading: false,
        lastAction: action.type,
      };
    }

    case 'SET_TOP_UP_DENOMINATION_ID': {
      const { topUpUpDenominationId } = action.payload;

      return {
        ...store,
        topUpUpDenominationId,
        errors: [],
        lastAction: action.type,
      };
    }

    case 'SET_PHONE_NUMBER': {
      const { mobileNumber } = action.payload;

      return {
        ...store,
        mobileNumber,
        errors: [],
        lastAction: action.type,
      };
    }

    case 'VALIDATE_PHONE_NUMBER_SUCCESS': {
      const { isPhoneNumberValid } = action.payload;

      return {
        ...store,
        errors: [],
        isPhoneNumberValid,
        isLoading: false,
        lastAction: action.type,
      };
    }

    case 'VALIDATE_PHONE_NUMBER_ERROR': {
      return {
        ...store,
        errors: [],
        isPhoneNumberValid: false,
        isLoading: false,
        lastAction: action.type,
      };
    }

    default:
      return {
        ...store,
        lastAction: action.type,
      };
  }
};
