import { INITIAL_APP_CONTEXT_SHARED } from './app.constants';
import { AppContextShared } from './app.hooks';
import { AppContextProviderTypeShared } from './app.types';

export const AppContextProviderShared = ({
  appName,
  isWebView,
  regions = [],
  originUri,
  children,
  osName,
  deviceType,
  messages,
  sendCountry,
  receiveCountry,
  sendCountries,
  receiveCountries,
  isAuthenticated,
  analyticsPageType,
  analyticsPageName = '',
  locale,
  intl,
}: AppContextProviderTypeShared) => {
  return (
    <AppContextShared.Provider
      value={{
        appName: appName || INITIAL_APP_CONTEXT_SHARED.appName,
        originUri: originUri || INITIAL_APP_CONTEXT_SHARED.originUri,
        isWebView: isWebView || INITIAL_APP_CONTEXT_SHARED.isWebView,
        osName: osName || INITIAL_APP_CONTEXT_SHARED.osName,
        deviceType: deviceType || INITIAL_APP_CONTEXT_SHARED.deviceType,
        messages: messages || INITIAL_APP_CONTEXT_SHARED.messages,
        regions:
          regions.filter((region): region is string => Boolean(region)) ||
          INITIAL_APP_CONTEXT_SHARED.regions,
        analyticsPageName:
          analyticsPageName || INITIAL_APP_CONTEXT_SHARED.analyticsPageName,
        analyticsPageType:
          analyticsPageType || INITIAL_APP_CONTEXT_SHARED.analyticsPageType,
        sendCountry: sendCountry || INITIAL_APP_CONTEXT_SHARED.sendCountry,
        receiveCountry:
          receiveCountry || INITIAL_APP_CONTEXT_SHARED.receiveCountry,
        locale: locale || INITIAL_APP_CONTEXT_SHARED.locale,
        sendCountries:
          sendCountries || INITIAL_APP_CONTEXT_SHARED.sendCountries,
        receiveCountries:
          receiveCountries || INITIAL_APP_CONTEXT_SHARED.receiveCountries,
        isAuthenticated:
          isAuthenticated || INITIAL_APP_CONTEXT_SHARED.isAuthenticated,
        intl: intl || INITIAL_APP_CONTEXT_SHARED.intl,
      }}
    >
      {children}
    </AppContextShared.Provider>
  );
};
