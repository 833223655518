import { useEffect, useState } from 'react';

export const useSmartLink = (
  url: string | null,
  smartLinkUrl: string,
): string | undefined => {
  // This hook is used to get the smart link from the AF_SMART_SCRIPT_RESULT object
  // We have been advised that it is safe to ALWAYS replace a onlink.me link with the smart link (if available)
  // as we always use onelink.me links for the app store links. This is because the onelink.me
  // links are used to track the number of clicks on the app store links. Smarlinks allow the marketing
  // team to change the app store link on the fly in certain situations. This is useful for A/B testing
  // and tracking specific campaigns or call to actions.

  const [smartLink, setSmartLink] = useState<string | undefined>(undefined);
  const [intervalLimit, setIntervalLimit] = useState(10);

  const appStoreLink: boolean = url?.startsWith(smartLinkUrl) || false;

  useEffect(() => {
    // We need to wait for the smart link to be available
    // the easiest way to do this is to poll the window object.
    // Only do this if the link is an app store link.
    if (appStoreLink && intervalLimit > 0) {
      const timeout = setTimeout(() => {
        if (window.AF_SMART_SCRIPT_RESULT?.clickURL) {
          setSmartLink(window.AF_SMART_SCRIPT_RESULT.clickURL);

          if (window?.AF_SMART_SCRIPT?.fireImpressionsLink) {
            window.AF_SMART_SCRIPT.fireImpressionsLink();
          }

          return;
        }

        setIntervalLimit(intervalLimit - 1);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [appStoreLink, intervalLimit]);

  return smartLink;
};
