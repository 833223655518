import { Typography } from '@mui/material';
import { Anchor } from '@wr/web-ui';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { useIntlMessages } from '@/hooks';
import { interpolate } from '@/utils';

import { LinksByPath } from '../sitemap.types';
import { useStyles } from './sitemap-list.styles';
import { SitemapListProps } from './sitemap-list.types';

export const SitemapList: React.FC<SitemapListProps> = ({
  linksByPaths,
  level = 0,
}) => {
  const classes = useStyles({ level });
  const intlMessages = useIntlMessages();
  const { query, locale } = useRouter();

  if (!Object.keys(linksByPaths || {}).length) {
    return null;
  }

  const sortFn = (cur: string, next: string): number => {
    const curLabel = linksByPaths?.[cur].__link?.children || cur;
    const nextLabel = linksByPaths?.[next].__link?.children || next;

    return curLabel.trim().localeCompare(nextLabel.trim());
  };

  const listItemLabel = (__link: LinksByPath['link']): React.ReactNode => {
    if (typeof __link?.children === 'string') {
      return (
        <NextLink
          href={
            query.sendCountryCode
              ? `/[sendCountryCode]/${__link.href}`
              : `/${__link.href}`
          }
          as={
            query.sendCountryCode
              ? `/${query.sendCountryCode}/${__link.href}`
              : `/${__link.href}`
          }
          locale={locale}
          passHref
          legacyBehavior
        >
          <Anchor target={'_blank'}>
            {interpolate(__link.children, intlMessages)}
          </Anchor>
        </NextLink>
      );
    }

    return null;
  };

  return (
    <ul className={classes.list} data-testid="sitemap-list">
      {Object.keys(linksByPaths || {})
        .sort(sortFn)
        .map((key, index) => {
          const entry = linksByPaths?.[key];
          const { __link, ...nestedLinksByPaths } = entry;
          const hasNestedPaths = Object.keys(nestedLinksByPaths).length > 0;

          if (!__link) {
            return null;
          }

          return (
            <Typography
              data-testid="sitemap-list-item"
              key={entry?.__link?.href || index}
              component="li"
              color={__link?.children ? 'primary' : 'secondary'}
              classes={{
                root: classes.listItemSecondary,
              }}
              className={classes.listItem}
              variant={level === 0 ? 'h5' : 'body1'}
              paragraph
            >
              {listItemLabel(__link)}
              {hasNestedPaths && (
                <SitemapList
                  linksByPaths={nestedLinksByPaths}
                  level={level + 1}
                />
              )}
            </Typography>
          );
        })}
    </ul>
  );
};
