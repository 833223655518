import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import useStyles from './select.styles';
import { SelectProps } from './select.types';

export const Select: React.FC<SelectProps> = ({
  name,
  onChange,
  onOpen,
  options = [],
  value,
  label,
  className,
  forwardedProps = {},
  isDisabled,
}) => {
  const classes = useStyles();

  const handleChange: MuiSelectProps['onChange'] = ({ target }): void => {
    if (typeof target.value === 'string') {
      onChange(target.value);
    }
  };

  const { classes: forwardedClasses, ...restForwardedProps } = forwardedProps;
  return (
    <FormControl fullWidth disabled={isDisabled}>
      <InputLabel id="mui-select-label">{label}</InputLabel>
      <MuiSelect
        labelId="mui-select-label"
        className={clsx(classes.root, className)}
        name={name}
        onChange={handleChange}
        onOpen={onOpen}
        value={value}
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          marginThreshold: 0,
        }}
        classes={{
          icon: classes.icon,
          select: classes.select,
          disabled: classes.disabled,
          ...forwardedClasses,
        }}
        style={{
          width: '100%',
        }}
        IconComponent={KeyboardArrowDownIcon}
        variant="outlined"
        {...restForwardedProps}
      >
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
