import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors, getBackground, important, ThemeBreakpoints } from '@wr/web-ui';

type StyleProps = {
  backgroundColor?: string | null;
};

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  sectionWrapper: ({ backgroundColor }) => ({
    background: getBackground(backgroundColor),
  }),
  gridWrapper: {
    display: 'grid',
    gap: theme.spacing(3.75),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'repeat(2, auto)',
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      gridTemplateRows: 'repeat(3, auto)',
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      padding: theme.spacing(4, 3),
    },
    [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
      padding: theme.spacing(4, 2),
    },
  },
  title: {
    gridColumn: '1/-1',
    fontSize: theme.spacing(4),
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      gridRow: 2,
      gridColumn: 1,
    },
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      fontSize: theme.spacing(5),
    },
    [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
      fontSize: theme.spacing(3),
    },
  },
  questionsWrapper: {
    gridRow: 2,
    gridColumn: 2,
    maxWidth: theme.spacing(42.5),
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      gridRow: 1,
      gridColumn: 1,
      maxWidth: 'none',
    },
  },
  topicsWrapper: {
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      gridRow: 3,
      gridColumn: 1,
    },
  },
  accordion: {
    boxShadow: 'none',
    padding: theme.spacing(4, 3),
    backgroundColor: colors.alabaster,
    borderRadius: important(theme.spacing(2)),
    marginBottom: theme.spacing(3),
  },
  accordionSummary: {
    'padding': 0,
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: colors.purpleNormal,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      paddingRight: theme.spacing(1.5),
      alignItems: 'center',
    },
  },
  accordionSummaryAvatar: {
    width: theme.spacing(5),
    marginRight: theme.spacing(2),
    height: theme.spacing(5),
  },
  accordionSummaryTitle: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      fontSize: theme.spacing(3.5),
    },
    [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
      fontSize: theme.spacing(2.5),
    },
  },
  accordionSummaryDescription: {
    color: colors.greyDark,
  },
  accordionDetails: {
    padding: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(7),
  },
  accordionDetailsLink: {
    display: 'flex',
    alignItems: 'center',
    color: colors.purpleNormal,
    marginBottom: theme.spacing(1),
  },
  accordionDetailsLinkText: {
    paddingRight: theme.spacing(0.4),
  },
}));
