export const interpolate = (
  text: string | undefined | null,
  values: { [key: string]: string },
  transformValue?: (value: string) => string,
): string => {
  if (text) {
    return text.replace(
      /{([^{}]*)}/g,
      (currentValue: string, fieldName: string): string => {
        if (
          typeof values[fieldName] === 'string' ||
          typeof values[fieldName] === 'number'
        ) {
          return typeof transformValue === 'function'
            ? transformValue(`${values[fieldName]}`)
            : `${values[fieldName]}`;
        }

        return currentValue;
      },
    );
  }
  return '';
};
