import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
  ({ spacing, breakpoints, customComponents }) => ({
    section: {
      backgroundColor: customComponents.howDoesItWork.section.backgroundColor,
      textAlign: 'center',

      [breakpoints.up('xs')]: {
        paddingTop: spacing(4.75),
      },
      [breakpoints.up('md')]: {
        paddingTop: spacing(6.25),
      },
    },
    stepHeader: {
      fontSize: '1.75rem',
      [breakpoints.down('sm')]: {
        paddingBottom: 0,
      },
    },
    stepDescription: {
      [breakpoints.up('sm')]: {
        fontSize: '1.125rem',
      },
    },
    arrowContainer: {
      width: 0,
      [breakpoints.up('sm')]: {
        width: '50%',
      },
    },
    carousel: {
      '& .slick-slide': {
        '& > div': {
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      '& button:nth-child(1)': {
        'padding': 0,
        '& span': {
          display: 'flex',
        },
        '& svg': {
          position: 'relative',
          marginRight: spacing(9.5),
          [breakpoints.up('sm')]: {
            marginRight: spacing(37.5),
          },
          [breakpoints.up('md')]: {
            marginRight: spacing(18.75),
          },
        },
      },
      '& button:nth-child(3)': {
        'padding': 0,
        '& span': {
          display: 'flex',
        },
        '& svg': {
          position: 'relative',
          marginLeft: spacing(9.5),
          [breakpoints.up('sm')]: {
            marginLeft: spacing(37.5),
          },
          [breakpoints.up('md')]: {
            marginLeft: spacing(18.75),
          },
        },
      },
    },
    card: {
      padding: 0,
      outline: 'none',
      [breakpoints.up('sm')]: {
        padding: '1rem',
        maxWidth: '37.5rem',
      },
    },
    buttons: {
      marginTop: spacing(2.6),
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    button: {
      width: 'auto',
    },
  }),
);
