import { CountryArticlesPrepositionsByCountryCode } from './locale.types';
import { GetIntlMessagesConfig } from './messages';

export const getCountryNameAndCode = (
  country:
    | GetIntlMessagesConfig['sendCountry']
    | GetIntlMessagesConfig['receiveCountry'],
  articlesAndPrepositions?: CountryArticlesPrepositionsByCountryCode,
): {
  name: string;
  code: string;
  nameAndArticle: string;
  prepositionFrom: string;
  prepositionTo: string;
  prepositionIn: string;
} => {
  // definite articles for countries e.g. the/die
  const countryArticle =
    articlesAndPrepositions?.[country.countryCode]?.article;
  // preposition for 'from' countries e.g. le Royaume-Uni/von dem Vereinigten Königreich (from the United Kingdom)
  const countryPrepositionFrom =
    articlesAndPrepositions?.[country.countryCode]?.prepositionFrom || '';
  // preposition for 'to' countries e.g. au	Royaume-Uni/in das Vereinigte Königreich (to the United Kingdom)
  const countryPrepositionTo =
    articlesAndPrepositions?.[country.countryCode]?.prepositionTo || '';
  // preposition for 'in' countries e.g. aux États-Unis /in den Vereinigten Staaten (in the United States)
  const countryPrepositionIn =
    articlesAndPrepositions?.[country.countryCode]?.prepositionIn || '';

  // combination of article and country name e.g. the Philippines/die Phillipinen
  const countryNameAndArticle =
    (!countryArticle ? country.name : `${countryArticle} ${country.name}`) ||
    '';

  return {
    name: country.name,
    nameAndArticle: countryNameAndArticle,
    code: country.countryCode,
    prepositionFrom: countryPrepositionFrom,
    prepositionTo: countryPrepositionTo,
    prepositionIn: countryPrepositionIn,
  };
};
