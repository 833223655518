import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  section: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(8.5),

    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    'marginTop': theme.spacing(4.5),
    'border': `4px solid ${theme.customComponents.comparisonTable.table.borderColor}`,
    'borderCollapse': 'separate',
    'borderBottomLeftRadius': theme.shape.borderRadius * 2,
    'borderBottomRightRadius': theme.shape.borderRadius * 2,
    '& caption': {
      captionSide: 'top',
      color: theme.customComponents.comparisonTable.tableCaption.color,
      backgroundColor:
        theme.customComponents.comparisonTable.tableCaption.backgroundColor,
      padding: theme.spacing(4, 3),
      borderTopLeftRadius: theme.shape.borderRadius * 2,
      borderTopRightRadius: theme.shape.borderRadius * 2,
    },
  },
  tableCell: {
    borderBottom: `2px solid ${theme.customComponents.comparisonTable.tableCell.borderColor}`,
  },
  clearIcon: {
    fontSize: '1.75rem',
  },
  footerTableCell: {
    padding: theme.spacing(3, 2),
    borderBottom: 'none',
  },
}));
