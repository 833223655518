export * from './contentful';
export * from './data-layer';
export * from './cookies';
export * from './context';
export * from './perimeter-x';
export * from './network';
export * from './optimizely';
export * from './headers';

export const DEFAULT_LANGUAGE = 'en';

export const ENV_GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
export const ENV_SW_GTM_ID = process.env.NEXT_PUBLIC_SW_GOOGLE_TAG_MANAGER_ID;
