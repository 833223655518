import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius * 4,
    height: '4.75rem',
    marginTop: theme.spacing(2),
    border: `3px solid transparent`,
    [theme.breakpoints.up('sm')]: {
      height: '5.4375rem',
    },
  },
  divider: {
    height: '100%',
    width: 1,
  },
  validNumber: {
    fontSize: '1.125rem',
    color: colors.greenDark,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
    },
  },
  dialCodeContainer: {
    marginRight: theme.spacing(0.5),
  },
  dialCode: {
    fontSize: '1.625rem',
    paddingTop: theme.spacing(1.8),
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.875rem',
    },
  },
  flag: {
    alignSelf: 'center',
    height: '1.5rem',
    width: '1.5rem',
    marginRight: theme.spacing(0.75),
  },
  fieldRounded: {
    borderRadius: 8,
  },
  fieldElevation: {
    boxShadow: `0 0 7px 0 ${colors.verySoftBlue}`,
  },
  fieldFocusedElevation: {
    boxShadow: `0 0 7px 0 ${colors.purpleNormal}`,
  },
  errorField: {
    border: `3px solid ${theme.palette.error.main}`,
  },
  inputError: {
    color: theme.palette.error.main,
  },
  inputNumber: {
    'fontSize': '1.625rem',
    'color': colors.purpleNormal,
    'padding': theme.spacing(2, 0, 0),
    'fontWeight': 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.875rem',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      'margin': 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      'margin': 0,
    },
    '-moz-appearance': 'textfield',
  },
  label: {
    color: colors.greyDark,
    transform: 'scale(1)',
    fontSize: '0.813rem',
    fontWeight: 400,
  },
  focusedLabel: {
    // Specificity needed to override focus color in label
    '&.Mui-focused': {
      color: 'inherit',
    },
  },
  adornment: {
    marginRight: '0.938rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
}));

export default useStyles;
