import {
  getInformationDialogClosedCookie,
  setInformationDialogClosedCookie,
} from '@wr/web-shared';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '@/context';

import { PayoutMethodsEnum } from '../calculator/src/types';
import { UseDialog } from './information-dialog.types';

export const useDialog = (): UseDialog => {
  const [isOpen, setIsOpen] = useState(false);
  const { corridorPayoutMethodsIds } = useContext(AppContext);

  const isCashPickupAvailable = corridorPayoutMethodsIds.includes(
    PayoutMethodsEnum.CASH_PICKUP,
  );
  const isBankTransferAvailable = corridorPayoutMethodsIds.includes(
    PayoutMethodsEnum.BANK_TRANSFER,
  );
  const hasAvailablePayoutOptions =
    isCashPickupAvailable || isBankTransferAvailable;

  useEffect(() => {
    if (!getInformationDialogClosedCookie() && hasAvailablePayoutOptions) {
      setIsOpen(true);
    }
  }, [hasAvailablePayoutOptions]);

  const onClose = (): void => {
    setInformationDialogClosedCookie('1');
    setIsOpen(false);
  };

  return { isOpen, onClose };
};
