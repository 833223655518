import { NextRouter } from 'next/router';

import { AnchorProps } from '@/components/anchor';
import { ButtonProps } from '@/components/button';

export const getRelAttribute = (
  noFollow: boolean,
  openInNewTab: boolean,
  href?: string,
  rel?: string,
): string | undefined => {
  if (!href) {
    return undefined;
  }

  const newRelTags = [];

  if (rel) {
    newRelTags.push(rel);
  }

  if (noFollow) {
    newRelTags.push('nofollow');
  }

  if (openInNewTab) {
    newRelTags.push('noopener', 'noreferrer');
  }

  if (!newRelTags.length) {
    return undefined;
  }

  return newRelTags.join(' ');
};

export const isAbsoluteUrl = (
  url: NonNullable<(AnchorProps | ButtonProps)['url']>,
) =>
  /^(#|http|https|whatsapp:\/\/|fb-messenger:\/\/|mailto:|tel:|send\/)/.test(
    url,
  );

export const startsWithSlash = (
  url: NonNullable<(AnchorProps | ButtonProps)['url']>,
) => url.startsWith('/');

export const getLinkHref = (
  url: (AnchorProps | ButtonProps)['url'],
  locale: NextRouter['locale'],
): string => {
  if (!url) {
    return '';
  }

  if (isAbsoluteUrl(url) || startsWithSlash(url)) {
    return url;
  }

  return `/${locale}/${url}`;
};
