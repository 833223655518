export const ENTER_CODE = 'Enter';
export const SPACE_CODE = 'Space';

export const onEnterOrSpacePress = (
  event: React.KeyboardEvent,
  callback: () => void,
): void => {
  const isEnter =
    event.nativeEvent.code === ENTER_CODE || event.key === ENTER_CODE;
  const isSpace =
    event.nativeEvent.code === SPACE_CODE || event.key === SPACE_CODE;

  if (isEnter || isSpace) {
    callback();
  }
};
