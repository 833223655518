import { createContext } from 'react';

import { CalculatorActionTypes, CalculatorContextType } from '@/context';

import {
  INITIAL_CALCULATOR_ACTIONS,
  INITIAL_CALCULATOR_CONTEXT,
} from './calculator.constants';

export const CalculatorContext = createContext<
  [CalculatorContextType, CalculatorActionTypes]
>([INITIAL_CALCULATOR_CONTEXT, INITIAL_CALCULATOR_ACTIONS]);

CalculatorContext.displayName = 'CalculatorContext';
