import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, ThemeBreakpoints } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(theme => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  currencyLink: {
    'color': colors.black,
    'textDecoration': 'none',
    '&:hover h3': {
      color: colors.purpleNormal,
    },
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
  infoCardWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  currencyContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.greyLight}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2, 3),
    gap: theme.spacing(2),
  },
  currencyHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  flag: {
    display: 'flex',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  currencyContent: {
    fontSize: theme.spacing(2),
    lineHeight: 1.5,
  },
}));
