import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next/types';

export const createIntlListFormat = (locale: string) => {
  const defaultListFormat = new Intl.ListFormat(locale, {
    style: 'short',
    type: 'unit',
  });

  // Motorcycle, Bus, and Car
  const conjunctionListFormat = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'conjunction',
  });

  // Motorcycle, Bus, or Car
  const disjunctionListFormat = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'disjunction',
  });

  return { defaultListFormat, conjunctionListFormat, disjunctionListFormat };
};

export const getIntlMonthFormat = (locale: string): Intl.DateTimeFormat =>
  new Intl.DateTimeFormat(locale, { month: 'long' });

export const getIntlLanguageName = (locale: string): Intl.DisplayNames =>
  new Intl.DisplayNames([locale], {
    type: 'language',
  });

export const getIntlCountryName = (locale: string): Intl.DisplayNames =>
  new Intl.DisplayNames([locale], { type: 'region' });

export const getCountryCodeFromLocale = (
  locale: GetServerSidePropsContext['locale'] | GetStaticPropsContext['locale'],
): string => {
  if (!locale) return DEFAULT_LANGUAGE;
  return locale.split('-')[0];
};

/**
 * Returns:
 * - gb for en-gb
 * - ca for fr-ca
 * - fr for fr
 * - undefined for en
 * @param locale
 */
export const getSendCountryCodeFromLocale = (
  locale: GetServerSidePropsContext['locale'] | GetStaticPropsContext['locale'],
): string => {
  if (!locale) return DEFAULT_LANGUAGE;
  const language = getCountryCodeFromLocale(locale);
  const sendCountryCode = locale.split('-')[1];

  if (language !== DEFAULT_LANGUAGE) {
    return sendCountryCode || language;
  }
  return sendCountryCode;
};
