import React from 'react';

import { CountrySelectOption } from '@/types/data-interfaces';

export interface UseCountrySelect {
  selectedCountry: CountrySelectOption | undefined;
  setCountry: React.Dispatch<
    React.SetStateAction<CountrySelectOption | undefined>
  >;
  selectCountries: CountrySelectOption[];
}
