import {
  contentfulSchema,
  ECOMMERCE_TYPE,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
  VARIABLE,
} from '@wr/web-shared';
import {
  CALCULATOR_CONSTANTS,
  CountryData,
  ExchangeRateCalculationType,
  OnCalculatorCountryChangeEvent,
} from '@wr/web-ui';

import {
  pushCalculationToDataLayer,
  PushCalculationToDataLayerProps,
} from '@/context';

import { PayoutMethod } from './cex-calculator.types';

const getDropdownName = (calculationType: ExchangeRateCalculationType) => {
  return calculationType === ExchangeRateCalculationType.Send
    ? CALCULATOR_CONSTANTS.SEND_COUNTRY_DROPDOWN_NAME
    : CALCULATOR_CONSTANTS.RECEIVE_COUNTRY_DROPDOWN_NAME;
};

export const sortedPayoutMethods = (
  payoutMethods: PayoutMethod[],
  payOutMethodsList: contentfulSchema.PayoutMethod[],
) => {
  if (!payOutMethodsList.length && payoutMethods.length) {
    return payoutMethods;
  }
  // get initial sorted methods
  const sortedPayoutMethods = payOutMethodsList
    .map(sortByMethod =>
      payoutMethods.find(method => method.code === sortByMethod.payoutMethodId),
    )
    .filter((method): method is PayoutMethod => method !== undefined);

  // get remaining unsortable methods
  const unsortablePayoutMethods = payoutMethods.filter(
    item =>
      !payOutMethodsList.some(
        sortItem => sortItem.payoutMethodId === item.code,
      ),
  );

  // combine into final array
  return [...sortedPayoutMethods, ...unsortablePayoutMethods];
};

export const buildDropdownSearchDataLayerValue = (
  searchQuery: string,
  selectedCountry?: CountryData,
): string => {
  const currency = selectedCountry?.currency?.toUpperCase?.();
  const countryCode = selectedCountry?.countryCode?.toUpperCase?.();
  const selection = selectedCountry ? `${countryCode}-${currency}` : 'none';

  return `query:${searchQuery.toLowerCase()}|selection:${selection}`;
};

export const setDataLayerForDropdownOpen = (
  calculationType: ExchangeRateCalculationType,
): void => {
  const isLite = true;
  const dropdownName = getDropdownName(calculationType);
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: isLite
      ? INTERACTION_NAME.LITE_CALC_OPEN_DROPDOWN
      : INTERACTION_NAME.CALC_OPEN_DROPDOWN,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_NAME
      : VARIABLE.CALC_DROPDOWN_NAME]: dropdownName,
  });
};

export const setDataLayerForDropdownSearch = ({
  searchText,
  calculationType,
  country,
}: Omit<OnCalculatorCountryChangeEvent, 'country'> & {
  country?: CountryData;
}): void => {
  const isLite = true;
  const dropdownName = getDropdownName(calculationType);
  const searchValue = buildDropdownSearchDataLayerValue(searchText, country);
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: isLite
      ? INTERACTION_NAME.LITE_CALC_SEARCH_DROPDOWN
      : INTERACTION_NAME.CALC_SEARCH_DROPDOWN,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_NAME
      : VARIABLE.CALC_DROPDOWN_NAME]: dropdownName,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_SEARCH_VALUE
      : VARIABLE.CALC_DROPDOWN_SEARCH_VALUE]: searchValue,
  });
};

export const setDataLayerForDropdownSelection = (
  countryCode: string,
  calculationType: ExchangeRateCalculationType,
): void => {
  const isLite = true;
  const dropdownName = getDropdownName(calculationType);
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: isLite
      ? INTERACTION_NAME.LITE_CALC_SELECT_DROPDOWN
      : INTERACTION_NAME.CALC_SELECT_DROPDOWN,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_NAME
      : VARIABLE.CALC_DROPDOWN_NAME]: dropdownName,
    [isLite
      ? VARIABLE.LITE_CALC_DROPDOWN_VALUE
      : VARIABLE.CALC_DROPDOWN_VALUE]: countryCode.toUpperCase(),
  });
};

export const setDataLayerForCalculation = (
  props: Pick<PushCalculationToDataLayerProps, 'state' | 'payoutMethod'>,
): void => {
  pushCalculationToDataLayer({
    ...props,
    ecommerceType: ECOMMERCE_TYPE.DETAIL,
    interactionName: INTERACTION_NAME.MONEY_TRANSFER_CALCULATION,
  });
};
