import { contentfulSchema } from '@wr/web-shared';
import { CardProps, Cards as UICards, ImageProps } from '@wr/web-ui';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import { AppContext } from '@/context';
import { useIntlMessages } from '@/hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';
import { formatDistanceToNow } from '@/utils/date';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';

export const Cards: React.FC<contentfulSchema.CardsFragment> = ({
  name,
  __typename,
  sys,
  header,
  description,
  carouselText,
  cardsCollection,
  paymentText,
  paymentIconsCollection,
}) => {
  const { messages, corridorPayoutMethodsIds } = useContext(AppContext);
  const intlMessages = useIntlMessages();
  const router = useRouter();

  const uiCards = (cardsCollection?.items as contentfulSchema.CardFragment[])
    .filter(
      card =>
        card &&
        (!card?.payoutMethodId ||
          corridorPayoutMethodsIds.includes(card.payoutMethodId)),
    )
    .map(card => {
      const publishedTime = card?.sys?.publishedAt
        ? formatDistanceToNow(card.sys.publishedAt, router.locale)
        : '';

      return {
        name: card?.payoutMethodId || card?.name,
        backgroundColor: card?.backgroundColor || null,
        backgroundImage: card?.backgroundImage
          ? {
              ...card.backgroundImage,
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'center',
              },
            }
          : null,
        header: interpolate(card?.header, intlMessages),
        description: interpolate(card?.description, {
          ...intlMessages,
          publishedTime,
        }),
        link: card?.link
          ? {
              ...card.link,
              children: card.link.label,
              underline: 'none',
            }
          : null,
        button: card?.link
          ? card?.backgroundImage
            ? {
                children: card.link.label,
              }
            : {
                ...card.link,
                children: card.link.label,
              }
          : null,
        containerProps: buildContentfulSidekickAttributes(
          card?.sys.id,
          card?.__typename,
          card?.name,
        ),
      };
    }) as CardProps<AnchorProps, ButtonProps>[];

  const paymentIcons = paymentIconsCollection?.items.filter(
    (i): i is ImageProps => Boolean(i),
  );

  return (
    <UICards<AnchorProps, ButtonProps>
      name={name || undefined}
      header={interpolate(header, intlMessages)}
      description={interpolate(description, intlMessages)}
      carouselText={carouselText || null}
      AnchorComponent={Anchor}
      ButtonComponent={Button}
      messages={{
        previous: messages?.previous,
        next: messages?.next,
      }}
      containerProps={buildContentfulSidekickAttributes(
        sys?.id,
        __typename,
        name,
      )}
      cards={uiCards}
      paymentText={paymentText || null}
      paymentIcons={paymentIcons || null}
    />
  );
};
