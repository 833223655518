export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/(^|\s|[-'`´([\]{}])\S/g, (char: string) => char.toUpperCase());
};

export const isValidEmail = (email: string) => {
  const emailRegex = /^[A-Z0-9a-z._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,4}$/;
  return emailRegex.test(email);
};

export const capitalizeFirstLetter = (value: string) => {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : null;
};
