import { useContext } from 'react';

import {
  AppContext,
  CalculatorContext,
  useCalculatorCorridors,
} from '@/context';

export const useCalculator = () => {
  const appContext = useContext(AppContext);
  const [state] = useContext(CalculatorContext);
  const { receiveCorridors } = useCalculatorCorridors();

  return {
    ...state,
    sendCountry: state.sendCountry || appContext.sendCountry || null,
    receiveCountry: state.receiveCountry || appContext.receiveCountry || null,
    selectedPayoutMethodId:
      state.payoutMethodId || appContext.selectedPayoutMethodId || null,
    sendCountries: appContext.sendCountries,
    receiveCountries: !receiveCorridors.length
      ? appContext.receiveCountries
      : receiveCorridors,
  };
};
