import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
import { useRef } from 'react';

import { useStyles } from './hover-dropdown.styles';
import { HoverDropdownProps } from './hover-dropdown.types';

export const HoverDropdown: React.FC<HoverDropdownProps> = ({
  selectedLabel,
  position,
  children,
  testId,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const classes = useStyles({
    listHeight: (listRef?.current?.clientHeight ?? 0) + 60,
  });
  return (
    <li data-testid="hover-dropdown-toggle" className={classes.dropdownToggle}>
      <button
        data-testid={testId ?? selectedLabel}
        className={classes.dropdownToggleButton}
        value={testId}
      >
        <span className={classes.buttonText}>{selectedLabel}</span>
        <ArrowDropDownIcon />
      </button>
      <div
        data-testid={
          position === 'top' ? 'hover-dropdown-top' : 'hover-dropdown'
        }
        className={classes.absolute}
      >
        <ul
          ref={listRef}
          data-testid={'hover-dropdown-list'}
          className={clsx(
            classes.dropdownMenu,
            position === 'top' ? classes.dropdownTop : '',
          )}
        >
          {children}
        </ul>
      </div>
    </li>
  );
};
