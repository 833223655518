import { Link as MuiLink } from '@mui/material';
import { LinkTypeMap } from '@mui/material/Link';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

import { AnchorProps } from './anchor.types';

export const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  function Link({ variant = 'inherit', ...props }, ref) {
    return <MuiLink {...props} variant={variant} ref={ref} />;
  },
) as OverridableComponent<LinkTypeMap<AnchorProps, 'a'>>;

export default Anchor;
