import { isValidVisitorTypeCookie } from '@wr/web-shared';
import { useContext } from 'react';

import { AppContext } from '@/context';
import { checkValidityOfCorridor } from '@/utils';

export const useIsBannerHidden = (
  visitorTypes: string[],
  showForCorridors: string[],
  addToInitialHtml = false,
): boolean => {
  const { sendCountry, receiveCountry } = useContext(AppContext);

  const isValidCorridor = checkValidityOfCorridor({
    sendCountry,
    receiveCountry,
    showForCorridors,
  });

  const initiallyVisible = addToInitialHtml;
  const visibleAfter =
    isValidVisitorTypeCookie(visitorTypes) && isValidCorridor;
  const visible = initiallyVisible || visibleAfter;

  return !visible;
};
