import mapValues from 'lodash.mapvalues';

/* eslint-disable @typescript-eslint/no-explicit-any */

// should be lower case substring of a field name
// example: 'firstname' will match 'SenderFirstName'
const SENSITIVE_FIELDS = [
  // sender-form fields: web-processes/services/bff/sender-form/sender-form.service.ts
  'firstname',
  'lastname',
  'middlename',
  'region',
  'flatunit',
  'building',
  'street',
  'suburb',
  'postalcode',
  'city',
  'mobile',
  'phonenumber',
  'dateofbirth',
  'email',
  'password',
  'newPassword',
  'address',
  'SenderSerialNumber',
  'key',
  'value',
];

/** replace all but first character with '*' */
function maskAllButFirstCharacter(str: string) {
  // negative look ahead regex 😛
  return str.replace(/(?!^)./g, '*');
}

function isSensitiveField(key: string): boolean {
  return SENSITIVE_FIELDS.some(sensitiveField =>
    key.toLowerCase().includes(sensitiveField.toLowerCase()),
  );
}

// Masks all sensitive fields in an object
function maskObjectFields(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(maskObjectFields);
  } else if (obj !== null && typeof obj === 'object') {
    return mapValues(obj, (value, key) => {
      if (isSensitiveField(key)) {
        return maskAllButFirstCharacter(String(value));
      } else if (typeof value === 'object') {
        return maskObjectFields(value);
      }
      return value;
    });
  }
  return obj;
}

function tryParseJson(str: string): object | null {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

function maskSensitiveDataInJSONProperties(properties: any): any {
  return properties.map((property: any) => {
    if (typeof property.value === 'string') {
      const parsedValue = tryParseJson(property.value);
      if (parsedValue) {
        return {
          ...property,
          value: JSON.stringify(maskObjectFields(parsedValue)),
        };
      }
    }
    return property;
  });
}

/**
 * Function masks sensitive data in grapqh json variables
 * in future we might want to cover other cases like string variable instead of json
 */
export function maskSensitiveData(variables: Record<string, any>) {
  const maskedVariables = { ...variables };
  if (maskedVariables.properties) {
    maskedVariables.properties = maskSensitiveDataInJSONProperties(
      maskedVariables.properties,
    );
  }
  return mapValues(maskedVariables, (value, key) => {
    if (isSensitiveField(key)) {
      return maskAllButFirstCharacter(String(value));
    }
    return maskObjectFields(value);
  });
}
