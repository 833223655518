import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(
  ({ palette, spacing, breakpoints, typography, shape }) => ({
    selectContentWrapper: {
      overflowY: 'auto',
      padding: spacing(2, 1),
      borderRadius: shape.borderRadius * 4,
      backgroundColor: palette.background.paper,
      fontWeight: typography.fontWeightMedium,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      [breakpoints.up('md')]: {
        maxHeight: '27.5rem',
      },
    },
    option: {
      'borderRadius': shape.borderRadius,
      'border': '3px solid transparent',
      'display': 'block',

      '&:focus': {
        borderColor: colors.purpleNormal,
      },
      '&:hover': {
        cursor: 'pointer',
      },
      '&:not($selected)': {
        'position': 'relative',

        '&:not(:last-child)': {
          '&:after': {
            display: 'block',
            content: "''",
            position: 'absolute',
            bottom: '-0.5rem',
            left: '0.5rem',
            right: 0,
            height: 1,
            width: `calc(100% - ${spacing(2)})`,
            backgroundColor: colors.darkGray,
            opacity: '0.26',
          },
        },
      },
    },
    selected: {
      borderColor: colors.purpleNormal,
    },
    disabled: {
      opacity: '0.3',
      cursor: 'none',
    },
    input: {
      'padding': spacing(2, 0, 2, 2),
      'fontSize': '1rem',
      'lineHeight': 1.25,
      'fontWeight': typography.fontWeightBold,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    root: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    inputDisabled: {
      opacity: '0.4',
    },
    notchedOutline: {
      'border': `2px solid ${colors.black}`,
      'borderRadius': shape.borderRadius,
      '&:focus, &:hover, &:active': {
        border: `2px solid ${colors.black}`,
      },
    },
    title: {
      color: palette.primary.dark,
      marginTop: spacing(1),
      marginBottom: spacing(2),
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
  }),
);

export default useStyles;
