import { BLOCKS } from '@contentful/rich-text-types';
import { TypographyProps } from '@mui/material';
import { StepByStep as UIStepByStep } from '@wr/web-ui';
import { FC } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { useIntlMessages } from '@/hooks/intl.hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

import { Button, ButtonProps } from '../button';
import { StepByStepProps } from './step-by-step.types';

export const StepByStep: FC<StepByStepProps> = ({
  sys,
  __typename,
  header,
  stepsCollection,
  name,
  link,
}) => {
  const messages = useIntlMessages();
  const steps = (stepsCollection?.items || []).map(item => ({
    header: interpolate(item?.header, messages),
    name: item?.name || '',
    description: item?.description
      ? {
          ...item.description,
          options: {
            [BLOCKS.PARAGRAPH]: {
              paragraph: false,
              variant: 'body1' as TypographyProps['variant'],
            },
          },
        }
      : null,
    imageProps: item?.image || null,
    containerProps: buildContentfulSidekickAttributes(
      item?.sys.id,
      item?.__typename,
      item?.name,
    ),
  }));

  return (
    <UIStepByStep<ButtonProps, RichTextProps>
      header={interpolate(header, messages)}
      name={name || ''}
      steps={steps}
      link={
        link?.label
          ? {
              ...link,
              children: link.label,
              color: 'primary',
              variant: 'contained',
            }
          : null
      }
      RichTextComponent={RichTextContentRenderer}
      LinkComponent={Button}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
