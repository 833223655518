import React, { ComponentType, PropsWithChildren } from 'react';

export function withDefaultProps<P>(
  Component: ComponentType<P>,
  defaultProps: Partial<P>,
): React.ComponentType<P> {
  function WithDefaultProps(props: PropsWithChildren<P>) {
    return <Component {...defaultProps} {...props} />;
  }

  WithDefaultProps.displayName = `WithDefaultProps(${
    Component.displayName || Component.name || 'Component'
  })`;
  return WithDefaultProps;
}
