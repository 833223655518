import { ExchangeRateProps } from './exchange-rate.types';

export const getExchangeRateText = (
  exchangeRate: ExchangeRateProps['exchangeRate'],
  isFetching: ExchangeRateProps['isFetching'],
  fromCurrency: ExchangeRateProps['fromCurrency'],
  toCurrency: ExchangeRateProps['toCurrency'],
  isCrossedOut?: boolean,
): string => {
  const exchangeRateStr = exchangeRate?.value ?? '—';
  const crossedOutValue = exchangeRate?.crossedOutValue ?? '-';
  const exchangeRateWithCurrency = isFetching
    ? ''
    : `${isCrossedOut ? crossedOutValue : exchangeRateStr} ${toCurrency}`;

  return `1 ${fromCurrency} = ${exchangeRateWithCurrency}`;
};
