import { Typography } from '@mui/material';
import React, { useRef } from 'react';
import Slider from 'react-slick';

import { Carousel } from '../carousel';
import { CarouselProps } from '../carousel/carousel.types';
import { Stepper } from '../stepper';
import { useStepSelection, useStepUpdate } from './how-does-it-work.hooks';
import { useStyles } from './how-does-it-work.styles';
import { HowDoesItWorkProps } from './how-does-it-work.types';

const goToCarouselSlide = (
  carouselRef: React.RefObject<Slider>,
  slideNumber: number,
): void => {
  if (carouselRef && carouselRef.current) {
    carouselRef.current.slickGoTo(slideNumber);
  }
};

export function HowDoesItWork({
  name,
  title,
  animationSteps,
  messages,
  containerProps,
}: HowDoesItWorkProps): React.ReactElement {
  const classes = useStyles();

  const carouselRef = useRef<Slider>(null);

  const { selectedId, onSelectionChange } = useStepSelection();

  const onStepperChange = (stepId: string): void => {
    const index = animationSteps.findIndex(step => step.id === stepId);

    goToCarouselSlide(carouselRef, index);
    onSelectionChange(stepId);
  };

  useStepUpdate(
    animationSteps,
    carouselRef,
    goToCarouselSlide,
    onSelectionChange,
  );

  const settings: CarouselProps['settings'] = {
    arrows: false,
    className: classes.carousel,
    autoplaySpeed: 3800,
    autoplay: false,
    beforeChange: (_, nextNumber) => {
      if (animationSteps?.length >= nextNumber + 1) {
        const step = animationSteps.find((__, index) => index === nextNumber);

        if (step) {
          onSelectionChange(step.id);
        }
      }
    },
  };

  const renderSteps = (): React.ReactNode[] => {
    if (!animationSteps || animationSteps.length === 0) return [];

    return animationSteps.map(step => (
      <div key={step.header} className={classes.card}>
        <Typography>{step.description}</Typography>
      </div>
    ));
  };

  const arrowProps = {
    className: classes.arrowContainer,
    next: messages?.next,
    prev: messages?.previous,
  };

  return (
    <section
      id={name}
      className={classes.section}
      {...containerProps}
      data-testid="how-does-it-work"
    >
      <Typography component="h2" variant="h2">
        {title}
      </Typography>

      <Carousel ref={carouselRef} settings={settings} arrowProps={arrowProps}>
        {renderSteps()}
      </Carousel>
      {animationSteps?.length > 0 && (
        <Stepper
          steps={animationSteps}
          selectedStep={selectedId}
          onChange={onStepperChange}
        />
      )}
    </section>
  );
}
