import { BLOCKS } from '@contentful/rich-text-types';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';

import { ErrorBoxAccordionProps } from '@/components/calculator/src/components/error-box-accordion/error-box-accordion.types';

import RichTextContentRenderer from '../../../../rich-text-content-renderer/rich-text-content-renderer.component';
import useStyles from './error-box-accordion.styles';

const ErrorBoxAccordion: React.FC<ErrorBoxAccordionProps> = ({
  title,
  message,
}) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = React.useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    setExpanded(isExpanded => !isExpanded);
  };

  return (
    <Accordion
      classes={{
        root: classes.accordion,
        rounded: classes.borderRounding,
        expanded: classes.expanded,
      }}
      elevation={0}
      expanded={isExpanded}
    >
      <AccordionSummary
        onClick={handleClick}
        classes={{
          root: classes.summary,
          content: classes.content,
          expanded: classes.expanded,
        }}
        expandIcon={
          isExpanded ? (
            <RemoveIcon className={classes.expandIcon} />
          ) : (
            <AddIcon className={classes.expandIcon} />
          )
        }
      >
        <Box display="flex" alignItems="flex-start">
          <ErrorOutlineIcon className={classes.errorIcon} color="primary" />
          <Typography className={classes.text} color="textSecondary">
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      {message?.json && (
        <AccordionDetails classes={{ root: classes.expandedMenu }}>
          <RichTextContentRenderer
            json={message.json}
            options={{
              [BLOCKS.PARAGRAPH]: {
                className: classes.text,
              },
            }}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default ErrorBoxAccordion;
