import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { StickyBannerContentStyleProps } from './sticky-banner-content.types';

export const useStyles = makeStyles<Theme, StickyBannerContentStyleProps>(
  theme => {
    return {
      image: {
        'width': '1.25rem',
        'height': '1.25rem',
        'display': 'flex',
        'flex': '0 0 1.25rem',
        'marginRight': theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(1.5),
        },
        '& img': {
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        },
      },
      description: ({
        textColor,
        backgroundColor,
      }: StickyBannerContentStyleProps) => ({
        backgroundColor: backgroundColor,
        color: textColor,
        textAlign: 'center',
        padding: theme.spacing(1, 1),

        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(2, 1),
        },
      }),
      overflowWrapper: ({
        isVisible,
        isCollapsed,
      }: StickyBannerContentStyleProps) => ({
        display: 'flex',
        justifyContent: 'center',
        ...(isVisible && !isCollapsed
          ? { minHeight: '1.25rem', maxHeight: 'none' }
          : { maxHeight: '1.25rem' }),
        overflow: 'hidden',

        [theme.breakpoints.up('md')]: {
          overflow: 'initial',
        },
      }),
      collapseWrapper: ({
        backgroundColor,
      }: StickyBannerContentStyleProps) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
        backgroundColor:
          theme.customComponents.stickyBannerContent.collapseWrapper
            .backgroundColor,
        color: backgroundColor,
        padding: theme.spacing(1),
        width: '100%',
        boxShadow: `0 2px 4px 0 ${theme.customComponents.stickyBannerContent.collapseWrapper.boxShadowColor}`,
      }),
      expandMoreIcon: ({
        backgroundColor,
        isCollapsed,
      }: StickyBannerContentStyleProps) => ({
        ...(!isCollapsed && { transform: 'rotate(180deg)' }),
        height: '1.125rem',
        width: '1.125rem',
        marginLeft: '0.5rem',
        color: theme.customComponents.stickyBannerContent.expandMoreIcon.color,
        backgroundColor: backgroundColor,
        borderRadius: '50%',
      }),
    };
  },
);
