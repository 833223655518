import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
  theme => ({
    section: {
      padding: theme.spacing(3, 0),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4, 0),
      },

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(10, 0),
      },

      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(8, 0),
      },

      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(15, 0),
      },
    },
    title: {
      marginBottom: theme.spacing(0.5),

      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
      },

      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(1),
        fontSize: '2rem',
        lineHeight: '2.25rem',
      },
    },
    description: {
      marginBottom: theme.spacing(3),

      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },

      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
      },

      [theme.breakpoints.up('xl')]: {
        ...theme.typography.subtitle1,
      },
    },
    selectIcon: {
      fontSize: '2rem',
      right: '1rem',
      top: 'calc(50% - 1rem)',
      color: theme.customComponents.weAreRegulated.selectIcon.color,
    },
    regulatoryBody: {
      padding: theme.spacing(1),
      backgroundColor:
        theme.customComponents.weAreRegulated.regulatoryBody.backgroundColor,
      borderRadius: theme.shape.borderRadius * 2,
      color: theme.customComponents.weAreRegulated.regulatoryBody.color,
    },
    regulatoryBodyContent: {
      'maxHeight': '20rem',
      'overflowY': 'scroll',
      'padding': theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },

      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(4),
      },

      '&::-webkit-scrollbar': {
        width: '0.75rem',

        [theme.breakpoints.up('sm')]: {
          width: '1em',
        },
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor:
          theme.customComponents.weAreRegulated.regulatoryBodyContent
            .scrollbarThumb.backgroundColor,
        borderRadius: theme.shape.borderRadius * 4,
        height: '2rem',

        [theme.breakpoints.up('sm')]: {
          height: '3rem',
        },

        [theme.breakpoints.up('sm')]: {
          height: '4.5rem',
        },
      },

      '& h3': {
        marginBottom: theme.spacing(0.5),

        [theme.breakpoints.up('lg')]: {
          ...theme.typography.body2,
        },
        [theme.breakpoints.up('xl')]: {
          ...theme.typography.subtitle1,
        },
      },

      '& p': {
        [theme.breakpoints.up('lg')]: {
          ...theme.typography.subtitle1,
        },
        [theme.breakpoints.up('xl')]: {
          fontSize: '1.75rem',
          lineHeight: '2rem',
        },
      },

      '& hr': {
        margin: theme.spacing(2, 0),
      },
    },
  }),
  { name: 'UIWeAreRegulated' },
);
