import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';

import { PayoutMethodsEnum } from '@/components/calculator/src/types';

import { CalculatorLoader } from '../../components/calculator-loader';
import { CalculatorContext } from '../../context';
import { displayDiscount } from '../../utils';
import { useStyles } from './payout-details.styles';
import { PayoutDetailsProps } from './payout-details.types';

export const PayoutDetails: React.FC<PayoutDetailsProps> = ({
  feeLabel,
  instantDiscountLabel,
  transferTimeLabel,
  totalToPayLabel,
  isLoading,
}: PayoutDetailsProps) => {
  const classes = useStyles();
  const { state } = useContext(CalculatorContext);

  const transferTime = state.payoutMethods
    ?.find(payoutMethod => payoutMethod?.code === state.selectedPayoutMethodId)
    ?.correspondents?.find(
      payoutMethod => payoutMethod?.id === state.correspondentId,
    )?.payOutTime;

  const isAirtime =
    state.selectedPayoutMethodId === PayoutMethodsEnum.AIRTIME_TOP_UP;

  const renderValue = <T extends string | undefined | null>(value: T) => {
    if (
      !value ||
      state.errors.length ||
      (isAirtime
        ? !state.isPhoneNumberValid || !state.topUpUpDenominationId
        : !state.correspondentId)
    ) {
      return '-';
    }
    return value;
  };

  const isDisplayDiscount: boolean = displayDiscount(state.feeValue);

  return (
    <Box mt={2}>
      <Box
        data-testid="fee-container"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={1}
      >
        <Typography
          variant="body1"
          component="span"
          className={isDisplayDiscount ? classes.instantFee : classes.fee}
        >
          {isDisplayDiscount ? instantDiscountLabel : feeLabel}
        </Typography>
        {isLoading ? (
          <CalculatorLoader size={2.5} />
        ) : (
          <Typography
            variant="body1"
            component="span"
            data-testid="fee-value"
            className={
              isDisplayDiscount ? classes.instantFee : classes.feeAmount
            }
          >
            <strong>
              {renderValue(state.feeValue)} {state.sendCountry?.currency ?? ''}
            </strong>
          </Typography>
        )}
      </Box>
      <Box
        data-testid="transfer-time-container"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={1}
      >
        <Typography variant="body1" component="span">
          {transferTimeLabel}
        </Typography>
        {isLoading ? (
          <CalculatorLoader size={2.5} />
        ) : (
          <Typography
            variant="body1"
            component="span"
            data-testid="transfer-time-value"
            className={classes.transferTime}
          >
            <strong>{renderValue(transferTime)}</strong>
          </Typography>
        )}
      </Box>
      <Box
        data-testid="send-amount-container"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={1}
      >
        <Typography variant="body1" component="span">
          {totalToPayLabel}
        </Typography>
        {isLoading ? (
          <CalculatorLoader size={2.5} />
        ) : (
          <Typography
            variant="body1"
            component="span"
            data-testid="send-amount-value"
          >
            <strong>
              {`${renderValue(state.totalToPay)} ${
                state.sendCountry?.currency ?? ''
              }`}
            </strong>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
