import { ApolloClient, bffSchema } from '@wr/web-shared';

import { logger } from '@/utils';

export const getCorrespondentLocationsFromBff = async ({
  bffClient,
  correspondentIds,
  countryCode,
}: {
  correspondentIds: string | null | undefined;
  countryCode: string;
  bffClient: ApolloClient;
}): Promise<
  NonNullable<
    bffSchema.CorrespondentLocationsFromBffQuery['correspondentLocations']
  >['edges']
> => {
  if (!correspondentIds) {
    return [];
  }
  try {
    const {
      data,
    } = await bffClient.query<bffSchema.CorrespondentLocationsFromBffQuery>({
      query: bffSchema.CorrespondentLocationsFromBff,
      variables: {
        correspondentIds,
        countryCode,
      },
    });

    return data?.correspondentLocations?.edges;
  } catch (error) {
    logger.error(
      error,
      `getCorrespondentLocationsFromBff query request failed`,
    );

    return [];
  }
};
