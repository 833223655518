import { ArrowBack, ArrowForward } from '@mui/icons-material';
import clsx from 'clsx';
import React from 'react';

import { Arrow } from '../arrow';
import { useStyles } from './cards-arrows.styles';
import { ArrowProps } from './cards-arrows.types';

export const NextArrow: React.FC<ArrowProps> = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <Arrow
      {...props}
      right
      arrowProps={{
        RightIcon: ArrowForward,
        next: label,
        className: clsx(classes.arrow, classes.nextArrow),
      }}
    />
  );
};

export const PrevArrow: React.FC<ArrowProps> = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <Arrow
      {...props}
      left
      arrowProps={{
        LeftIcon: ArrowBack,
        prev: label,
        className: clsx(classes.arrow, classes.prevArrow),
      }}
    />
  );
};
