import { ButtonProps } from '@mui/material';
import { BlogSelect } from '@wr/web-ui';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { AppContext } from '@/context';
import { useUpdateBlogSelectionAndLocale } from '@/hooks';

import { BlogTag, HeaderTheme } from '../header.types';

type HeaderNavBlogSelectProps = {
  blogTags?: BlogTag[] | null | undefined;
  color?: ButtonProps['color'];
  headerTheme: HeaderTheme;
};

export const HeaderNavBlogSelect: React.FC<HeaderNavBlogSelectProps> = ({
  blogTags,
  color,
  headerTheme,
}) => {
  const router = useRouter();
  const { messages } = useContext(AppContext);
  const allBlogTag = messages?.all?.toLowerCase();
  const { onBlogCategorySelect } = useUpdateBlogSelectionAndLocale(
    router,
    allBlogTag,
  );

  return (
    <BlogSelect
      color={color}
      isMobile={false}
      blogTags={blogTags}
      allBlogTag={messages?.all}
      onBlogCategorySelect={onBlogCategorySelect}
      headerVariant={headerTheme}
      router={router}
    />
  );
};
