export enum DROPDOWN {
  SEND_FROM_COUNTRY = 'send from country',
  SEND_TO_COUNTRY = 'send to country',
  LANGUAGE_SELECTOR = 'language selector',
  MY_ACCOUNT = 'my account dropdown',
  COUNTRY_REGULATOR_SELECTOR = 'country regulator selector',
  COUNTRY_SELECTOR = 'country selector',
}

export enum EVENT {
  VISITOR_INTERACTION = 'visitor interaction',
  VIRTUAL_PAGE_VIEW = 'virtual page view',
  EXPERIMENT = 'AB Test',
  CALCULATOR_INTERACTION = 'calculator_interaction',
  PAGE_LOADED = 'page_loaded',
}

export enum ECOMMERCE_TYPE {
  DETAIL = 'detail',
  ADD = 'add',
}

export enum CALCULATOR_TYPE {
  AIRTIME = 'airtime',
  MONEY_TRANSFER = 'money transfer',
}

export enum INTERACTION_NAME {
  AIRTIME_CALCULATION = 'airtime calculation',
  CALC_CANCEL_CLICK = 'cancel calculation click',
  CALC_DIAL_CODE_CLICK = 'country code click',
  CALC_ERROR = 'calculator error',
  CALC_OPEN_DROPDOWN = 'calc open dropdown',
  CALC_PHONE_NUMBER_CLICK = 'phone number click',
  CALC_PHONE_NUMBER_INVALID = 'invalid phone number',
  CALC_PHONE_NUMBER_VALID = 'valid phone number',
  CALC_PHONE_NUMBER_VALIDATION = 'phone number entry',
  CALC_SEARCH_DROPDOWN = 'calculator search in dropdown',
  CALC_SELECT_DROPDOWN = 'calc select dropdown',
  COMMENCE_PROCESS = 'commence process',
  DOWNLOAD_APP_CLICK = 'download app click',
  DROPDOWN_OPEN = 'dropdown open',
  DROPDOWN_SELECTION = 'dropdown selection',
  FAQ_KEY_INTERACTION = 'faq key interaction',
  FRIEND_SIGN_UP_BUTTON = 'friend signup button',
  FX_SEND_BAND_BANNER = 'fx send band banner',
  HEADER_BLOG_CATEGORY_CLICK = 'category blog header click',
  LITE_CALC_CALCULATION = 'lite calc calculation',
  LITE_CALC_CONTINUE = 'lite calc continue',
  LITE_CALC_ERROR = 'lite calc error',
  LITE_CALC_OPEN_DROPDOWN = 'lite calc open dropdown',
  LITE_CALC_SEARCH_DROPDOWN = 'lite calc search in dropdown',
  LITE_CALC_SELECT_DROPDOWN = 'lite calc select dropdown',
  MONEY_TRANSFER_CALCULATION = 'money transfer calculation',
  REFER_FRIEND_BUTTON = 'refer friend button',
  SELECT_HP_VERSION = 'select homepage version',
  SWITCH_SERVICE_TAB = 'switch service tab',
  TOP_NAVIGATION_CLICK = 'top navigation click',
}

export enum VARIABLE {
  CALC_AMOUNT_FIELD = 'amountField',
  CALC_CALCULATOR_TYPE = 'calculatorType',
  CALC_CORRIDOR = 'id',
  CALC_PAYOUT_METHOD = 'name',
  CALC_TOTAL_TO_PAY = 'price',
  CALC_SEND_OR_RECEIVE = 'brand',
  CALC_QUANTITY = 'quantity',
  CALC_CORRIDOR_WITH_CURRENCIES = 'category',
  CALC_EXCHANGE_RATE = 'variant',
  CALC_CURRENCY_CORRIDOR = 'dimension14',
  CALC_CORRESPONDENT = 'dimension15',
  CALC_FIRST_TIME = 'dimension93',
  CALC_PROMOTION = 'dimension95',
  CALC_PROMOTION_NAME = 'dimension96',
  CALC_TRANSFER_TIME = 'dimension104',
  CALC_SEND_AMOUNT = 'metric17',
  CALC_RECEIVE_AMOUNT = 'metric18',
  CALC_FEES = 'metric19',
  CALC_DROPDOWN_NAME = 'calcDropdownName',
  CALC_DROPDOWN_VALUE = 'calcDropdownValue',
  CALC_DROPDOWN_SEARCH_VALUE = 'calcDropdownSearchValue',
  CALC_AIRTIME_DROPDOWN_VALUE = 'airtimeValue',
  CALC_MESSAGE = 'calcMessage',

  LITE_CALC_CORRIDOR = 'liteCalcCorridor',
  LITE_CALC_CURRENCY_CORRIDOR = 'liteCalcCurrencyCorridor',
  LITE_CALC_SEND_OR_RECEIVE = 'liteCalcSendOrReceive',
  LITE_CALC_FX_RATE = 'liteCalcFxRate',
  LITE_CALC_AMOUNT = 'liteCalcAmount',
  LITE_CALC_PAYOUT_METHOD = 'liteCalcServiceType',
  LITE_CALC_DROPDOWN_NAME = 'liteCalcDropdownName',
  LITE_CALC_DROPDOWN_VALUE = 'liteCalcDropdownValue',
  LITE_CALC_DROPDOWN_SEARCH_VALUE = 'liteCalcDropdownSearchValue',
  LITE_CALC_FIELD_NAME = 'liteCalcFieldName',
  LITE_CALC_MESSAGE = 'liteCalcMessage',
  FAQ_INTERACTION_TYPE = 'faqInteractionType',
  FAQ_INTERACTION_VALUE = 'faqInteractionValue',
  FAQ_INTERACTION_DEFAULT_SEARCH_VALUE = 'faqInteractionDefaultSearchValue',
  FAQ_INTERACTION_SEARCH_KEYWORD = 'faqInteractionSearchKeyword',
}

export enum LINK_LOCATION {
  BODY = 'body',
}

export enum LINK_TYPE {
  LINK = 'link',
}

export enum FAQ_INTERACTION_TYPE {
  FEEDBACK_BUTTON_CLICK = 'feedback button click',
  SEARCH = 'search',
  FAQ_NAVIGATION_LINK = 'faq navigation link',
}

export enum FAQ_INTERACTION_VALUE {
  YES = 'yes',
  NO = 'no',
  NO_ARTICLE_SELECTED = 'no article selected',
}

export enum APP_NAME {
  ANDROID = 'android',
  IOS = 'ios',
}

export enum HP_VERSION {
  NEW = 'try new site',
  CURRENT = 'use current site',
}

export enum REFER_BUTTON_NAME {
  COPY = 'copy',
}

export enum ANALYTICS_PAGE_TYPE {
  Account = 'account',
  Blog = 'blog',
  Brand = 'brand',
  Company = 'company',
  Country = 'country',
  CountryOperator = 'country operator',
  CountryService = 'country service',
  ErrorPage = 'error page',
  FAQ = 'faq',
  Homepage = 'homepage',
  Identity = 'identity',
  InstantWin = 'instant win',
  MyAccount = 'my account',
  News = 'news',
  Operator = 'operator',
  PromoMessage = 'promo message',
  RecipientPages = 'recipient pages',
  SignupPage = 'signuppage',
  TransferProcess = 'transfer process',
  Website = 'website',
  CurrencyConverter = 'currency converter',
}
