import { bffSchema } from '@wr/web-shared';
import { ReferAFriendMessages } from '@wr/web-ui';

const BASE_SLUG = 'refer-a-friend';
const SINGED_UP_SLUG = 'signed-up';

export const getReferAFriendMessages = (
  referralScheme?: bffSchema.GetReferralSchemeQuery['referralScheme'],
  referrerName?: string | null,
): ReferAFriendMessages | Record<string, never> => {
  if (!referralScheme) {
    return {};
  }

  return {
    'referFriend.rewardAmount': `${referralScheme?.reward?.amount} ${referralScheme?.reward?.currency}`,
    'referFriend.minSendAmount': `${referralScheme?.qualifyingConditions?.minimalTransfer?.amount} ${referralScheme?.qualifyingConditions?.minimalTransfer?.currency}`,
    'referFriend.rewardAmountForReferee': `${referralScheme?.rewardForReferee?.amount} ${referralScheme?.rewardForReferee?.currency}`,
    'referFriend.referrerName': referrerName ?? '',
  };
};

interface GetRAFPageData {
  slug: string;
  requireAuthentication: boolean;
}

export const getRAFPageData = (
  querySlug?: string | string[],
): GetRAFPageData => {
  if (!querySlug) {
    return {
      slug: BASE_SLUG,
      requireAuthentication: false,
    };
  }

  const arraySlug = Array.isArray(querySlug) ? querySlug : [querySlug];

  return {
    slug: `${BASE_SLUG}/${arraySlug.join('/')}`,
    requireAuthentication: arraySlug.includes(SINGED_UP_SLUG),
  };
};
