import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CountrySelectStyleProps } from './country-select.types';

export const useStyles = makeStyles<Theme, CountrySelectStyleProps>(theme => ({
  selectIcon: {
    marginRight: theme.spacing(0.5),
  },
  field: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    width: '100%',
    outline: 'none',
  },
  flag: {
    display: 'flex',
    width: 'auto',
    height: '1.25em',
    boxShadow: ({ countryFlagType }: CountrySelectStyleProps) =>
      countryFlagType === 'rectangle'
        ? `0 0 1px 1px ${theme.customComponents.countrySelect.flag.boxShadow}`
        : undefined,
  },
}));
