import { Container as UIContainer } from '@wr/web-ui';
import React from 'react';

import { buildContentfulSidekickAttributes } from '@/utils';

import { useStyles } from './sitemap.styles';
import { SitemapProps } from './sitemap.types';
import { getLinksByPaths } from './sitemap.utils';
import { SitemapList } from './sitemap-list';

export const Sitemap: React.FC<SitemapProps> = ({
  sitemapLinksSlugsAndTitles,
  sys,
  name,
  __typename,
}) => {
  const classes = useStyles();
  const linksByPaths = getLinksByPaths(sitemapLinksSlugsAndTitles);
  return (
    <section
      id={name || undefined}
      className={classes.section}
      {...buildContentfulSidekickAttributes(sys?.id, __typename, name)}
    >
      <UIContainer>
        <SitemapList linksByPaths={linksByPaths} level={0} />
      </UIContainer>
    </section>
  );
};
