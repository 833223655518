import throttle from 'lodash.throttle';
import React, { useEffect, useState } from 'react';

import { UseMoreButton } from './sticky-banner-content.types';

export const useMoreButton = (
  isCollapsible: boolean,
  contentRef: React.RefObject<HTMLDivElement>,
): UseMoreButton => {
  const [isCollapsed, setCollapseState] = useState<boolean>(true);
  const [isVisible, setVisibility] = useState<boolean>(false);
  const [currentClientWidth, setClientWidth] = useState<number>(0);

  const checkVisibility = ({
    current: element,
  }: React.RefObject<HTMLDivElement>): void => {
    if (!element) {
      return;
    }

    const { scrollHeight } = element;
    const styles = window.getComputedStyle(element) || {};
    const minHeight = parseInt(styles.minHeight, 10) || 0;
    const maxHeight = parseInt(styles.maxHeight, 10) || Number.MAX_VALUE;
    const isHigherThanMinHeight = minHeight < scrollHeight;
    const isHigherThanMaxHeight = maxHeight < scrollHeight;

    setVisibility(minHeight ? isHigherThanMinHeight : isHigherThanMaxHeight);
  };

  useEffect(() => {
    if (!isCollapsible) {
      return;
    }
    const resizeListener = (): void => {
      const { clientWidth } = document.body;

      if (currentClientWidth !== clientWidth) {
        checkVisibility(contentRef);
        setClientWidth(clientWidth);
      }
    };

    resizeListener();

    const throttledResize = throttle(resizeListener, 100);

    window.addEventListener('resize', throttledResize);

    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, [isCollapsible, contentRef, currentClientWidth]);

  return { isVisible, setVisibility, isCollapsed, setCollapseState };
};
