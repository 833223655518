import { Typography } from '@mui/material';
import React from 'react';

import useStyles from './exchange-rate.styles';
import { ExchangeRateProps } from './exchange-rate.types';
import { getExchangeRateText } from './exchange-rate.utils';
import { ExchangeRateText } from './exchange-rate-text';

export const ExchangeRate: React.FC<ExchangeRateProps> = ({
  isFetching,
  exchangeRate,
  fromCurrency,
  toCurrency,
  isPromo,
  exchangeRatePromoLabel,
}) => {
  const classes = useStyles();

  if (isPromo && exchangeRate?.crossedOutValue) {
    return (
      <div
        className={classes.exchangeRateWrapper}
        data-testid="exchange-rate-with-promotion"
      >
        <Typography
          className={classes.promoLabel}
          data-testid="exchange-rate-promo-label"
        >
          {exchangeRatePromoLabel}
        </Typography>
        <Typography
          className={classes.exchangeRate}
          color="primary"
          data-testid="exchange-rate-promo-label-converter"
        >
          <strong>
            1 {fromCurrency} ={' '}
            <s className={classes.crossedExchangeRate}>
              {exchangeRate?.crossedOutValue} {toCurrency}
            </s>{' '}
            {exchangeRate?.value} {toCurrency}
          </strong>
        </Typography>
      </div>
    );
  }

  const exchangeRateText = getExchangeRateText(
    exchangeRate,
    isFetching,
    fromCurrency,
    toCurrency,
  );

  return (
    <ExchangeRateText
      exchangeRateText={exchangeRateText}
      isFetching={isFetching}
      isTop={false}
    />
  );
};
