import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  'container': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4.5),
  },
  'circle': {
    backgroundColor:
      theme.customComponents.video.playButton.circle.backgroundColor,
    opacity: 0.4,
    borderRadius: '50%',
    width: '7.5rem',
    height: '7.5rem',
  },
  'icon': {
    position: 'absolute',
    color: theme.customComponents.video.playButton.icon.color,
    width: '5rem',
    height: '5rem',
  },
  'circleAnimation': {
    position: 'absolute',
    backgroundColor:
      theme.customComponents.video.playButton.circleAnimation.backgroundColor,
    opacity: 0,
    borderRadius: '50%',
    width: '7.5rem',
    height: '7.5rem',
    transform: 'scaleX(0.8) scaleY(0.8)',
    animation: '$playButton 5s infinite ease-out ',
  },
  '@keyframes playButton': {
    '0%, 100%, 30%': {
      opacity: 0,
      transform: 'scaleX(0.8) scaleY(0.8)',
    },
    '15%': {
      opacity: 0.2,
    },
    '20%': {
      opacity: 0.9,
      transform: 'scaleX(1.1) scaleY(1.1)',
    },
    '25%': {
      opacity: 0,
      transform: 'scaleX(1.2) scaleY(1.2)',
    },
  },

  /* Contained Video Styles */
  'containedContainer': {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  'containedCircle': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor:
      theme.customComponents.video.playButton.circle.backgroundColor,
    opacity: 0.4,
    borderRadius: '50%',
    width: '7.5rem',
    height: '7.5rem',
  },
  'containedIcon': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.customComponents.video.playButton.icon.color,
    width: '5rem',
    height: '5rem',
  },
  'containedCircleAnimation': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor:
      theme.customComponents.video.playButton.circleAnimation.backgroundColor,
    opacity: 0,
    borderRadius: '50%',
    width: '7.5rem',
    height: '7.5rem',
    animation: '$playButton 5s infinite ease-out ',
  },
}));
