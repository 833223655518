import { ContainerProps } from '@mui/material';
import { ThemeBreakpoints } from '@wr/web-ui';
import clsx from 'clsx';

import { Container as UIContainer } from '../container';
import { Image } from '../image';
import { useStyles } from './text-section.styles';
import { TextSectionProps } from './text-section.types';

export function TextSection<RichText>({
  name,
  content,
  image,
  isOrderReversed,
  RichTextComponent,
  containerProps,
  maxWidth,
  backgroundColor,
}: TextSectionProps<RichText>) {
  const classes = useStyles({ image, backgroundColor, isOrderReversed });

  return (
    <section id={name} {...containerProps} className={classes.textSection}>
      {image && (
        <div
          className={clsx(classes.textSectionColumn, classes.textSectionImage)}
          data-testid="text-section-image"
        >
          <Image
            {...image}
            sizes={`(max-width:${ThemeBreakpoints.tablet}px) 100vw, 50vw`}
          />
        </div>
      )}
      <div
        className={clsx(classes.textSectionColumn, classes.textSectionContent)}
        data-testid={`text-section-content`}
      >
        {content && (
          <UIContainer maxWidth={maxWidth as ContainerProps['maxWidth']}>
            <RichTextComponent {...content} />
          </UIContainer>
        )}
      </div>
    </section>
  );
}
