import {
  contentfulSchema,
  DROPDOWN,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
} from '@wr/web-shared';
import {
  CountrySelectProps,
  WeAreRegulated as UIWeAreRegulated,
} from '@wr/web-ui';
import React from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { useIntlMessages } from '@/hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

import { useCountrySelect } from './we-are-regulated.hooks';
import { getRegulators } from './we-are-regulated.selectors';

export const WeAreRegulated = ({
  __typename,
  sys,
  regulatorsCollection,
  name,
  title,
  description,
  ...props
}: contentfulSchema.WeAreRegulatedFragment): React.ReactElement | null => {
  const intlMessages = useIntlMessages();

  const filteredRegulators = getRegulators(regulatorsCollection);
  const { selectedCountry, setCountry, selectCountries } = useCountrySelect(
    filteredRegulators,
  );

  const dataLayerProps = {
    event: EVENT.VISITOR_INTERACTION,
    dropdownName: DROPDOWN.SEND_TO_COUNTRY,
  };

  const onChange = (country: Parameters<CountrySelectProps['onChange']>[0]) => {
    setCountry(country);
    pushToDataLayer({
      ...dataLayerProps,
      interactionName: INTERACTION_NAME.DROPDOWN_SELECTION,
      dropdownValue: country?.code.toLowerCase(),
    });
  };

  const onOpen = () => {
    pushToDataLayer({
      ...dataLayerProps,
      interactionName: INTERACTION_NAME.DROPDOWN_OPEN,
    });
  };

  const countrySelectProps: CountrySelectProps = {
    name: 'we-are-regulated-select',
    countries: selectCountries,
    onChange,
    onOpen,
    selectedCountry,
    countryFlagType: 'circle',
  };

  return (
    <UIWeAreRegulated<RichTextProps>
      {...props}
      name={name || undefined}
      title={interpolate(title, intlMessages)}
      description={interpolate(description, intlMessages)}
      RichTextComponent={RichTextContentRenderer}
      regulators={filteredRegulators}
      countrySelectProps={countrySelectProps}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name || undefined,
      )}
    />
  );
};
