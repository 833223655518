import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  flagFlexWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: spacing(0.5),
  },
  flagArrow: {
    color: colors.greenDark,
  },
  flag: {
    width: spacing(5),
    height: spacing(5),
  },
}));
