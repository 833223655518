import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { Button } from '../button';
import { useStyles } from './information-dialog.styles';
import { InformationDialogProps } from './information-dialog.types';

export const InformationDialog = <T,>({
  name,
  content,
  isOpen,
  onClose,
  RichTextComponent,
  classes,
  title,
}: InformationDialogProps<T>): ReactElement => {
  const localClasses = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: clsx(localClasses.paper, classes?.paper) }}
      id={name}
    >
      <DialogContent
        classes={{
          root: clsx(localClasses.dialogContent, classes?.dialogContent),
        }}
      >
        <Button
          className={clsx(localClasses.closeButton, classes?.closeButton)}
          onClick={onClose}
          data-testid="close-button"
        >
          <CloseIcon />
        </Button>
        {title && (
          <Typography variant="h3" className={localClasses.title}>
            {title}
          </Typography>
        )}
        <RichTextComponent
          {...content}
          className={clsx(
            localClasses.richTextContent,
            classes?.richTextContent,
          )}
          classNameMap={{
            richTextEntryLink: clsx(
              localClasses.link,
              classes?.richTextContent,
            ),
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
