import { InterpolateMessageProps } from './parse-errors.types';

export const SEND_COUNTRY_SELECTOR = '[send-country]';
export const RECEIVE_COUNTRY_SELECTOR = '[receive-country]';
export const PAYOUT_METHOD_SELECTOR = '[payout-method]';

export const interpolateErrorMessage = ({
  message,
  payoutMethodName = '',
  receiveCountryName = '',
  sendCountryName = '',
}: InterpolateMessageProps) => {
  return message
    .replace(PAYOUT_METHOD_SELECTOR, payoutMethodName)
    .replace(SEND_COUNTRY_SELECTOR, sendCountryName)
    .replace(RECEIVE_COUNTRY_SELECTOR, receiveCountryName);
};
