import { contentfulSchema } from '@wr/web-shared';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '@/context';

import { createChatbotScript } from './chatbot.script';
import { initializeIntercomChat, shutDownIntercomChat } from './chatbot.utils';

export const Chatbot: React.FC<Pick<contentfulSchema.ExternalScript, 'id'>> = ({
  id,
}) => {
  const { accountDetails } = useContext(AppContext);
  const [isChatbotScriptReady, setIsChatbotScriptReady] = useState(false);

  useEffect(() => {
    if (id) {
      createChatbotScript(id, (): void => setIsChatbotScriptReady(true));

      return shutDownIntercomChat;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && isChatbotScriptReady) {
      initializeIntercomChat(accountDetails, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatbotScriptReady]);

  return null;
};
