import { Button, Typography } from '@mui/material';
import { useState } from 'react';

import { SelectedCurrency } from '../back-to-school';
import useStyles from './currency-controller.styles';
import { CurrencyControllerProps } from './currency-controller.types';

export const CurrencyController: React.FC<CurrencyControllerProps> = ({
  countriesCollection,
  handleCurrencyChange,
  chooseCurrencyLabel,
}) => {
  const defaultCurrency = countriesCollection?.items?.find(
    country => country?.currency === 'USD',
  );
  const [selectedCurrency, setSelectedCurrency] = useState<SelectedCurrency>({
    currency: defaultCurrency?.currency || '',
    currencySymbol: defaultCurrency?.currencySymbol || '',
    exchangeRate: 1,
  });
  const classNames = useStyles();
  if (
    !Array.isArray(countriesCollection?.items) ||
    !countriesCollection?.items.length
  ) {
    return null;
  }

  const currencies = [
    ...new Set(
      countriesCollection.items
        .map(country => country?.currency)
        .filter(item => item),
    ),
  ];

  const handleSelect = (currencyKey: string): void => {
    const country = countriesCollection.items.find(
      _country => _country?.currency === currencyKey,
    );

    const currency = {
      currency: country?.currency || '',
      currencySymbol: country?.currencySymbol || '',
      exchangeRate: country?.exchangeRate || 1,
    };

    handleCurrencyChange(currency);
    setSelectedCurrency(currency);
  };

  return (
    <section
      className={classNames.currencySection}
      data-testid="currency-controller"
    >
      <section className={classNames.currencyFlexBox}>
        <section className={classNames.currencyFlexBoxInner}>
          <Typography variant="subtitle2" data-testid="choose-current-label">
            {chooseCurrencyLabel}
          </Typography>
        </section>
        <section className={classNames.currencySelectBox}>
          {currencies.map(currency => (
            <Button
              key={`currency-controller-${currency}`}
              onClick={() => handleSelect(currency ?? '')}
              className={classNames.currencyButton}
            >
              <input
                className={classNames.currencyItemInput}
                type="radio"
                name="exchange"
                id={`back-to-school-currency-${currency}`}
                value={currency ?? ''}
                checked={selectedCurrency.currency === currency}
                onChange={() => handleSelect(currency ?? '')}
                data-testid={`choose-currency-input${currency}`}
              />
              <label
                htmlFor={`back-to-school-currency-${currency}`}
                className={classNames.currencyItemLabel}
                data-testid={`choose-currency-label-${currency}`}
              >
                <Typography variant="button">{currency}</Typography>
              </label>
            </Button>
          ))}
        </section>
      </section>
    </section>
  );
};
