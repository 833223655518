import ArrowIcon from '@mui/icons-material/ArrowForwardSharp';
import { CountryFlag } from '@wr/web-ui';
import React from 'react';

import { useStyles } from './cex-country-flag.styles';
import { CexCountryFlagProps } from './cex-country-flag.types';

export const CexCountryFlag: React.FC<CexCountryFlagProps> = ({
  countryCode,
  sendCountryCode,
}) => {
  const classes = useStyles();

  if (!sendCountryCode) {
    return <CountryFlag className={classes.flag} code={countryCode} />;
  }

  return (
    <div
      data-testid="cex-country-flags-wrapper"
      className={classes.flagFlexWrapper}
    >
      <CountryFlag className={classes.flag} code={sendCountryCode} />
      <ArrowIcon className={classes.flagArrow} />
      <CountryFlag className={classes.flag} code={countryCode} />
    </div>
  );
};
