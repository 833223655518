import { PageProps } from '../../../types/data-interfaces';
import { IntercomData } from './chatbot.types';

export const getDataForIntercom = (
  userData: PageProps['accountDetails'],
  appId: string,
): IntercomData => {
  return userData?.reference
    ? {
        'app_id': appId,
        'email': userData.email?.email || undefined,
        'name':
          `${userData.firstName || ''} ${userData.lastName || ''}`.trim() ||
          undefined,
        // 'user_id': userData.intercomIdentity.externalUserId,
        // 'user_hash': userData.intercomIdentity.externalUserIdHash,
        // 'Legacy user ID': userData.intercomIdentity.legacyUserId,
        'Customer ID': userData.reference || undefined,
      }
    : {
        app_id: appId,
      };
};

export const initializeIntercomChat = (
  userData: PageProps['accountDetails'],
  appId: string,
): void => {
  const w = window;

  if (w.Intercom) {
    const data = getDataForIntercom(userData, appId);

    w.Intercom('boot', { ...data });
  }
};

export const showIntercomChat = (): void => {
  window.Intercom?.('show');
};

export const shutDownIntercomChat = (): void => {
  window.Intercom?.('shutdown');
};
