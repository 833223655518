import { ICountryData } from '@/components/calculator/src/types';

import { CALCULATOR_INPUT_VALUE_WITH_TWO_DIGITS } from '../../core/core.constants';

export const getInputValue = (value: string): string | null => {
  const isValueEndsWithSingleDot =
    value.split('.').length - 1 === 1 && value.endsWith('.');

  if (CALCULATOR_INPUT_VALUE_WITH_TWO_DIGITS.test(value)) {
    return value;
  }
  if (isValueEndsWithSingleDot) {
    const replacedValue = value.replace(/^0+/, '');

    if (replacedValue.length === 1 && replacedValue === '.') {
      return '0.';
    }

    return replacedValue;
  }

  return null;
};

export const isCountryCodeEqual = (
  country: ICountryData,
  countryCode?: string,
) => country.countryCode.toLowerCase() === countryCode?.toLowerCase();

export const isCountryCodeAndCurrencyCodeEqual = (
  country: ICountryData,
  countryCode?: string,
  currencyCode?: string,
) =>
  isCountryCodeEqual(country, countryCode) &&
  country.currency.toLowerCase() === currencyCode?.toLowerCase();

export const displayDiscount = (value: string | undefined): boolean => {
  if (!value) {
    return false;
  }

  return parseInt(value) < 0;
};
