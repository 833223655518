import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = makeStyles<Theme>(({ palette, zIndex, spacing }) => ({
  pxElement: {
    'borderRadius': 'inherit',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'center',
    'textAlign': 'center',
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'height': '100%',
    'padding': spacing(1.5),
    'color': palette.primary.contrastText,
    'zIndex': zIndex.mobileStepper,

    '&.visible': {
      display: 'flex',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
      background: colors.purpleNormal,
      opacity: 0.9,
      zIndex: -1,
    },
  },
}));

export default useStyles;
