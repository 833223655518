import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { getBackground } from '../../utils';
import { IconGridProps } from './icon-grid.types';

const useStyles = makeStyles<Theme>(theme => ({
  section: ({
    backgroundColor,
    secondBackgroundColor,
  }: Pick<IconGridProps, 'backgroundColor' | 'secondBackgroundColor'>) => ({
    textAlign: 'center',
    background: getBackground(backgroundColor, secondBackgroundColor),
    padding: theme.spacing(4, 0),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0),
    },
  }),
  heading: {
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h1,
      marginBottom: theme.spacing(4),
    },
  },
  iconGrid: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 9),
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  itemImage: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  itemHeading: {
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h2,
    },
  },
}));

export default useStyles;
