import { DEFAULT_LOCALE } from '@wr/web-shared';
import {
  getIntlMessages,
  GetIntlMessagesResult,
  ReferAFriendMessages,
} from '@wr/web-ui';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import { AppContext, CalculatorContext } from '@/context';
import { getReferAFriendMessages } from '@/utils';

export function useIntlMessages():
  | GetIntlMessagesResult
  | ReferAFriendMessages
  | Record<string, never> {
  const { locale = DEFAULT_LOCALE } = useRouter();

  const {
    sendCountries,
    receiveCountries,
    corridorPayoutMethodsIds,
    selectedPayoutMethodId,
    intl,
  } = useContext(AppContext);

  const [{ sendCountry, receiveCountry, calculationAmount }] = useContext(
    CalculatorContext,
  );

  return useMemo(
    () => {
      if (!sendCountry?.countryCode || !receiveCountry?.countryCode) {
        return {
          ...getReferAFriendMessages(intl.referralScheme, intl.referrerName),
        };
      }
      const currentDate = new Date();

      return {
        ...getIntlMessages({
          sendCountry,
          receiveCountry,
          sendCountries,
          receiveCountries,
          payoutMethodsLabels: intl.payoutMethodsLabelsById,
          countryArticlesAndPrepositions: intl.countryArticlesAndPrepositions,
          selectedPayoutMethodId: selectedPayoutMethodId || null,
          corridorPayoutMethodsIds,
          currentDate,
          locale,
        }),

        // for cex pages
        sendCurrencyCode: sendCountry.currency,
        sendCurrencyName: sendCountry.currencyName,
        receiveCurrencyCode: receiveCountry.currency,
        receiveCurrencyName: receiveCountry.currencyName,
        sendAmount: calculationAmount,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      receiveCountry?.countryCode,
      sendCountry?.countryCode,
      selectedPayoutMethodId,
      locale,
    ],
  );
}
