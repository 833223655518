import throttle from 'lodash.throttle';
import React, { RefObject, useEffect, useMemo, useState } from 'react';

import { isClient } from '../../utils';
import { HeaderProps, UseMenuToggler } from './header.types';

export const useMenuToggler = (): UseMenuToggler => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ): void => setAnchorEl(event.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);

  return { isOpen, anchorEl, handleOpen, handleClose };
};

export function useLinkVisibilityByIndex<Anchor, Button>({
  isAuthenticated,
  isAuthenticatingInProgress,
  headerLinksRef,
  myAccount,
  links,
  language,
}: Pick<
  HeaderProps<Anchor, Button>,
  | 'isAuthenticatingInProgress'
  | 'isAuthenticated'
  | 'language'
  | 'links'
  | 'myAccount'
> & {
  headerLinksRef: RefObject<HTMLDivElement>;
}): boolean[] {
  const [linkVisibilityByIndex, setLinkVisibilityByIndex] = React.useState<
    boolean[]
  >([]);
  const [width, setWidth] = React.useState<number>(
    isClient() ? window.innerWidth : 0,
  );
  // get array of sum of link and all following links widths e.g.
  // ['Live Updates', 'Help' 'Sign Up'] => [120, 40, 80] => [120, 160, 240] => [240, 160, 120]
  const initialLinkWidths = useMemo(
    () =>
      links
        // calculate link width by its label including padding and gap
        .map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (el, i) => (el as any)?.children?.length * 9 + (i > 0 ? 20 : 0) + 24,
        )
        .concat(
          !isAuthenticatingInProgress && isAuthenticated && myAccount
            ? myAccount.length * 9 + 20 + 24
            : 0,
        )
        .reverse()
        .reduce((acc: number[], el, i) => {
          acc.push((acc[i - 1] || 0) + el);
          return acc;
        }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticatingInProgress, isAuthenticated, language],
  );

  useEffect(() => {
    const navDomRect = headerLinksRef.current?.getBoundingClientRect();
    // check if wrapping parent width exceeds any links width
    // to hide that element on desktop and display on mobile resolution, e.g.
    // for initialLinkWidths of [240, 160, 120] and nav width of 360 return [true, true, true]
    // for initialLinkWidths of [240, 160, 120] and nav width of 200 return [false, true, true]
    const newLinkVisibilityByIndex = initialLinkWidths
      .map(w => {
        return (navDomRect?.width || 0) >= w;
      })
      .reverse();

    setLinkVisibilityByIndex(newLinkVisibilityByIndex);

    // don't include initialLinkWidths in deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticatingInProgress,
    isAuthenticated,
    width,
    language,
    headerLinksRef.current,
  ]);

  useEffect(() => {
    const resizeListener = (): void => {
      setWidth(window.innerWidth);
    };

    resizeListener();
    window.addEventListener('resize', throttle(resizeListener, 100));

    return () => {
      window.removeEventListener('resize', throttle(resizeListener, 100));
    };
  }, []);

  return linkVisibilityByIndex;
}
