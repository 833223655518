type BuildContentfulSidekickAttributesResult = Partial<{
  'data-csk-entry-id': string;
  'data-csk-entry-type': string;
  'data-csk-entry-display-text': string;
}>;

export const buildContentfulSidekickAttributes = (
  id?: string,
  type?: string,
  displayText?: string | null,
): BuildContentfulSidekickAttributesResult => ({
  'data-csk-entry-id': id,
  'data-csk-entry-type': type,
  'data-csk-entry-display-text': displayText || undefined,
});
