import { SelectOption } from '@wr/web-ui';

import {
  PayoutMethodProps,
  PayoutMethodSelectOptionProps,
} from '@/components/calculator/src/composition/payout-method/payout-method.types';
import { AppContextType } from '@/context';

import { CalculatorState } from '../../types';

// MOVE: make client responsible for providing list of payout method alerts for current 'country corridor'/'payout method'
export const filterPayoutMethodAlerts = (
  payoutMethodAlerts: CalculatorState['payoutMethodAlerts'],
  payoutMethods: CalculatorState['payoutMethods'],
  sendCountry: CalculatorState['sendCountry'],
  receiveCountry: CalculatorState['receiveCountry'],
): CalculatorState['payoutMethodAlerts'] => {
  const isCountryCorridorAlert = (
    payoutMethod: CalculatorState['payoutMethodAlerts'][0],
  ): boolean =>
    Boolean(
      payoutMethod?.sendCountryList?.length &&
        payoutMethod?.receiveCountryList?.length &&
        payoutMethod?.sendCountryList.includes(
          sendCountry?.countryCode?.toLowerCase?.() ?? '',
        ) &&
        payoutMethod?.receiveCountryList.includes(
          receiveCountry?.countryCode?.toLowerCase?.() ?? '',
        ),
    );

  const isSendToCountryAlert = (
    payoutMethod: CalculatorState['payoutMethodAlerts'][0],
  ): boolean =>
    Boolean(
      !payoutMethod?.sendCountryList?.length &&
        payoutMethod?.receiveCountryList?.length &&
        payoutMethod?.receiveCountryList.includes(
          receiveCountry?.countryCode?.toLowerCase?.() ?? '',
        ),
    );

  const isSendFromCountryAlert = (
    payoutMethod: CalculatorState['payoutMethodAlerts'][0],
  ): boolean =>
    Boolean(
      !payoutMethod?.receiveCountryList?.length &&
        payoutMethod?.sendCountryList?.length &&
        payoutMethod?.sendCountryList.includes(
          sendCountry?.countryCode?.toLowerCase?.() ?? '',
        ),
    );

  const payoutMethodsCodes =
    payoutMethods?.map(payoutMethod => payoutMethod?.code) || [];

  return payoutMethodAlerts?.filter(payoutMethod => {
    const payoutMethodAlertCodes = payoutMethod?.payoutMethodsCollection?.items?.map(
      payoutMethod => payoutMethod?.payoutMethodId,
    );

    return (
      (isCountryCorridorAlert(payoutMethod) ||
        isSendToCountryAlert(payoutMethod) ||
        isSendFromCountryAlert(payoutMethod)) &&
      payoutMethodsCodes.some(payoutMethodsCode =>
        payoutMethodAlertCodes?.includes(payoutMethodsCode),
      )
    );
  });
};

export const getPayoutMethodSelectOptions = (
  payoutMethods: CalculatorState['payoutMethods'],
  contentfulPayoutMethodsList: PayoutMethodProps['contentfulPayoutMethodsList'],
): (PayoutMethodSelectOptionProps & SelectOption)[] => {
  return payoutMethods
    .map(payoutMethod => {
      const contentfulPayoutMethod = contentfulPayoutMethodsList?.items.find(
        contentfulPayoutMethod =>
          contentfulPayoutMethod?.payoutMethodId === payoutMethod?.code,
      );

      if (contentfulPayoutMethod && payoutMethod?.code) {
        return {
          value: payoutMethod.code,
          label: contentfulPayoutMethod?.name || '',
          description: contentfulPayoutMethod?.description || '',
          transferTimeTooltip:
            contentfulPayoutMethod?.transferTimeTooltip || '',
        };
      }
    })
    .filter(
      (
        payoutMethod:
          | (PayoutMethodSelectOptionProps & SelectOption)
          | undefined,
      ): payoutMethod is PayoutMethodSelectOptionProps & SelectOption =>
        !!payoutMethod,
    ) as (PayoutMethodSelectOptionProps & SelectOption)[];
};

export const getInitialSelectedPayoutMethod = (
  currentPayoutMethodId: AppContextType['selectedPayoutMethodId'],
  payoutMethods: CalculatorState['payoutMethods'],
) => {
  const currentPayoutMethod = payoutMethods.find(
    payoutMethod => payoutMethod?.code === currentPayoutMethodId,
  );
  return currentPayoutMethod?.code ?? payoutMethods[0]?.code ?? null;
};
