import {
  DROPDOWN,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
} from '@wr/web-shared';
import { useCallback } from 'react';

export const useUpdateLanguageSelection = (
  setLanguageToCookie: (language: string) => void,
  reload: () => void,
) => {
  const onLanguageChange = useCallback(
    (value: string) => {
      const dropdownValue = value;

      const GA_EVENT = {
        event: EVENT.VISITOR_INTERACTION,
        dropdownName: DROPDOWN.LANGUAGE_SELECTOR,
        interactionName: INTERACTION_NAME.DROPDOWN_OPEN,
      };
      pushToDataLayer({
        ...GA_EVENT,
        value: dropdownValue,
      });

      setLanguageToCookie(dropdownValue);
      reload();
    },
    [setLanguageToCookie, reload],
  );

  const onLanguageOpen = () => {
    pushToDataLayer({
      event: EVENT.VISITOR_INTERACTION,
      dropdownName: DROPDOWN.LANGUAGE_SELECTOR,
      interactionName: INTERACTION_NAME.DROPDOWN_OPEN,
    });
  };

  return {
    onLanguageChange,
    onLanguageOpen,
  };
};
