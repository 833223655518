import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  container: {
    'paddingTop': spacing(3),
    'paddingBottom': spacing(3),

    [breakpoints.down('md')]: {
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
    },

    [breakpoints.down('sm')]: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },

    '& br': {
      display: 'none',
    },
  },
  searchBarWrapper: {
    'padding': spacing(2, 0),

    '&.MuiFormControl-root-MuiTextField-root, .MuiTextField-root': {
      marginBottom: 0,
    },
  },
}));
