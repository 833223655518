import { ANALYTICS_PAGE_TYPE, bffSchema } from '@wr/web-shared';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import { getValidationCalculationErrorMessages } from '@/components/calculator/component/calculator.utils';
import {
  RECEIVE_COUNTRY_DROPDOWN_NAME,
  SEND_COUNTRY_DROPDOWN_NAME,
} from '@/components/calculator/core/core.constants';
import {
  setDataLayerForDropdownOpen,
  setDataLayerForDropdownSearch,
  setDataLayerForDropdownSelection,
} from '@/components/calculator/core/core.utils';
import { AppContext } from '@/context';

import { CountryField } from '../../components/country-field';
import { ExchangeRate } from '../../components/exchange-rate';
import { CalculatorContext } from '../../context';
import { ICountryData, PayoutMethodsEnum } from '../../types';
import { useCorridorsBySendCountry } from './exchange.hooks';
import { ExchangeProps } from './exchange.types';

// eslint-disable-next-line complexity
const Exchange: React.FC<ExchangeProps> = ({
  isLite,
  countriesSearchPlaceholder,
  sendLabel,
  receiveLabel,
  exchangeRate,
  isFetching,
  isPromo,
  exchangeRatePromoLabel,
}) => {
  const { state, dispatch } = useContext(CalculatorContext);
  const {
    sendCountries,
    selectedPayoutMethodId,
    isAuthenticated,
    analyticsPageType,
    countriesByCode,
  } = useContext(AppContext);
  const router = useRouter();

  const fieldErrorMessages = getValidationCalculationErrorMessages(
    state.errors,
  );
  const sendCountryErrors =
    state.calculationType === bffSchema.CalculationType.Send
      ? fieldErrorMessages
      : [];
  const receiveCountryErrors =
    state.calculationType === bffSchema.CalculationType.Receive
      ? fieldErrorMessages
      : [];
  const isAirtime = selectedPayoutMethodId === PayoutMethodsEnum.AIRTIME_TOP_UP;
  const isLoading = isFetching || state.isLoading;

  const shouldCreateCalculation = isLite || (!isLite && !isAirtime);

  useCorridorsBySendCountry();

  const onSendCountryValueChange = (value: string): void => {
    if (value && value !== state.sendFromValue) {
      dispatch({
        type: 'SET_SEND_AMOUNT',
        payload: {
          sendFromValue: value,
        },
      });

      if (shouldCreateCalculation) {
        dispatch({
          type: 'CREATE_CALCULATION_PENDING',
        });
      }
    }
  };

  const onSendCountryOpen = () =>
    setDataLayerForDropdownOpen(SEND_COUNTRY_DROPDOWN_NAME, isLite);

  const onSendCountrySearched = (
    searchQuery: string,
    selectedCountry: ICountryData | undefined,
  ): void => {
    const value = buildDropdownSearchDataLayerValue(
      searchQuery,
      selectedCountry,
    );

    setDataLayerForDropdownSearch(value, SEND_COUNTRY_DROPDOWN_NAME, isLite);
  };

  const onSendCountryChange = async (newSendCountry: ICountryData) => {
    if (
      state.sendCountry?.countryCode !== newSendCountry.countryCode ||
      state.sendCountry?.currency !== newSendCountry.currency
    ) {
      dispatch({
        type: 'SET_SEND_COUNTRY',
        payload: {
          sendCountry: newSendCountry,
        },
      });

      setDataLayerForDropdownSelection(
        newSendCountry.countryCode.toUpperCase(),
        SEND_COUNTRY_DROPDOWN_NAME,
        isLite,
      );
    }
  };

  const onReceiveCountryValueChange = (value: string): void => {
    if (value && value !== state.sendToValue) {
      dispatch({
        type: 'SET_RECEIVE_AMOUNT',
        payload: {
          sendToValue: value,
        },
      });

      if (shouldCreateCalculation) {
        dispatch({
          type: 'CREATE_CALCULATION_PENDING',
        });
      }
    }
  };

  const onReceiveCountryOpen = () =>
    setDataLayerForDropdownOpen(RECEIVE_COUNTRY_DROPDOWN_NAME, isLite);

  const onReceiveCountrySearched = (
    searchQuery: string,
    selectedCountry: ICountryData | undefined,
  ): void => {
    const value = buildDropdownSearchDataLayerValue(
      searchQuery,
      selectedCountry,
    );

    setDataLayerForDropdownSearch(value, RECEIVE_COUNTRY_DROPDOWN_NAME, isLite);
  };

  const onReceiveCountryChange = (newReceiveCountry: ICountryData) => {
    if (
      state.receiveCountry?.countryCode !== newReceiveCountry.countryCode ||
      state.receiveCountry?.currency !== newReceiveCountry.currency
    ) {
      setDataLayerForDropdownSelection(
        newReceiveCountry.countryCode.toUpperCase(),
        RECEIVE_COUNTRY_DROPDOWN_NAME,
        isLite,
      );

      dispatch({
        type: 'SET_RECEIVE_COUNTRY',
        payload: {
          receiveCountry: newReceiveCountry,
        },
      });

      if (analyticsPageType === ANALYTICS_PAGE_TYPE.Country) {
        const slug = countriesByCode[newReceiveCountry.countryCode]?.slug;

        router
          .push(
            {
              pathname: router.pathname,
              query: {
                ...router.query,
                slug: [slug],
              },
            },
            undefined,
            {
              shallow: false,
            },
          )
          .then(isDone => {
            if (isDone) {
              dispatch({
                type: 'CREATE_CALCULATION_PENDING',
              });
            }
          });
      }
    }
  };

  const buildDropdownSearchDataLayerValue = (
    searchQuery: string,
    selectedCountry: ICountryData | undefined,
  ): string => {
    const countryCode = selectedCountry?.countryCode.toUpperCase();
    const currency = selectedCountry?.currency;
    const selection = selectedCountry ? `${countryCode}-${currency}` : 'none';

    return `query:${searchQuery.toLowerCase()}|selection:${selection}`;
  };

  return (
    <>
      {state.sendCountry && (
        <CountryField
          isLite={isLite}
          name="send-field-container"
          onlyCountrySelect={isAirtime}
          isLoading={isLoading}
          label={sendLabel || ''}
          isDisabled={isLoading}
          disableCountrySelect={isAuthenticated || isLoading}
          value={state.sendFromValue}
          countries={sendCountries}
          countriesSearchPlaceholder={countriesSearchPlaceholder}
          selectedCountry={state.sendCountry}
          onCountryChange={onSendCountryChange}
          onValueChange={onSendCountryValueChange}
          onDropdownOpen={onSendCountryOpen}
          onSearchCompleted={onSendCountrySearched}
          errorMessages={sendCountryErrors}
        />
      )}
      {state.sendCountry?.currency &&
        state.receiveCountry?.currency &&
        !isLite &&
        !isAirtime && (
          <ExchangeRate
            isFetching={isLoading}
            fromCurrency={state.sendCountry.currency}
            toCurrency={state.receiveCountry.currency}
            exchangeRate={exchangeRate}
            isPromo={isPromo}
            exchangeRatePromoLabel={exchangeRatePromoLabel}
          />
        )}
      {state.receiveCountry && (
        <CountryField
          isLite={isLite}
          name="receive-field-container"
          disableCountrySelect={isLoading}
          onlyCountrySelect={isAirtime}
          isLoading={isLoading}
          isDisabled={isLoading}
          label={receiveLabel || ''}
          value={state.sendToValue}
          countries={state.receiveCountries}
          countriesSearchPlaceholder={countriesSearchPlaceholder}
          selectedCountry={state.receiveCountry}
          onCountryChange={onReceiveCountryChange}
          onValueChange={onReceiveCountryValueChange}
          onDropdownOpen={onReceiveCountryOpen}
          onSearchCompleted={onReceiveCountrySearched}
          errorMessages={receiveCountryErrors}
        />
      )}
    </>
  );
};

export default Exchange;
