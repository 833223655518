import type { GetServerSidePropsContext } from 'next/types';

import { QueryParams } from '@/constants';

export function slugify(text: string): string {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function getSingleValueFromQueryParam(
  queryParam: string[] | string | undefined,
): string | undefined {
  if (queryParam) {
    if (Array.isArray(queryParam)) {
      return queryParam[0];
    }
    return queryParam;
  }
  return;
}

export function getPathsFromSlug(slug: string): string[] {
  const splitSlug = slug.split('/');
  const result = [];
  let currentPath = '';

  for (let i = 0; i < splitSlug.length; i++) {
    currentPath += splitSlug[i];
    result.push(currentPath);
    currentPath += '/';
  }

  return result;
}

export function getPageQueryParam(query: GetServerSidePropsContext['query']) {
  const pageQueryParam =
    getSingleValueFromQueryParam(query?.[QueryParams.page]) ?? '';
  const parsedPage = parseInt(pageQueryParam, 10);

  return !isNaN(parsedPage) && parsedPage > 0 ? parsedPage : 1;
}
