export async function executeWithRetry(
  asyncFunc: () => Promise<unknown>,
  attempts: number,
  onFailedAttempt: (attempt: number) => void = () => {},
) {
  const wrappedWithRetry = withRetry(asyncFunc, attempts, onFailedAttempt);
  return await wrappedWithRetry();
}

/**
 * wrap original function with retry logic
 * @param asyncFunc original async function
 * @param attempts number of attempts
 * @param onFailedAttempt can be used for logs etc
 * @returns function that try to call original function 'attempts' times
 */
export function withRetry<T extends Array<unknown>, U>(
  asyncFunc: (...args: T) => Promise<U>,
  attempts: number,
  onFailedAttempt: (attempt: number) => void = () => {},
) {
  return async function wrappedWithRetry(...args: T) {
    for (let currentAttempt = 1; currentAttempt <= attempts; currentAttempt++) {
      try {
        return await asyncFunc(...args);
      } catch (e) {
        if (currentAttempt === attempts) {
          throw e;
        }
        onFailedAttempt(currentAttempt);
      }
    }
  } as (...args: T) => Promise<U>; // otherwise ts doesn't believe that function never returns Promise<undefined>
}
