import { contentfulSchema } from '@wr/web-shared';
import { Reviews as UIReviews } from '@wr/web-ui';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { AppContext } from '@/context';
import { formatDistanceToNow } from '@/utils/date';

import { Anchor, AnchorProps } from '../anchor';

export const Reviews: React.FC<contentfulSchema.ReviewsSectionFragment> = props => {
  const router = useRouter();
  const { messages } = useContext(AppContext);
  const reviews =
    props.reviews?.items.map(review => {
      const timeDuration = review?.reviewDate
        ? formatDistanceToNow(review.reviewDate, router.locale)
        : '';

      return {
        ...review,
        timeDuration,
      };
    }) || [];

  return (
    <UIReviews<AnchorProps>
      reviews={reviews}
      messages={{
        previous: messages?.previous,
        next: messages?.next,
      }}
      heading={props.heading || ''}
      name={props.name || ''}
      hideTrustpilotHeader={props.hideTrustpilotHeader || false}
      reviewsTotalScore={props.reviewsTotalScore || undefined}
      reviewsNumberLabel={props.reviewsNumberLabel || ''}
      carouselText={props.carouselText || ''}
      starIcon={props.starIcon || null}
      avatarIcon={props.avatarIcon || null}
      trustpilotLogo={props.trustpilotLogo || null}
      trustpilotLink={
        props.trustpilotLink
          ? {
              ...props.trustpilotLink,
              children: props.trustpilotLink.label,
              color: props.trustpilotLink.color || undefined,
              display: 'block',
            }
          : null
      }
      LinkComponent={Anchor}
    />
  );
};
