import { ApolloClient, contentfulSchema } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import { CexCountry } from '@/components';
import { getActiveCorridors, getCorridorsBySendCountry } from '@/services';
import { getSendCountries } from '@/services/bff/send-countries';
import {
  contentfulClient,
  getAllModuleItems,
  logger,
  Module,
  QueryOptions,
} from '@/utils';

import { CexCurrencyCorridor } from '../currency-corridors';
import { CexCurrencyFragment } from './currency-data.types';
import {
  formatCorridors,
  getCurrencyCorridorSlug,
  parseCexCountryToCexCurrency,
  parseCexCurrency,
} from './currency-data.utils';

export async function getCurrencyList(queryArgs: QueryOptions) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyListQuery,
      contentfulSchema.CexCurrencyListQueryVariables
    >({
      query: contentfulSchema.CexCurrencyList,
      variables: {
        preview: queryArgs.preview,
        locale: queryArgs?.locale ?? '',
      },
    });

    const metadata = data?.cexCurrencyCollection?.items || [];

    const modules = await getAllModuleItems({
      modules: metadata as Module[],
      ...queryArgs,
    });

    return [...modules]
      .map(module => {
        const __typename = Object.keys(module);
        return parseCexCurrency(module[__typename[0]] as CexCurrencyFragment);
      })
      .sort((a, b) => {
        const aCurrencyCode = a?.currencyCode ?? '';
        const bCurrencyCode = b?.currencyCode ?? '';
        return aCurrencyCode.localeCompare(bCurrencyCode);
      });
  } catch (error) {
    logger.error(error, `CexCurrencyListQuery request failed`);
    return [];
  }
}

export async function getCurrencyInformationBySlug({
  slug,
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  slug: string;
}) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyBySlugQuery,
      contentfulSchema.CexCurrencyBySlugQueryVariables
    >({
      query: contentfulSchema.CexCurrencyBySlug,
      variables: {
        slug,
        preview,
        locale,
      },
    });

    if (data?.cexCurrencyCollection?.items?.length) {
      return data.cexCurrencyCollection.items?.[0];
    }

    return null;
  } catch (error) {
    logger.error(error, `CexCurrencyBySlugQuery request failed`);
    return null;
  }
}

export async function getCurrencyInformationByCode({
  currencyCode,
  currencyName,
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  currencyCode: string;
  currencyName?: string;
}) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyByCodeQuery,
      contentfulSchema.CexCurrencyByCodeQueryVariables
    >({
      query: contentfulSchema.CexCurrencyByCode,
      variables: {
        code: [currencyCode.toLowerCase(), currencyCode.toUpperCase()],

        preview,
        locale,
      },
    });

    if (!data?.cexCurrencyCollection?.items?.length) {
      return null;
    }

    const currency = data.cexCurrencyCollection.items?.[0];

    if (currencyName) {
      const actualCurrencyName = currency?.currencyName
        ?.toLowerCase()
        .split(' ')
        .join('-');
      if (currencyName !== actualCurrencyName) {
        return null;
      }
    }

    return currency;
  } catch (error) {
    logger.error(error, `CexCurrencyByCodeQuery request failed`);
    return null;
  }
}

export async function getCurrencyByCurrencyAndCountryCode({
  currencyCode,
  countryCode,
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'> & {
  currencyCode: string;
  countryCode: string;
}) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencyByCurrencyAndCountryCodeQuery,
      contentfulSchema.CexCurrencyByCurrencyAndCountryCodeQueryVariables
    >({
      query: contentfulSchema.CexCurrencyByCurrencyAndCountryCode,
      variables: {
        currencyCode: [currencyCode.toLowerCase(), currencyCode.toUpperCase()],
        countryCode: [countryCode.toLowerCase(), countryCode.toUpperCase()],
        preview,
        locale,
      },
    });

    if (data?.cexCurrencyCollection?.items?.length) {
      return data.cexCurrencyCollection.items?.[0];
    }

    return null;
  } catch (error) {
    logger.error(error, `CexCurrencyByCodeAndCountryQuery request failed`);
    return null;
  }
}

export async function getCurrencySlugs({
  locale = '',
  preview,
}: Pick<GetServerSidePropsContext, 'locale' | 'preview'>) {
  try {
    const { data } = await contentfulClient.query<
      contentfulSchema.CexCurrencySlugsQuery,
      contentfulSchema.CexCurrencySlugsQueryVariables
    >({
      query: contentfulSchema.CexCurrencySlugs,
      variables: {
        preview,
        locale,
      },
    });

    return (data?.cexCurrencyCollection?.items ?? [])
      .filter((entry): entry is CexCurrencyFragment => entry !== null)
      .map(parseCexCurrency);
  } catch (error) {
    logger.error(error, `CexCurrencySlugsQuery request failed`);
    return [];
  }
}

export async function getCurrencyCorridors({
  locales,
  currencyInformation,
  analyticsPageType,
  bffClient,
}: {
  locales?: string[];
  currencyInformation: CexCurrencyFragment;
  analyticsPageType: string;
  bffClient: ApolloClient;
}): Promise<CexCurrencyCorridor[]> {
  const [
    activeCorridorsByReceiveCountry,
    { sendCountriesSorted },
    { corridorsBySendCountrySortedByReceiveCountryName },
  ] = await Promise.all([
    getActiveCorridors({
      locales: locales,
      receiveCountryCode: currencyInformation.countryCode ?? '',
      analyticsPageType,
      bffClient,
    }),
    getSendCountries({ bffClient }),
    getCorridorsBySendCountry({
      sendCountryCode: currencyInformation.countryCode ?? '',
      bffClient,
    }),
  ]);

  const activeSendCountries: CexCountry[] = sendCountriesSorted.filter(
    country =>
      country.code !== currencyInformation.countryCode &&
      activeCorridorsByReceiveCountry?.includes(country.code) &&
      !!country.currency,
  );

  const activeReceiveCountries: CexCountry[] =
    corridorsBySendCountrySortedByReceiveCountryName?.map(corridor => {
      return { ...corridor.receiveCountry, currency: corridor.receiveCurrency };
    }) ?? [];

  const corridors = formatCorridors({
    sendCountries: activeSendCountries,
    receiveCountries: activeReceiveCountries,
    currencyInformation,
  });

  return corridors;
}

export async function getCorridor({
  sendCountryCode,
  receiveCountryCode,
  sendCurrencyCode,
  receiveCurrencyCode,
  bffClient,
}: {
  sendCountryCode: string;
  receiveCountryCode: string;
  sendCurrencyCode?: string;
  receiveCurrencyCode?: string;
  bffClient: ApolloClient;
}): Promise<CexCurrencyCorridor | null> {
  const [
    { sendCountriesSorted },
    { corridorsBySendCountrySortedByReceiveCountryName },
  ] = await Promise.all([
    getSendCountries({ bffClient }),
    getCorridorsBySendCountry({
      sendCountryCode: sendCountryCode,
      bffClient,
    }),
  ]);

  const sendCountry = sendCountriesSorted?.find(
    country =>
      (sendCurrencyCode &&
        (country.currency?.code as string)?.toLowerCase() ===
          sendCurrencyCode.toLowerCase() &&
        (country.code as string).toLowerCase() ===
          sendCountryCode.toLowerCase()) ||
      (!sendCurrencyCode &&
        (country.code as string).toLowerCase() ===
          sendCountryCode.toLowerCase()),
  );

  const receiveCountry = corridorsBySendCountrySortedByReceiveCountryName
    .filter(
      corridor =>
        (receiveCurrencyCode &&
          (corridor.receiveCurrency?.code as string).toLowerCase() ===
            receiveCurrencyCode.toLowerCase() &&
          (corridor.receiveCountry.code as string).toLowerCase() ===
            receiveCountryCode.toLowerCase()) ||
        (!receiveCurrencyCode &&
          (corridor.receiveCountry.code as string).toLowerCase() ===
            receiveCountryCode.toLowerCase()),
    )
    .map(corridor => {
      return { ...corridor.receiveCountry, currency: corridor.receiveCurrency };
    })[0];

  if (!sendCountry || !receiveCountry) return null;

  return {
    sendCurrency: parseCexCountryToCexCurrency(sendCountry),
    receiveCurrency: parseCexCountryToCexCurrency(receiveCountry),
    pageSlug: getCurrencyCorridorSlug({
      sendCurrencyCode: sendCountry.currency?.code as string,
      receiveCurrencyCode: receiveCountry.currency?.code as string,
    }),
  };
}
