import { AppContextShared } from '@wr/web-ui';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {
  AppContext,
  AppContextType,
  CalculatorContextProvider,
} from '@/context';
import { riskifiedTracking } from '@/utils';

import { INITIAL_APP_CONTEXT } from './app.constants';

export const AppContextProvider = ({
  countriesByCode,
  children,
  corridorPayoutMethodsIds,
  payoutMethods,
  payoutMethodAlerts,
  accountDetails,
  referralCode,
  selectedPayoutMethodId,
  calculatorSendCountry,
  calculatorSendAmount,
  locationCountryCookie,
  currencies,
  corridors,
  ...props
}: PropsWithChildren<Partial<AppContextType>>) => {
  const sharedContext = useContext(AppContextShared);

  /**
   * Incase any other prop is wrongfully passed it's still available
   */
  const safeContextValues = useMemo(() => {
    return {
      ...sharedContext,
      ...props,
    };
  }, [props, sharedContext]);

  useEffect(() => {
    riskifiedTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...safeContextValues,
        selectedPayoutMethodId:
          selectedPayoutMethodId || INITIAL_APP_CONTEXT.selectedPayoutMethodId,
        referralCode: referralCode || INITIAL_APP_CONTEXT.referralCode,
        payoutMethods: payoutMethods || INITIAL_APP_CONTEXT.payoutMethods,
        corridorPayoutMethodsIds:
          corridorPayoutMethodsIds ||
          INITIAL_APP_CONTEXT.corridorPayoutMethodsIds,
        payoutMethodAlerts:
          payoutMethodAlerts || INITIAL_APP_CONTEXT.payoutMethodAlerts,
        calculatorSendAmount:
          calculatorSendAmount || INITIAL_APP_CONTEXT.calculatorSendAmount,
        calculatorSendCountry:
          calculatorSendCountry || INITIAL_APP_CONTEXT.calculatorSendCountry,
        currencies: currencies || INITIAL_APP_CONTEXT.currencies,
        corridors: corridors || INITIAL_APP_CONTEXT.corridors,
        countriesByCode,
        accountDetails,
        locationCountryCookie,
      }}
    >
      <CalculatorContextProvider>{children}</CalculatorContextProvider>
    </AppContext.Provider>
  );
};
