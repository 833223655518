import { AppBar, AppBarProps, styled } from '@mui/material';

interface HeaderAppBarProps extends AppBarProps {
  hasSubMenuItems?: boolean;
  isHeaderDarkTheme?: boolean;
}

const shouldForwardProp = (prop: string) =>
  !prop.match('isHeaderDarkTheme') && !prop.match('hasSubMenuItems');

export const HeaderAppBar = styled(AppBar, {
  shouldForwardProp,
})<HeaderAppBarProps>(({ theme, hasSubMenuItems, isHeaderDarkTheme }) => ({
  background: isHeaderDarkTheme ? '#0000007F' : '#FFFFFF',
  overflow: 'hidden',
  justifyContent: 'center',
  boxShadow: 'none',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(hasSubMenuItems ? 0 : 2),
  top: 'initial',
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),

  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(hasSubMenuItems ? 0 : 3),
  },
}));
