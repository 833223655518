import { styled } from '@mui/material';
import { ExtendedButtonProps } from '@wr/web-ui';

import { Button } from '@/components/button';

// Currently required to address an issue with an unrelated breaking test for correspondent-locations-test
// TODO: https://worldremit.atlassian.net/browse/CONWEB-4388

const UniqueButton: React.FC<ExtendedButtonProps> = (
  props: ExtendedButtonProps,
) => <Button {...props} />;

interface NavButtonProps extends ExtendedButtonProps {
  isHeaderDarkTheme?: boolean;
}

const shouldForwardProp = (prop: string) => !prop.match('isHeaderDarkTheme');

export const NavButton = styled(UniqueButton, {
  shouldForwardProp,
})<NavButtonProps>(({ theme, isHeaderDarkTheme }) => ({
  'color': isHeaderDarkTheme ? 'white' : 'black',
  'textDecoration': 'none',
  'fontSize': '16px',
  '&.MuiButton-contained': {
    color: 'white',
  },
  '&:hover': {
    color: 'white',
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.dark,
  },
}));
