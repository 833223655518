import {
  setAirtimeDialCodeCookie,
  setAirtimePhoneNumberCookie,
} from '@wr/web-shared';
import React, { useContext } from 'react';

import { CalculatorProps } from '@/components/calculator/component/calculator.types';
import { useValidatePhoneNumber } from '@/components/calculator/src/composition/phone-validator/phone-validator.hooks';

import { PhoneInput } from '../../components/phone-input';
import { CalculatorContext } from '../../context';

export const PhoneValidator: React.FC<
  Pick<
    CalculatorProps,
    | 'phoneValidatorLabel'
    | 'phoneValidatorPlaceholder'
    | 'phoneNumberInvalidErrorMessage'
  >
> = ({
  phoneValidatorLabel,
  phoneValidatorPlaceholder,
  phoneNumberInvalidErrorMessage,
}) => {
  const { state, dispatch } = useContext(CalculatorContext);

  const { data, loading, error } = useValidatePhoneNumber();

  const onValueChange = (value: string): void => {
    if (value && value !== state.mobileNumber) {
      dispatch({
        type: 'SET_PHONE_NUMBER',
        payload: {
          mobileNumber: value,
        },
      });
      setAirtimePhoneNumberCookie(value);
      setAirtimeDialCodeCookie(state.receiveCountry?.dialCode);
    }
  };

  const isError = Boolean(
    (data?.validatePhoneNumber.__typename === 'PhoneNumberValidationFailed' &&
      data.validatePhoneNumber?.message) ||
      error,
  );

  return (
    <PhoneInput
      phoneValidatorLabel={phoneValidatorLabel}
      phoneNumberInvalidErrorMessage={phoneNumberInvalidErrorMessage}
      isFetching={loading}
      isError={isError}
      mobileNumber={state.mobileNumber}
      isPhoneNumberValid={state.isPhoneNumberValid}
      phoneValidatorPlaceholder={phoneValidatorPlaceholder}
      dialCode={state.receiveCountry?.dialCode}
      onValueChange={onValueChange}
    />
  );
};
