import { Paper, Typography } from '@mui/material';
import type { PerimeterXCaptchaData } from '@wr/web-shared';
import type { Logger } from 'pino';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import {
  loadScriptAndInitPerimeterXCaptcha,
  PERIMETER_X_CAPTCHA_PLACEHOLDER_ELEMENT_ID,
  PERIMETER_X_SHOW_CAPTCHA_EVENT,
} from '../../utils';
import { pxCaptchaStyles, useStyles } from './perimeter-x-captcha-modal.styles';

type CaptchaProps = PropsWithChildren<{
  label: string;
  logger: Logger;
}>;

/**
 * full screen dialog with #px-captcha element where perimeterX html captcha should be loaded
 * supports 2 strategies:
 * ThrowAndReloadPage - hides main page content and reloads page on captcha success
 * IgnorePXError - shows main page content and just hide modal on captcha success
 */
export const PerimeterXCaptchaModal: React.FC<CaptchaProps> = ({
  label,
  children,
  logger,
}: CaptchaProps) => {
  const classes = useStyles();

  const [captchaDetails, setCaptchaDetails] = useState<PerimeterXCaptchaData>();

  const isCaptchaVisible = Boolean(captchaDetails);

  useEffect(() => {
    const showCaptcha = ((event: CustomEvent<PerimeterXCaptchaData>) => {
      loadScriptAndInitPerimeterXCaptcha({
        captchaData: event.detail,
        styles: pxCaptchaStyles,
        onCaptchaSuccess: () => {
          if (event.detail.perimeterXBlockStrategy === 'ThrowAndReloadPage') {
            window.location.reload();
          } else {
            setCaptchaDetails(undefined);
          }
        },
        logger,
      });

      setCaptchaDetails(event.detail);
    }) as EventListener;
    window.addEventListener(PERIMETER_X_SHOW_CAPTCHA_EVENT, showCaptcha);

    return () => {
      window.removeEventListener(PERIMETER_X_SHOW_CAPTCHA_EVENT, showCaptcha);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // logger is missing on purpose

  if (!isCaptchaVisible) {
    return <>{children}</>;
  }

  const showPageMainContent =
    captchaDetails?.perimeterXBlockStrategy === 'IgnorePXError';

  return (
    <>
      {showPageMainContent && children}
      <div data-testid="pxElement" className={classes.pxOverlay}>
        <Paper
          elevation={2}
          classes={{ root: classes.pxDialog, rounded: classes.rounded }}
        >
          <Typography variant="body2" component="h2">
            {label}
          </Typography>
          <div
            id={PERIMETER_X_CAPTCHA_PLACEHOLDER_ELEMENT_ID}
            className={classes.pxCaptcha}
          ></div>
        </Paper>
      </div>
    </>
  );
};
