import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { StickyBannerContent as UIStickyBannerContent } from '@wr/web-ui';
import React, { useContext } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { StickyBannerContentProps } from '@/components/sticky-banner';
import { AppContext } from '@/context';
import { buildContentfulSidekickAttributes } from '@/utils';

export const StickyBannerContent: React.FC<StickyBannerContentProps> = ({
  __typename,
  sys,
  name,
  backgroundColor,
  textColor,
  image,
  description,
}) => {
  const { messages } = useContext(AppContext);
  return (
    <UIStickyBannerContent<RichTextProps>
      name={name}
      backgroundColor={backgroundColor}
      textColor={textColor}
      image={image}
      description={
        description?.json
          ? ({
              ...description,
              options: {
                [BLOCKS.PARAGRAPH]: {
                  variant: 'body1',
                  display: 'inline',
                  paragraph: false,
                },
                [INLINES.HYPERLINK]: {
                  color: 'inherit',
                },
              },
            } as RichTextProps)
          : null
      }
      RichTextComponent={RichTextContentRenderer}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
      readLessLabel={messages?.readLess ?? ''}
      readMoreLabel={messages?.readMore ?? ''}
      isCollapsible
    />
  );
};
