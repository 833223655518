import { CountryData } from '@wr/web-ui';

export const normalizeStr = (str: string): string => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase();
};

export const filterCountries = (
  countries: CountryData[],
  searchedStr?: string,
): CountryData[] => {
  if (!searchedStr) {
    return countries;
  }

  const normalizedSearchedStr = normalizeStr(searchedStr);

  return countries.filter(country => {
    const normalizeCurrency = normalizeStr(country.currency || '').includes(
      normalizedSearchedStr,
    );
    const normalizeCountryName = normalizeStr(country.name).includes(
      normalizedSearchedStr,
    );

    return normalizeCurrency || normalizeCountryName;
  });
};

export const removeScroll = (): void => {
  document.body.style.position = 'fixed';
  document.body.style.height = '100%';
  document.body.style.overflow = 'hidden';
  document.body.style.top = '0';
  document.body.style.bottom = '0';
  document.body.style.left = '0';
  document.body.style.right = '0';
};

export const bringBackScroll = (position: DOMRect): void => {
  document.body.style.removeProperty('overflow');
  document.body.style.removeProperty('position');
  document.body.style.removeProperty('height');
  document.body.style.removeProperty('top');
  document.body.style.removeProperty('bottom');
  document.body.style.removeProperty('left');
  document.body.style.removeProperty('right');
  window.scrollTo(position.x, position.y - position.height);
};

export const sortArrayByProperty = <T>(array: T[], property: keyof T): T[] =>
  array.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
