import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ThemeBreakpoints } from '../theme';

export const useStyles = makeStyles<Theme>(theme => ({
  root: {
    // default inherited padding xs (16px) and sm (24px)
    [theme.breakpoints.up(ThemeBreakpoints.desktop)]: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15),
    },
    [theme.breakpoints.down(ThemeBreakpoints.desktop)]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down(ThemeBreakpoints.tablet)]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down(ThemeBreakpoints.mobile)]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down(ThemeBreakpoints.xs)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));
