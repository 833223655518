import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '../../styles';
import { StyledRatingStyleProps } from './styled-rating.types';

export const getBackgroundColor = ({
  rating,
}: StyledRatingStyleProps): string => {
  // trustpilot style guide
  // https://support.trustpilot.com/hc/en-us/articles/206289947-Style-guidelines-for-Trustpilot-s-marketing-assets
  if (rating < 1.75) {
    return colors.trustpilotRed;
  }

  if (rating < 2.75) {
    return colors.trustpilotOrange;
  }

  if (rating < 3.75) {
    return colors.trustpilotYellow;
  }

  if (rating < 4.25) {
    return colors.trustpilotLightGreen;
  }

  return colors.trustpilotGreen;
};

export const useStyles = makeStyles<Theme, StyledRatingStyleProps>(theme => ({
  iconEmpty: {
    backgroundColor: colors.gainsboro,
  },

  iconFilled: {
    backgroundColor: getBackgroundColor,
  },

  decimal: {
    margin: theme.spacing(0, 0.25),
  },
}));
