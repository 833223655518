import NextImage from 'next/image';
import React, { useMemo } from 'react';

import { ImageProps } from './image.types';
import { contentfulImageLoader, imageAssetLoader } from './image.utils';

export const Image: React.FC<ImageProps> = ({
  url,
  alt,
  title,
  width,
  height,
  className,
  loading = 'lazy',
  fill = false,
  style,
  priority = false,
  'data-testid': dataTestId,
  sizes,
  __typename,
}) => {
  const props = fill
    ? { fill }
    : {
        width: typeof width === 'number' ? width : 0,
        height: typeof height === 'number' ? height : 0,
      };

  // assets with __typename are from contentful
  const loader = useMemo(() => {
    return __typename ? contentfulImageLoader : imageAssetLoader;
  }, [__typename]);

  if (!url) {
    return null;
  }

  return (
    <NextImage
      data-testid={dataTestId}
      className={className}
      loader={loader}
      style={style}
      sizes={sizes}
      loading={loading}
      alt={alt || ''}
      title={title || ''}
      src={url}
      priority={priority}
      {...props}
    />
  );
};
