import { HeaderVariant } from './header.types';

export const isDarkTheme = (theme: HeaderVariant | undefined): boolean =>
  theme === 'dark';

export enum DataAuthentication {
  isNotAuthenticated = 'isNotAuthenticated',
  inProgress = 'inProgress',
  isAuthenticated = 'isAuthenticated',
}

export const getAuthenticatedId = (
  isAuthenticatingInProgress: boolean,
  isAuthenticated: boolean,
): DataAuthentication => {
  if (isAuthenticatingInProgress) {
    return DataAuthentication.inProgress;
  }
  if (isAuthenticated) {
    return DataAuthentication.isAuthenticated;
  }
  return DataAuthentication.isNotAuthenticated;
};
