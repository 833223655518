import { Regulator } from '@wr/web-ui';
import { useContext, useState } from 'react';

import { RichTextProps } from '@/components/rich-text-content-renderer';
import { AppContext } from '@/context';
import { CountrySelectOption } from '@/types/data-interfaces';

import { UseCountrySelect } from './we-are-regulated.types';
import { getPreselectedRegulator } from './we-are-regulated.utils';

export const useCountrySelect = (
  regulators: Regulator<RichTextProps>[],
): UseCountrySelect => {
  const { sendCountry } = useContext(AppContext);

  const selectCountries = regulators.map(({ name, code, customFlagCode }) => {
    return {
      name,
      code,
      customFlagCode,
    };
  });

  const preselectedRegulator = getPreselectedRegulator(regulators, sendCountry);

  const [selectedCountry, setCountry] = useState<
    CountrySelectOption | undefined
  >(preselectedRegulator);

  return { selectedCountry, setCountry, selectCountries };
};
