import { CountryData } from '@wr/web-ui';
import { useState } from 'react';

import { useCalculator } from '@/hooks';

export const useRateAlertFields = () => {
  const {
    sendCountry,
    sendCountries,
    receiveCountries,
    receiveCountry,
    payoutMethods,
  } = useCalculator();

  const [email, setEmail] = useState('');
  const [selectedPayoutMethod, setSelectedPayoutMethod] = useState<string>();
  const [selectedSendCountry, setSelectedSendCountry] = useState<CountryData>();

  const [
    selectedReceiveCountry,
    setSelectedReceiveCountry,
  ] = useState<CountryData>();

  return {
    email,
    setEmail,
    selectedPayoutMethod,
    setSelectedPayoutMethod,
    selectedSendCountry: selectedSendCountry || sendCountry || undefined,
    setSelectedSendCountry,
    selectedReceiveCountry:
      selectedReceiveCountry || receiveCountry || undefined,
    setSelectedReceiveCountry,
    receiveCountries,
    sendCountries,
    payoutMethods,
  };
};
