import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { PromotionalBannerContextualProps } from './promotional-banner-contextual.types';

export const useStyles = makeStyles<
  Theme,
  Partial<PromotionalBannerContextualProps<unknown, unknown, unknown>>
>(({ breakpoints, spacing, customComponents }) => ({
  root: ({ backgroundColor }) => ({
    backgroundColor:
      backgroundColor ||
      customComponents.promotionalBannerContextual.root.backgroundColor,

    [breakpoints.up('sm')]: {
      display: 'flex',
    },
  }),
  content: {
    'color': customComponents.promotionalBannerContextual.content.color,
    'padding': spacing(3, 3, 0),

    [breakpoints.up('sm')]: {
      flex: 3,
      padding: spacing(3, 0, 3, 3),
    },

    [breakpoints.up('md')]: {
      padding: spacing(5, 0, 5, 5),
    },
    [breakpoints.up('lg')]: {
      padding: spacing(8, 0, 8, 8),
    },
    [breakpoints.up('xl')]: {
      padding: spacing(15, 0, 15, 15),
    },

    '& b': {
      color: customComponents.promotionalBannerContextual.content.b.color,
    },
  },
  button: {
    marginBottom: spacing(2),
  },
  link: {
    display: 'block',
    marginTop: spacing(1),
  },
  disclaimer: {
    marginTop: spacing(1),
  },
  image: ({ backgroundColor }) => ({
    'position': 'relative',
    'height': '20rem',

    [breakpoints.up('sm')]: {
      flex: 5,
      height: 'inherit',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'inline-block',
      background: `linear-gradient(to bottom, ${
        backgroundColor ||
        customComponents.promotionalBannerContextual.image.backgroundColor
      } 0%, ${alpha(
        backgroundColor ||
          customComponents.promotionalBannerContextual.image.backgroundColor,
        0,
      )} 50%)`,

      [breakpoints.up('sm')]: {
        background: `linear-gradient(to right, ${
          backgroundColor ||
          customComponents.promotionalBannerContextual.image.backgroundColor
        } 0%, ${alpha(
          backgroundColor ||
            customComponents.promotionalBannerContextual.image.backgroundColor,
          0,
        )} 50%)`,
      },
    },
  }),
}));
