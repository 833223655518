import { ENABLED_NUMERIC_KEY_INPUTS } from '@/components/calculator/core/core.constants';

import { ICountryData } from '../../types';

export const removeScroll = (): void => {
  document.body.style.position = 'fixed';
  document.body.style.height = '100%';
  document.body.style.overflow = 'hidden';
  document.body.style.top = '0';
  document.body.style.bottom = '0';
  document.body.style.left = '0';
  document.body.style.right = '0';
};

export const bringBackScroll = (): void => {
  document.body.style.removeProperty('overflow');
  document.body.style.removeProperty('position');
  document.body.style.removeProperty('height');
  document.body.style.removeProperty('top');
  document.body.style.removeProperty('bottom');
  document.body.style.removeProperty('left');
  document.body.style.removeProperty('right');
};

export const replaceAccentCharactersWithStandard = (text: string): string =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const normalizeStr = (str: string): string =>
  replaceAccentCharactersWithStandard(str).trim().toLowerCase();

export const search = (str: string, searchedStr: string): boolean =>
  normalizeStr(str).includes(searchedStr);

export const filterCountries = (
  countries: ICountryData[],
  searchedStr?: string,
): ICountryData[] => {
  if (!searchedStr) {
    return countries;
  }

  const normalizedSearchedStr = normalizeStr(searchedStr);

  return countries.filter(
    country =>
      normalizeStr(country.currency).includes(normalizedSearchedStr) ||
      normalizeStr(country.name).includes(normalizedSearchedStr),
  );
};

export const isInputEnabledKey = (keyEvent: string): boolean =>
  ENABLED_NUMERIC_KEY_INPUTS.includes(keyEvent);
