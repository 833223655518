import { Typography } from '@mui/material';
import React, { useState } from 'react';

import { CARDS_CAROUSEL_SETTINGS } from '../../constants';
import { Card, Carousel, Container } from '..';
import { NextArrow, PrevArrow } from '../carousel/cards-arrows';
import { Image } from '../image';
import { useStyles } from './cards.styles';
import { CardsProps } from './cards.types';

export function Cards<Anchor, Button>({
  name,
  header,
  description,
  carouselText,
  settings,
  containerProps,
  cards,
  paymentText,
  paymentIcons,
  messages,
  AnchorComponent,
  ButtonComponent,
}: CardsProps<Anchor, Button>): React.ReactElement | null {
  const classes = useStyles();
  const [currentSlide, setCurrenSlide] = useState<number>(0);

  return cards?.length ? (
    <section {...containerProps} id={name} className={classes.section}>
      <Container>
        {header && (
          <Typography
            component="h2"
            variant="h2"
            className={classes.title}
            data-testid="cards-header"
          >
            {header}
          </Typography>
        )}
        {description && (
          <Typography
            component="p"
            variant="subtitle1"
            className={classes.description}
            data-testid="cards-description"
          >
            {description}
          </Typography>
        )}
        {carouselText && (
          <Typography
            variant="body2"
            className={classes.carouselText}
            data-testid="cards-carousel-text"
          >
            {carouselText}
          </Typography>
        )}
        <Carousel
          settings={{
            ...CARDS_CAROUSEL_SETTINGS,
            nextArrow: <NextArrow label={messages?.next || ''} />,
            prevArrow: <PrevArrow label={messages?.previous || ''} />,
            ...settings,
            beforeChange: (_oldIndex: number, newIndex: number) => {
              setCurrenSlide(newIndex);
            },
          }}
        >
          {cards.map((card, index) => {
            return (
              <Card
                name={card?.name}
                key={card?.name}
                backgroundColor={card?.backgroundColor}
                backgroundImage={card?.backgroundImage}
                className={classes.card}
                header={card?.header}
                description={card?.description}
                AnchorComponent={AnchorComponent}
                ButtonComponent={ButtonComponent}
                link={card.link}
                button={card.button}
                containerProps={card?.containerProps}
                isActive={currentSlide === index}
                chipLabel={null}
              />
            );
          })}
        </Carousel>
        {paymentIcons && paymentText && (
          <div className={classes.cardsFooter} data-testid={'cards-footer'}>
            <Typography className={classes.carouselText} color="textSecondary">
              {paymentText}
            </Typography>

            <ul className={classes.paymentIcons}>
              {paymentIcons.map(paymentIcon => (
                <li key={paymentIcon.url} className={classes.paymentIcon}>
                  <Image {...paymentIcon} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </Container>
    </section>
  ) : null;
}
