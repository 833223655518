import Cookies from 'js-cookie';

import { COOKIES, QUEBEC_ISO_CODE } from '../constants';

type VisitorType = 'prospect' | 'sign up' | 'activation' | 'repeat customer';
const VisitorType = ['prospect', 'sign up', 'activation', 'repeat customer'];

const isVisitorType = (visitor: string): visitor is VisitorType =>
  VisitorType.includes(visitor);

const validateVisitorType = (visitor: string): VisitorType | undefined =>
  (isVisitorType(visitor) && visitor) || undefined;

export const getVisitorTypeLongCookie = (): VisitorType | undefined =>
  validateVisitorType(Cookies.get(COOKIES.VISITOR_TYPE_LONG) || '');

export const getVisitorTypeShortCookie = (): VisitorType | undefined =>
  validateVisitorType(Cookies.get(COOKIES.VISITOR_TYPE_SHORT) || '');

export const isValidVisitorTypeCookie = (visitorTypes: string[]): boolean => {
  const validVisitorTypes = visitorTypes
    .map(validateVisitorType)
    .filter(Boolean) as VisitorType[];
  const visitorTypeLongCookie = getVisitorTypeLongCookie();
  const visitorTypeShortCookie = getVisitorTypeShortCookie();

  const hasVisitorTypeLongCookie =
    !!visitorTypeLongCookie &&
    validVisitorTypes.includes(visitorTypeLongCookie);
  const hasVisitorTypeShortCookie =
    !!visitorTypeShortCookie &&
    validVisitorTypes.includes(visitorTypeShortCookie);

  return hasVisitorTypeLongCookie || hasVisitorTypeShortCookie;
};

export const setLanguageToCookie = (lang: string): void => {
  Cookies.set(COOKIES.LANGUAGE, `CULTURA=${lang}`, { expires: 365 });
  Cookies.set('NEXT_LOCALE', lang, { expires: 365 });
};

export const getLanguageValue = (cookieValue: string | undefined) =>
  (cookieValue || '').split('CULTURA=').pop();

export const getLanguageFromCookie = (): string | undefined =>
  getLanguageValue(Cookies.get(COOKIES.LANGUAGE));

export const getInformationDialogClosedCookie = (): string | undefined =>
  Cookies.get(COOKIES.INFORMATION_DIALOG_CLOSED);

export const setInformationDialogClosedCookie = (value: string): void => {
  Cookies.set(COOKIES.INFORMATION_DIALOG_CLOSED, value);
};

export const getLocationCountryCookie = (): string | undefined =>
  Cookies.get(COOKIES.LOCATION_COUNTRY);

export const setLocationCountryCookie = (country: string): void => {
  Cookies.set(COOKIES.LOCATION_COUNTRY, country);
};

export const getGeoCookie = (): string | undefined => Cookies.get(COOKIES.GEO);

export const setGeoCookie = (country: string): void => {
  Cookies.set(COOKIES.GEO, country);
};

export const setCountryFromCookie = (country: string): void => {
  Cookies.set(COOKIES.SELECT_FROM, country);
};

export const getCountryFromCookie = (): string | undefined =>
  Cookies.get(COOKIES.SELECT_FROM);

export const getCountryToCookie = (): string | undefined =>
  Cookies.get(COOKIES.SELECT_TO);

export const setCountryToCookie = (country: string): string => {
  Cookies.set(COOKIES.SELECT_TO, country);
  return country;
};

export const getOptimizelyEndUserId = (): string | undefined =>
  Cookies.get('optimizelyEndUserId');

export const isUserIsInQuebec = (): boolean => {
  const userCityLocation = Cookies.get('userCityLocation');

  return userCityLocation === QUEBEC_ISO_CODE;
};

export const setAirtimePhoneNumberCookie = (PhoneNumber: string): void => {
  Cookies.set(COOKIES.AIR_TIME_PHONE_NUMBER, PhoneNumber);
};

export const setAirtimeDialCodeCookie = (
  DialCode: string | undefined,
): void => {
  Cookies.set(COOKIES.AIR_TIME_DIAL_CODE, DialCode as string);
};
