import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Hidden, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';

import { Button } from '../../button';
import { UserAccountIcon } from '../../icons';
import { useMenuToggler } from '../header.hooks';
import { useStyles } from './user-menu.styles';
import { UserMenuProps } from './user-menu.types';

const USER_MENU_BUTTON_ID = 'header-user-account';

export function UserMenu<Anchor extends { url?: string | null }>({
  myAccount,
  welcomeMessage,
  userDropdownMenuItems,
  color,
  AnchorComponent,
}: UserMenuProps<Anchor>): React.ReactElement {
  const classes = useStyles({ color });
  const { isOpen, anchorEl, handleOpen, handleClose } = useMenuToggler();

  const endIcon = isOpen ? (
    <KeyboardArrowUpIcon className={classes.endIcon} />
  ) : (
    <KeyboardArrowDownIcon className={classes.endIcon} />
  );

  return (
    <>
      <Hidden smUp>
        <Button
          id={USER_MENU_BUTTON_ID}
          data-testid="button-icon"
          color={color}
          size="small"
          aria-label="user"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <UserAccountIcon className={classes.icon} />
        </Button>
      </Hidden>
      <Hidden smDown>
        <Button
          id={USER_MENU_BUTTON_ID}
          variant="text"
          size="small"
          endIcon={endIcon}
          color={color}
          data-testid="button-text"
          aria-label={myAccount}
          aria-haspopup="true"
          onClick={handleOpen}
        >
          {myAccount}
        </Button>
      </Hidden>

      <Menu
        id={`${USER_MENU_BUTTON_ID}-menu`}
        data-testid="user-menu"
        MenuListProps={{ disablePadding: true }}
        transitionDuration={0}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: classes.itemsWrapper,
        }}
        PaperProps={{
          id: 'account-navigation',
        }}
      >
        <Typography component="p" variant="body2" paragraph>
          {welcomeMessage}
        </Typography>
        {userDropdownMenuItems.map(linkProps => (
          <MenuItem
            disableGutters
            key={linkProps.url}
            classes={{ root: classes.menuItem }}
          >
            <AnchorComponent {...linkProps} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
