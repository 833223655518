import {
  CALCULATOR_TYPE,
  ECOMMERCE_TYPE,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
  VARIABLE,
} from '@wr/web-shared';

import {
  AppContextType,
  CalculatorContextType,
  PushCalculationToDataLayerProps,
} from '@/context';

export const getPayoutMethodDetails = (
  payoutMethodId: CalculatorContextType['payoutMethodId'],
  payoutMethods: AppContextType['payoutMethods'],
) => {
  const selectedPayoutMethod = payoutMethods.find(
    item => item?.code === payoutMethodId,
  );
  if (selectedPayoutMethod) {
    return {
      ...selectedPayoutMethod,
      defaultCorrespondentId:
        selectedPayoutMethod?.correspondents?.[0]?.id ?? '',
    };
  }
};

export const getCalculationCorridor = (
  sendCountryCode: string,
  receiveCountryCode: string,
) => {
  return `${sendCountryCode}-${receiveCountryCode}`.toUpperCase();
};

export const getCalculationCorridorWithCurrencies = (
  sendCountryCode: string,
  sendCurrency: string,
  receiveCountryCode: string,
  receiveCurrency: string,
): string => {
  return `${sendCountryCode}/${receiveCountryCode}/${sendCurrency}/${receiveCurrency}`.toUpperCase();
};

export const pushErrorToDataLayer = (errorMessage: string): void => {
  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: INTERACTION_NAME.CALC_ERROR,
    [VARIABLE.CALC_MESSAGE]: errorMessage,
  });
};

export const pushCalculationToDataLayer = ({
  state,
  ecommerceType,
  interactionName,
  payoutMethod,
}: PushCalculationToDataLayerProps) => {
  const dataLayerProps = {
    event: EVENT.VISITOR_INTERACTION,
    interactionName,
    [ecommerceType === ECOMMERCE_TYPE.DETAIL
      ? VARIABLE.CALC_AMOUNT_FIELD
      : VARIABLE.CALC_CALCULATOR_TYPE]:
      ecommerceType === ECOMMERCE_TYPE.DETAIL
        ? state.calculationType
        : CALCULATOR_TYPE.MONEY_TRANSFER,
    ecommerce: {
      [ecommerceType]: {
        products: [
          {
            [VARIABLE.CALC_CORRIDOR]: getCalculationCorridor(
              state.sendCountry?.countryCode ?? '',
              state.receiveCountry?.countryCode ?? '',
            ),
            [VARIABLE.CALC_CURRENCY_CORRIDOR]: getCalculationCorridor(
              state.sendCountry?.currency ?? '',
              state.receiveCountry?.currency ?? '',
            ),
            [VARIABLE.CALC_CORRIDOR_WITH_CURRENCIES]: getCalculationCorridorWithCurrencies(
              state.sendCountry?.countryCode ?? '',
              state.sendCountry?.currency ?? '',
              state.receiveCountry?.countryCode ?? '',
              state.receiveCountry?.currency ?? '',
            ),
            [VARIABLE.CALC_PAYOUT_METHOD]: payoutMethod?.code,
            [VARIABLE.CALC_TOTAL_TO_PAY]: state.exchangeRate?.totalToPay,
            [VARIABLE.CALC_SEND_OR_RECEIVE]: state.calculationType,
            [VARIABLE.CALC_EXCHANGE_RATE]: state.exchangeRate?.conversionRate,
            [VARIABLE.CALC_CORRESPONDENT]: payoutMethod?.defaultCorrespondentId,
            [VARIABLE.CALC_PROMOTION]: 'no', // TODO
            [VARIABLE.CALC_PROMOTION_NAME]: '', // TODO
            [VARIABLE.CALC_TRANSFER_TIME]: payoutMethod?.payOutTimeEstimate,
            [VARIABLE.CALC_RECEIVE_AMOUNT]: state.receiveAmount,
            [VARIABLE.CALC_SEND_AMOUNT]: state.sendAmount,
            [VARIABLE.CALC_FEES]: state.exchangeRate?.fee,
            [VARIABLE.CALC_QUANTITY]: state.calculationAmount,
          },
        ],
      },
    },
  };
  pushToDataLayer(dataLayerProps);
};
