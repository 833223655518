import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Alert from '@mui/lab/Alert';
import { Box, InputBase, Typography } from '@mui/material';
import {
  Autocomplete as UIAutocomplete,
  CountryFlag as UICountryFlag,
  CountryRow as UICountryRow,
} from '@wr/web-ui';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ENTER_KEY_INPUT,
  SPACE_CODE,
  SPACE_CODE_OLD,
} from '@/components/calculator/core/core.constants';
import { getInputValue } from '@/components/calculator/src/utils';
import { IOS } from '@/constants';
import { AppContext } from '@/context';

import { ICountryData } from '../../types';
import { CalculatorLoader } from '../calculator-loader';
import { Dropdown } from '../dropdown';
import useStyles from './country-field.styles';
import { CountryFieldProps } from './country-field.types';
import {
  bringBackScroll,
  filterCountries,
  removeScroll,
} from './country-field.utils';

export const CountryField: React.FC<CountryFieldProps> = ({
  isLite,
  name,
  label,
  value,

  onlyCountrySelect,
  disableCountrySelect,
  selectedCountry,
  countries,
  countriesSearchPlaceholder,
  errorMessages,
  isLoading,
  isDisabled,
  messages,
  onCountryChange,
  onValueChange,
  onDropdownOpen,
  onDropdownClose,
  onSearchCompleted,
}) => {
  const { osName } = useContext(AppContext);
  const classes = useStyles();

  const rootEl = useRef<HTMLDivElement>(null);
  const searchCountryInputEl = useRef<HTMLElement>(null);
  const inputEl = useRef<HTMLInputElement | null>(null);

  const isIOS = osName === IOS;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isFieldFocused, setFieldFocused] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const open = Boolean(anchorEl);

  const handleCountryChange = (country: ICountryData): void => {
    onCountryChange(country);
    if (searchText) {
      onSearchCompleted?.(searchText, country);
    }
  };

  const handleClick = (): void => {
    if (disableCountrySelect) {
      return;
    }

    if (isIOS) {
      removeScroll();
    }

    setAnchorEl(rootEl?.current);

    onDropdownOpen?.();
  };

  const handleCountrySelectKeyDown = (event: React.KeyboardEvent): void => {
    if (!open && (event.key === SPACE_CODE || event.key === SPACE_CODE_OLD)) {
      event.preventDefault();
      handleClick();
    }
  };

  const handleClose = (
    _: React.ChangeEvent<unknown>,
    reason?: string,
  ): void => {
    // 'blur' reason of Autocomplete#onClose can produce duplications
    // Dropdown#onClose works more stable (it's reasons 'backdropClick' or undefined)
    if (reason === 'toggleInput' || reason === 'blur') {
      return;
    }
    if (searchText && reason !== 'select-option') {
      onSearchCompleted?.(searchText);
    }

    setAnchorEl(null);
    onDropdownClose?.();
    setTimeout(() => {
      setSearchText('');
    }, 300);

    const position = rootEl?.current?.getBoundingClientRect();

    if (isIOS && position) {
      setTimeout(() => {
        bringBackScroll();
        window.scrollTo(position.x, position.y - position.height);
      }, 0);
    }
  };

  const onFocusIn = (): void => {
    setFieldFocused(true);
  };

  const onInputClick = (): void => {
    if (onlyCountrySelect) {
      handleClick();
    }
  };

  const onFocusOut = (): void => {
    handleKeyUp.cancel(); // cancel() is a method returned by lodash.debounce
    setFieldFocused(false);

    if (state && state !== value) {
      onValueChange(Number(state).toFixed(2));
    }
  };

  const valueChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    const value = getInputValue(event?.currentTarget?.value);

    if (value || value === '') {
      setState(value);
    }
  };

  const inputValue = onlyCountrySelect ? selectedCountry?.name : state;

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): void | boolean => {
    if (e.key === ENTER_KEY_INPUT) {
      (e.target as HTMLElement).blur();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyUp = useCallback(
    debounce((value, state) => {
      if (state && state !== value) {
        onValueChange(Number(state).toFixed(2));
        if (inputEl?.current) {
          inputEl.current.blur();
        }
      }
    }, 3000),
    [],
  );

  return (
    <Box id={name} mb={isLite ? 3 : 2}>
      <div
        ref={rootEl}
        className={clsx(classes.root, {
          [classes.rootFocused]: isFieldFocused,
        })}
      >
        <Box
          className={classes.amountContainer}
          display="flex"
          flexDirection="column"
          padding={2}
          flex={1}
        >
          <Typography
            color="textSecondary"
            component="span"
            variant="body1"
            id={`${name}-input-label`}
          >
            {label}
          </Typography>
          {isLoading ? (
            <CalculatorLoader size={6} className={classes.loader} />
          ) : (
            <InputBase
              classes={{
                input: clsx(
                  { [classes.disableArrows]: !onlyCountrySelect },
                  { [classes.countryOnly]: onlyCountrySelect },
                ),
              }}
              id={`${name}-amount`}
              inputProps={{
                'className': classes.exchangeInput,
                'aria-labelledby': `${name}-input-label`,
                'inputMode': 'numeric',
                'type': !onlyCountrySelect ? 'decimal' : 'text',
                'pattern': '[0-9]*',
                'autoComplete': 'off',
              }}
              disabled={isDisabled}
              className={classes.exchangeInputRoot}
              placeholder="-"
              onKeyUp={() => handleKeyUp(value, state)}
              onChange={valueChangeHandler}
              onKeyDown={handleKeyDown}
              onFocus={onFocusIn}
              onBlur={onFocusOut}
              onClick={onInputClick}
              value={inputValue}
              inputRef={inputEl}
            />
          )}
        </Box>
        <Box
          id={`${name}-currency`}
          data-testid={`${name}-currency`}
          className={clsx(classes.currency, {
            [classes.currencySelect]: !disableCountrySelect,
          })}
          display="flex"
          alignItems="center"
          tabIndex={0}
          minWidth={140}
          onKeyDown={handleCountrySelectKeyDown}
        >
          <Box
            data-testid="field-country-select"
            display="flex"
            flex="1"
            onClick={handleClick}
            paddingX={2}
            paddingY={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <UICountryFlag
              className={classes.flag}
              type="circle"
              code={selectedCountry?.countryCode ?? ''}
            />
            <Typography
              variant="h6"
              component="strong"
              color="primary.contrastText"
            >
              {selectedCountry?.currency ?? ''}
            </Typography>
            {!disableCountrySelect && (
              <ExpandMoreRoundedIcon fontSize="large" htmlColor="white" />
            )}
            {disableCountrySelect && <Box width="2rem" />}
          </Box>
          <Dropdown
            id={`${name}-search-input-container`}
            isOpen={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            width={rootEl?.current?.offsetWidth}
          >
            <UIAutocomplete
              name={name}
              placeholder={countriesSearchPlaceholder || ''}
              searchText={searchText}
              setSearchText={setSearchText}
              options={countries}
              noOptionsText={messages?.noOptions}
              inputRef={searchCountryInputEl}
              onClose={handleClose}
              getOptionLabel={option =>
                `${option.countryCode}-${option.currency}`
              }
              filterOptions={options => filterCountries(options, searchText)}
              renderOption={(props, option) => (
                <li {...props}>
                  <UICountryRow
                    countryCode={option.countryCode.toLowerCase()}
                    countryName={option.name.toUpperCase()}
                    currencyCode={option.currency.toUpperCase()}
                  />
                </li>
              )}
              onChange={(_event, newValue) => {
                if (newValue) {
                  handleCountryChange(newValue);
                }
              }}
              isIOS={isIOS}
            />
          </Dropdown>
        </Box>
      </div>

      {errorMessages?.map(errorMessage => {
        return (
          <Alert
            severity="error"
            icon={false}
            key={errorMessage}
            data-testid={name + '-error'}
          >
            {errorMessage}
          </Alert>
        );
      })}
    </Box>
  );
};
