import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    gap: spacing(1.5),

    [breakpoints.down('md')]: {
      paddingTop: spacing(4),
      paddingBottom: spacing(3),
    },

    [breakpoints.down('sm')]: {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },
  },
}));
