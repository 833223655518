export const TAG_LINKS = [
  {
    rel: 'preconnect',
    urls: [
      'https://cdn.optimizely.com',
      'https://tagmanager.google.com',
      '//logx.optimizely.com',
      '//cdn3.optimizely.com',
    ],
  },
  {
    rel: 'dns-prefetch',
    urls: ['https://cdn.optimizely.com', 'https://tagmanager.google.com'],
  },
];

export const REGION_CODES = {
  frCa: 'CA',
  esUs: 'US',
  enUs: 'US',
  enGb: 'GB',
  enNz: 'NZ',
  enMy: 'MY',
  enSe: 'SE',
  enAu: 'AU',
  enCa: 'CA',
  daDk: 'DK',
  en: 'en',
};
