import { CountryData } from '@wr/web-ui';

import { CexCountry } from '@/components';
import { CexCurrencyCorridor } from '@/services';

import {
  CexCorridorGroup,
  CexCurrency,
  CexCurrencyDataFragment,
  CexCurrencyFragment,
  CexCurrencyGroup,
} from './currency-data.types';

export const getCurrencyCorridorSlug = ({
  sendCurrencyCode,
  receiveCurrencyCode,
  sendCountryCode,
  receiveCountryCode,
  cexPageSlug,
}: {
  sendCurrencyCode: string;
  receiveCurrencyCode: string;
  sendCountryCode?: string;
  receiveCountryCode?: string;
  cexPageSlug?: string;
}) => {
  if (!sendCurrencyCode || !receiveCurrencyCode) return cexPageSlug || '';
  return `currency-converter/${sendCurrencyCode}${
    sendCountryCode ? `_${sendCountryCode}` : ''
  }-${receiveCurrencyCode}${
    receiveCountryCode ? `_${receiveCountryCode}` : ''
  }`.toLowerCase();
};

export const getCurrencySlug = ({
  currencyCode,
}: {
  currencyCode?: string;
}) => {
  if (!currencyCode) return '';

  return `currency-converter/currencies/${currencyCode}`.toLowerCase();
};

export const getCurrencyDetailsFromSlug = (slug: string) => {
  const slugParams = slug.split('-');
  const params = slugParams.reduce(
    (
      result: {
        currencyName: string[];
        currencyCode: string;
        countryCode?: string;
      },
      entry,
      index,
    ) => {
      if (index === slugParams.length - 1) {
        result.currencyCode = entry.split('_')[0];
        result.countryCode = entry.split('_')[1];
      } else {
        result.currencyName.push(entry);
      }
      return result;
    },
    { currencyName: [], currencyCode: '', countryCode: '' },
  );
  return {
    currencyCode: params.currencyCode.toLowerCase(),
    countryCode: params.countryCode?.toLowerCase(),
    currencyName: params.currencyName.join('-').toLowerCase(),
  };
};

export const parseCexCurrencyAsCountry = (
  currency: CexCurrencyDataFragment,
) => {
  return {
    currency: currency?.currencyCode ?? '',
    currencyName: currency?.currencyName ?? '',
    countryCode: currency?.countryCode ?? '',
    iso2: (currency?.countryCode ?? '').toLowerCase(),
    name: currency?.countryName ?? '',
  } as CountryData;
};

export const parseCexCurrency = (currency: CexCurrencyDataFragment) => {
  return {
    currencyCode: currency?.currencyCode ?? '',
    currencyName: currency?.currencyName ?? '',
    countryCode: currency?.countryCode ?? '',
    countryName: currency?.countryName ?? '',
    pageSlug: currency?.pageSlug ?? '',
  };
};

export const parseCexCountryToCexCurrency = (
  country: CexCountry,
): CexCurrency => {
  return {
    currencyCode: country.currency?.code as string,
    currencyName: country.currency?.name ?? '',
    countryCode: country.code as string,
    countryName: country.name ?? '',
    pageSlug: getCurrencySlug({
      currencyCode: (country.currency?.code as string) ?? undefined,
    }),
  };
};

export const parseCurrencyInformationToCexCurrency = (
  currencyInformation: CexCurrencyFragment,
): CexCurrency => {
  return {
    currencyCode: currencyInformation.currencyCode ?? '',
    currencyName: currencyInformation.currencyName ?? '',
    countryCode: currencyInformation.countryCode ?? '',
    countryName: currencyInformation.countryName ?? '',
    pageSlug: getCurrencySlug({
      currencyCode: currencyInformation.currencyCode ?? undefined,
    }),
  };
};

export const parseCexCorridors = (
  corridors: (CexCurrencyDataFragment | null)[],
) => {
  return corridors
    .map(currency => {
      if (!currency) return;
      return parseCexCurrency(currency);
    })
    .filter((currency): currency is CexCurrency => currency !== null);
};

export const groupCurrenciesByAlphabet = (
  currencies: CexCurrency[],
): CexCurrencyGroup => {
  return currencies.reduce((currencyGroup: CexCurrencyGroup, currency) => {
    const firstLetter = currency?.currencyCode?.charAt(0)?.toUpperCase() ?? '';
    if (!currencyGroup[firstLetter]) {
      currencyGroup[firstLetter] = [currency];
    } else {
      currencyGroup[firstLetter].push(currency);
    }
    return currencyGroup;
  }, {});
};

export const groupCorridorsByAlphabet = (
  corridors: CexCurrencyCorridor[],
  currencyCode: string | null | undefined,
): CexCorridorGroup => {
  return corridors.reduce((currencyGroup: CexCorridorGroup, corridor) => {
    const firstLetter =
      currencyCode === corridor.sendCurrency.currencyCode
        ? corridor.receiveCurrency.currencyCode?.charAt(0)?.toUpperCase()
        : corridor.sendCurrency.currencyCode?.charAt(0)?.toUpperCase() ?? '';
    if (!currencyGroup[firstLetter]) {
      currencyGroup[firstLetter] = [corridor];
    } else {
      currencyGroup[firstLetter].push(corridor);
    }
    return currencyGroup;
  }, {});
};

export const formatCorridors = ({
  sendCountries,
  receiveCountries,
  currencyInformation,
}: {
  sendCountries: CexCountry[];
  receiveCountries: CexCountry[];
  currencyInformation: CexCurrencyFragment;
}): CexCurrencyCorridor[] => {
  const currencyCorridors: CexCurrencyCorridor[] = [
    ...sendCountries.map(country => {
      return {
        pageSlug: getCurrencyCorridorSlug({
          sendCurrencyCode: country.currency?.code as string,
          receiveCurrencyCode: currencyInformation.currencyCode ?? '',
          cexPageSlug: currencyInformation.pageSlug ?? undefined,
        }),
        sendCurrency: parseCexCountryToCexCurrency(country),
        receiveCurrency: parseCurrencyInformationToCexCurrency(
          currencyInformation,
        ),
      };
    }),
    ...receiveCountries.map(country => {
      return {
        pageSlug: getCurrencyCorridorSlug({
          sendCurrencyCode: currencyInformation.currencyCode ?? '',
          receiveCurrencyCode: country.currency?.code as string,
          cexPageSlug: currencyInformation.pageSlug ?? undefined,
        }),
        sendCurrency: parseCurrencyInformationToCexCurrency(
          currencyInformation,
        ),
        receiveCurrency: parseCexCountryToCexCurrency(country),
      };
    }),
  ];

  return currencyCorridors;
};
