import { Rating as MuiRating } from '@mui/material';
import React from 'react';

import { useStyles } from './styled-rating.styles';
import { StyledRatingProps } from './styled-rating.types';

export const StyledRating: React.FC<StyledRatingProps> = ({
  value,
  precision = 0.5,
  classes: customClasses,
  icon,
}) => {
  const classes = useStyles({ rating: value });

  return (
    <MuiRating
      data-testid="styled-rating"
      value={value}
      precision={precision}
      readOnly
      icon={icon}
      emptyIcon={icon}
      classes={{
        iconEmpty: classes.iconEmpty,
        iconFilled: classes.iconFilled,
        decimal: classes.decimal,
        ...customClasses,
      }}
    />
  );
};
