import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

export const BRANCH_SDK_ROUTES = (
  process.env.NEXT_PUBLIC_BRANCH_SDK_ROUTES || ''
).split(',');
export const BRANCH_SDK_ENABLED = process.env.NEXT_PUBLIC_BRANCH_SDK_ENABLED;
export const BRANCH_SDK_API_KEY =
  process.env.NEXT_PUBLIC_BRANCH_SDK_API_KEY || '';
export const BRANCH_SDK_API_URL =
  process.env.NEXT_PUBLIC_BRANCH_SDK_API_URL || '';

export const BranchWebSDK: React.FC = () => {
  const { route } = useRouter();

  if (
    BRANCH_SDK_ENABLED &&
    BRANCH_SDK_API_URL &&
    route &&
    BRANCH_SDK_ROUTES.includes(route)
  ) {
    const snippet = `(function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="${BRANCH_SDK_API_URL}";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);branch.init("${BRANCH_SDK_API_KEY}");`;

    return (
      <Head>
        <script type="text/javascript">{snippet}</script>
      </Head>
    );
  }

  return null;
};
