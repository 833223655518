import { Accordion as MuiAccordion } from '@mui/material';
import * as React from 'react';

import { MuiAccordionProps } from './accordion.types';

export const Accordion: React.FC<MuiAccordionProps> = (
  props: MuiAccordionProps,
) => {
  return <MuiAccordion {...props}>{props.children}</MuiAccordion>;
};
