export type ExchangeRate = {
  fee: number;
  totalToPay: number;
  conversionRate: number;
  crossedOutRate: number;
  hasPromo: boolean;
};

export enum ExchangeRateCalculationType {
  Receive = 'RECEIVE',
  Send = 'SEND',
}

export type CalculatorExchangeRateProps = {
  isLoading?: boolean;
  sendCurrencyCode: string;
  receiveCurrencyCode: string;
  onCalculateExchangeRate?: (rate: number) => void;
  exchangeRate?: ExchangeRate;
  promoLabel?: string | null;
  blendedRateLabel?: string | null;
};
