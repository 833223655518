import { DEFAULT_LANGUAGE, setLanguageToCookie } from '@wr/web-shared';
import { HeaderSelect } from '@wr/web-ui';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import { AppContext } from '@/context';
import { useUpdateLanguageSelection } from '@/hooks';

import { HeaderLanguageSelectProps } from './header-language-select.types';
import { getLanguages } from './header-language-select.utils';

export const HeaderLanguageSelect: React.FC<HeaderLanguageSelectProps> = ({
  color,
  headerTheme,
  languageFromCookie,
}) => {
  const { regions } = useContext(AppContext);
  const { reload } = useRouter();
  const { onLanguageChange, onLanguageOpen } = useUpdateLanguageSelection(
    setLanguageToCookie,
    reload,
  );

  const languages = useMemo(() => getLanguages(languageFromCookie, regions), [
    regions,
    languageFromCookie,
  ]);

  const language = languageFromCookie || DEFAULT_LANGUAGE;
  return (
    <>
      {languages?.length && language && (
        <HeaderSelect
          name="language-selector"
          color={color}
          onOpen={onLanguageOpen}
          onChange={onLanguageChange}
          value={language}
          options={languages}
          data-testid="language-selector"
          headerVariant={headerTheme}
        />
      )}
    </>
  );
};
