import { Typography } from '@mui/material';
import { bffSchema } from '@wr/web-shared';
import React, { useEffect, useState } from 'react';

import { useCalculatorExchangeRate } from '@/context';

import { CexCurrencyExchangeRateProps } from './cex-currency-conversion-rates.types';

export const CexCurrencyExchangeRate: React.FC<CexCurrencyExchangeRateProps> = ({
  sendCurrency,
  receiveCurrency,
}) => {
  const { calculateExchangeRate } = useCalculatorExchangeRate();

  const [exchangeRate, setExchangeRate] = useState(0);

  useEffect(() => {
    if (sendCurrency.currencyCode && receiveCurrency.currencyCode) {
      calculateExchangeRate({
        amount: 1,
        type: bffSchema.CalculationType.Send,
        sendCountryCode: sendCurrency?.countryCode ?? '',
        sendCurrencyCode: sendCurrency?.currencyCode ?? '',
        receiveCountryCode: receiveCurrency?.countryCode ?? '',
        receiveCurrencyCode: receiveCurrency?.currencyCode ?? '',
        payOutMethodCode: '',
        correspondentId: '',
      }).then(result => {
        setExchangeRate(result?.conversionRate ?? 0);
      });
    }
  }, [
    calculateExchangeRate,
    receiveCurrency?.countryCode,
    receiveCurrency.currencyCode,
    sendCurrency?.countryCode,
    sendCurrency.currencyCode,
  ]);

  return <Typography component="span">{exchangeRate.toFixed(2)}</Typography>;
};
