import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputLabel,
  Paper,
} from '@mui/material';
import { EVENT, INTERACTION_NAME, pushToDataLayer } from '@wr/web-shared';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { useDebounce } from '@/hooks/use-debounce';

import { CalculatorLoader } from '../calculator-loader';
import useStyles from './phone-input.styles';
import { PhoneInputProps } from './phone-input.types';
import {
  preventPastingSpecificValuesInPhoneInput,
  preventTypingAdditionalKeysInPhoneInput,
  preventWheelInPhoneInput,
} from './phone-input.utils';

export const PhoneInput: React.FC<PhoneInputProps> = ({
  phoneNumberInvalidErrorMessage,
  isFetching,
  isPhoneNumberValid,
  isError,
  phoneValidatorLabel,
  phoneValidatorPlaceholder,
  dialCode,
  mobileNumber,
  onValueChange,
}) => {
  const classes = useStyles();
  const inputEl = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(mobileNumber || '');

  const debouncedValue = useDebounce(value || '', 1000);

  useEffect(() => {
    if (debouncedValue) {
      onValueChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    const value = event?.currentTarget?.value;

    if (value || value === '') {
      setValue(event?.currentTarget.value);
    }
  };

  return (
    <>
      <Paper
        ref={inputEl}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        classes={{
          root: clsx(classes.root, { [classes.errorField]: isError }),
          rounded: classes.fieldRounded,
          elevation1: classes.fieldElevation,
          elevation2: classes.fieldFocusedElevation,
        }}
        elevation={isFocused ? 2 : 1}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.dialCodeContainer}
          minWidth={80}
        >
          {dialCode && (
            <span
              className={classes.dialCode}
              data-testid="phone-selector-dial-code"
            >
              +{dialCode}
            </span>
          )}
        </Box>
        <Divider className={classes.divider} orientation="vertical" />
        <Box display="flex" alignItems="center" paddingLeft={2} width="100%">
          <FormControl fullWidth>
            <InputLabel
              id="phone-input-label"
              shrink
              htmlFor="phone-input"
              className={classes.label}
              classes={{
                focused: classes.focusedLabel,
              }}
            >
              {phoneValidatorLabel}
            </InputLabel>
            <InputBase
              id={`phone-input`}
              classes={{
                error: classes.inputError,
              }}
              data-testid="phone-input"
              aria-describedby={`phone-input-error`}
              inputProps={{
                inputMode: 'tel',
                type: 'number',
                maxLength: 12,
                className: classes.inputNumber,
              }}
              color="primary"
              error={isError}
              placeholder={phoneValidatorPlaceholder || undefined}
              onChange={onChange}
              value={value}
              onClick={() => {
                pushToDataLayer({
                  event: EVENT.VISITOR_INTERACTION,
                  interactionName: INTERACTION_NAME.CALC_PHONE_NUMBER_CLICK,
                });
              }}
              onKeyDown={preventTypingAdditionalKeysInPhoneInput}
              onPaste={preventPastingSpecificValuesInPhoneInput}
              onWheel={preventWheelInPhoneInput}
              endAdornment={
                <InputAdornment position="start" className={classes.adornment}>
                  {isFetching ? (
                    <CalculatorLoader size={4} />
                  ) : (
                    value &&
                    !isError &&
                    isPhoneNumberValid && (
                      <CheckCircleIcon
                        data-testid={`phone-input-valid-number`}
                        className={classes.validNumber}
                      />
                    )
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Paper>
      {isError && (
        <FormHelperText id={`phone-input-error`} aria-live="assertive" error>
          {phoneNumberInvalidErrorMessage}
        </FormHelperText>
      )}
    </>
  );
};
