import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Hidden, Typography } from '@mui/material';
import React from 'react';

import { onEnterOrSpacePress } from '../../utils';
import { Image } from '../image';
import { useMoreButton } from './sticky-banner-content.hooks';
import { useStyles } from './sticky-banner-content.styles';
import { StickyBannerContentProps } from './sticky-banner-content.types';

export function StickyBannerContent<RichText>({
  image,
  RichTextComponent,
  description,
  name,
  backgroundColor,
  textColor,
  isCollapsible = false,
  readMoreLabel,
  readLessLabel,
  containerProps,
}: StickyBannerContentProps<RichText>): React.ReactElement {
  const contentRef = React.createRef<HTMLDivElement>();

  const { isVisible, isCollapsed, setCollapseState } = useMoreButton(
    isCollapsible,
    contentRef,
  );

  const classes = useStyles({
    backgroundColor,
    textColor,
    isCollapsed,
    isVisible,
  });
  const handleClick = (): void => setCollapseState(!isCollapsed);
  const handleKeyDown = (event: React.KeyboardEvent): void =>
    onEnterOrSpacePress(event, handleClick);

  return (
    <div role="banner" id={name} {...containerProps}>
      <div
        className={classes.description}
        data-testid="sticky-banner-description"
      >
        <div className={classes.overflowWrapper} ref={contentRef}>
          {image && (
            <div className={classes.image}>
              <Image {...image} />
            </div>
          )}
          {description && RichTextComponent && (
            <RichTextComponent {...description} />
          )}
        </div>
      </div>
      {isVisible && (
        <Hidden mdUp>
          <div
            className={classes.collapseWrapper}
            data-testid="sticky-banner-collapse-wrapper"
            role="button"
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
          >
            <Typography variant="body1">
              {isCollapsed ? readMoreLabel : readLessLabel}
            </Typography>
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          </div>
        </Hidden>
      )}
    </div>
  );
}
