import { useLazyQuery } from '@apollo/client';
import { bffSchema } from '@wr/web-shared';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  AppContext,
  CalculatorContext,
  PayoutMethod,
  PayoutMethodsQueryVariables,
} from '@/context';

export const useCalculatorPayoutMethods = ({
  isExternal,
}: {
  isExternal: boolean;
}) => {
  const { intl } = useContext(AppContext);

  const [{ sendCountry, receiveCountry }] = useContext(CalculatorContext);
  const [payoutMethods, setPayoutMethods] = useState<PayoutMethod[] | null>(
    null,
  );

  const parsePayoutMethod = useCallback(
    (method: bffSchema.PayOutMethod) => {
      return {
        code: method?.code ?? '',
        name: intl.payoutMethodsLabelsById[method?.code ?? ''],
        payOutTimeEstimate: method?.payOutTimeEstimate ?? '',
        defaultCorrespondentId: method?.correspondents?.[0]?.id ?? '',
        correspondents: (method?.correspondents ?? []).map(correspondent => ({
          id: correspondent?.id ?? '',
          name: correspondent?.name ?? '',
          payOutTime: correspondent?.payOutTime ?? '',
        })),
      };
    },
    [intl.payoutMethodsLabelsById],
  );

  const [getPayoutMethods] = useLazyQuery<
    bffSchema.PayoutMethodsQuery,
    bffSchema.PayoutMethodsQueryVariables
  >(bffSchema.PayoutMethods, {
    onCompleted: result => {
      if (!isExternal) {
        if (result?.payOutMethods?.length) {
          setPayoutMethods(
            result.payOutMethods
              .filter(
                (method): method is bffSchema.PayOutMethod => method !== null,
              )
              .map(parsePayoutMethod),
          );
        } else {
          setPayoutMethods([]);
        }
      }
    },
  });

  const fetchPayoutMethods = useCallback(
    async (variables: PayoutMethodsQueryVariables) => {
      if (
        variables?.sendCountry &&
        variables?.receiveCountry &&
        variables?.receiveCurrency
      ) {
        const result = await getPayoutMethods({
          variables,
        });
        if (result?.data?.payOutMethods?.length) {
          return result.data.payOutMethods
            .filter(
              (method): method is bffSchema.PayOutMethod => method !== null,
            )
            .map(parsePayoutMethod);
        }
      }
      return [];
    },
    [getPayoutMethods, parsePayoutMethod],
  );

  useEffect(() => {
    if (
      !isExternal &&
      sendCountry?.countryCode &&
      receiveCountry?.countryCode
    ) {
      fetchPayoutMethods({
        sendCountry: sendCountry.countryCode,
        receiveCountry: receiveCountry.countryCode,
        receiveCurrency: receiveCountry.currency,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sendCountry?.countryCode,
    receiveCountry?.countryCode,
    receiveCountry?.currency,
  ]);

  /**
   * Default payout method (first option)
   */
  const defaultPayoutMethod = useMemo(() => {
    return payoutMethods === null
      ? null
      : !payoutMethods.length
      ? undefined
      : payoutMethods[0];
  }, [payoutMethods]);

  return {
    payoutMethods,
    defaultPayoutMethod,
    isLoading: payoutMethods === null,
    fetchPayoutMethods,
  };
};
