import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  section: {
    padding: theme.spacing(7, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10, 0),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(13, 0),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(20, 0),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  description: {
    marginBottom: theme.spacing(4),
  },
  card: {
    height: '15.5rem',
    width: '15.5rem',

    [theme.breakpoints.up('lg')]: {
      height: '16.875rem',
      width: '16.875rem',
    },
    [theme.breakpoints.up('xl')]: {
      height: '20.125rem',
      width: '20.125rem',
    },
  },
  carouselText: {
    marginBottom: theme.spacing(2),
  },
  cardsFooter: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
  paymentIcons: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  paymentIcon: {
    height: '2rem',
    width: '3.25rem',
    margin: theme.spacing(0, 0.5),
  },
}));
