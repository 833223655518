import { alpha } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';

import { colors } from '../../styles';

// all main languages are covered here
export const latinCharsRange =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD';
// more rare languages or charecters, like polish or czech languages, which we don't have at the moment, but users can input them
export const extLatinCharsRange =
  'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF';

// More readable values
export const ThemeBreakpoints = {
  xs: 375,
  mobile: 640,
  tablet: 960,
  desktop: 1280,
};

// https://www.figma.com/file/m4mpQtyhdo5OIWXytwfuQm/Stylesheet
export const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: colors.purpleNormal,
      contrastText: colors.white,
      dark: colors.purpleDark,
    },
    secondary: {
      main: colors.pelorous,
      contrastText: colors.black,
      dark: colors.greenDark,
    },
    text: {
      primary: colors.black,
      secondary: colors.greyDark,
    },
    error: {
      main: colors.redNormal,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Figtree, Arial',
    h1: {
      fontSize: '2.5rem', // 40px
      lineHeight: 1.1, // 44px
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem', // 32px
      lineHeight: 1.125, // 36px
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.75rem', // 28px
      lineHeight: 1.14285714286, // 32px
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.5rem', // 24px
      lineHeight: 1.16666666667, // 28px
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.2, // 24px
      fontSize: '1.25rem', // 20px
    },
    h6: {
      fontWeight: 700,
      lineHeight: 1.2, // 24px
      fontSize: '1.25rem', // 20px
    },
    body1: {
      fontWeight: 400,
      lineHeight: 1.5, // 24px
      fontSize: '1rem', // 16px
    },
    body2: {
      fontWeight: 400,
      lineHeight: 1.5, // 30px
      fontSize: '1.25rem', // 20px
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '1.5rem', // 24px
      lineHeight: 1.16666666667, // 28px
    },
    subtitle2: {
      'fontSize': '0.875rem',
      '@media (min-width: 640px)': {
        fontSize: '1.125rem',
      },
      '@media (min-width: 960px)': {
        fontSize: '1.375rem',
      },
    },
    caption: {
      fontSize: '0.8125rem',
    },
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiAccordion-expanded': {
            margin: 0,
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexDirection: 'column',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          'minHeight': '3.5rem',

          '&.MuiAccordionSummary-expanded': {
            minHeight: '3.5rem',
          },
        },
        content: {
          'margin': '1.125rem 0',

          '&.MuiAccordionSummary-expanded': {
            margin: '1.125rem 0',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '0.875rem 1rem',
          lineHeight: 1.5, // 20px
          fontSize: '1rem', // 16px
        },
        message: {
          padding: 0,
        },
        standardInfo: {
          color: colors.white,
          backgroundColor: colors.purpleNormal,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible, &:active': {
            outline: 'none',
            boxShadow: `0 0 0 4px ${colors.purpleLight}`,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: 'calc(50% - 0.75rem)',
        },
        hasPopupIcon: {
          '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            paddingRight: '1rem',
          },
        },
        inputRoot: {
          '&[class*="MuiInput-root"]': {
            padding: '1rem',
            paddingBottom: '1rem',
          },
          '&[class*="MuiOutlinedInput-root"]': {
            'padding': '0.625rem 1rem',

            '& .MuiAutocomplete-input': {
              padding: '0.5rem 0.25rem',
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        sizeLarge: {
          fontSize: '1rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          'marginBottom': '0.5rem',

          '@media (min-width: 960px)': {
            marginBottom: '1rem',
          },
        },
        gutterBottom: {
          'marginBottom': '0.25rem',

          '@media (min-width: 960px)': {
            marginBottom: '0.5rem',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: colors.purpleNormal,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: 1.25, // 20px
          fontSize: '1rem', // 16px
          color: colors.black,
          paddingTop: '1rem',
          paddingBottom: '1rem',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          'paddingTop': '0.25rem',
          'paddingBottom': '0.25rem',

          '&.MuiListItem-selected': {
            backgroundColor: colors.purpleLight,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          'paddingTop': '1rem',
          'paddingBottom': '1rem',

          '@media (min-width: 960px)': {
            fontSize: 'initial',
            lineHeight: 'initial',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'textTransform': 'initial',
          'fontWeight': 700,
          // default button size is "medium"
          'minWidth': 'unset',
          'padding': '1rem',
          'fontSize': '1.25rem',
          'lineHeight': 1.2,
          '&:hover': {
            backgroundColor: 'none',
          },
          '&.MuiButton-disabled': {
            color: colors.greyDark,
          },
        },

        sizeSmall: {
          fontSize: '1rem',
          lineHeight: 1.25,
        },

        contained: {
          'boxShadow': 'none',

          '&:hover': {
            boxShadow: 'none',
            backgroundColor: colors.purpleDark,
          },
          '&.MuiButton-disabled': {
            backgroundColor: colors.greyLight,
            color: colors.greyDark,
          },
          '&.Mui-focusVisible, &:active': {
            outline: 'none',
            boxShadow: `0 0 0 4px ${colors.purpleLight}`,
          },
        },

        // containedPrimary is already set correctly based on colors in theme

        containedSecondary: {
          'backgroundColor': colors.white,
          'color': colors.purpleNormal,

          '&:hover': {
            color: colors.white,
            backgroundColor: colors.purpleDark,
          },
        },

        containedSizeSmall: {
          padding: '0.625rem',
        },

        outlined: {
          'padding': '1rem',

          '&.MuiButton-disabled': {
            backgroundColor: colors.greyLight,
            border: 'none',
          },
          '&.Mui-focusVisible, &:active': {
            outline: 'none',
            boxShadow: `0 0 0 4px ${colors.purpleLight}`,
          },
        },

        outlinedSizeSmall: {
          padding: '0.625rem',
          fontSize: '1rem',
          lineHeight: 1.25,
        },

        outlinedPrimary: {
          'border': `2px solid ${colors.purpleNormal}`,

          '&:hover': {
            border: `2px solid ${colors.purpleDark}`,
            color: colors.purpleDark,
            backgroundColor: 'none',
          },
        },

        outlinedSecondary: {
          'border': `2px solid ${colors.white}`,
          'color': colors.white,

          '&:hover': {
            color: colors.purpleDark,
            border: `2px solid ${colors.purpleDark}`,
          },
          '&.MuiButton-disabled': {
            border: 'none',
          },
        },

        text: {
          'borderRadius': 0,
          'fontWeight': 500,

          '&.Mui-focusVisible, &:active': {
            outline: 'none',
            boxShadow: `0 0 0 4px ${colors.purpleLight}`,
          },
        },

        textSizeSmall: {
          padding: '0.375rem 0.25rem',
        },

        textPrimary: {
          'color': colors.black,
          '&:hover': {
            backgroundColor: colors.purpleDark,
            color: colors.white,
          },
          '&.Mui-focusVisible, &:active': {
            backgroundColor: colors.purpleLight,
          },
        },
        textSecondary: {
          'color': colors.white,
          '&:hover': {
            backgroundColor: colors.purpleDark,
          },
          '&.Mui-focusVisible, &:active': {
            backgroundColor: colors.purpleLight,
            color: colors.black,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '&.MuiSelect-outlined': {
            paddingRight: '3rem',
            minHeight: 'auto',
            lineHeight: 1,
          },
        },
        icon: {
          color: 'inherit',
          opacity: 0.54,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '&::-webkit-scrollbar': {
            width: 14,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            boxShadow: 'inset 1px 0 0 #DEDEDE',
            webkitBoxShadow: 'inset 1px 0 0 #DEDEDE',
          },
          '&::-webkit-scrollbar-thumb': {
            border: '4px solid transparent',
            backgroundClip: 'content-box',
            backgroundColor: colors.purpleNormal,
            borderRadius: 16,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          'marginTop': 0,
          'textTransform': 'titlecase',

          '&.MuiFormHelperText-error': {
            fontWeight: 600,
            fontSize: '1rem',
            paddingTop: '0.5rem',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0.625rem',
        },
        colorSecondary: {
          'color': colors.white,

          '&:hover, &:active, &.Mui-focusVisible': {
            backgroundColor: alpha(colors.white, 0.3),
          },
        },
        sizeSmall: {
          padding: '0.125rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
        input: {
          fontSize: '1.25rem',
          height: '1.25rem',
          lineHeight: 1.2,
          fontWeight: 700,
          paddingTop: '0.625rem',
          paddingBottom: '0.625rem',
        },
        inputSizeSmall: {
          fontSize: '1rem',
          lineHeight: 1.25,
          padding: '0.53125rem 0.875rem',
        },
        colorSecondary: {
          color: colors.white,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.darkGray,
          height: '0.125rem',
        },
        light: {
          backgroundColor: colors.white,
        },
        vertical: {
          width: 'auto',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: colors.purpleNormal,
        },
        colorSecondary: {
          'color': colors.white,

          '&.MuiFormLabel-focused': {
            color: colors.white,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '1.25rem',
        },
        fontSizeSmall: {
          fontSize: '1rem',
        },
        fontSizeLarge: {
          fontSize: '2rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '1rem 0.75rem',
        },
        inputSizeSmall: {
          fontSize: '1rem',
          lineHeight: 1.25,
          padding: '0.53125rem 0.875rem',
        },
        notchedOutline: {
          borderColor: colors.purpleNormal,
        },
        root: {
          'padding': 0,

          '& legend': {
            fontSize: '1rem',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.purpleNormal,
          },
          '&.MuiOutlinedInput-error': {
            '&.MuiOutlinedInput-notchedOutline': {
              borderColor: colors.redNormal,
            },
          },
        },
        colorSecondary: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.white,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.white,
          },
          '&.MuiOutlinedInput-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.white,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.MuiTextField-root': {
            marginBottom: '1rem',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          'transform': 'translate(14px, 12px) scale(1)',
          'fontSize': '1.25rem',
          'fontWeight': 700,

          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -11px) scale(0.8)',
            fontWeight: 500,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          color: alpha(colors.purpleNormal, 0.54),
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body: {
          backgroundColor: #fff,
          lineHeight: 1.25,
          fontSize: 16,
        }       
      `,
    },
  },
  customComponents: {
    appBanner: {
      backgroundColor: colors.creamNormal,
    },
    appsImages: {
      badge: {
        backgroundColor: colors.greenDark,
      },
      badgeLabel: {
        color: colors.white,
      },
    },
    autoComplete: {
      paper: {
        color: colors.veryDarkGrayishBlue,
      },
      inputBase: {
        backgroundColor: colors.white,
        borderBottomColor: colors.darkGray,
      },
    },
    calculator: {
      exchangeRate: {
        textColor: colors.purpleNormal,
        strikeThroughColor: colors.black,
      },
      countryField: {
        borderRadius: 8,
        labelColor: colors.purpleNormal,
        backgroundColor: colors.purpleDark,
        selectColor: colors.white,
        inputColor: colors.black,
      },
      autocomplete: {
        textColor: colors.black,
        borderColor: 'transparent',
      },
    },
    card: {
      backgroundColor: colors.purpleNormal,
      border: 'none',
      overlay: {
        background: colors.purpleExtraLight,
        button: {
          background: colors.purpleNormal,
          borderColor: colors.purpleNormal,
          color: colors.white,
        },
      },
      chip: {
        backgroundColor: colors.greyLight,
      },
      textContainer: {
        color: colors.white,
      },
      description: {
        color: colors.white,
      },
      ctaButton: {
        backgroundColor: 'transparent',
        color: colors.white,
        border: `2px solid ${colors.white}`,
      },
      title: {
        color: colors.white,
      },
    },
    carousel: {
      arrow: {
        color: colors.greenDark,
      },
      button: {
        color: colors.greenDark,
      },
    },
    comparisonTable: {
      table: {
        borderColor: colors.purpleNormal,
      },
      tableCell: {
        borderColor: colors.purpleNormal,
      },
      tableCaption: {
        color: colors.white,
        backgroundColor: colors.purpleNormal,
      },
    },
    correspondent: {
      backgroundColor: colors.white,
      borderColor: colors.greyLight,
    },
    correspondents: {
      section: {
        backgroundColor: colors.creamNormal,
      },
    },
    countrySearch: {
      inputBase: {
        backgroundColor: colors.white,
        border: colors.purpleNormal,
        boxShadow: colors.purpleLight,
      },
      option: {
        backgroundColor: colors.purpleExtraLight,
      },
    },
    countrySelect: {
      flag: {
        boxShadow: colors.softCatalinaBlue,
      },
    },
    dialog: {
      header: {
        color: colors.purpleNormal,
      },
      legend: {
        color: colors.greyDark,
      },
    },
    dropdownDesktop: {
      popper: {
        borderColor: colors.solitude,
        color: colors.veryDarkGrayishBlue,
      },
    },
    dropdownMobile: {
      closeButton: {
        color: colors.white,
      },
    },
    exchangeRateGraph: {
      graph: {
        backgroundColor: colors.purpleNormal,
      },
      tab: {
        color: colors.greenDark,
      },
      selected: {
        color: colors.white,
        backgroundColor: colors.purpleNormal,
      },
      legend: {
        backgroundColor: colors.purpleDark,
        color: colors.white,
      },
    },
    faqModule: {
      section: {
        backgroundColor: colors.purpleNormal,
      },
    },
    faqModuleItem: {
      expandIcon: {
        color: colors.greenDark,
      },
      expandedMenu: {
        backgroundColor: colors.creamNormal,
        borderLeftColor: colors.greenDark,
      },
    },
    footer: {
      backgroundColor: colors.black,
      navigationLink: {
        color: colors.black,
        backgroundColor: colors.purpleLight,
        textDecoration: 'none',
      },
      navigationGroupHeading: {
        fontFamily: 'Figtree, Arial',
        fontSize: '2rem',
        fontWeight: 700,
      },
    },
    formSectionButton: {
      color: colors.black,
      borderColor: {
        hasData: colors.purpleNormal,
        hasNoData: colors.black,
      },
      contextualIcon: {
        color: colors.purpleNormal,
      },
      actionWrapper: {
        color: colors.greenDark,
      },
    },
    formSelectField: {
      inputLabel: {
        color: colors.greyDark,
      },
      select: {
        color: colors.black,
      },
      disabledInput: {
        backgroundColor: colors.whiteSmoke,
      },
    },
    howDoesItWork: {
      section: {
        backgroundColor: colors.creamNormal,
      },
    },
    informationBanner: {
      container: {
        backgroundColor: colors.maritime,
        color: colors.purpleDark,
      },
    },
    informationDialog: {
      richTextContent: {
        h3: {
          color: colors.black,
        },
      },
      closeButton: {
        color: colors.kale,
      },
    },
    message: {
      secondaryText: {
        color: colors.white,
      },
      reviewsMessage: {
        color: colors.white,
      },
      reviewsLink: {
        color: colors.white,
      },
    },
    moduleHeader: {
      header: {
        color: colors.black,
      },
      underscore: {
        backgroundColor: colors.white,
      },
      mainHeader: {
        color: colors.black,
        backgroundColor: colors.white,
      },
    },
    notFound: {
      container: {
        backgroundColor: colors.white,
      },
    },
    perimeterXCaptchaModal: {
      pxDialog: {
        backgroundColor: colors.creamNormal,
      },
    },
    promotionalBanner: {
      container: {
        backgroundColor: colors.creamNormal,
        color: colors.white,
      },
      description: {
        h2: {
          backgroundColor: colors.purpleDark,
          backgroundImage: {
            left: colors.blueViolet,
            right: colors.violet,
          },
        },
        p: {
          color: colors.black,
        },
      },
    },
    promotionalBannerContextual: {
      root: {
        backgroundColor: colors.purpleNormal,
      },
      content: {
        color: colors.white,
        b: {
          color: colors.greenLight,
        },
      },
      image: {
        backgroundColor: colors.purpleNormal,
      },
    },
    regionDropdown: {
      notchedOutline: {
        borderColor: colors.white,
      },
    },
    review: {
      review: {
        borderColor: colors.greyNormal,
      },
      comment: {
        color: colors.greyDark,
      },
    },
    reviews: {
      section: {
        backgroundColor: colors.creamNormal,
      },
      heading: {
        backgroundColor: colors.black,
      },
    },
    search: {
      option: {
        borderTopColor: colors.greyLight,
      },
      link: {
        color: colors.black,
        textDecorationColor: 'rgba(129, 63, 214, 0.4)',
      },
    },
    select: {
      select: {
        color: colors.black,
      },
      icon: {
        color: colors.pelorous,
        hover: {
          color: colors.turquoise,
        },
      },
      disabled: {
        color: colors.redNormal,
        hover: {
          color: colors.redNormal,
        },
      },
    },
    alert: {
      error: {
        backgroundColor: colors.redNormal,
        color: colors.black,
      },
      info: {
        backgroundColor: colors.purpleDark,
        color: colors.white,
      },
      warning: {
        backgroundColor: colors.yellow,
        color: colors.black,
      },
      success: {
        backgroundColor: colors.greenDark,
        color: colors.black,
      },
    },
    socialProofing: {
      title: {
        color: colors.black,
      },
      subTitle: {
        b: {
          color: colors.purpleNormal,
        },
      },
      stores: {
        color: colors.black,
      },
    },
    stepByStep: {
      listItem: {
        backgroundColor: colors.creamNormal,
      },
      stepHeading: {
        color: colors.purpleNormal,
      },
    },
    stepper: {
      selectedStepHeader: {
        color: colors.purpleLight,
      },
      numberMobile: {
        color: colors.purpleLight,
        backgroundColor: colors.purpleNormal,
      },
    },
    stickyBannerContent: {
      collapseWrapper: {
        backgroundColor: colors.white,
        boxShadowColor: colors.shadeBlack,
      },
      expandMoreIcon: {
        color: colors.white,
      },
    },
    storeRatings: {
      wrapper: {
        color: colors.black,
        hoverColor: colors.black,
      },
    },
    table: {
      color: colors.greyDark,
      tbody: {
        backgroundColor: colors.solitude,
      },
    },
    textField: {
      root: {
        backgroundColor: colors.white,
        color: colors.greyDark,
      },
    },
    tooltip: {
      backgroundColor: colors.white,
      borderColor: colors.solitude,
      boxShadowColor: colors.resolutionBlue,
      color: colors.black,
      arrow: {
        color: colors.white,
      },
    },
    weAreRegulated: {
      selectIcon: {
        color: colors.greenDark,
      },
      regulatoryBody: {
        backgroundColor: colors.purpleNormal,
        color: colors.white,
      },
      regulatoryBodyContent: {
        scrollbarThumb: {
          backgroundColor: colors.white,
        },
      },
    },
    video: {
      playButton: {
        circle: {
          backgroundColor: colors.white,
        },
        icon: {
          color: colors.white,
        },
        circleAnimation: {
          backgroundColor: colors.white,
        },
      },
    },
    listOfCountries: {
      section: {
        backgroundColor: colors.purpleNormal,
      },
      countryLink: {
        backgroundColor: alpha(colors.greyDark, 0.6),
        borderRadius: '4px',
        boxShadow: 'none',
        hover: {
          backgroundColor: colors.purpleDark,
        },
      },
      subheader: {
        color: colors.white,
      },
      heading: {
        color: colors.white,
      },
    },
    header: {
      headerWrapper: {
        backgroundColor: colors.white,
        dark: {
          backgroundColor: alpha(colors.black, 0.5),
        },
      },
      divider: {
        backgroundColor: colors.black,
      },
      menuItem: {
        borderTopColor: colors.solitude,
      },
      logo: {
        height: 30,
        breakpointsUpSm: {
          height: 44,
        },
      },
      navLink: {
        color: colors.black,
        textDecoration: 'none',
        fontSize: '16px',
        dark: {
          color: colors.white,
        },
        hover: {
          color: colors.white,
          backgroundColor: colors.purpleDark,
          textDecoration: 'none',
        },
      },
      selectButtonText: {
        color: colors.black,
        textDecoration: 'none',
        fontSize: '16px',
        dark: {
          color: colors.white,
        },
        hover: {
          color: colors.white,
          backgroundColor: colors.purpleDark,
          textDecoration: 'none',
        },
      },
      regionLangText: {
        color: colors.darkGray,
      },
    },
    dynamicFormPopup: {
      icon: {
        fillColor: colors.greenDark,
      },
    },
    formCountryField: {
      suffix: {
        color: colors.black,
      },
    },
    formDateField: {
      datePicker: {
        borderColor: colors.purpleNormal,
      },
    },
    formRadioField: {
      label: {
        color: colors.black,
      },
    },
    dynamicFormSelectField: {
      select: {
        color: colors.black,
        notchedOutline: {
          borderColor: colors.purpleNormal,
        },
      },
      suffix: {
        color: colors.black,
      },
      clearIconButton: {
        color: colors.black,
        focus: {
          borderColor: colors.purpleNormal,
        },
      },
    },
    formTextField: {
      prefix: {
        p: {
          color: colors.purpleNormal,
        },
      },
      optional: {
        color: colors.black,
      },
      textField: {
        color: colors.black,
      },
    },
    textSection: {
      flexDirection: 'column',
      textSectionColumn: {
        breakpointsUpMd: {
          height: 'auto',
          alignItems: 'normal',
        },
      },
      textSectionContent: {
        textAlign: 'inherit',
      },
      textSectionImage: {
        breakpointsSm: {
          height: '10rem',
        },
        breakpointsMdUp: {
          height: 'inherit',
        },
      },
    },
    fullWidthBanner: {
      section: {
        breakpointSm: {
          flexDirection: 'unset',
        },
        breakpointMdUp: {
          flexDirection: 'unset',
        },
      },
      container: {
        breakpointSm: {
          paddingLeft: createTheme().spacing(4),
          paddingRight: createTheme().spacing(4),
        },
      },
      borderContainer: {
        padding: createTheme().spacing(5),
      },
    },
  },
};

export const muiTheme = createTheme(theme as ThemeOptions);
