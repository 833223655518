import { Typography } from '@mui/material';
import clsx from 'clsx';

import { Container as UIContainer } from '../container';
import { Image } from '../image';
import { QrCode } from '../qr-code';
import { TrustpilotWidget } from '../trustpilot-widget';
import { useStyles } from './hero.styles';
import { HeroProps } from './hero.types';

export function Hero<Anchor, Button, RichText>({
  name,
  heading,
  subheading,
  button,
  foregroundImage,
  backgroundImage,
  bottomImage,
  trustpilotMessage,
  trustpilotLogo,
  trustpilotLink,
  trustpilotWidgetUrl,
  qrCode,
  AnchorComponent,
  ButtonComponent,
  RichTextComponent,
  isSectionOnTop,
  containerProps,
  children,
  backgroundColor,
  secondBackgroundColor,
  appStoreIconLink,
  playStoreIconLink,
  contentFlexDirection = 'row',
  contentAlignment,
  hideDescriptionOnMobile,
}: HeroProps<Anchor, Button, RichText>) {
  const classes = useStyles({
    isSectionOnTop,
    backgroundColor,
    secondBackgroundColor,
    contentFlexDirection,
    contentAlignment,
    hideDescriptionOnMobile,
  });
  const hasChildren = Boolean(children);
  const trustpilotContent = (
    <>
      <Typography
        component="span"
        display="block"
        variant="h6"
        color="primary.contrastText"
      >
        {trustpilotMessage}
      </Typography>
      <Typography
        component="span"
        display="block"
        variant="h6"
        color="primary.contrastText"
      >
        {trustpilotLink?.children}
      </Typography>
    </>
  );

  const content = (
    <div
      data-testid="hero-content"
      className={clsx(classes.heroContent, {
        [classes.heroContentCenter]: hasChildren,
      })}
    >
      {heading && <RichTextComponent {...heading} />}
      <div className={classes.descriptionContainer}>
        {subheading && <RichTextComponent {...subheading} />}
      </div>
      {foregroundImage && (
        <div className={classes.foregroundImageContainer}>
          <Image {...foregroundImage} priority={true} />
        </div>
      )}
      {button && <ButtonComponent {...button} />}
      <div className={classes.flexContainer}>
        {qrCode?.url && <QrCode {...qrCode} />}

        {trustpilotWidgetUrl && (
          <div
            data-testid="hero-trustpilot-widget"
            className={`${classes.trustpilotWidget}`}
          >
            <TrustpilotWidget url={trustpilotWidgetUrl} />
          </div>
        )}
      </div>

      {!trustpilotWidgetUrl && trustpilotLogo && trustpilotLink && (
        <div
          className={clsx(classes.heroTrustpilot, {
            [classes.heroContentCenter]: hasChildren,
          })}
        >
          <AnchorComponent
            {...trustpilotLink}
            className={classes.trustpilotLogo}
          >
            <Image {...trustpilotLogo} />
          </AnchorComponent>
          {trustpilotMessage && (
            <div className={classes.heroTrustpilotReviews}>
              <AnchorComponent {...trustpilotLink}>
                {trustpilotContent}
              </AnchorComponent>
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <section
      id={name}
      data-testid="hero"
      {...containerProps}
      className={classes.heroSection}
    >
      {children ? (
        <>
          {backgroundImage && <Image {...backgroundImage} priority={true} />}
          {bottomImage && <Image {...bottomImage} />}
          <div className={classes.heroBackgroundRow}>
            <div className={classes.heroBackgroundMobile}>{content}</div>
            <div className={classes.heroRightColumn}>
              {children}
              {appStoreIconLink && playStoreIconLink && (
                <div className={classes.appStoreLinksContainer}>
                  <AnchorComponent
                    {...appStoreIconLink}
                    className={classes.appStoreLink}
                  />
                  <AnchorComponent
                    {...playStoreIconLink}
                    className={classes.appStoreLink}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={classes.heroBackground}>
          {backgroundImage && <Image {...backgroundImage} priority={true} />}
          <UIContainer>{content}</UIContainer>
        </div>
      )}
    </section>
  );
}
