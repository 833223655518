import { AppContextType } from '@/context';
import { CexCurrencyDataFragment, parseCexCurrency } from '@/services';

import {
  CexCurrencyCorridor,
  CexCurrencyCorridorData,
} from './currency-corridors.types';

export type SlugParams = {
  sendCurrencyCode: string;
  receiveCurrencyCode: string;
  sendCountryCode: string;
  receiveCountryCode: string;
  calculationAmount: number;
};

export const parseCexCorridor = (corridor: CexCurrencyCorridorData) => {
  return {
    name: corridor?.name ?? '',
    pageSlug: corridor?.pageSlug ?? '',
    sendCurrency: parseCexCurrency(
      corridor.sendCurrency as CexCurrencyDataFragment,
    ),
    receiveCurrency: parseCexCurrency(
      corridor.receiveCurrency as CexCurrencyDataFragment,
    ),
  } as CexCurrencyCorridor;
};

export const getCorridorDetailsFromSlug = (slug: string): SlugParams => {
  const slugParts = slug.split('-');

  const slugParams = {
    sendCurrencyCode: '',
    receiveCurrencyCode: '',
    sendCountryCode: '',
    receiveCountryCode: '',
    calculationAmount: 0,
  };

  if (slugParts.length === 2) {
    slugParams.calculationAmount = 0;
    slugParams.sendCurrencyCode = slugParts[0].split('_')[0];
    slugParams.sendCountryCode = slugParts[0].split('_')[1];
    slugParams.receiveCurrencyCode = slugParts[1].split('_')[0];
    slugParams.receiveCountryCode = slugParts[1].split('_')[1];
  }

  if (slugParts.length === 3) {
    slugParams.calculationAmount = Number(slugParts[0]) || 0;
    slugParams.sendCurrencyCode = slugParts[1].split('_')[0];
    slugParams.sendCountryCode = slugParts[1].split('_')[1];
    slugParams.receiveCurrencyCode = slugParts[2].split('_')[0];
    slugParams.receiveCountryCode = slugParts[2].split('_')[1];
  }

  return slugParams;
};

export const getCalculatorCorridorSlug = ({
  currentSlug,
  sendCurrencyCode,
  receiveCurrencyCode,
  corridors,
}: {
  currentSlug: string;
  sendCurrencyCode: string;
  receiveCurrencyCode: string;
  corridors: AppContextType['corridors'];
}) => {
  const corridor = corridors?.find?.(
    corridor =>
      corridor.sendCurrency.currencyCode.toLowerCase() ===
        sendCurrencyCode?.toLowerCase?.() &&
      corridor.receiveCurrency.currencyCode.toLowerCase() ===
        receiveCurrencyCode?.toLowerCase?.(),
  );

  if (corridor) {
    const { calculationAmount } = getCorridorDetailsFromSlug(currentSlug);
    const newPageSlug = `${sendCurrencyCode}-${receiveCurrencyCode}`.toLowerCase();
    return calculationAmount
      ? `${calculationAmount}-${newPageSlug}`
      : newPageSlug;
  }
};
