import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
  ({ spacing, typography, customComponents }) => ({
    option: {
      borderTop: `1px solid ${customComponents.search.option.borderTopColor}`,
      padding: 0,
      width: '100%',
    },
    link: {
      padding: spacing(2),
      width: '100%',
      color: customComponents.search.link.color,
      textDecorationColor: customComponents.search.link.textDecorationColor,
    },
    listLabel: {
      paddingLeft: spacing(2),
      fontWeight: typography.fontWeightBold,
    },
    inputIcon: {
      fontSize: 24,
    },
  }),
);
