import React from 'react';

import useStyles from './promotional-banner.styles';
import { PromotionalBannerProps } from './promotional-banner.types';

export const PromotionalBanner = <T,>({
  name,
  bannerDescription,
  RichTextComponent: RichText,
}: PromotionalBannerProps<T>): React.ReactElement | null => {
  const classes = useStyles();

  return (
    <section className={classes.container} id={name}>
      <div className={classes.description}>
        <RichText {...bannerDescription} />
      </div>
    </section>
  );
};
