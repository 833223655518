import React from 'react';

import { STICKY_BANNERS_WRAPPER_ID } from '@/constants';

import { StickyBannerContent } from './sticky-banner.component';
import { useStickyBanners } from './sticky-banner.hook';
import { useStyles } from './sticky-banner.styles';
import { StickyBannersWrapperProps } from './sticky-banner.types';

export const StickyBannersWrapper: React.FC<StickyBannersWrapperProps> = ({
  children,
}) => {
  const classes = useStyles();
  const { stickyBanners } = useStickyBanners();

  return (
    <div
      id={STICKY_BANNERS_WRAPPER_ID}
      className={classes.wrapper}
      data-csk-entry-display-text="StickyBannersWrapper"
    >
      {stickyBanners.map(banner => (
        <StickyBannerContent key={banner.name} {...banner} />
      ))}

      {children}
    </div>
  );
};

export default StickyBannersWrapper;
