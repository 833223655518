import { Tooltip as MuiTooltip } from '@mui/material';
import React from 'react';

import { useStyles } from './tooltip.styles';
import { TooltipProps } from './tooltip.types';

const Tooltip: React.FC<TooltipProps> = ({
  classes: customClasses = {},
  className,
  children,
  id,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={title}
      id={id}
      classes={{ ...classes, ...customClasses }}
      className={className}
      arrow
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
