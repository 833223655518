import { INITIAL_APP_CONTEXT_SHARED } from '@wr/web-ui';

import { AppContextType } from '@/context';

export const INITIAL_APP_CONTEXT: AppContextType = {
  ...INITIAL_APP_CONTEXT_SHARED,
  accountDetails: null,
  referralCode: null,
  countriesByCode: {},
  calculatorSendAmount: 0,
  calculatorSendCountry: {
    name: '',
    currencyName: '',
    currency: '',
    countryCode: '',
    iso2: '',
  },
  corridorPayoutMethodsIds: [],
  payoutMethods: [],
  payoutMethodAlerts: [],
  selectedPayoutMethodId: null,
  locationCountryCookie: null,
  currencies: [],
  corridors: [],
};
