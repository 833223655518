import clsx from 'clsx';
import React from 'react';

import { Container } from '../container';
import useStyles from './body-text.styles';
import { BodyTextProps } from './body-text.types';

export function BodyText<T>({
  name,
  bodyTextHeading,
  bodyTextContent,
  bodyTextContentTwo,
  isPageSection,
  listIndent,
  paragraphIndent,
  contentAlign,
  classNameMap,
  RichTextComponent,
  containerProps,
}: BodyTextProps<T>): React.ReactElement {
  const classes = useStyles({
    listIndent,
    paragraphIndent,
    contentAlign,
    isPageSection,
  });

  const Tag = isPageSection ? 'section' : 'div';

  const content = (
    <>
      {bodyTextHeading && <RichTextComponent {...bodyTextHeading} />}
      {bodyTextContent && bodyTextContentTwo ? (
        <div
          className={clsx(classes.content, classes.columnsContainer)}
          data-testid="body-text-section-column-container"
        >
          <div
            className={classes.column}
            data-testid="body-text-section-column"
          >
            {bodyTextContent && <RichTextComponent {...bodyTextContent} />}
          </div>
          <div
            className={classes.column}
            data-testid="body-text-section-column"
          >
            {bodyTextContentTwo && (
              <RichTextComponent {...bodyTextContentTwo} />
            )}
          </div>
        </div>
      ) : (
        <div
          className={classes.content}
          data-testid="body-text-section-column-container"
        >
          {bodyTextContent && <RichTextComponent {...bodyTextContent} />}
          {bodyTextContentTwo && <RichTextComponent {...bodyTextContentTwo} />}
        </div>
      )}
    </>
  );

  return (
    <Tag
      key={name}
      id={name}
      className={clsx(classNameMap?.bodyTextSection, {
        [classes.bodyTextSection]: isPageSection,
      })}
      {...containerProps}
    >
      {isPageSection ? <Container>{content}</Container> : content}
    </Tag>
  );
}
