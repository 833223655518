import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, important } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  linkWrapper: {
    textDecoration: important('none'),
    color: important(colors.black),
  },
  card: {
    display: 'flex',
    padding: spacing(3),
    borderRadius: spacing(0.5),
    border: `1px solid ${colors.greyLight}`,
    backgroundColor: colors.white,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: spacing(0.5),
    cursor: 'pointer',
  },
  currencyName: {
    fontWeight: 500,
    marginBottom: 0,
  },
}));
