import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  arrow: {
    'position': 'unset',
    'padding': 0,
    'minWidth': 'unset',
    'width': 'auto',
    'marginBottom': theme.spacing(3),
    'color': theme.customComponents.carousel.arrow.color,

    '&:hover': {
      color: theme.customComponents.carousel.arrow.color,
    },

    '&& svg': {
      fontSize: '2rem',
    },
  },
  prevArrow: {
    marginRight: theme.spacing(2),
  },
  nextArrow: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(5),
  },
}));
