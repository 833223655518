import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Container as UIContainer } from '@wr/web-ui';
import { useRouter } from 'next/router';
import React from 'react';

import { Button } from '@/components/button';
import { buildContentfulSidekickAttributes } from '@/utils';

import useStyles from './correspondent-locations.styles';
import { CorrespondentLocationsProps } from './correspondent-locations.types';

export const CorrespondentLocations: React.FC<CorrespondentLocationsProps> = ({
  sys,
  __typename,
  name,
  title,
  locationDetailsNames,
  locationDetailsIds,
  ctaButton,
  correspondentLocations,
}) => {
  const { locale, query } = useRouter();
  const classes = useStyles({ locationDetailsIds });

  if (!correspondentLocations || !Array.isArray(correspondentLocations)) {
    return null;
  }

  const url = query?.slug?.[0] ? `/${locale}/${query.slug[0]}` : `/${locale}`;

  return (
    <section
      className={classes.section}
      data-testid={name}
      id={name || undefined}
      {...buildContentfulSidekickAttributes(sys.id, __typename, name)}
    >
      <UIContainer>
        <div className={classes.header}>
          {title && (
            <Typography
              component="h1"
              variant="subtitle1"
              className={classes.title}
            >
              {title}
            </Typography>
          )}
          {ctaButton && (
            <Button url={url} variant="contained" color="primary">
              {ctaButton}
            </Button>
          )}
        </div>

        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow key="thead-row">
                {locationDetailsIds?.map((locationDetailsId, index) => (
                  <TableCell
                    key={locationDetailsId}
                    className={classes.tableCell}
                  >
                    {locationDetailsNames?.[index]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {correspondentLocations?.map((correspondentLocation, index) => (
                <TableRow
                  tabIndex={-1}
                  key={correspondentLocation?.node?.address || 'tr' + index}
                >
                  {locationDetailsIds?.map(locationDetailsId => {
                    const value = (correspondentLocation?.node as Record<
                      string,
                      string
                    >)?.[locationDetailsId || ''];
                    return <TableCell key={'td' + value}>{value}</TableCell>;
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </UIContainer>
    </section>
  );
};
