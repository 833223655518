import { dset } from 'dset';

import { LinksByPath, SitemapProps } from '@/components/sitemap/sitemap.types';

export const getLinksByPaths = (
  sitemapLinksSlugsAndTitles: SitemapProps['sitemapLinksSlugsAndTitles'],
): LinksByPath | null => {
  const obj = {};

  sitemapLinksSlugsAndTitles.forEach(pageEntry => {
    const path = (pageEntry.slug || '').split('/').join('.');

    dset(obj, path, {
      __link: {
        children: pageEntry.title,
        href: pageEntry.slug,
      },
    });
  });

  return obj;
};
