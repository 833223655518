import { GridSpacing, Theme, useMediaQuery } from '@mui/material';

export const LG_SPACING = 8;
export const MD_SPACING = 5;
export const SM_SPACING = 3;
export const DEFAULT_SPACING = 2;

export const useSpacing = (): GridSpacing => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  if (isLG) {
    return LG_SPACING;
  }

  if (isMd) {
    return MD_SPACING;
  }

  if (isSm) {
    return SM_SPACING;
  }

  return DEFAULT_SPACING;
};
