import { NextRouter } from 'next/router';

export const formatDistanceToNow = (
  date: string,
  locale: NextRouter['locale'],
) => {
  const now = new Date();
  const previousDate = new Date(date);
  const timeDiff = now.getTime() - previousDate.getTime();
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  if (years > 0) {
    return formatter.format(-years, 'year');
  } else if (months > 0) {
    return formatter.format(-months, 'month');
  } else if (days > 0) {
    return formatter.format(-days, 'day');
  } else if (hours > 0) {
    return formatter.format(-hours, 'hour');
  } else if (minutes > 0) {
    return formatter.format(-minutes, 'minute');
  } else {
    return formatter.format(-seconds, 'second');
  }
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }
  return date.toISOString().split('T')[0];
};
