import { Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { useMemo } from 'react';

import { Container } from '../../container';
import { ExtendedButtonProps, SubmenuProps } from '../header.types';
import { useStyles } from './submenu.styles';

export const Submenu = <Button extends ExtendedButtonProps>({
  headerVariant,
  ButtonComponent,
  submenuItems,
  router,
}: SubmenuProps<Button>) => {
  const classes = useStyles({
    headerVariant,
  });

  const selectedTab = useMemo(() => {
    const [currentPath] = ((router?.asPath ?? '') as string).split('?');
    const index =
      submenuItems?.findIndex?.(link => `/${link.url}` === currentPath) ?? -1;
    return index >= 0 ? index : false;
  }, [router?.asPath, submenuItems]);

  if (!submenuItems?.length) {
    return null;
  }

  return (
    <div className={classes.submenuWrapper}>
      <Container>
        <Toolbar
          data-testid="submenu"
          className={classes.submenuToolbar}
          disableGutters
        >
          <Tabs
            value={selectedTab}
            classes={{
              root: classes.submenuTabs,
            }}
          >
            {submenuItems.map((link, index) => {
              return (
                <ButtonComponent
                  {...link}
                  className={classes.submenuButtonComponent}
                  key={link.name}
                >
                  <Tab
                    classes={{
                      root: classes.submenuTab,
                    }}
                    label={
                      <Typography
                        component="span"
                        className={`${classes.submenuTabLabel} ${
                          selectedTab === index
                            ? classes.submenuTabLabelActive
                            : ''
                        }`}
                      >
                        {link.children}
                      </Typography>
                    }
                  />
                </ButtonComponent>
              );
            })}
          </Tabs>
        </Toolbar>
      </Container>
    </div>
  );
};
