import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(
  ({ spacing, typography, breakpoints, shape, customComponents }) => ({
    section: {
      backgroundColor: customComponents.faqModule.section.backgroundColor,
      textAlign: 'center',
      padding: spacing(4, 0),
    },
    header: {
      marginBottom: spacing(3),

      [breakpoints.up('md')]: {
        ...typography.h1,
      },
    },
    subheading: {
      marginBottom: spacing(3),
    },
    questions: {
      marginBottom: spacing(3),
    },
    imageWrapper: {
      'position': 'relative',
      'marginBottom': spacing(3),

      '& img': {
        borderRadius: shape.borderRadius * 2,
      },
    },
    borderRounding: {
      borderRadius: shape.borderRadius * 2,
    },
    ctaButton: {
      padding: spacing(2, 10),
    },
  }),
);

export default useStyles;
