import { useLazyQuery } from '@apollo/client';
import { bffSchema } from '@wr/web-shared';
import { CountryData } from '@wr/web-ui';
import { useCallback, useContext, useState } from 'react';

import { AppContext } from '@/context';

export const useCalculatorCorridors = () => {
  const { receiveCountries } = useContext(AppContext);
  const [receiveCorridors, setReceiveCorridors] = useState<CountryData[]>();

  /**
   * Get receive countries by send country
   */
  const [
    getCorridorsBySendCountry,
  ] = useLazyQuery<bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryNameQuery>(
    bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryName,
    {
      onCompleted: result => {
        if (result.corridorsBySendCountrySortedByReceiveCountryName.length) {
          const corridors = result.corridorsBySendCountrySortedByReceiveCountryName.map(
            corridor => {
              return {
                name: corridor?.receiveCountry?.name,
                currencyName: corridor?.receiveCurrency?.name,
                currency: corridor?.receiveCurrency?.code,
                countryCode: corridor?.receiveCountry?.code,
                dialCode: corridor?.receiveCountry?.dialCode,
              };
            },
          ) as CountryData[];
          setReceiveCorridors(corridors);
        }
      },
    },
  );

  const fetchCorridors = useCallback((sendCountryCode: string) => {
    if (sendCountryCode) {
      getCorridorsBySendCountry({
        variables: {
          sendCountry: sendCountryCode,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    receiveCorridors: receiveCorridors?.length
      ? receiveCorridors
      : receiveCountries,
    fetchCorridors,
  };
};
