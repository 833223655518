export enum COOKIES {
  VISITOR_TYPE_LONG = 'gaVisitorTypeLong',
  VISITOR_TYPE_SHORT = 'gaVisitorTypeShort',
  INFORMATION_DIALOG_CLOSED = 'informationDialogClosed',
  SELECT_FROM = 'selectFrom',
  SELECT_TO = 'selectTo',
  GEO = 'geo',
  LOCATION_COUNTRY = 'locationCountry', // cloudflare header cf-ipcountry
  Authorization = 'Authorization',
  LANGUAGE = 'Language',
  GA_CLIENT_ID = '_ga',
  AIR_TIME_PHONE_NUMBER = 'AIR_TIME_PHONE_NUMBER',
  AIR_TIME_DIAL_CODE = 'AIR_TIME_DIAL_CODE',
}

export const QUEBEC_ISO_CODE = 'QC';
