import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Table } from '@wr/web-ui';
import React, { useCallback } from 'react';

import { Anchor } from '@/components';

import { useStyles } from './cex-rate-table.styles';
import { CexExchangeRateTableProps } from './cex-rate-table.types';

export const CexExchangeRateTable = ({
  tableTitleLabel,
  tableSubtitleOne,
  tableSubtitleTwo,
  conversionAmounts,
  exchangeRate,
  sendCurrencyCode,
  receiveCurrencyCode,
  sendCurrencyName,
  receiveCurrencyName,
  precision,
  links,
}: CexExchangeRateTableProps) => {
  const classes = useStyles();

  const corridorSlug = useCallback(
    (amount?: string) => {
      if (links?.corridorSlugParts) {
        const [template, slug] = links.corridorSlugParts;
        return amount
          ? `${template}/${amount}-${slug}`
          : links.corridorSlugParts.join('/');
      }
    },
    [links?.corridorSlugParts],
  );

  return (
    <Table className={classes.tableParent}>
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell colSpan={2}>
            <Typography variant="h4" className={classes.tableTitle}>
              <span>{tableTitleLabel}</span>
              {links?.sendCurrencySlug ? (
                <Anchor url={links?.sendCurrencySlug}>
                  {sendCurrencyName}
                </Anchor>
              ) : (
                <span>{sendCurrencyName}</span>
              )}
              <span>to</span>
              {links?.receiveCurrencySlug ? (
                <Anchor url={links?.receiveCurrencySlug}>
                  {receiveCurrencyName}
                </Anchor>
              ) : (
                <span>{receiveCurrencyName}</span>
              )}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow className={classes.tableRow}>
          <TableCell>
            <Typography variant="body1">
              <b>{tableSubtitleOne}</b>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              <b>{tableSubtitleTwo}</b>
            </Typography>
          </TableCell>
        </TableRow>
        {conversionAmounts?.map(conversionAmount => (
          <TableRow key={conversionAmount} className={classes.tableRow}>
            <TableCell>
              <Typography variant="body1">
                {links?.corridorSlugParts ? (
                  <Anchor url={corridorSlug(conversionAmount || '')}>
                    {`${conversionAmount} ${sendCurrencyCode}`}
                  </Anchor>
                ) : (
                  <span>
                    {conversionAmount} {sendCurrencyCode}
                  </span>
                )}
              </Typography>
            </TableCell>
            <TableCell data-testid="cex-rate-table-exchange-rate">
              <Typography variant="body1">
                {exchangeRate
                  ? `${(
                      parseFloat(conversionAmount || '0') * exchangeRate
                    ).toFixed(precision)} ${receiveCurrencyCode}`
                  : '-'}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
