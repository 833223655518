import { SelectOption } from '../select';
import { CountrySelectOption } from './country-select.types';

export const valueAccessor = (country: CountrySelectOption): string =>
  country.code;

export const mapCountryToOption = (
  country: CountrySelectOption,
): SelectOption => ({
  label: country.name,
  value: country.code,
});
