import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Typography } from '@mui/material';
import React from 'react';

import { Anchor } from '@/components';

import { useStyles } from './faq-popular-questions.styles';
import { FaqPopularQuestionsProps } from './faq-popular-questions.types';

export const FaqPopularQuestions: React.FC<FaqPopularQuestionsProps> = ({
  title,
  questions,
}) => {
  const classes = useStyles();

  return (
    <section data-testid={'popular-questions-container'}>
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      {questions?.items.map(question => (
        <div key={question.sys.id} className={classes.questionContainer}>
          <Anchor
            url={question.slug}
            data-testid={`faq-topics-module-item-link-${question.sys.id}`}
            className={classes.questionLink}
            underline="none"
          >
            <span>{question.name}</span>
            <ArrowRightAltIcon />
          </Anchor>
        </div>
      ))}
    </section>
  );
};
