import { FC, useMemo } from 'react';

import { HeaderSelect } from '../header';
import { SelectOption } from './../select/select.types';
import { useStyles } from './blog-select.styles';
import { BlogSelectProps } from './blog-select.types';

export const BlogSelect: FC<BlogSelectProps> = ({
  blogTags,
  onBlogCategorySelect,
  allBlogTag,
  router,
  color,
  isMobile,
  headerVariant,
}) => {
  const classes = useStyles();
  const options = useMemo(() => {
    const parsedOptions = blogTags?.map(blogTag => ({
      label: blogTag?.name,
      value: blogTag?.id,
    }));

    const allOption = {
      label: allBlogTag,
      value: allBlogTag?.toLocaleLowerCase(),
    };

    if (router.pathname !== '/blog') {
      parsedOptions?.unshift(allOption);
    }

    return parsedOptions?.filter(
      option => option?.value !== router?.query?.category?.toString(),
    );
  }, [blogTags, allBlogTag, router.pathname, router.query.category]);

  return (
    <HeaderSelect
      isMobile={isMobile}
      name="blog-select"
      color={color}
      onOpen={() => {}}
      onChange={(e: string): void =>
        onBlogCategorySelect && onBlogCategorySelect(e)
      }
      label="Blog"
      value={''}
      className={classes.blogSelect}
      options={options as SelectOption[]}
      data-testid="blog-select"
      headerVariant={headerVariant}
    />
  );
};
