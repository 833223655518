import { InformationDialog as UIInformationDialog } from '@wr/web-ui';
import { FC } from 'react';

import { RichTextContentRenderer } from '../rich-text-content-renderer';
import { useDialog } from './information-dialog.hooks';
import { InformationDialogProps } from './information-dialog.types';

export const InformationDialog: FC<InformationDialogProps> = props => {
  const { isOpen, onClose } = useDialog();

  return (
    <UIInformationDialog
      {...props}
      isOpen={isOpen}
      onClose={onClose}
      RichTextComponent={RichTextContentRenderer}
    />
  );
};
