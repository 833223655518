import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(
  ({ spacing, shape, breakpoints, customComponents }) => ({
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: customComponents.autoComplete.paper.color,
      fontSize: '0.8125rem',
      borderRadius: 0,
      borderBottomLeftRadius: shape.borderRadius * 4,
      borderBottomRightRadius: shape.borderRadius * 4,
    },
    popperDisablePortal: {
      position: 'relative !important' as 'relative',
      transform: 'none !important' as 'none',
    },
    listbox: {
      maxHeight: '70vh',
      [breakpoints.up('md')]: {
        maxHeight: '40vh',
      },
    },
    inputBase: {
      padding: `${spacing(2)} !important`,
      backgroundColor: customComponents.autoComplete.inputBase.backgroundColor,
      borderRadius: 0,
      borderTopLeftRadius: shape.borderRadius * 4,
      borderTopRightRadius: shape.borderRadius * 4,
      borderBottom: `1px solid ${customComponents.autoComplete.inputBase.borderBottomColor}`,
    },
    input: {
      '&::placeholder': {
        fontSize: '1rem',
      },
    },
    notchedOutline: {
      border: 'none',
    },
    options: {
      '&&': {
        padding: spacing(1.5, 2.375),
      },
    },
    textField: {
      'background': '#F7F7F7',
      'borderRadius': shape.borderRadius * 4,

      '&.MuiTextField-root': {
        marginBottom: 0,
      },
    },
  }),
);

export default useStyles;
