import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  sectionWrapper: {
    width: '100%',
    paddingBottom: spacing(4),
  },
  sectionContainer: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    paddingTop: spacing(4.5),
    paddingBottom: spacing(15),

    [breakpoints.up('md')]: {
      paddingTop: spacing(10.5),
      paddingBottom: spacing(8),
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(9.25),
      paddingBottom: spacing(15),
    },
    [breakpoints.up('xl')]: {
      paddingTop: spacing(9.75),
    },
  },
  contentContainer: {
    paddingTop: spacing(5),
  },
  content: { position: 'relative' },
  iframe: {
    minWidth: '100vw',
    minHeight: '40vh',
    [breakpoints.up('sm')]: {
      minWidth: '50vw',
      minHeight: '50vh',
    },
  },

  /* Contained Video Styles */
  containedSection: {
    padding: spacing(4, 0),
  },
  containedSectionContainer: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
}));
