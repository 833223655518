import ExpandMoreIcon from '@mui/icons-material/ArrowDropDownSharp';
import { Box, Typography } from '@mui/material';
import { APP_NAME } from '@wr/web-shared';
import {
  Autocomplete,
  CALCULATOR_CONSTANTS,
  CountryData,
  CountryFlag,
  CountryRow,
} from '@wr/web-ui';
import clsx from 'clsx';
import React from 'react';

import { CurrencySelectDropdown } from './currency-select.dropdown';
import { useStyles } from './currency-select.styles';
import { CurrencySelectProps } from './currency-select.types';
import {
  bringBackScroll,
  filterCountries,
  removeScroll,
  sortArrayByProperty,
} from './currency-select.utils';

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  id,
  countries,
  calculationType,
  onCountryChange,
  onDropdownOpen,
  onSearchCompleted,
  autocompletePlaceholder,
  selectedCountry,
  classes = {},
  loading,
  context,
  inputRef,
  rootRef,
}) => {
  const styles = useStyles();

  const [searchText, setSearchText] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const isIOS = context.osName.toLowerCase() === APP_NAME.IOS.toLowerCase();
  const isOpen = Boolean(anchorEl);

  const toggleDropDownOpen = () => {
    if (loading) return;
    if (isIOS) removeScroll();
    setAnchorEl(rootRef?.current);
    onDropdownOpen?.();
  };

  const toggleDropDownClose = (reason?: string) => {
    if (loading) return;
    if (reason === 'toggleInput' || reason === 'blur') {
      return;
    }
    setTimeout(() => setSearchText(''), 300);
    if (searchText && reason !== 'select-option') {
      onSearchCompleted?.({ searchText, calculationType });
    }
    const position = rootRef?.current?.getBoundingClientRect?.();
    if (isIOS && position) bringBackScroll(position);
    setAnchorEl(null);
    return;
  };

  const handleCountrySelectKeyDown = (event: React.KeyboardEvent): void => {
    if (
      !isOpen &&
      (event.key === CALCULATOR_CONSTANTS.SPACE_CODE ||
        event.key === CALCULATOR_CONSTANTS.SPACE_CODE_OLD)
    ) {
      event.preventDefault();
      toggleDropDownOpen();
    }
  };

  const handleCountryChange = (country: CountryData | null): void => {
    if (country) {
      onCountryChange({
        country,
        calculationType,
        searchText,
      });
      toggleDropDownClose();
    }
  };

  return (
    <Box
      onKeyDown={handleCountrySelectKeyDown}
      className={clsx(styles.selectContainer, classes.selectContainer)}
    >
      <button
        onClick={toggleDropDownOpen}
        className={clsx(
          styles.selectContentWrapper,
          classes.selectContentWrapper,
        )}
        data-testid={`${id}-select`}
      >
        <Box className={styles.selectContentContainer}>
          {selectedCountry?.countryCode && (
            <CountryFlag
              className={clsx(styles.countryFlag, classes.countryFlag)}
              code={selectedCountry.countryCode.toUpperCase()}
            />
          )}
          <Typography
            component="strong"
            className={clsx(styles.inputSelectText, classes.inputSelectText)}
          >
            {selectedCountry?.currency?.toUpperCase() || ''}
          </Typography>
        </Box>
        <ExpandMoreIcon
          className={clsx(styles.selectIcon, classes.selectIcon)}
        />
      </button>
      <CurrencySelectDropdown
        id={`${id}-dropdown`}
        isOpen={isOpen}
        anchorEl={anchorEl}
        onClose={toggleDropDownClose}
        showCloseIcon={false}
        width={rootRef.current?.offsetWidth}
      >
        <Autocomplete
          name={id}
          inputRef={inputRef}
          searchText={searchText}
          setSearchText={setSearchText}
          noOptionsText="No countries found"
          placeholder={autocompletePlaceholder || 'Search'}
          options={sortArrayByProperty(countries, 'name')}
          onClose={(_, reason) => toggleDropDownClose(reason)}
          getOptionLabel={option => `${option.countryCode}-${option.currency}`}
          onChange={(_event, newValue) => handleCountryChange(newValue)}
          filterOptions={options => filterCountries(options, searchText)}
          renderOption={(props, option) => (
            <li {...props}>
              <CountryRow
                currencyCode={option.currency.toUpperCase()}
                countryCode={option.countryCode.toLowerCase()}
                countryName={option.name.toUpperCase()}
              />
            </li>
          )}
          classes={{
            root: classes.dropdownWrapper,
          }}
          isIOS={isIOS}
        />
      </CurrencySelectDropdown>
    </Box>
  );
};
