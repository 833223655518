import { TableContainer } from '@mui/material';
import { Container as UIContainer } from '@wr/web-ui';
import React, { useContext, useMemo } from 'react';

import { RichTextContentRenderer } from '@/components/rich-text-content-renderer';
import { AppContext, CalculatorContext } from '@/context';

import { CexExchangeRateTable } from './cex-rate-table.component.table';
import { useStyles } from './cex-rate-table.styles';
import { CexRateTableProps } from './cex-rate-table.types';

export const CexRateTable = ({
  name,
  title,
  tableTitleLabel,
  conversionAmounts,
  tableSubtitleOne,
  tableSubtitleTwo,
}: CexRateTableProps) => {
  const classes = useStyles();
  const [state] = useContext(CalculatorContext);
  const { currencies } = useContext(AppContext);

  const links = useMemo(() => {
    const sendCurrency = currencies.find(
      currency =>
        currency.currencyCode.toLowerCase() ===
        state.sendCountry?.currency.toLowerCase(),
    );
    const receiveCurrency = currencies.find(
      currency =>
        currency.currencyCode.toLowerCase() ===
        state.receiveCountry?.currency.toLowerCase(),
    );

    const sendCurrencySlug = sendCurrency?.pageSlug ?? ``;

    const receiveCurrencySlug = receiveCurrency?.pageSlug ?? ``;

    const corridorSlugParts = [
      'currency-converter',
      `${state.sendCountry?.currency.toLocaleLowerCase()}_${state.sendCountry?.countryCode.toLocaleLowerCase()}-${state.receiveCountry?.currency.toLocaleLowerCase()}_${state.receiveCountry?.countryCode.toLocaleLowerCase()}`,
    ];

    return { sendCurrencySlug, receiveCurrencySlug, corridorSlugParts };
  }, [
    currencies,
    state.receiveCountry?.countryCode,
    state.receiveCountry?.currency,
    state.sendCountry?.countryCode,
    state.sendCountry?.currency,
  ]);

  const exchangeRate = useMemo(() => {
    const rate = state.exchangeRate?.conversionRate ?? 0;
    return { send: rate, receive: rate ? 1 / rate : 0 };
  }, [state.exchangeRate?.conversionRate]);

  return (
    <UIContainer
      component="section"
      data-testid="cex-rate-table"
      className={classes.container}
      id={name || ''}
    >
      {title?.json && (
        <RichTextContentRenderer
          data-testid="cex-rate-table-title"
          json={title.json}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <CexExchangeRateTable
          precision={2}
          sendCurrencyCode={state.sendCountry?.currency ?? ''}
          sendCurrencyName={state.sendCountry?.currencyName ?? ''}
          receiveCurrencyCode={state.receiveCountry?.currency ?? ''}
          receiveCurrencyName={state.receiveCountry?.currencyName ?? ''}
          exchangeRate={exchangeRate.send}
          tableTitleLabel={tableTitleLabel}
          conversionAmounts={conversionAmounts}
          tableSubtitleOne={tableSubtitleOne}
          tableSubtitleTwo={tableSubtitleTwo}
          links={links}
        />

        <CexExchangeRateTable
          precision={4}
          sendCurrencyCode={state.receiveCountry?.currency ?? ''}
          sendCurrencyName={state.receiveCountry?.currencyName ?? ''}
          receiveCurrencyCode={state.sendCountry?.currency ?? ''}
          receiveCurrencyName={state.sendCountry?.currencyName ?? ''}
          exchangeRate={exchangeRate.receive}
          tableTitleLabel={tableTitleLabel}
          conversionAmounts={conversionAmounts}
          tableSubtitleOne={tableSubtitleOne}
          tableSubtitleTwo={tableSubtitleTwo}
          links={{
            sendCurrencySlug: links.receiveCurrencySlug,
            receiveCurrencySlug: links.sendCurrencySlug,
            corridorSlugParts: undefined,
          }}
        />
      </TableContainer>
    </UIContainer>
  );
};
