import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { contentfulSchema } from '@wr/web-shared';
import { PromotionalBannerContextual as UIPromotionalBanner } from '@wr/web-ui';
import { FC } from 'react';

import { RichTextProps } from '@/components/rich-text-content-renderer';
import { getRichTextHeadingOptions } from '@/components/rich-text-content-renderer/rich-text-content-renderer.utils';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';
import { RichTextContentRenderer } from '../rich-text-content-renderer';
import { useStyles } from './promotional-banner-contextual.styles';

export const ContextualPromoBanner: FC<contentfulSchema.ContextualPromoBannerFragment> = ({
  sys,
  __typename,
  name,
  description,
  disclaimer,
  button,
  link,
  promoIcon,
  verticalBackgroundImage,
  backgroundColor,
}) => {
  const classes = useStyles();

  return (
    <UIPromotionalBanner<RichTextProps, AnchorProps, ButtonProps>
      name={name || ''}
      description={
        description?.json
          ? ({
              ...description,
              options: {
                ...getRichTextHeadingOptions({
                  variant: 'h1',
                  className: classes.heading,
                  gutterBottom: true,
                }),
                [BLOCKS.PARAGRAPH]: {
                  variant: 'body2',
                  className: classes.content,
                },
                [INLINES.HYPERLINK]: {
                  color: 'inherit',
                },
                [BLOCKS.EMBEDDED_ASSET]: {
                  className: classes.asset,
                },
              },
            } as RichTextProps)
          : null
      }
      disclaimer={disclaimer || null}
      promoIcon={promoIcon || null}
      backgroundColor={backgroundColor || undefined}
      image={
        verticalBackgroundImage
          ? {
              ...verticalBackgroundImage,
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'center',
              },
            }
          : null
      }
      link={
        link?.label
          ? {
              ...link,
              children: link.label,
              color: 'secondary',
              openInNewTab: true,
            }
          : null
      }
      button={
        button?.label
          ? {
              ...button,
              children: button.label,
              variant: 'contained',
              color: 'secondary',
            }
          : null
      }
      RichTextComponent={RichTextContentRenderer}
      AnchorComponent={Anchor}
      ButtonComponent={Button}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
