import {
  Button,
  Collapse,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useRef, useState } from 'react';

import { Container as UIContainer } from '..';
import { CountryItem } from './country-item';
import useStyles from './list-of-countries.styles';
import { ListOfCountriesProps } from './list-of-countries.types';

export function ListOfCountries<Link>({
  name,
  header,
  subheader,
  buttonLabelExpandedList,
  buttonLabelHiddenList,
  allCountries,
  visibleCountriesCodes,
  containerProps,
  ButtonComponent,
}: ListOfCountriesProps<Link>) {
  const classes = useStyles();
  const [isCollapsed, handleClick] = useState(true);
  const textRef = useRef<HTMLElement | null>(null);
  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const visibleCountriesCodesForBreakpoint = isSmUp
    ? visibleCountriesCodes
    : visibleCountriesCodes.slice(0, 3);

  const onClick = (): void => {
    handleClick(!isCollapsed);
    const shouldScrollTop = !isCollapsed && textRef && textRef.current;

    setTimeout(() => {
      if (shouldScrollTop) {
        window.scrollTo({
          top: textRef?.current?.offsetTop,
          behavior: 'smooth',
        });
      }
    }, 0);
  };

  const visibleCountries = allCountries.filter(country =>
    visibleCountriesCodesForBreakpoint.includes(country?.code),
  );

  const collapsedCountries = allCountries
    .filter(
      country => !visibleCountriesCodesForBreakpoint.includes(country?.code),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <section
      id={name}
      ref={textRef}
      className={classes.section}
      {...containerProps}
    >
      <UIContainer>
        {header && (
          <Typography variant="h2" className={classes.heading}>
            {header}
          </Typography>
        )}
        {subheader && (
          <Typography variant="body2" className={classes.subheading}>
            {subheader}
          </Typography>
        )}
        <Grid
          data-testid={`${name}-grid`}
          container
          spacing={isMdUp ? 2 : 1}
          classes={{ root: classes.grid }}
        >
          {visibleCountries.map(country => {
            return country?.code ? (
              <CountryItem
                key={country.code}
                isCondensed={false}
                link={country}
                ButtonComponent={ButtonComponent}
              />
            ) : null;
          })}
        </Grid>
        <Collapse
          in={!isCollapsed}
          data-testid={`${name}-grid-collapsed-container`}
        >
          <Grid
            container
            spacing={isMdUp ? 2 : 1}
            data-testid={`${name}-grid-collapsed`}
          >
            {collapsedCountries.map(country => {
              return country?.code ? (
                <CountryItem
                  key={country.code}
                  isCondensed={isCollapsed}
                  link={country}
                  ButtonComponent={ButtonComponent}
                />
              ) : null;
            })}
          </Grid>
        </Collapse>
        <Button
          className={classes.expandButton}
          color="secondary"
          variant="outlined"
          onClick={onClick}
        >
          {!isCollapsed ? buttonLabelExpandedList : buttonLabelHiddenList}
        </Button>
      </UIContainer>
    </section>
  );
}
