import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { HoverDropDownStyleProps } from './hover-dropdown.types';
export const useStyles = makeStyles<Theme, HoverDropDownStyleProps>(theme => ({
  dropdownToggle: {
    'width': 180,
    'listStyle': 'none',
    '&:hover > *, &:focus-within > *': {
      'opacity': 1,
      'zIndex': 1,
      'transition': '0.5s ease',
      '& > svg': {
        transform: 'rotate(180deg)',
      },
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  absolute: {
    position: 'absolute',
    opacity: 0,
    zIndex: -1,
    height: 0,
  },
  dropdownMenu: {
    'position': 'relative',
    'background': '#FFFFFF',
    'color': 'black',
    'padding': 0,
    'margin': 0,
    'marginRight': theme.spacing(1),
    'borderRadius': theme.spacing(0.5),
    'maxHeight': 320,
    'overflow': 'scroll',
    'overflowY': 'auto',
    'overflowX': 'hidden',
    'boxShadow': 'inset 1px 0 0 #DEDEDE',
    'transition': '0.5s ease',
    '&::-webkit-scrollbar-thumb': {
      'border': '4px solid transparent',
      'backgroundColor': '#813FD6',
      'borderRadius': 16,
      '-webkit-background-clip': 'content-box',
      'backgroundClip': 'content-box',
    },
    '&::-webkit-scrollbar': {
      width: 14,
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      maxWidth: 'none',
    },
  },
  dropdownToggleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'none',
    color: 'inherit',
    padding: theme.spacing(2),
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    borderRadius: theme.spacing(0.5),
    borderColor: '#FFFFFF',
    borderWidth: 1,
    width: '100%',
    height: 60,
    maxWidth: 180,
    borderStyle: 'solid',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 'none',
    },
  },
  buttonText: {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: 1.2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
  },
  dropdownTop: {
    top: ({ listHeight }: HoverDropDownStyleProps) => `${-listHeight}px`,
    right: 60,
    [theme.breakpoints.down('md')]: {
      top: -300,
      right: 0,
    },
  },
}));
