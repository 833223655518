import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  footer: {
    background: theme.customComponents.footer.backgroundColor,
    color: theme.palette.common.white,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },

  navigationGroupHeading: {
    ...theme.typography.h2,
    color: theme.palette.common.white,
    fontFamily:
      theme.customComponents.footer?.navigationGroupHeading?.fontFamily,
    fontSize: theme.customComponents.footer?.navigationGroupHeading?.fontSize,
    fontWeight:
      theme.customComponents.footer?.navigationGroupHeading?.fontWeight,
  },

  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  navigationGroupList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1, 2),

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr',
    },
  },

  navigationLink: {
    'fontSize': '1.25rem',
    'lineHeight': 1.6,

    '&:hover': {
      color: theme.customComponents.footer.navigationLink.color,
      backgroundColor:
        theme.customComponents.footer.navigationLink.backgroundColor,
      textDecoration:
        theme.customComponents.footer.navigationLink.textDecoration,
    },
  },

  regionDropdown: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1, 3),
    margin: theme.spacing(3, 0),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  socialLinks: {
    marginBottom: theme.spacing(3),
  },

  socialIconsRelaunch: {
    'filter': 'brightness(0) invert(1)',

    '&:hover': {
      filter: 'none',
    },
  },

  legalMessage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    gap: theme.spacing(5),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      textAlign: 'left',
    },
  },

  flag: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: theme.spacing(3),
  },

  menuItem: {
    ...theme.typography.body2,
  },

  logo: {
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  footerLogo: {
    width: '5rem',
    height: '5rem',
    margin: theme.spacing(0, 'auto', 2),
    display: 'block',
  },
}));
