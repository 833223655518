import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';

import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_GQL_CONTENT_URL,
  CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  CONTENTFUL_SENDWAVE_ACCESS_TOKEN,
  CONTENTFUL_SENDWAVE_GQL_CONTENT_URL,
  CONTENTFUL_SENDWAVE_PREVIEW_ACCESS_TOKEN,
  WEB_CMS_SENDWAVE,
  WEB_PROCESSES,
} from '../constants/contentful';
import { timedFetch } from '../timed-functions';
import { default as contentfulSendwaveIntrospectionResult } from '../types/sendwave/contentful-sendwave-introspection-result';
import { default as contentfulIntrospectionResult } from '../types/worldremit/contentful-introspection-result';
import { default as webProcessesContentfulIntrospectionResult } from '../types/worldremit/web-processes-contentful-introspection-result';
import { defaultErrorLink } from './default-error-link';
import { ApolloConfig } from './shared.types';

const getPossibleTypes = (type: string) => {
  switch (type) {
    case WEB_PROCESSES:
      return webProcessesContentfulIntrospectionResult.possibleTypes;
    case WEB_CMS_SENDWAVE:
      return contentfulSendwaveIntrospectionResult.possibleTypes;
    default:
      return contentfulIntrospectionResult.possibleTypes;
  }
};

export const createContentfulClient = ({
  logger,
  type,
}: Pick<ApolloConfig, 'logger'> & { type: string }) => {
  const httpLink = new HttpLink({
    fetch: process.env.NODE_ENV === 'development' ? timedFetch : fetch,
    uri: ({ variables, setContext }) => {
      if (variables?.preview) {
        setContext({
          headers: {
            authorization: `Bearer ${
              type === WEB_CMS_SENDWAVE
                ? CONTENTFUL_SENDWAVE_PREVIEW_ACCESS_TOKEN
                : CONTENTFUL_PREVIEW_ACCESS_TOKEN
            }`,
          },
        });
      }

      return type === WEB_CMS_SENDWAVE
        ? CONTENTFUL_SENDWAVE_GQL_CONTENT_URL
        : CONTENTFUL_GQL_CONTENT_URL;
    },
    headers: {
      'authorization': `Bearer ${
        type === WEB_CMS_SENDWAVE
          ? CONTENTFUL_SENDWAVE_ACCESS_TOKEN
          : CONTENTFUL_ACCESS_TOKEN
      }`,
      'Content-Type': 'application/json',
    },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: false,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error,
    },
  });

  const errorLink = defaultErrorLink(logger);

  return new ApolloClient({
    link: from([retryLink, errorLink, httpLink]),
    credentials: 'omit',
    ssrMode: typeof window === 'undefined',
    cache: new InMemoryCache({
      possibleTypes: getPossibleTypes(type),
      dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}` : undefined),
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
      },
      query: {
        errorPolicy: 'all', // https://github.com/contentful/contentful.js/issues/418#issuecomment-717503876
      },
    },
  });
};
