import { Container, Grid, Typography } from '@mui/material';
import { Image } from '@wr/web-ui';
import React from 'react';

import { useIntlMessages } from '@/hooks';
import { interpolate } from '@/utils';

import { Button } from '../button';
import { useStyles } from './refer-friend-header.styles';
import { ReferFriendHeaderProps } from './refer-friend-header.types';

export const ReferFriendHeader: React.FC<ReferFriendHeaderProps> = ({
  name,
  heading,
  subheading,
  hintText,
  icon,
  backgroundImage,
  signedOutButton,
  textColor,
}) => {
  const classes = useStyles();

  const intlMessages = useIntlMessages();

  return (
    <section id={name}>
      <div className={classes.backgroundWrapper}>
        <Image
          {...backgroundImage}
          fill={true}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          data-testid="refer-friend-header-background-image"
        />
        <Container className={classes.main}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            className={classes.container}
          >
            <Grid item>
              <Image className={classes.iconImage} {...icon} />
              <Typography
                variant="h1"
                color={textColor ?? 'primary.contrastText'}
              >
                {interpolate(heading, intlMessages)}
              </Typography>
              <Typography
                variant="h3"
                color={textColor ?? 'primary.contrastText'}
              >
                {interpolate(subheading, intlMessages)}
              </Typography>
              <Button
                {...signedOutButton}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                {signedOutButton?.label}
              </Button>
              {hintText && (
                <Typography className={classes.hintText} align="center">
                  {hintText}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </section>
  );
};
