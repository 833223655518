import { createContext } from 'react';

import { INITIAL_APP_CONTEXT_SHARED } from './app.constants';
import { AppContextTypeShared } from './app.types';

export const AppContextShared = createContext<AppContextTypeShared>(
  INITIAL_APP_CONTEXT_SHARED,
);

AppContextShared.displayName = 'AppContextShared';
