import { BLOCKS } from '@contentful/rich-text-types';
import { contentfulSchema } from '@wr/web-shared';
import { Video as UIVideo } from '@wr/web-ui';
import React, { FC } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { buildContentfulSidekickAttributes } from '@/utils';

export const Video: FC<contentfulSchema.VideoFragment> = ({
  sys,
  __typename,
  name,
  content,
  backgroundImage,
  videoId,
}) => {
  return (
    <UIVideo<RichTextProps>
      name={name || ''}
      videoId={videoId || ''}
      content={
        content?.json
          ? {
              ...content,
              options: {
                [BLOCKS.HEADING_1]: {
                  color: 'primary.contrastText',
                  align: 'center',
                },
                [BLOCKS.HEADING_2]: {
                  color: 'primary.contrastText',
                  align: 'center',
                },
                [BLOCKS.PARAGRAPH]: {
                  color: 'primary.contrastText',
                  align: 'center',
                  variant: 'body2',
                },
              },
            }
          : null
      }
      backgroundImage={
        backgroundImage
          ? {
              ...backgroundImage,
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'center',
              },
            }
          : null
      }
      RichTextComponent={RichTextContentRenderer}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
