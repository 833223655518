import { useLazyQuery } from '@apollo/client';
import { bffSchema } from '@wr/web-shared';
import { useContext, useEffect } from 'react';

import { CalculatorContext } from '@/components/calculator/src/context';
import { ICountryData } from '@/components/calculator/src/types';

export const useCorridorsBySendCountry = () => {
  const { state, dispatch } = useContext(CalculatorContext);

  const [
    getCorridorsBySendCountry,
  ] = useLazyQuery<bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryNameQuery>(
    bffSchema.GetCorridorsBySendCountrySortedByReceiveCountryName,
    {
      onCompleted: result => {
        if (result.corridorsBySendCountrySortedByReceiveCountryName.length) {
          const receiveCountries = result.corridorsBySendCountrySortedByReceiveCountryName.map(
            corridor => {
              return {
                name: corridor?.receiveCountry?.name,
                currencyName: corridor?.receiveCurrency?.name,
                currency: corridor?.receiveCurrency?.code,
                countryCode: corridor?.receiveCountry?.code,
                dialCode: corridor?.receiveCountry?.dialCode,
              };
            },
          ) as ICountryData[];

          dispatch({
            type: 'GET_CORRIDORS_BY_SEND_COUNTRY_SUCCESS',
            payload: { receiveCountries },
          });
        } else {
          dispatch({
            type: 'GET_CORRIDORS_BY_SEND_COUNTRY_ERROR',
          });
        }
      },
    },
  );

  useEffect(() => {
    if (state.lastAction === 'SET_SEND_COUNTRY') {
      getCorridorsBySendCountry({
        variables: {
          sendCountry: state.sendCountry?.countryCode,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction]);
};
