import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(({ spacing, customComponents }) => {
  const { countryLink } = customComponents.listOfCountries;

  return {
    countryLink: {
      'backgroundColor': countryLink.backgroundColor,
      'borderRadius': countryLink.borderRadius,
      'boxShadow': countryLink.boxShadow,
      'maxHeight': '3.5rem',

      '&:hover': {
        backgroundColor: countryLink.hover.backgroundColor,
        boxShadow: countryLink.boxShadow,
      },
    },
    flag: {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: spacing(2),
    },
    arrowRight: {
      marginLeft: 'auto',
    },
  };
});

export default useStyles;
