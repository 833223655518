import { create } from 'zustand';

import { isDev } from '../../utils';
import {
  FeatureFlagActions,
  FeatureFlagData,
  FeatureFlagState,
} from './feature-flag.types';
import { mapFeatureFlags } from './feature-flag.utils';
import { defaultFeatureFlags } from './feature-flag-defaults';

export const useFeatureFlagStore = create<
  FeatureFlagState & FeatureFlagActions
>((set, get) => ({
  isLoading: false,
  error: null,
  featureFlags: { ...defaultFeatureFlags },
  setError: (errorMessage: string): void => {
    set({
      error: errorMessage,
    });
  },
  setLoading: (loading: boolean): void => {
    set({ isLoading: loading });
  },
  setFeatureFlags: (featureFlagData: FeatureFlagData): void => {
    if (isDev()) {
      console.log(
        'DEV ENV: Using feature flags in feature-flag-defaults.ts and not fetching flags from contentful',
      );
      return;
    }
    const remoteFeatureFlags = mapFeatureFlags(
      featureFlagData.featureFlagCollection,
    );
    const featureFlags = get().featureFlags;
    set({ featureFlags: { ...featureFlags, ...remoteFeatureFlags } });
  },
}));
