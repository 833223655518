import { getLanguageNameAndCode, LanguageCode } from '@wr/web-shared';

import { Nullable } from '@/types';
import { getLanguageFromLocale } from '@/utils/intl';

export const getLanguages = (
  languageFromCookie: string | Nullable,
  regions: string[],
) =>
  languageFromCookie !== undefined
    ? [...new Set(regions.map(getLanguageFromLocale))]
        .sort((a, b) => a?.localeCompare(b || '') || -1)
        .map(item => getLanguageNameAndCode(item as LanguageCode))
    : undefined;
