export enum CALCULATOR_QUERY_PARAMS {
  TRANSFER = 'transfer',
  SELECT_TO = 'selectto',
  FROM_LITE = 'fromlite',
  SELECT_FROM = 'selectfrom',
  CURRENCY_TO = 'currencyto',
  AMOUNT_FROM = 'amountfrom',
  CURRENCY_FROM = 'currencyfrom',
  PAY_OUT_METHOD = 'PayOutMethod',
  PRODUCT_ID_AIRTIME = 'productIdAirtime',
  CALCULATION_VERSION = 'calculationVersion',
}

export enum QueryParams {
  query = 'q',
  page = 'page',
  category = 'category',
}
