import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  container: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),

    [breakpoints.up('sm')]: {
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
    },
    [breakpoints.up('md')]: {
      paddingTop: spacing(5),
      paddingBottom: spacing(5),
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(8),
      paddingBottom: spacing(8),
    },
    [breakpoints.up('xl')]: {
      paddingTop: spacing(15),
      paddingBottom: spacing(15),
    },
  },
}));

export default useStyles;
