import { ListSubheader, MenuItem } from '@mui/material';
import { DEFAULT_LANGUAGE } from '@wr/web-shared';

import { HoverDropdown } from '../hover-dropdown/hover-dropdown.component';
import { useStyles } from './region-dropdown.styles';
import { RegionDropdownProps } from './region-dropdown.types';
import { getRegionDropdownOptions } from './region-dropdown.utils';
import {
  RegionDropdownGlobalItem,
  RegionDropdownItem,
} from './region-dropdown-item';

export function RegionDropdown({
  locale,
  locales,
  internationalLabel,
  countriesLabel,
  onChange,
  LinkComponent,
  position,
}: RegionDropdownProps) {
  const classes = useStyles();
  const filteredLocales = locales.filter(l => l !== DEFAULT_LANGUAGE);
  const options = getRegionDropdownOptions(locale, filteredLocales);

  if (!options?.length || !internationalLabel) {
    return null;
  }

  const renderValue = (value: unknown): string => {
    if (value === DEFAULT_LANGUAGE) {
      return internationalLabel as string;
    }

    return (options.find(option => option.value === value)?.label ||
      internationalLabel) as string;
  };

  const handleChange = (value: string): void => {
    onChange?.(value);
  };

  return (
    <HoverDropdown
      testId={`region-selector-hover-dropdown-${locale}`}
      selectedLabel={renderValue(locale)}
      position={position}
    >
      {locales.includes(DEFAULT_LANGUAGE) && LinkComponent ? (
        <MenuItem
          key={DEFAULT_LANGUAGE}
          className={classes.menuItem}
          value={DEFAULT_LANGUAGE}
          onClick={() => handleChange(DEFAULT_LANGUAGE)}
          role="option"
        >
          <LinkComponent locale={DEFAULT_LANGUAGE} className={classes.link}>
            <RegionDropdownGlobalItem internationalLabel={internationalLabel} />
          </LinkComponent>
        </MenuItem>
      ) : null}

      {locales.includes(DEFAULT_LANGUAGE) && !LinkComponent ? (
        <MenuItem key={DEFAULT_LANGUAGE} value={DEFAULT_LANGUAGE} role="option">
          <RegionDropdownGlobalItem internationalLabel={internationalLabel} />
        </MenuItem>
      ) : null}

      {countriesLabel && (
        <ListSubheader role="option" disableSticky>
          {countriesLabel}
        </ListSubheader>
      )}
      {options.map(option =>
        LinkComponent ? (
          <MenuItem
            key={option.value}
            data-testid={`region-selector-dropdown-option-${option.value}`}
            className={classes.menuItem}
            value={option.value}
            onClick={() => handleChange(option.value)}
            role="option"
          >
            <LinkComponent locale={option.value} className={classes.link}>
              <RegionDropdownItem option={option} />
            </LinkComponent>
          </MenuItem>
        ) : (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleChange(option.value)}
            role="option"
          >
            <RegionDropdownItem option={option} />
          </MenuItem>
        ),
      )}
    </HoverDropdown>
  );
}
