import { BLOCKS } from '@contentful/rich-text-types';
import { Theme, useMediaQuery } from '@mui/material';
import { ComparisonTable as UIComparisonTable } from '@wr/web-ui';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import {
  RichTextContentRenderer,
  RichTextProps,
} from '@/components/rich-text-content-renderer';
import { AppContext } from '@/context';
import { buildContentfulSidekickAttributes } from '@/utils';

import { ComparisonTableProps } from './comparison-table.types';
import {
  formatComparisonTableDate,
  getLocalisedRows,
} from './comparison-table.utils';

export function ComparisonTable({
  sys,
  __typename,
  name,
  content,
  providersLabel,
  caption,
  rowsCollection,
  footer,
}: ComparisonTableProps) {
  const router = useRouter();
  const { corridorPayoutMethodsIds, intl } = useContext(AppContext);

  const isSMUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  if (isSMUp && corridorPayoutMethodsIds?.length) {
    const formattedDate = sys?.publishedAt
      ? formatComparisonTableDate(sys.publishedAt, router.locale)
      : '';

    const rows = getLocalisedRows(
      rowsCollection.items,
      corridorPayoutMethodsIds,
    );
    const columns = corridorPayoutMethodsIds.map(payoutMethodId => ({
      payoutMethodId,
      name: intl.payoutMethodsLabelsById[payoutMethodId],
    }));

    return (
      <UIComparisonTable<RichTextProps>
        name={name || undefined}
        content={
          content?.json
            ? {
                ...content,
                options: {
                  [BLOCKS.PARAGRAPH]: {
                    variant: 'h4',
                  },
                },
              }
            : null
        }
        providersLabel={providersLabel || null}
        caption={caption || null}
        footer={`${footer} ${formattedDate}`}
        rows={rows}
        columns={columns}
        RichTextComponent={RichTextContentRenderer}
        containerProps={buildContentfulSidekickAttributes(
          sys.id,
          __typename,
          name,
        )}
      />
    );
  }
  return null;
}
