export const CORRESPONDENT_DROPDOWN_NAME = 'correspondent';
export const PAYOUT_METHOD_DROPDOWN_NAME = 'payout method';
export const TOP_UP_AMOUNT_DROPDOWN_NAME = 'amount';

export const PAYOUT_METHOD_SELECTOR = '[payout-method]';
export const SEND_COUNTRY_SELECTOR = '[send-country]';
export const RECEIVE_COUNTRY_SELECTOR = '[receive-country]';

export const PHONE_NUMBER_INPUT_NAME = 'phone number';

export const SEND_COUNTRY_DROPDOWN_NAME = 'send';
export const RECEIVE_COUNTRY_DROPDOWN_NAME = 'receive';

export const ESC_CODE = 'Escape';
export const SPACE_CODE = ' ';
export const SPACE_CODE_OLD = 'Spacebar';
export const ENTER_KEY_INPUT = 'Enter';

export const PHONE_INPUT_BLACKLIST_PAST_KEYS = ['e', '.', '+', '-', 'E', ','];
export const PHONE_INPUT_BLACKLIST_KEYS = PHONE_INPUT_BLACKLIST_PAST_KEYS.concat(
  ['ArrowUp', 'ArrowDown'],
);
export const PHONE_INPUT_BLACKLIST_KEY_CODES = [
  101,
  190,
  187,
  189,
  69,
  38,
  40,
  188,
];
export const ENABLED_NUMERIC_KEY_INPUTS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
];

export const CALCULATOR_INPUT_VALUE_WITH_TWO_DIGITS = /^\d{0,20}(\.\d{1,2})?$/;
