import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { DropdownDesktopProps } from './dropdown-desktop.types';

export const useStyles = makeStyles<Theme, Pick<DropdownDesktopProps, 'width'>>(
  theme => ({
    popper: {
      border: `1px solid ${theme.customComponents.dropdownDesktop.popper.borderColor}`,
      width: ({ width }) => width,
      color: theme.customComponents.dropdownDesktop.popper.color,
      overflow: 'hidden',
      borderRadius: 15,
      zIndex: 1300,
      boxShadow: '0 2px 7px 6px rgba(0, 0, 0, 0.1)',
    },
  }),
);
