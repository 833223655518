import { bffSchema } from '@wr/web-shared';
import { CountryData } from '@wr/web-ui';

import { CalculatorReducerActionTypes } from '@/components/calculator/component/calculator.actions';
import { AppContextType } from '@/context';

export enum PayoutMethodsEnum {
  AIRTIME_TOP_UP = 'ATP',
  BANK_TRANSFER = 'BNK',
  CASH_PICKUP = 'CSH',
  MOBILE_MONEY = 'MOB',
  DOOR_TO_DOOR = 'DTD',
  ALIPAY = 'ALP',
}

export enum TypingState {
  None = 'None',
  SendFrom = 'SendFrom',
  SendTo = 'SendTo',
  PhoneNumber = 'PhoneNumber',
}

export type ICountryData = CountryData;

export type CalculatorState = Pick<
  bffSchema.CreateCalculationMutation['createCalculation'],
  'errors' | 'calculation'
> &
  Pick<AppContextType, 'payoutMethods' | 'payoutMethodAlerts'> & {
    sendCountry: AppContextType['calculatorSendCountry'] | null;
    receiveCountry: AppContextType['receiveCountry'];
    receiveCountries: AppContextType['receiveCountries'];
    isLoading: boolean;
    sendFromValue: string;
    sendToValue: string;
    feeValue: string | undefined;
    totalToPay: string | undefined;
    calculationType: bffSchema.CalculationType;
    lastAction: CalculatorReducerActionTypes['type'] | null;
    isPerimeterXSolved: boolean;
    correspondentId: string | undefined;
    mobileNumber: string | undefined;
    isPhoneNumberValid: boolean | undefined;
    topUpUpDenominationId: string | undefined;
    selectedPayoutMethodId: AppContextType['selectedPayoutMethodId'];
  };
