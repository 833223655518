import { DocumentNode } from '@apollo/client';
import { contentfulSchema as queries } from '@wr/web-shared';
import { GetServerSidePropsContext } from 'next';

import { PageQueryVariables } from '@/services';
import { contentfulClient, logger } from '@/utils';
import { Module, ModuleItem } from '@/utils/contentful';

export type ModuleWithData = { module: Module; data: ModuleItem | null };

export const getModulesData = async ({
  module,
  locale,
  preview = false,
  sendCountryCode,
  receiveCountryCode,
}: {
  module: Module;
} & Pick<GetServerSidePropsContext, 'preview' | 'locale'> &
  Pick<
    PageQueryVariables,
    'sendCountryCode' | 'receiveCountryCode'
  >): Promise<ModuleWithData | null> => {
  const query = (queries as Record<string, unknown>)[
    `${module?.__typename}Query`
  ] as DocumentNode;

  if (query) {
    try {
      const { data } = await contentfulClient.query<ModuleItem>({
        query,
        variables: {
          id: module?.sys.id,
          locale,
          preview,
          sendCountryCode,
          receiveCountryCode: receiveCountryCode?.toLowerCase(),
        },
      });

      return { module, data };
    } catch (error) {
      logger.error(error, `Module: ${module?.sys.id} query request failed`);

      return null;
    }
  } else {
    return null;
  }
};
