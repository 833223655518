import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import { HeaderSelect, SelectOption } from '@wr/web-ui';
import { useRouter } from 'next/router';

import { useHeaderHooks } from '@/components/header/header.hooks';

import { HeaderRegionSelectProps } from './header-region-select.types';

export const HeaderRegionSelect: React.FC<HeaderRegionSelectProps> = ({
  color,
  headerTheme,
}) => {
  const {
    fullGeoDropDownOptions: regionDropdownOptions,
    onRegionChange,
    region,
  } = useHeaderHooks();
  const { locale = DEFAULT_LANGUAGE } = useRouter();

  return (
    <>
      {regionDropdownOptions && (region || locale) && (
        <HeaderSelect
          name="geo-location-selector"
          color={color}
          onChange={onRegionChange}
          data-testid="geo-country-selector"
          value={(region ?? locale) as string}
          options={regionDropdownOptions as SelectOption[]}
          headerVariant={headerTheme}
          renderCountryDropdownOptions={true}
        />
      )}
    </>
  );
};
