import { MutationResult } from '@apollo/client';
import {
  bffSchema,
  CALCULATOR_TYPE,
  contentfulSchema,
  ECOMMERCE_TYPE,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
  VARIABLE,
} from '@wr/web-shared';
import { IntlContextValue } from '@wr/web-ui';
import { NextRouter } from 'next/router';

import { CALCULATOR_QUERY_PARAMS } from '@/constants';
import { AppContextType } from '@/context';
import { getContentfulCountries } from '@/services/contentful/country';
import { getCalculatorReceiveCountryCodeBySendCountryCode } from '@/services/contentful/country/country.utils';
import { getSingleValueFromQueryParam } from '@/utils';

import {
  getCalculationCorridor,
  getCalculationCorridorWithCurrencies,
  getPayoutMethodError,
} from '../core/core.utils';
import { CalculatorState, ICountryData, PayoutMethodsEnum } from '../src/types';
import { CalculatorProps } from './calculator.types';

export const commenceTransferProcessDataLayerPush = ({
  state,
  selectedPayoutMethodId,
  exchangeRate,
  ecommerceType,
  interactionName,
}: Pick<AppContextType, 'selectedPayoutMethodId'> & {
  state: CalculatorState;
  exchangeRate:
    | NonNullable<
        bffSchema.CreateCalculationResult['calculation']
      >['exchangeRate']
    | undefined;
  ecommerceType: ECOMMERCE_TYPE;
  interactionName: INTERACTION_NAME;
}): void => {
  const isAirTime = selectedPayoutMethodId === PayoutMethodsEnum.AIRTIME_TOP_UP;
  const sendType = isAirTime
    ? bffSchema.CalculationType.Send
    : state.calculationType;
  const airtimeReceiveValue = isAirTime
    ? { [VARIABLE.CALC_AIRTIME_DROPDOWN_VALUE]: state.sendToValue }
    : {};

  const calculatorType = isAirTime
    ? CALCULATOR_TYPE.AIRTIME
    : CALCULATOR_TYPE.MONEY_TRANSFER;
  const payOutTimeEstimate = state.payoutMethods.find(
    p => p?.code === selectedPayoutMethodId,
  )?.payOutTimeEstimate;
  const sendValue =
    isAirTime && state.feeValue ? state.totalToPay : state.sendFromValue;

  const dataLayerProps = {
    event: EVENT.VISITOR_INTERACTION,
    interactionName,
    [ecommerceType === ECOMMERCE_TYPE.DETAIL
      ? VARIABLE.CALC_AMOUNT_FIELD
      : VARIABLE.CALC_CALCULATOR_TYPE]:
      ecommerceType === ECOMMERCE_TYPE.DETAIL ? sendType : calculatorType,
    ...airtimeReceiveValue,
    ecommerce: {
      [ecommerceType]: {
        products: [
          {
            [VARIABLE.CALC_CORRIDOR]: getCalculationCorridor(
              state.sendCountry?.countryCode ?? '',
              state.receiveCountry?.countryCode ?? '',
            ),
            [VARIABLE.CALC_PAYOUT_METHOD]: selectedPayoutMethodId,
            [VARIABLE.CALC_TOTAL_TO_PAY]: state.totalToPay,
            [VARIABLE.CALC_SEND_OR_RECEIVE]: sendType,
            [VARIABLE.CALC_CORRIDOR_WITH_CURRENCIES]: getCalculationCorridorWithCurrencies(
              state.sendCountry?.countryCode ?? '',
              state.sendCountry?.currency ?? '',
              state.receiveCountry?.countryCode ?? '',
              state.receiveCountry?.currency ?? '',
            ),
            ...(!isAirTime && {
              [VARIABLE.CALC_EXCHANGE_RATE]: exchangeRate?.value,
            }),
            [VARIABLE.CALC_CURRENCY_CORRIDOR]: getCalculationCorridor(
              state.sendCountry?.currency ?? '',
              state.receiveCountry?.currency ?? '',
            ),
            [VARIABLE.CALC_QUANTITY]: 1,
            [VARIABLE.CALC_CORRESPONDENT]: state.correspondentId,
            [VARIABLE.CALC_PROMOTION]: 'no', // TODO
            [VARIABLE.CALC_PROMOTION_NAME]: '', // TODO
            [VARIABLE.CALC_SEND_AMOUNT]: sendValue,
            [VARIABLE.CALC_TRANSFER_TIME]: payOutTimeEstimate,
            [VARIABLE.CALC_RECEIVE_AMOUNT]: state.sendToValue,
            [VARIABLE.CALC_FEES]: state.feeValue,
          },
        ],
      },
    },
  };
  pushToDataLayer(dataLayerProps);
};

export const setDataLayerForSubmit = ({
  isLite,
  state,
  selectedPayoutMethodId,
  exchangeRate,
}: Pick<AppContextType, 'selectedPayoutMethodId'> &
  Pick<CalculatorProps, 'isLite'> & {
    state: CalculatorState;
    exchangeRate:
      | NonNullable<
          bffSchema.CreateCalculationResult['calculation']
        >['exchangeRate']
      | undefined;
  }): void => {
  if (isLite) {
    const dataLayerProps = {
      event: EVENT.VISITOR_INTERACTION,
      interactionName: INTERACTION_NAME.LITE_CALC_CONTINUE,
      [VARIABLE.LITE_CALC_CORRIDOR]: getCalculationCorridor(
        state.sendCountry?.countryCode ?? '',
        state.receiveCountry?.countryCode ?? '',
      ),
      [VARIABLE.LITE_CALC_CURRENCY_CORRIDOR]: getCalculationCorridor(
        state.sendCountry?.currency ?? '',
        state.receiveCountry?.currency ?? '',
      ),
      [VARIABLE.LITE_CALC_SEND_OR_RECEIVE]:
        state.calculationType === bffSchema.CalculationType.Send
          ? bffSchema.CalculationType.Send.toLowerCase()
          : bffSchema.CalculationType.Receive.toLowerCase(),
      [VARIABLE.LITE_CALC_FX_RATE]: exchangeRate?.value,
      [VARIABLE.LITE_CALC_AMOUNT]: state.sendFromValue,
      [VARIABLE.LITE_CALC_PAYOUT_METHOD]: selectedPayoutMethodId,
    };

    pushToDataLayer(dataLayerProps);
  } else {
    commenceTransferProcessDataLayerPush({
      state,
      exchangeRate,
      selectedPayoutMethodId,
      ecommerceType: ECOMMERCE_TYPE.ADD,
      interactionName: INTERACTION_NAME.COMMENCE_PROCESS,
    });
  }
};

export const setDataLayerForCalculation = ({
  isLite,
  selectedPayoutMethodId,
  exchangeRate,
  state,
  sendFromValue,
}: Pick<AppContextType, 'selectedPayoutMethodId'> &
  Pick<CalculatorProps, 'isLite'> & {
    state: CalculatorState;
    sendFromValue: string;
    exchangeRate:
      | NonNullable<
          bffSchema.CreateCalculationResult['calculation']
        >['exchangeRate']
      | undefined;
  }): void => {
  if (isLite) {
    const dataLayerProps = {
      event: EVENT.VISITOR_INTERACTION,
      interactionName: INTERACTION_NAME.LITE_CALC_CALCULATION,
      [VARIABLE.LITE_CALC_CORRIDOR]: getCalculationCorridor(
        state.sendCountry?.countryCode ?? '',
        state.receiveCountry?.countryCode ?? '',
      ),
      [VARIABLE.LITE_CALC_CURRENCY_CORRIDOR]: getCalculationCorridor(
        state.sendCountry?.currency ?? '',
        state.receiveCountry?.currency ?? '',
      ),
      [VARIABLE.LITE_CALC_SEND_OR_RECEIVE]: state.calculationType.toLowerCase(),
      [VARIABLE.LITE_CALC_FX_RATE]: exchangeRate?.value,
      [VARIABLE.LITE_CALC_AMOUNT]: sendFromValue,
      [VARIABLE.LITE_CALC_PAYOUT_METHOD]: selectedPayoutMethodId,
    };

    pushToDataLayer(dataLayerProps);
  } else {
    commenceTransferProcessDataLayerPush({
      state,
      exchangeRate,
      selectedPayoutMethodId,
      ecommerceType: ECOMMERCE_TYPE.DETAIL,
      interactionName: INTERACTION_NAME.MONEY_TRANSFER_CALCULATION,
    });
  }
};

export const findSendCountryByCode = (
  countries: ICountryData[],
  countryCode: string,
): ICountryData | undefined => {
  return countries?.find(country => {
    return country?.countryCode === countryCode.toUpperCase();
  });
};

export const findReceiveCountryByCodeAndCurrency = ({
  receiveCountries,
  receiveCountryCode,
  receiveCurrencyCode,
  contentfulCountries,
  sendCountry,
}: {
  receiveCountries: ICountryData[];
  receiveCountryCode: string;
  receiveCurrencyCode?: string;
  contentfulCountries: Awaited<ReturnType<typeof getContentfulCountries>>;
  sendCountry: ICountryData | undefined;
}): ICountryData | undefined => {
  let receiveCountry;

  if (!receiveCountry && receiveCurrencyCode) {
    receiveCountry = receiveCountries?.find(
      country =>
        country?.countryCode === receiveCountryCode.toUpperCase() &&
        country?.currency === receiveCurrencyCode.toUpperCase(),
    );
  }

  if (!receiveCountry) {
    receiveCountry = receiveCountries?.find(
      country => country?.countryCode === receiveCountryCode.toUpperCase(),
    );
  }

  // if receive countries array has no receive country (e.g. wrong url)
  // default to calculator receive country defined for send country in Contentful
  if (!receiveCountry) {
    const calculatorReceiveCountryCode = getCalculatorReceiveCountryCodeBySendCountryCode(
      contentfulCountries,
      sendCountry?.countryCode,
    );

    receiveCountry = receiveCountries?.find(
      country =>
        country?.countryCode === calculatorReceiveCountryCode.toUpperCase(),
    );
  }

  // if calculator receive country doesn't exist in receive countries array
  // (e.g. outdated receive country) return first from the array
  return receiveCountry || receiveCountries[0];
};

export const getSelectedPayoutMethodId = ({
  payoutMethodsIds,
  query,
}: {
  payoutMethodsIds: (contentfulSchema.Maybe<string> | undefined)[];
  query: NextRouter['query'] | null;
}): string | null => {
  const payoutMethodId = getSingleValueFromQueryParam(
    query?.[CALCULATOR_QUERY_PARAMS.TRANSFER],
  );

  if (
    payoutMethodId &&
    payoutMethodsIds.includes(payoutMethodId.toUpperCase())
  ) {
    return payoutMethodId.toUpperCase();
  }

  return payoutMethodsIds[0] || null;
};

// Possible error codes and types
// https://github.com/Worldremit/pricing-facade/blob/master/service/main/src/main/resources/static/documentation/schemas/validation-errors-schema.yml
export const getValidationCalculationErrorMessages = (
  errors: CalculatorState['errors'],
) => {
  return errors
    .filter(error => error.__typename === 'ValidationCalculationError')
    .map(error => error.message);
};

export const getGenericCalculationErrorMessages = (
  state: CalculatorState,
  {
    generalErrorMessage,
    senderPayoutMethodUnavailableMessage,
    receiverPayoutMethodUnavailableMessage,
    unavailableCorridorErrorMessage,
    payoutMethodsLabelsById,
    selectedPayoutMethodId,
    receiveCountries,
    calculationResult,
  }: Pick<
    CalculatorProps,
    | 'generalErrorMessage'
    | 'senderPayoutMethodUnavailableMessage'
    | 'receiverPayoutMethodUnavailableMessage'
    | 'unavailableCorridorErrorMessage'
  > &
    Pick<AppContextType, 'selectedPayoutMethodId' | 'receiveCountries'> &
    Pick<IntlContextValue, 'payoutMethodsLabelsById'> & {
      calculationResult: MutationResult<bffSchema.CreateCalculationMutation>;
    },
): string[] => {
  return state.errors
    .map(error => {
      if (
        error.__typename === 'GenericCalculationError' &&
        error.genericType === bffSchema.GenericCalculationErrorType.ServerError
      ) {
        if (
          state.calculationType === bffSchema.CalculationType.Send &&
          senderPayoutMethodUnavailableMessage &&
          selectedPayoutMethodId
        ) {
          return getPayoutMethodError(
            senderPayoutMethodUnavailableMessage,
            payoutMethodsLabelsById[selectedPayoutMethodId],
            state.sendCountry?.name ?? '',
            state.receiveCountry?.name ?? '',
          );
        }
        if (
          state.calculationType === bffSchema.CalculationType.Receive &&
          receiverPayoutMethodUnavailableMessage &&
          selectedPayoutMethodId
        ) {
          return getPayoutMethodError(
            receiverPayoutMethodUnavailableMessage,
            payoutMethodsLabelsById[selectedPayoutMethodId],
            state.sendCountry?.name ?? '',
            state.receiveCountry?.name ?? '',
          );
        }
      }

      if (
        error.__typename === 'GenericCalculationError' &&
        error.genericType === bffSchema.GenericCalculationErrorType.ClientError
      ) {
        if (selectedPayoutMethodId && senderPayoutMethodUnavailableMessage) {
          return getPayoutMethodError(
            senderPayoutMethodUnavailableMessage,
            payoutMethodsLabelsById[selectedPayoutMethodId],
            state.sendCountry?.name ?? '',
            state.receiveCountry?.name ?? '',
          );
        }

        if (!selectedPayoutMethodId && unavailableCorridorErrorMessage) {
          return getPayoutMethodError(
            unavailableCorridorErrorMessage,
            '',
            state.sendCountry?.name ?? '',
            state.receiveCountry?.name ?? '',
          );
        }
      }
    })
    .concat(
      !state.payoutMethods.length && unavailableCorridorErrorMessage
        ? getPayoutMethodError(
            unavailableCorridorErrorMessage,
            '',
            state.sendCountry?.name ?? '',
            state.receiveCountry?.name ?? '',
          )
        : undefined,
    )
    .concat(
      (calculationResult?.error || !receiveCountries.length) &&
        generalErrorMessage
        ? generalErrorMessage
        : undefined,
    )
    .filter((message): message is string => typeof message === 'string');
};

export const getInitialSelectedCorrespondent = (
  payoutMethods: CalculatorState['payoutMethods'],
  selectedPayoutMethodId: AppContextType['selectedPayoutMethodId'],
): string | undefined => {
  const selectedPayoutMethod = payoutMethods.find(
    payoutMethod => payoutMethod?.code === selectedPayoutMethodId,
  );

  if (selectedPayoutMethod?.correspondents?.length === 1) {
    return selectedPayoutMethod.correspondents[0]?.id;
  }
};
