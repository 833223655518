import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  dialogContent: {
    'padding': theme.spacing(0, 3, 3),
    'margin': '0 auto',

    '&:first-child': {
      paddingTop: 0,
    },

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 10, 5),
    },
  },
  paper: {
    maxWidth: '33.75rem',
    position: 'relative',
    width: `calc(100% - ${theme.spacing(2)})`,
    paddingTop: theme.spacing(9),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  richTextContent: {
    '& p': {
      fontSize: '1rem',
      fontWeight: 400,
      margin: theme.spacing(0, 0, 2),
    },

    '& h1,h2,h3,h4,h5,h6': {
      textAlign: 'center',
    },

    '& h3': {
      marginBottom: theme.spacing(2.75),
      color: theme.customComponents.informationDialog.richTextContent.h3,
      fontSize: '1.4375rem',
      fontWeight: 500,
    },

    [theme.breakpoints.up('sm')]: {
      '& h3': {
        fontSize: '1.75rem',
      },
    },
  },
  link: {
    'width': '100%',
    'height': '3rem',
    'fontWeight': 700,
    'fontSize': '1.25rem',
    'paddingTop': 0,
    'paddingBottom': 0,
    'marginBottom': theme.spacing(2),

    '&:first-of-type': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    'color': theme.customComponents.informationDialog.closeButton.color,
    'position': 'absolute',
    'top': '1.1875rem',
    'right': '1.1875rem',
    'minWidth': 0,
    'padding': 0,

    '&:hover': {
      background: 'none',
    },

    '& svg': {
      height: '1.5625rem',
      width: '1.5625rem',
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: '1.4375rem',
    fontWeight: 700,
    textAlign: 'center',
  },
}));
