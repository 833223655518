import { Theme, useMediaQuery } from '@mui/material';
import { ImageProps, ThemeBreakpoints } from '@wr/web-ui';

interface UseImageByBreakpointProps {
  mobile?: ImageProps;
  tablet?: ImageProps;
  desktop?: ImageProps;
}

export const useImageByBreakpoint = ({
  mobile,
  tablet,
  desktop,
}: UseImageByBreakpointProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(ThemeBreakpoints.mobile),
  );
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(ThemeBreakpoints.tablet),
  );

  if (isMobile) return mobile || tablet || desktop;

  if (isTablet) return tablet || desktop || mobile;

  return desktop || tablet || mobile;
};
