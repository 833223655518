import { ANALYTICS_PAGE_TYPE, DEFAULT_LANGUAGE } from '@wr/web-shared';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import {
  DEFAULT_SOCIAL_IMAGE_PATH,
  REGION_CODES,
  WORLD_REMIT,
} from '@/constants';
import { AppContext } from '@/context';
import { useIntlMessages } from '@/hooks';
import { interpolate } from '@/utils';

import { PageMetadataProps } from './page-metadata.types';
import {
  getActiveRegionCode,
  getBreadcrumbsSchemaMarkup,
  getCanonicalUrl,
  getFaqPageSchemaMarkup,
  getLanguageAlternates,
  getNewsArticleSchemaMarkup,
  getOrganizationSchemaMarkup,
} from './page-metadata.utils';

export const PageMetadataComponent: React.FC<PageMetadataProps> = ({
  activeCorridors,
  breadcrumbs,
  title,
  description,
  noIndex,
  socialImagePath = DEFAULT_SOCIAL_IMAGE_PATH,
  tagLinks,
  originUri = 'https://localhost:3000',
  pathname,
  modules,
  organizationSchema,
  newsArticleSchema,
  regionCode,
  pageLinksWithRegionsAndSlugs,
}) => {
  const messages = useIntlMessages();
  const { locale = DEFAULT_LANGUAGE, query } = useRouter();
  const { regions, analyticsPageType } = useContext(AppContext);

  const formattedPathname = pathname !== '/' ? pathname : '';
  const actualPageSplited: string[] = formattedPathname.split('/');
  const mustRenderArticleMarkup: boolean = actualPageSplited.length > 3;
  const canonical = getCanonicalUrl({ originUri, locale, query, pathname });

  const languageAlternates = getLanguageAlternates({
    activeCorridors,
    originUri,
    pathname: formattedPathname,
    noIndex,
    regions,
    analyticsPageType,
  });

  const getAvailableLanguageAlternates = () => {
    const formattedActiveUrls = pageLinksWithRegionsAndSlugs?.map(
      (link: { slug: string }) => ({
        href: `${originUri}/${link.slug}`,
      }),
    );

    return languageAlternates.filter(lang => {
      return formattedActiveUrls?.some(
        (url: { href: string }) => url.href === lang.href,
      );
    });
  };

  const isFaqPage = analyticsPageType === ANALYTICS_PAGE_TYPE.FAQ;
  const isRootPage = analyticsPageType === ANALYTICS_PAGE_TYPE.Homepage;

  const organizationSchemaMarkup = isRootPage
    ? getOrganizationSchemaMarkup(organizationSchema)
    : null;

  const faqPageSchemaMarkup = isFaqPage
    ? getFaqPageSchemaMarkup(modules)
    : null;
  const breadcrumbsSchemaMarkup = getBreadcrumbsSchemaMarkup({
    breadcrumbs,
    locale,
    originUri,
  });
  const newsArticleSchemaMarkup = mustRenderArticleMarkup
    ? getNewsArticleSchemaMarkup(newsArticleSchema)
    : null;

  const hasQueryParams = Object.keys(query).length > 0;

  const defaultSocialImage = `${originUri}${socialImagePath}`;
  const socialImage = organizationSchema?.image?.url || defaultSocialImage;

  const activeRegion =
    regionCode?.length && getActiveRegionCode(regionCode, locale);

  const formattedTitle = title && interpolate(title.trim(), messages);

  const renderActiveRegion = () => {
    const pipedWrSuffix = `| ${WORLD_REMIT}`;
    const hasPipedWrSuffix = formattedTitle?.includes(pipedWrSuffix);

    if (activeRegion) {
      if (activeRegion !== REGION_CODES.en) {
        if (!hasPipedWrSuffix) {
          return ` ${pipedWrSuffix} ${activeRegion}`;
        } else {
          return activeRegion;
        }
      } else {
        if (hasPipedWrSuffix) {
          return '';
        }

        return pipedWrSuffix;
      }
    }

    return '';
  };

  const isLanguageCodeOnly = formattedPathname.includes(
    'blog' || 'currency-converter',
  );

  return (
    <Head>
      {title && <title>{`${formattedTitle} ${renderActiveRegion()}`}</title>}
      {description && (
        <meta name="description" content={interpolate(description, messages)} />
      )}
      {noIndex && !hasQueryParams && (
        // must only be used on the parameter-free URLs
        <meta name="robots" content="noindex,nofollow" />
      )}
      {canonical && <link rel="canonical" href={canonical} />}
      {tagLinks?.flatMap(({ rel, urls }) => {
        return urls.map(url => (
          <link rel={rel} href={url} key={rel + url} crossOrigin="" />
        ));
      })}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@WorldRemit" />
      <meta name="twitter:title" content={interpolate(title, messages)} />
      <meta
        name="twitter:description"
        content={interpolate(description, messages)}
      />
      <meta name="twitter:creator" content="@WorldRemit" />
      <meta name="twitter:image:src" content={socialImage} />
      <meta property="og:title" content={interpolate(title, messages)} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${originUri}/${locale}${pathname}`} />
      <meta property="og:image" content={socialImage} />
      <meta
        property="og:description"
        content={interpolate(description, messages)}
      />
      <meta property="og:site_name" content="WorldRemit" />

      {isLanguageCodeOnly
        ? getAvailableLanguageAlternates()?.map(
            ({ href, hreflang }) =>
              hreflang !== 'x-default' &&
              !query.page && (
                <link
                  key={`${href}-${hreflang}`}
                  rel="alternate"
                  href={href}
                  hreflang={hreflang}
                />
              ),
          )
        : getAvailableLanguageAlternates()?.map(({ href, hreflang }) => (
            <link
              key={`${href}-${hreflang}`}
              rel="alternate"
              hreflang={hreflang}
              href={href}
            />
          ))}
      {organizationSchemaMarkup && (
        <script
          type="application/ld+json"
          data-testid="organization-schema"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationSchemaMarkup),
          }}
        />
      )}
      {faqPageSchemaMarkup && (
        <script
          type="application/ld+json"
          data-testid="faq-page-schema"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(faqPageSchemaMarkup),
          }}
        />
      )}
      {newsArticleSchemaMarkup && (
        <script
          type="application/ld+json"
          data-testid="news-article-schema"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(newsArticleSchemaMarkup),
          }}
        />
      )}
      {breadcrumbsSchemaMarkup && (
        <script
          type="application/ld+json"
          data-testid="breadcrumbs-schema"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbsSchemaMarkup),
          }}
        />
      )}
    </Head>
  );
};
