import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors, important } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  'root': {
    minHeight: '435px',
    padding: spacing(3),
    background: colors.white,
    borderRadius: spacing(1),
  },
  'errorsWrapper': {
    display: 'flex',
    flexDirection: 'column',
    margin: spacing(0),
    marginTop: spacing(0.8),
    gap: spacing(1.5),
  },
  '@keyframes fadeAndScaleIn': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.5)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  'errorAnimateIn': {
    animation: `$fadeAndScaleIn 0.4s`,
  },
  'ctaLinkWrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
  },
  'ctaLinkButton': {
    'marginTop': spacing(1),
    '&.MuiButton-text': {
      'fontWeight': 700,
      'fontSize': spacing(2),
      'color': colors.purpleNormal,
      '&:hover': {
        color: colors.purpleDark,
        backgroundColor: important('transparent'),
        textDecoration: 'underline',
      },
    },
  },
  '@keyframes fadeInFromLeft': {
    from: {
      opacity: 0,
      transform: 'translateX(-10px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  'receiveMethodSelect': {
    height: '55px',
    marginBottom: spacing(1.1),
  },
  'details': {
    marginTop: spacing(0.5),
    display: 'flex ',
    justifyContent: 'space-between',
    marginBottom: spacing(1),
  },
  'detailValue': {
    animation: '$fadeInFromLeft 0.1s ease-out',
  },
  'feeInfo': {
    marginBottom: spacing(2),
  },
  'payoutMethodContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacing(2),
  },
  'footerWrapper': {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    minHeight: '206px',
  },
  'footerWrapperLite': {
    minHeight: '20px',
  },
}));
