import { Typography } from '@mui/material';
import React from 'react';

import { CountryFlag } from '../country-flag-image';
import useStyles from './country-row.styles';
import { CountryRowProps } from './country-row.types';

export function CountryRow({
  countryCode,
  countryName,
  currencyCode,
}: CountryRowProps): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.flexContainer}>
      <CountryFlag className={classes.flag} type="circle" code={countryCode} />
      <div className={classes.flexContainer}>
        <Typography variant="body1" color="textSecondary">
          {countryName}
        </Typography>
      </div>
      <Typography variant="body1">
        <b>{currencyCode}</b>
      </Typography>
    </div>
  );
}
