import { contentfulSchema } from '@wr/web-shared';
import React from 'react';

import { Chatbot } from './chatbot';
import { OneTrustCookiesPolicy } from './one-trust-cookies-policy';

const ONE_TRUST_COOKIE = 'ONE_TRUST_COOKIE';
const CHATBOT = 'CHATBOT';

export const ExternalScript: React.FC<contentfulSchema.ExternalScript> = ({
  scriptType,
  id,
}) => {
  switch (scriptType) {
    case ONE_TRUST_COOKIE:
      return <OneTrustCookiesPolicy id={id} />;
    case CHATBOT:
      return <Chatbot id={id} />;
    default:
      return null;
  }
};
