import { CardProps, Cards as UICards } from '@wr/web-ui';
import React, { useContext } from 'react';

import { AppContext } from '@/context';
import { useIntlMessages } from '@/hooks';
import { PageProps } from '@/types/data-interfaces';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';
import { Button, ButtonProps } from '../button';

export const RelatedPages: React.FC<
  Pick<PageProps, 'relatedPagesCollection'>
> = ({ relatedPagesCollection }) => {
  const { messages } = useContext(AppContext);
  const intlMessages = useIntlMessages();

  const uiCards = relatedPagesCollection?.items
    .filter(relatedPage => relatedPage)
    .map(relatedPage => {
      return {
        name: relatedPage?.name,
        description: null,
        backgroundColor: null,
        chipLabel: null,
        backgroundImage: relatedPage.banner
          ? {
              ...relatedPage.banner,
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'center',
              },
            }
          : null,
        header: interpolate(relatedPage?.title, intlMessages),
        link: {
          url: relatedPage.slug,
          children: messages?.readMore,
          underline: 'none',
        },
        button: relatedPage.banner
          ? {
              children: messages?.readMore,
            }
          : {
              url: relatedPage.slug,
              children: messages?.readMore,
            },
        containerProps: buildContentfulSidekickAttributes(
          relatedPage?.sys.id,
          relatedPage?.__typename,
          relatedPage?.name,
        ),
      };
    }) as CardProps<AnchorProps, ButtonProps>[];

  return (
    <UICards<AnchorProps, ButtonProps>
      name="related-posts"
      header={messages?.relatedPosts || ''}
      description={''}
      carouselText={null}
      AnchorComponent={Anchor}
      ButtonComponent={Button}
      messages={{
        previous: messages?.previous,
        next: messages?.next,
      }}
      cards={uiCards}
      paymentText={null}
      paymentIcons={null}
    />
  );
};
