import {
  Correspondents as UICorrespondents,
  CorrespondentsProps as UICorrespondentsProps,
} from '@wr/web-ui';
import { useContext, useEffect, useState } from 'react';

import { AppContext, AppContextType } from '@/context';
import { useIntlMessages } from '@/hooks';
import { buildContentfulSidekickAttributes, interpolate } from '@/utils';

import { Anchor, AnchorProps } from '../anchor';
import { getPayoutMethodsTabs } from './correspondents.selectors';
import { CorrespondentsProps } from './correspondents.types';

export const Correspondents: React.FC<CorrespondentsProps> = ({
  sys,
  __typename,
  name,
  title,
  subtitle,
  correspondentLogos,
}) => {
  const {
    intl,
    selectedPayoutMethodId: payoutMethodIdSelectedInCalculator,
    payoutMethods,
  } = useContext(AppContext);
  const intlMessages = useIntlMessages();

  const payoutMethodsTabs = getPayoutMethodsTabs(
    payoutMethods,
    intl.payoutMethodsLabelsById,
    correspondentLogos,
  );

  const getInitialPayoutMethod = (
    payoutMethodIdSelectedInCalculator: AppContextType['selectedPayoutMethodId'],
    tabs: UICorrespondentsProps<AnchorProps>['payoutMethodsTabs'],
  ): string => {
    if (
      payoutMethodIdSelectedInCalculator &&
      tabs?.find(
        payoutMethodsTab =>
          payoutMethodsTab?.value === payoutMethodIdSelectedInCalculator,
      )?.correspondents?.length
    ) {
      // if there are correspondents for selected payout method, return them
      return payoutMethodIdSelectedInCalculator;
    }

    return tabs[0]?.value;
  };

  const [payoutMethodId, setPayoutMethodId] = useState(
    getInitialPayoutMethod(
      payoutMethodIdSelectedInCalculator,
      payoutMethodsTabs,
    ),
  );

  useEffect(() => {
    setPayoutMethodId(
      getInitialPayoutMethod(
        payoutMethodIdSelectedInCalculator,
        payoutMethodsTabs,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutMethodIdSelectedInCalculator, payoutMethodsTabs.length]);

  return (
    <UICorrespondents<AnchorProps>
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name || undefined,
      )}
      selectedPayoutMethodId={payoutMethodId}
      onPayoutMethodChange={setPayoutMethodId}
      name={name || ''}
      title={interpolate(title, intlMessages)}
      subtitle={interpolate(subtitle, intlMessages)}
      payoutMethodsTabs={payoutMethodsTabs}
      LinkComponent={Anchor}
    />
  );
};
