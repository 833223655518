import { BLOCKS } from '@contentful/rich-text-types';
import { contentfulSchema } from '@wr/web-shared';
import { NotFound as UINotFound } from '@wr/web-ui';
import { useRouter } from 'next/router';

import { buildContentfulSidekickAttributes } from '@/utils';

import { Button, ButtonProps } from '../button';
import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';
import { useStyles } from './not-found-content.styles';

export const NotFoundContent: React.FC<contentfulSchema.NotFoundContentFragment> = ({
  message,
  buttonsCollection,
  sys,
  name,
  __typename,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const buttons =
    (buttonsCollection?.items.map(link => ({
      ...link,
      url: link?.url || `/${router.locale}/`,
      children: link?.label,
    })) as ButtonProps[]) || [];

  return (
    <UINotFound<ButtonProps, RichTextProps>
      containerProps={{
        'data-testid': 'not-found-page',
        ...buildContentfulSidekickAttributes(sys?.id, __typename, name),
      }}
      LinkComponent={Button}
      RichTextComponent={RichTextContentRenderer}
      message={
        {
          ...message,
          options: {
            [BLOCKS.HEADING_1]: { className: classes.messageHeading },
            [BLOCKS.HEADING_4]: { className: classes.messageSubheading },
            [BLOCKS.PARAGRAPH]: { className: classes.contentParagraph },
          },
        } as RichTextProps
      }
      buttons={buttons}
    />
  );
};
