import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  AppBar,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  Popover,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import { getSendCountryCodeFromLocale } from '@wr/web-ui';
import React, { createRef } from 'react';

import globeIcon from '../../../static/icons/globe.svg';
import { AppDownloadDropdown } from '../app-download-dropdown';
import { Container } from '../container';
import { CountryFlag } from '../country-flag-image';
import { SelectOption } from '../select/select.types';
import { BlogSelect } from './../blog-select/blog-select.component';
import { useLinkVisibilityByIndex, useMenuToggler } from './header.hooks';
import { useStyles } from './header.styles';
import { ExtendedButtonProps, HeaderProps } from './header.types';
import { getAuthenticatedId, isDarkTheme } from './header.utils';
import { HeaderSelect } from './header-select';
import { Logo } from './logo';
import { Submenu } from './submenu';
import { UserMenu } from './user-menu';

const headerLinksRef = createRef<HTMLDivElement>();
export const logoId = 'header_logo';

export function Header<
  Anchor extends { url?: string | null },
  Button extends ExtendedButtonProps
>({
  appDownloadDropdownProps,
  isAuthenticated,
  isAuthenticatingInProgress = false,
  headerVariant,
  welcomeMessage,
  myAccount,
  menuLabel,
  position = 'static',
  links,
  logo,
  userDropdownLinks,
  language,
  languages,
  onLanguageOpen,
  onLanguageChange,
  AnchorComponent,
  ButtonComponent,
  actualSendCountry,
  onBlogCategorySelect,
  blogTags,
  allBlogTag,
  router,
  isReferAFriend,
  referAFriendSendFromLabel,
  loyaltyCountries,
  loyaltyCountryDefault,
  onLoyaltyCountryChange,
  submenuItems,
  region,
  regionDropdownOptions,
  locale,
  onRegionChange,
  hideRegionSelector,
  locationLangLabel,
}: HeaderProps<Anchor, Button>): React.ReactElement {
  const classes = useStyles({
    headerVariant,
    submenuItems,
  });
  const color = isDarkTheme(headerVariant) ? 'secondary' : 'primary';
  const logoDarkOrLight = isDarkTheme(headerVariant)
    ? logo.logoDark
    : logo.logoLight;
  const isSmDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );
  const linkVisibilityByIndex = useLinkVisibilityByIndex<Anchor, Button>({
    isAuthenticated,
    isAuthenticatingInProgress,
    links,
    myAccount,
    headerLinksRef,
    language,
  });

  const determineRegionIcon = (locale: string) => {
    return locale === DEFAULT_LANGUAGE ? (
      <img alt="international" src={globeIcon} className={classes.mobileFlag} />
    ) : (
      <CountryFlag
        code={getSendCountryCodeFromLocale(locale)}
        className={classes.mobileFlag}
        type="rectangle"
      />
    );
  };

  const { isOpen, anchorEl, handleOpen, handleClose } = useMenuToggler();
  const allowedNavigateToBlog: string[] = ['es', 'fr', 'gb'];

  const shouldRenderMobileMenu =
    (isSmDown && !!languages?.length && language) ||
    linkVisibilityByIndex.some(i => i === false);

  return (
    <AppBar
      data-test-id="header-group"
      position={position}
      classes={{ root: classes.headerWrapper }}
    >
      <Container className={classes.headerContainer}>
        <Toolbar
          data-is-authenticated={getAuthenticatedId(
            isAuthenticatingInProgress,
            isAuthenticated,
          )}
          disableGutters
        >
          <div className={classes.leftSide} id={logoId} data-testid={logoId}>
            {shouldRenderMobileMenu && (
              //this is the small burger menu on mobile
              <>
                <Button
                  id="header-mobile-menu"
                  data-testid="hamburger-menu-button"
                  aria-label={menuLabel}
                  aria-controls="header-links"
                  aria-haspopup="true"
                  size="small"
                  color={color}
                  onClick={handleOpen}
                >
                  <MenuRoundedIcon />
                  {menuLabel ? (
                    <span
                      className={classes.hamburgerMenuLabel}
                      data-testid="hamburger-menu-text"
                    >
                      {menuLabel}
                    </span>
                  ) : null}
                </Button>

                <Popover
                  id="header-links"
                  anchorEl={anchorEl}
                  open={isOpen}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    square: true,
                  }}
                  onClose={handleClose}
                  data-testid="hamburger-menu-links"
                >
                  <List disablePadding>
                    {blogTags && (
                      <Hidden mdUp>
                        <BlogSelect
                          color={color}
                          isMobile={true}
                          blogTags={blogTags}
                          allBlogTag={allBlogTag}
                          router={router}
                          onBlogCategorySelect={onBlogCategorySelect}
                          headerVariant={headerVariant}
                        />
                      </Hidden>
                    )}
                    {links.map((link, i) => {
                      if (!linkVisibilityByIndex[i]) {
                        return (
                          <ListItem
                            button
                            component={props => (
                              <AnchorComponent
                                {...props}
                                {...link}
                                color="textPrimary"
                                variant={null}
                              >
                                {link?.children}
                              </AnchorComponent>
                            )}
                            key={link?.name ?? i}
                            color="inherit"
                          />
                        );
                      }
                    })}
                    <Divider
                      aria-hidden="true"
                      component="li"
                      className={classes.regionDivider}
                      light={!isDarkTheme(headerVariant)}
                    />
                    <Typography className={classes.regionLangText}>
                      {locationLangLabel}
                    </Typography>
                    {!isReferAFriend &&
                      locale &&
                      regionDropdownOptions &&
                      !hideRegionSelector && (
                        <HeaderSelect
                          isMobile
                          name="geo-location-selector"
                          color={color}
                          onChange={onRegionChange}
                          startIcon={determineRegionIcon(locale)}
                          data-testid="geo-country-selector"
                          value={(region ?? locale) as string}
                          options={regionDropdownOptions as SelectOption[]}
                          headerVariant={headerVariant}
                          renderCountryDropdownOptions={true}
                        />
                      )}
                    {!!languages?.length && language && (
                      <HeaderSelect
                        isMobile
                        name="language-selector"
                        color={color}
                        onOpen={onLanguageOpen}
                        onChange={onLanguageChange}
                        value={language}
                        options={languages}
                        data-testid="language-selector"
                        headerVariant={headerVariant}
                      />
                    )}
                    {isReferAFriend && loyaltyCountries && (
                      <HeaderSelect
                        isMobile
                        name="loyalty-country-selector"
                        color={color}
                        onChange={onLoyaltyCountryChange}
                        data-testid="loyalty-country-selector"
                        label={`${referAFriendSendFromLabel} `}
                        value={loyaltyCountryDefault as string}
                        options={loyaltyCountries}
                        headerVariant={headerVariant}
                      />
                    )}
                  </List>
                </Popover>
              </>
            )}

            <AnchorComponent
              {...logo.link}
              data-testid={headerVariant + '-logo'}
            >
              <Logo {...logoDarkOrLight} />
            </AnchorComponent>
            {!!languages?.length && language && (
              <Divider
                orientation="vertical"
                className={classes.divider}
                light={!isDarkTheme(headerVariant)}
              />
            )}
          </div>

          <Hidden lgDown>
            {/* this header select is unique to RaF */}
            {/* it retrieves greenfield countries for the 'Send From' dropdown */}
            {isReferAFriend && loyaltyCountries && (
              <HeaderSelect
                name="loyalty-country-selector"
                color={color}
                onChange={onLoyaltyCountryChange}
                data-testid="loyalty-country-selector"
                label={`${referAFriendSendFromLabel} `}
                value={loyaltyCountryDefault as string}
                options={loyaltyCountries}
                headerVariant={headerVariant}
              />
            )}
            {!!languages?.length && language && (
              <HeaderSelect
                name="language-selector"
                color={color}
                onOpen={onLanguageOpen}
                onChange={onLanguageChange}
                value={language}
                options={languages}
                data-testid="language-selector"
                headerVariant={headerVariant}
              />
            )}
            {/* conditional check to mitigate conflict with Greenfield RaF dropdown */}
            {!isReferAFriend &&
              regionDropdownOptions &&
              (region || locale) &&
              !hideRegionSelector && (
                <HeaderSelect
                  name="geo-location-selector"
                  color={color}
                  onChange={onRegionChange}
                  label={`${referAFriendSendFromLabel} `}
                  data-testid="geo-country-selector"
                  value={(region ?? locale) as string}
                  options={regionDropdownOptions as SelectOption[]}
                  headerVariant={headerVariant}
                  renderCountryDropdownOptions={true}
                />
              )}
          </Hidden>

          <nav ref={headerLinksRef} className={classes.nav}>
            <ul className={classes.navUl}>
              {blogTags && (
                <Hidden mdDown>
                  <BlogSelect
                    color={color}
                    isMobile={false}
                    blogTags={blogTags}
                    allBlogTag={allBlogTag}
                    router={router}
                    onBlogCategorySelect={onBlogCategorySelect}
                    headerVariant={headerVariant}
                  />
                </Hidden>
              )}
              {(links || []).map((link, index) => {
                if (
                  linkVisibilityByIndex[index] === undefined ||
                  linkVisibilityByIndex[index]
                ) {
                  if (link.variant === 'LinkGroup') {
                    return (
                      <li key={link?.name ?? index} className={classes.navLi}>
                        <HeaderSelect
                          color={color}
                          name={link?.name ?? ''}
                          label={link?.label ?? ''}
                          onChange={(e: string) => link?.onSelectDropdown?.(e)}
                          data-testid={`header-link-group-${link.name}`}
                          options={link?.options ?? []}
                          onOpen={() => {}}
                          value=""
                          headerVariant={headerVariant}
                        />
                      </li>
                    );
                  } else if (link?.url !== 'blog') {
                    return (
                      <li key={link?.name ?? index} className={classes.navLi}>
                        <ButtonComponent
                          {...link}
                          className={classes.navLink}
                        />
                      </li>
                    );
                  } else {
                    if (allowedNavigateToBlog.includes(actualSendCountry)) {
                      return (
                        <li key={link?.name ?? index} className={classes.navLi}>
                          <ButtonComponent
                            {...link}
                            className={classes.navLink}
                          />
                        </li>
                      );
                    }
                  }
                }
                return null;
              })}
              {isAuthenticated && (
                <li className={classes.navLi}>
                  <UserMenu<Anchor>
                    AnchorComponent={AnchorComponent}
                    myAccount={myAccount}
                    welcomeMessage={welcomeMessage}
                    userDropdownMenuItems={userDropdownLinks}
                    color={color}
                  />
                </li>
              )}
              {appDownloadDropdownProps && (
                <li className={classes.navLi}>
                  <AppDownloadDropdown
                    {...appDownloadDropdownProps}
                    headerVariant={headerVariant}
                  />
                </li>
              )}
            </ul>
          </nav>
        </Toolbar>
      </Container>
      {submenuItems?.length && (
        <Submenu
          {...{ submenuItems, headerVariant, ButtonComponent, router }}
        />
      )}
    </AppBar>
  );
}
