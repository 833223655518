import { Popper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import React from 'react';

import { useStyles } from './dropdown-desktop.styles';
import { DropdownDesktopProps } from './dropdown-desktop.types';

export const DropdownDesktop: React.FC<DropdownDesktopProps> = ({
  id,
  width,
  isOpen,
  anchorEl,
  children,
  onClose,
}) => {
  const classes = useStyles({ width });

  if (!anchorEl) return null;

  return (
    <Popper
      id={id}
      open={isOpen}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          options: {
            escapeWithReference: true,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ]}
      placement="bottom-end"
      anchorEl={anchorEl}
      className={classes.popper}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div>{children}</div>
      </ClickAwayListener>
    </Popper>
  );
};
