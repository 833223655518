import { Box, Typography } from '@mui/material';
import React from 'react';

import { PHILIPPINES_RECEIVE_COUNTRY_CODE } from '@/constants';
import { buildContentfulSidekickAttributes } from '@/utils';

import { PayoutMethodsEnum } from '../../types';
import ErrorBoxAccordion from '../error-box-accordion';
import { useStyles } from './payout-method-select-option.styles';
import { PayoutMethodsSelectOptionProps } from './payout-method-select-option.types';

export const PayoutMethodSelectOption: React.FC<PayoutMethodsSelectOptionProps> = ({
  label,
  value,
  description,
  transferTimeTooltip,
  payoutMethodAlerts,
  bankTransferWarning,
  sendCountry,
}) => {
  const classes = useStyles();
  const payoutMethodAlertsForPayoutMethodCode = payoutMethodAlerts.filter(
    payoutMethodAlert => {
      return payoutMethodAlert?.payoutMethodsCollection?.items.some(
        item => item?.payoutMethodId === value,
      );
    },
  );

  return (
    <>
      <Box data-testid="payout-method-select-option" mb={1.5}>
        <Typography variant="body1" color="primary">
          <strong>{label}</strong>
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>

      {transferTimeTooltip && (
        <Typography
          variant="body1"
          className={classes.payoutMethodTransferTime}
        >
          {sendCountry?.countryCode === PHILIPPINES_RECEIVE_COUNTRY_CODE &&
          value === PayoutMethodsEnum.BANK_TRANSFER &&
          bankTransferWarning
            ? bankTransferWarning
            : transferTimeTooltip}
        </Typography>
      )}

      {payoutMethodAlertsForPayoutMethodCode?.map(payoutMethodAlert => {
        return (
          <Box
            data-testid="payout-method-alert"
            marginTop={2.25}
            key={value}
            {...buildContentfulSidekickAttributes(
              payoutMethodAlert?.sys.id,
              payoutMethodAlert?.__typename,
              payoutMethodAlert?.name,
            )}
          >
            {payoutMethodAlert?.title && payoutMethodAlert?.message && (
              <ErrorBoxAccordion
                title={payoutMethodAlert.title}
                message={payoutMethodAlert.message}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};
