import React, { useMemo } from 'react';

import { resolveComponents } from '@/utils';

import { CexCurrencyPairPageProps } from './cex-currency-pair-page.types';

export const CexCurrencyPairPage: React.FC<CexCurrencyPairPageProps> = ({
  sys,
  modules,
  sendCurrency,
  receiveCurrency,
  correspondentLogos,
  correspondentLocations,
  header,
}) => {
  const propsOverrides = {
    Correspondents: {
      correspondentLogos,
    },
    CorrespondentLocations: {
      correspondentLocations,
    },
    sendCurrency,
    receiveCurrency,
  };

  const modulesWithCurrencyInformation = useMemo(() => {
    const cexInformation = {
      CexCurrencyInformation: {
        sys: { id: sys.id },
        sendCurrencyCode: sendCurrency?.currencyCode,
        receiveCurrencyCode: receiveCurrency?.currencyCode,
      },
    };

    if (!modules || !modules.length) return [cexInformation];

    const [firstModule, ...otherModules] = modules;

    // If the first item is the hero show cex-information after it
    if (firstModule && Object.keys(firstModule)[0] === 'BetterWayTop') {
      return [firstModule, cexInformation, ...otherModules];
    }
    return [cexInformation, firstModule, ...otherModules];
  }, [
    modules,
    receiveCurrency?.currencyCode,
    sendCurrency?.currencyCode,
    sys.id,
  ]);

  if (!modulesWithCurrencyInformation || !modulesWithCurrencyInformation.length)
    return null;

  const resolvedComponents = resolveComponents(
    modulesWithCurrencyInformation,
    propsOverrides,
    header,
  );

  return <>{resolvedComponents}</>;
};
