import { ANALYTICS_PAGE_TYPE, ApolloClient, bffSchema } from '@wr/web-shared';
import { Router } from 'next/router';

import { logger } from '@/utils';
import { getSendCountryCodeFromLocale } from '@/utils/intl';

export const getActiveCorridors = async ({
  locales,
  receiveCountryCode,
  analyticsPageType,
  bffClient,
}: {
  locales: Router['locales'];
  bffClient: ApolloClient;
  receiveCountryCode: string;
  analyticsPageType: string;
}): Promise<
  | bffSchema.GetActiveCorridorsQuery['getActiveCorridors']['sendCountries']
  | null
> => {
  if (
    locales &&
    [ANALYTICS_PAGE_TYPE.Country, ANALYTICS_PAGE_TYPE.CountryOperator].includes(
      analyticsPageType as ANALYTICS_PAGE_TYPE,
    )
  ) {
    const sendCountries = [
      ...new Set(
        locales
          ?.map(getSendCountryCodeFromLocale)
          .filter((s): s is string => Boolean(s)),
      ),
    ].map(sendCountryCode => sendCountryCode.toUpperCase());

    try {
      const { data } = await bffClient.query<bffSchema.GetActiveCorridorsQuery>(
        {
          query: bffSchema.GetActiveCorridors,
          variables: {
            sendCountries,
            receiveCountry: receiveCountryCode.toUpperCase(),
          },
        },
      );

      return data.getActiveCorridors.sendCountries;
    } catch (error) {
      logger.error(error, `GetActiveCorridors query request failed`);

      try {
        const {
          data,
        } = await bffClient.query<bffSchema.GetActiveCorridorsQuery>({
          query: bffSchema.GetActiveCorridors,
          variables: {
            sendCountries,
            receiveCountry: receiveCountryCode.toUpperCase(),
          },
        });

        return data.getActiveCorridors.sendCountries;
      } catch (error) {
        return [];
      }
    }
  }

  return null;
};
