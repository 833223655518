import React, { useEffect, useRef } from 'react';

import { TrustpilotWidgetProps } from './trustpilot-widget.types';

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement, isOptIn: boolean) => void;
    };
  }
}

export const TrustpilotWidget: React.FC<TrustpilotWidgetProps> = ({ url }) => {
  const trustpilotWidgetRef = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window?.Trustpilot && trustpilotWidgetRef.current) {
      window?.Trustpilot?.loadFromElement(trustpilotWidgetRef.current, true);
    }
  }, []);

  return (
    <div
      ref={trustpilotWidgetRef}
      data-testid="trustpilot-widget"
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="5e6f1fdc44891200011a4635"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
    >
      <a href={url} target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};
