import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  logo: {
    width: 'auto',
    height: theme.customComponents.header.logo.height,
    [theme.breakpoints.up('sm')]: {
      height: theme.customComponents.header.logo.breakpointsUpSm.height,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoMobile: {
    width: 'auto',
    height: theme.customComponents.header.logo.height,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
