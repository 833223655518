import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';

import { Container } from '../container';
import { Image } from '../image';
import { RegionDropdown } from '../region-dropdown';
import { SocialLinks } from '../social-links/social-links.component';
import { useStyles } from './footer.styles';
import { FooterProps } from './footer.types';

export function Footer<
  Anchor extends { url?: string | null } | null,
  RichText
>({
  legalMessage,
  copyrightText,
  socialLinks,
  navigation,
  footerLogo,
  AnchorComponent,
  breadcrumbs,
  containerProps,
  RichTextComponent,
  chooseYourRegionLabel,
  regionDropdownProps,
}: FooterProps<Anchor, RichText>): React.ReactElement {
  const classes = useStyles();
  return (
    <footer className={classes.footer} {...containerProps}>
      <Container>
        {breadcrumbs && <div>{breadcrumbs}</div>}
        <Grid container direction="row" justifyContent="flex-start" spacing={5}>
          {navigation?.map(navigationGroup => {
            const { title, links } = navigationGroup || {};
            const allowedCountries = ['en', 'fr', 'es'];
            const filterAllowedLinks = (
              link: (Anchor & { name?: string | null }) | null,
            ) => {
              if (
                allowedCountries.includes(regionDropdownProps?.locale as string)
              ) {
                if (regionDropdownProps?.locale == 'en') return link;
                if (link?.name !== 'news') return link;
              } else {
                if (link?.name !== 'blog' && link?.name !== 'news') {
                  return link;
                }
              }
            };

            const linksAllowedPerCountry = links.filter(filterAllowedLinks);

            return (
              <Grid
                key={title}
                item
                xs={12}
                md={6}
                lg={4}
                data-testid="footer-links-group"
              >
                <Typography
                  component="h4"
                  variant="h4"
                  className={classes.navigationGroupHeading}
                >
                  {title}
                </Typography>
                <Divider className={classes.divider} light />
                <ul
                  className={classes.navigationGroupList}
                  data-testid={`footer-links-${title}`}
                >
                  {linksAllowedPerCountry.map(link => {
                    return (
                      <li key={link?.url}>
                        <AnchorComponent
                          {...link}
                          data-testid={link?.url}
                          className={classes.navigationLink}
                        />
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Divider className={classes.divider} light />
          </Grid>
        </Grid>
        {regionDropdownProps && (
          <div className={classes.regionDropdown}>
            {chooseYourRegionLabel && (
              <Typography
                component="h4"
                variant="h4"
                className={classes.navigationGroupHeading}
              >
                {chooseYourRegionLabel}
              </Typography>
            )}
            <RegionDropdown
              name="footer-region-dropdown"
              onChange={regionDropdownProps.onChange}
              value={regionDropdownProps.value}
              locale={regionDropdownProps.locale}
              locales={regionDropdownProps.locales}
              countriesLabel={regionDropdownProps.countriesLabel}
              internationalLabel={regionDropdownProps.internationalLabel}
              menuProps={regionDropdownProps.menuProps}
              LinkComponent={regionDropdownProps.LinkComponent}
              position={'top'}
            />
          </div>
        )}

        <SocialLinks<Anchor>
          AnchorComponent={AnchorComponent}
          socialIconsLinks={socialLinks}
          className={classes.socialLinks}
        />

        <div className={classes.legalMessage} {...legalMessage?.containerProps}>
          {legalMessage?.content && (
            <div data-testid="footer-legal-messaging">
              <RichTextComponent {...legalMessage.content} />
            </div>
          )}
          {footerLogo && copyrightText && (
            <div className={classes.logo} data-testid="footer-logo">
              {footerLogo && (
                <Image className={classes.footerLogo} {...footerLogo} />
              )}
              {copyrightText && (
                <Typography
                  data-testid="footer-copyright"
                  variant="body1"
                  align="center"
                  noWrap
                >
                  {copyrightText}
                </Typography>
              )}
            </div>
          )}
        </div>
      </Container>
    </footer>
  );
}
