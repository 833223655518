import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { SitemapListProps } from './sitemap-list.types';

const listStyleTypes = ['none', 'disc', 'circle', 'square'];

export const useStyles = makeStyles<Theme, Pick<SitemapListProps, 'level'>>(
  theme => ({
    list: ({ level }) => ({
      marginTop: theme.spacing(level === 0 ? 3 : 1),
      paddingLeft: theme.spacing(level === 0 ? 0 : 3),

      listStyleType: listStyleTypes[level],
    }),
    listItem: ({ level }) => ({
      marginBottom: theme.spacing(level === 0 ? 3 : 1),
    }),
    listItemLink: {
      display: 'block',
      marginBottom: theme.spacing(1),
    },
    listItemSecondary: {
      color: theme.palette.primary.main,
    },
  }),
);
