/* eslint-disable max-len */

export const colors = {
  //https://www.figma.com/file/xaBV9lewHQ2RT1CSVAerk8/Colours-%7C-All-Platforms?node-id=474%3A12
  // Primary
  purpleDark: '#3D1152',
  purpleNormal: '#813FD6',
  purpleLight: '#C7ABEA',
  purpleExtraLight: '#E9DDF7',

  greenDark: '#128387',
  greenLight: '#33CFB8',

  // Secondary
  greyDark: '#444C4C',
  greyNormal: '#949494',
  greyLight: '#E2E2E2',

  creamNormal: '#F8F7F2',

  // Supplementary
  redNormal: '#CC0000',

  // HS-1712 HS-1717 Check brand guidelines
  // rgb(51, 207, 184) tertiary
  pink: '#eb4b60', // rgb(235, 75, 96) tertiary
  yellow: '#efbd2f', // rgb(239, 189, 47) tertiary
  grey: '#696876',
  white: '#FFFFFF',
  black: '#000000',

  success: {
    dark: '#37b635',
  },
  kale: '#2e3a9d',
  lightCyan: '#B1EAE0',
  turquoise: '#46cfb8',
  solitude: '#eaecef', // TODO: Rename to stone as per schema
  softCatalinaBlue: 'rgba(47, 70, 98, .1)',
  resolutionBlue: '#30467A', // rgb(48, 70,	122)
  snow: '#fafafa',
  alabaster: '#F8F8F8',
  violet: '#462d64',
  blueViolet: '#803fd6',
  gainsboro: '#ddd', // rgb(221, 221, 221)
  linkWater: '#cdd3d9',
  veryDarkGrayishBlue: '#586069',
  darkGray: '#979797',
  whiteSmoke: '#f3f3f3',
  shadeBlack: 'rgba(0, 0, 0, 0.12)',
  verySoftBlue: '#bac4cf',
  brand: '#663399',
  pelorous: '#22AABA',
  suvaGrey: '#919191',
  silverGrey: '#BDBDBD',
  whiteSmokeGrey: '#F5F5F5',
  trustpilotGreen: '#00B57B',
  trustpilotLightGreen: '#73CE17',
  trustpilotYellow: '#FECE00',
  trustpilotOrange: '#FF8523',
  trustpilotRed: '#FE3723',
  tundora: '#484848',
  maritime:
    'linear-gradient(0deg, rgba(19, 120, 124, 0.3), rgba(19, 120, 124, 0.3))',
  lightViolet: '#D9C8F3',
  darkGreen: '#128387;',
};
