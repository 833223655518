import {
  contentfulSchema,
  DEFAULT_LANGUAGE,
  EVENT,
  INTERACTION_NAME,
  pushToDataLayer,
} from '@wr/web-shared';
import { SelectOption } from '@wr/web-ui';
import type { NextRouter } from 'next/router';

import { LayoutProps } from '@/components/layout';
import { AppContextType } from '@/context';
import {
  getLanguageFromLocale,
  getSendCountryCodeFromLocale,
} from '@/utils/intl';

export const getAllValidTopLevelLinks = (
  headerItems: contentfulSchema.HeaderFragment,
  isAuthenticated: boolean,
): contentfulSchema.LinkFragment[] => {
  if (!isAuthenticated) {
    return (
      headerItems.notLoggedInMenuItemsCollection?.items.filter(
        (link): link is contentfulSchema.LinkFragment => link !== null,
      ) || []
    );
  }

  return (
    headerItems.loggedInMenuItemsCollection?.items.filter(
      (link): link is contentfulSchema.LinkFragment => link !== null,
    ) || []
  );
};

export const pushNavigationClickToDataLayer = (
  gaTrackName?: string | null,
  prefix?: string,
) => (): void => {
  if (!gaTrackName) {
    return;
  }

  pushToDataLayer({
    event: EVENT.VISITOR_INTERACTION,
    interactionName: INTERACTION_NAME.TOP_NAVIGATION_CLICK,
    linkName: prefix ? `${prefix} - ${gaTrackName}` : gaTrackName,
  });
};

export const selectCountriesWithCode = (
  countries: AppContextType['sendCountries'],
): SelectOption[] =>
  countries.map(country => ({
    value: country.countryCode,
    label: country.name,
  }));

export const getInitialGeoLocationRegion = (
  locale: NextRouter['locale'],
  locales: NextRouter['locales'],
  locationCountryCookie: LayoutProps['locationCountryCookie'],
): string | null => {
  if (!locales || !locale || !locationCountryCookie) {
    return null;
  }

  const currentLanguage = getLanguageFromLocale(locale);

  for (const region of locales) {
    const regionLanguage = getLanguageFromLocale(region);
    const regionCountry = getSendCountryCodeFromLocale(region);

    if (regionCountry === locationCountryCookie) {
      if (locales.includes(`${currentLanguage}-${regionCountry}`)) {
        return `${currentLanguage}-${regionCountry}`;
      }
      return region;
    }

    if (!regionCountry && regionLanguage === locationCountryCookie) {
      return region;
    }
  }

  return DEFAULT_LANGUAGE; // returns "international" region by default
};
