import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Hidden,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import { CalculatorProps } from '@/components/calculator/component/calculator.types';

import useStyles from './phone-input-info.styles';

export const PhoneInputInfo: React.FC<
  Pick<
    CalculatorProps,
    'phoneInputInfoMoreInfoLabel' | 'phoneInputInfoPopupText'
  >
> = ({ phoneInputInfoMoreInfoLabel, phoneInputInfoPopupText }) => {
  const [isPopupOpened, setPopupOpened] = useState(false);
  const phoneInputPopupButtonRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handlePopupOpen = (): void => {
    setPopupOpened(true);
  };
  const handlePopupClose = (): void => {
    setPopupOpened(false);
  };
  const handlePopupToggle = (): void => {
    setPopupOpened(!isPopupOpened);
  };

  const popupHandlers = isMobile
    ? { onClick: handlePopupToggle }
    : {
        onFocus: handlePopupOpen,
        onBlur: handlePopupClose,
        onMouseEnter: handlePopupOpen,
        onMouseLeave: handlePopupClose,
      };

  const popupContent = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="flex-start"
      py={{ xs: 1.25, md: 2.5 }}
      pb={2.5}
      px={1.25}
      mt={1.875}
      className={clsx(classes.popupContainer)}
    >
      <Hidden mdUp>
        <button
          className={classes.closeButton}
          type="button"
          data-testid={`phone-input-info-popup-close-button`}
          onClick={handlePopupClose}
        >
          <CloseIcon className={classes.closeButtonIcon} />
        </button>
      </Hidden>
      <Typography className={classes.popupText} component="div">
        {phoneInputInfoPopupText}
      </Typography>
    </Box>
  );

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="baseline"
      width="100%"
      mt={1}
      mb={2}
      data-testid="phone-input-info"
    >
      <div ref={phoneInputPopupButtonRef}>
        <Box display="flex" alignItems="center">
          <div
            role="button"
            tabIndex={0}
            className={classes.openButton}
            {...popupHandlers}
          >
            <Typography
              variant="inherit"
              component="span"
              className={clsx(classes.text, classes.value)}
              data-testid="phone-input-info-label"
            >
              {phoneInputInfoMoreInfoLabel}
            </Typography>
            <HelpOutlineIcon className={classes.openButtonIcon} />
          </div>
        </Box>
      </div>
      <Popper
        anchorEl={phoneInputPopupButtonRef.current}
        open={isPopupOpened}
        placement="bottom-start"
        data-testid="phone-input-info-popup"
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
        ]}
      >
        <Hidden mdUp>
          <ClickAwayListener onClickAway={handlePopupClose}>
            {popupContent}
          </ClickAwayListener>
        </Hidden>
        <Hidden mdDown>{popupContent}</Hidden>
      </Popper>
    </Box>
  );
};
