import { Typography } from '@mui/material';
import { Image, Tooltip } from '@wr/web-ui';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

import {
  BackToSchoolCategory,
  BackToSchoolCountry,
  formatCurrencyValue,
} from '@/components/back-to-school';
import { RichTextContentRenderer } from '@/components/rich-text-content-renderer';
import ArrowIcon from '@/public/next-assets/arrow-icon.svg';

import useStyles from './interactive-table.styles';
import { InteractiveTableProps } from './interactive-table.types';
import { getBarGridBreakpoints } from './interactive-table.utils';

export const InteractiveTable: React.FC<InteractiveTableProps> = ({
  countriesCollection,
  categoriesCollection,
  itemsListHeading,
  itemsListMessage,
  selectedCurrency,
  totalSpendPerChildLabel,
  hasCurrencyChart,
}) => {
  const classes = useStyles();

  const countryBarRefs = useRef<Record<string, HTMLDivElement>[]>([]);
  const countryBarTextRefs = useRef<Record<string, HTMLDivElement>[]>([]);
  const countryBarTextPadding = 10;
  useEffect(() => {
    if (
      countryBarRefs.current.length > 0 &&
      countryBarTextRefs.current.length > 0
    ) {
      countryBarTextRefs.current.forEach((countries, index) => {
        Object.keys(countries).forEach(country => {
          const textWidth = countries[country].offsetWidth;
          const barWidth = countryBarRefs.current[index][country].offsetWidth;

          // Hide country labels if they are too long for the bar
          if (textWidth > barWidth - countryBarTextPadding) {
            countries[country].style.display = 'none';
          }
        });
      });
    }
  }, [countryBarRefs, countryBarTextRefs]);

  if (
    !Array.isArray(countriesCollection?.items) ||
    !countriesCollection?.items.length
  ) {
    return null;
  }

  const { currencySymbol, exchangeRate } = selectedCurrency;

  const barGridBreakpoints = getBarGridBreakpoints(!!hasCurrencyChart);

  const getTotalCountryCost = (country: BackToSchoolCountry) => {
    return country?.totalChildCost ?? country?.totalHouseholdCost ?? 0;
  };

  const maxBarGridScale = Math.round(
    Math.max(...countriesCollection.items.map(getTotalCountryCost)),
  );

  const costsCountryMap = Object.fromEntries(
    countriesCollection.items.map(country => [
      country.country,
      Object.fromEntries(country.costs?.tableData ?? []),
    ]),
  );

  const getCostPercentage = (
    cost: string | number,
    totalCost: number,
  ): number => Math.round((Number(cost) / totalCost) * 100);

  const TooltipBody = (country: BackToSchoolCountry): React.ReactChild => (
    <div className={classes.barTooltip}>
      <div className={classes.barTooltipHeader}>
        <div className={classes.barTooltipCountryWrapper}>
          <div
            className={clsx(
              classes.barCountryFlag,
              classes.barTooltipCountryFlag,
            )}
          >
            <Image {...country.countryFlag} />
          </div>
          <Typography variant="subtitle2">{country.country}</Typography>
        </div>
        <div>
          <Typography variant="subtitle1">
            {formatCurrencyValue(
              getTotalCountryCost(country),
              exchangeRate,
              currencySymbol,
            )}
          </Typography>
        </div>
      </div>
      <div style={{ padding: 12 }}>
        {categoriesCollection.items.map(category => (
          <div key={category.name} className={classes.barTooltipCategory}>
            <div className={classes.barTooltipCategoryWrapper}>
              <div
                className={classes.marker}
                style={{
                  backgroundColor: category.categoryHexColour
                    ? category.categoryHexColour
                    : undefined,
                }}
              />
              <Typography variant="body1">{category.categoryTitle}</Typography>
            </div>
            <div
              className={classes.barTooltipCategoryValue}
              style={{
                color: category.categoryHexColour
                  ? category.categoryHexColour
                  : undefined,
              }}
            >
              <Typography variant="body1">
                {formatCurrencyValue(
                  costsCountryMap[country.country][category.categoryKey],
                  exchangeRate,
                  currencySymbol,
                )}
              </Typography>
              <div className={classes.barTooltipPercent}>
                <Typography variant="body1">
                  {getCostPercentage(
                    costsCountryMap[country.country as string][
                      category.categoryKey
                    ],
                    getTotalCountryCost(country),
                  )}
                  %
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const CountryLabel = (country: BackToSchoolCountry): React.ReactChild => (
    <div
      className={classes.barCountry}
      data-testid={`country-label-${country.name}`}
    >
      <div className={classes.barCountryContentWrapper}>
        <div className={classes.barCountryFlag}>
          <Image {...country.countryFlag} />
        </div>
        <div className={classes.barCountryLabel}>
          <Typography variant="subtitle2">{country.country}</Typography>
        </div>
      </div>
    </div>
  );

  const CountryBar = (country: BackToSchoolCountry): React.ReactChild => {
    const barWidth = hasCurrencyChart
      ? getCostPercentage(getTotalCountryCost(country), maxBarGridScale)
      : '100';

    return (
      <div className={classes.barScale} style={{ width: `${barWidth}%` }}>
        <div className={classes.barFill}>
          {categoriesCollection.items.map(category => (
            <div
              key={category.name}
              className={classes.barItem}
              style={{
                width: `${getCostPercentage(
                  costsCountryMap[country.country][category.categoryKey],
                  getTotalCountryCost(country),
                )}%`,
                backgroundColor: category.categoryHexColour
                  ? category.categoryHexColour
                  : undefined,
              }}
            />
          ))}
        </div>
        <div className={classes.barPercent}>
          {categoriesCollection.items.map((category, index) => {
            const percentageValue = getCostPercentage(
              costsCountryMap[country.country][category.categoryKey],
              getTotalCountryCost(country),
            );

            return (
              <div
                key={category.name}
                ref={(element: HTMLDivElement) => {
                  countryBarRefs.current[index] = {
                    ...countryBarRefs.current[index],
                    [country.country]: element,
                  };
                }}
                className={classes.barItem}
                style={{ width: `${percentageValue}%` }}
              >
                <div
                  ref={(element: HTMLDivElement) => {
                    countryBarTextRefs.current[index] = {
                      ...countryBarTextRefs.current[index],
                      [country.country]: element,
                    };
                  }}
                  className={classes.barPercentValue}
                >
                  <Typography variant="body1">
                    {`${percentageValue > 0 ? `${percentageValue}%` : ''}`}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const Row = (): React.ReactChild => (
    <div style={{ position: 'relative' }}>
      {countriesCollection.items.map(country => {
        const totalCostValue = `${currencySymbol}${Math.round(
          country?.totalHouseholdCost ?? 0,
        )}`;

        return (
          <Tooltip
            key={country.name}
            title={TooltipBody(country)}
            classes={{
              tooltip: classes.tooltipWrapper,
              arrow: classes.arrowWrapper,
            }}
            id={`bar-tooltip-${country.country}`}
            enterTouchDelay={50}
            leaveTouchDelay={5000}
          >
            <div className={classes.barsRow}>
              {CountryLabel(country)}
              {CountryBar(country)}
              {!hasCurrencyChart && (
                <div className={classes.barValue}>
                  <Typography variant="body1">{totalCostValue}</Typography>
                </div>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );

  const Grid = (): React.ReactChild => (
    <div className={classes.barsGrid}>
      {barGridBreakpoints.map(e => {
        const gridLabel = hasCurrencyChart
          ? `${currencySymbol}${Math.round(e * maxBarGridScale * exchangeRate)}`
          : `${e}%`;
        const gridGutter = hasCurrencyChart ? `${e * 100}%` : `${e}%`;

        return (
          <div
            key={`breakpoint-${e}`}
            data-testid={`breakpoint-${e}`}
            className={classes.barsGridItem}
            style={{ left: gridGutter }}
          >
            <Typography variant="body1">{gridLabel}</Typography>
          </div>
        );
      })}
    </div>
  );

  const Bars = (): React.ReactChild => (
    <div className={classes.barsWrapper}>
      {totalSpendPerChildLabel && (
        <div className={classes.barsLabelWrapper}>
          <div style={{ marginRight: 8 }}>
            <Typography variant="body1">{totalSpendPerChildLabel}</Typography>
          </div>
          <img src={ArrowIcon} alt="arrow-icon" width="86" />
        </div>
      )}
      {Grid()}
      <div>{Row()}</div>
    </div>
  );

  const Category = (category: BackToSchoolCategory): React.ReactChild => (
    <div
      key={`category-${category.name}`}
      data-testid={`category-${category.name}`}
      className={classes.category}
    >
      <div className={classes.categoryImageWrapper}>
        <Image {...category.categoryImage} />
      </div>
      <div
        className={classes.categoryTitle}
        style={{
          backgroundColor: category.categoryHexColour
            ? category.categoryHexColour
            : undefined,
        }}
      >
        <Typography variant="subtitle2">{category.categoryTitle}</Typography>
      </div>
    </div>
  );

  return (
    <section className={classes.section} data-testid="interactive-table">
      <div
        data-testid="category-country-cost-text"
        className={classes.sectionContentWrapper}
      >
        <Typography className={classes.sectionContentHeader} variant="h4">
          {itemsListHeading}
        </Typography>
        {itemsListMessage?.json && (
          <div style={{ paddingTop: 16 }}>
            <RichTextContentRenderer json={itemsListMessage.json} />
          </div>
        )}
      </div>
      {Array.isArray(categoriesCollection.items) &&
        categoriesCollection.items.length && (
          <div data-testid="category-country-cost-chart">
            <div className={classes.categoryWrapper}>
              {categoriesCollection.items.map(category => Category(category))}
            </div>
            {Bars()}
          </div>
        )}
    </section>
  );
};

export default InteractiveTable;
