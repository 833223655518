import { Chip, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Image } from '../image';
import { useStyles } from './card.styles';
import { CardProps } from './card.types';

export function Card<Anchor, Button>({
  name,
  chipLabel,
  className,
  header,
  description,
  AnchorComponent,
  ButtonComponent,
  link,
  button,
  backgroundColor,
  backgroundImage,
  containerProps,
  isActive = true,
}: CardProps<Anchor, Button>): React.ReactElement {
  const classes = useStyles({ backgroundImage, backgroundColor });

  const card = (
    <>
      {backgroundImage && (
        <Image {...backgroundImage} className={classes.backgroundImage} />
      )}
      {chipLabel && <Chip label={chipLabel} className={classes.chip} />}
      <div
        className={clsx(
          classes.textContainer,
          backgroundImage && classes.overlay,
          'card-text-container',
        )}
      >
        {header && (
          <Typography
            component="h3"
            variant="h5"
            gutterBottom
            data-testid={'card-' + name + '-header'}
            className={classes.title}
          >
            {header}
          </Typography>
        )}
        {description && (
          <Typography
            component="p"
            data-testid={'card-' + name + '-description'}
            variant="body1"
            className={classes.description}
          >
            {description}
          </Typography>
        )}

        {!backgroundImage && button && ButtonComponent && (
          <ButtonComponent
            {...button}
            data-testid={'card-' + name + '-button'}
            tabIndex={isActive ? 0 : -1}
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.ctaButton}
          />
        )}

        {backgroundImage && button && ButtonComponent && (
          <ButtonComponent
            {...button}
            data-testid={'card-' + name + '-button'}
            tabIndex={-1}
            component="span"
            variant="outlined"
            color="secondary"
            size="small"
          />
        )}
      </div>
    </>
  );

  return (
    <Paper
      data-testid={'card-' + name}
      className={className}
      classes={{
        root: classes.card,
        rounded: classes.rounded,
      }}
      elevation={0}
      {...containerProps}
    >
      {backgroundImage && link && AnchorComponent ? (
        <AnchorComponent
          {...link}
          data-testid={'card-' + name + '-link'}
          className={classes.cardLink}
        >
          {card}
        </AnchorComponent>
      ) : (
        card
      )}
    </Paper>
  );
}
