import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles<Theme>(theme => ({
  heading: {
    [theme.breakpoints.up('md')]: {
      fontSize: '3.25rem', // 52px
      lineHeight: 1.07692307692, // 56px
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem', // 24px
      lineHeight: 1.16666666667, // 28px
    },
  },
  asset: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
}));
