import { Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { Anchor } from '@/components';
import { getCurrencyCorridorSlug } from '@/services';

import { CexCountryFlag } from '../cex-country-flag';
import { CexCurrencyCode } from '../cex-currency-code';
import { useStyles } from './cex-corridor-card.styles';
import { CexCorridorCardProps } from './cex-corridor-card.types';

export const CexCorridorCard: React.FC<CexCorridorCardProps> = ({
  corridor,
  currencyCode,
}) => {
  const classes = useStyles();

  const url = useMemo(
    () =>
      getCurrencyCorridorSlug({
        receiveCurrencyCode: corridor.receiveCurrency.currencyCode,
        sendCurrencyCode: corridor.sendCurrency.currencyCode,
        sendCountryCode: corridor.sendCurrency.countryCode,
        receiveCountryCode: corridor.receiveCurrency.countryCode,
        cexPageSlug: corridor.pageSlug,
      }),
    [corridor],
  );

  return (
    <Anchor className={classes.linkWrapper} url={url}>
      <div
        className={classes.card}
        data-testid={`cex-corridor-card-${corridor.pageSlug}`}
      >
        <CexCountryFlag
          countryCode={corridor.receiveCurrency.countryCode}
          sendCountryCode={corridor.sendCurrency.countryCode}
        />
        <CexCurrencyCode
          currencyCode={corridor.receiveCurrency.currencyCode}
          sendCurrencyCode={corridor.sendCurrency.currencyCode}
        />
        <Typography
          component="div"
          className={classes.currencyName}
          data-testid="cex-corridor-card-name"
          paragraph
        >
          {currencyCode === corridor.sendCurrency.currencyCode
            ? corridor.receiveCurrency.currencyName
            : corridor.sendCurrency.currencyName}
        </Typography>
      </div>
    </Anchor>
  );
};
