import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(
  ({ spacing, breakpoints, shape, customComponents }) => ({
    section: {
      textAlign: 'center',
      padding: spacing(3, 0),
      [breakpoints.up('md')]: {
        padding: spacing(4, 0),
      },
      [breakpoints.up('lg')]: {
        padding: spacing(5, 0),
      },
    },
    stepsContainer: {
      listStyle: 'none',
      padding: spacing(0),
      margin: spacing(0),
      display: 'flex',
      gap: spacing(2, 0),
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',

      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        gap: spacing(3),
      },
    },
    heading: {
      marginBottom: spacing(2),
    },
    listItem: {
      backgroundColor: customComponents.stepByStep.listItem.backgroundColor,
      borderRadius: shape.borderRadius * 2,
      padding: spacing(2, 2, 2, 6),
      position: 'relative',
      textAlign: 'left',
      width: '100%',

      [breakpoints.up('sm')]: {
        padding: spacing(2),
        textAlign: 'center',
      },
      [breakpoints.up('md')]: {
        padding: spacing(3),
      },
    },
    image: {
      margin: spacing(0, 'auto', 2),
      display: 'block',
    },
    stepHeading: {
      color: customComponents.stepByStep.stepHeading.color,
      display: 'inline-block',
      marginBottom: spacing(0.5),
    },
    numeration: {
      position: 'absolute',
      display: 'block',
      marginBottom: spacing(0.5),
      top: spacing(2),
      left: spacing(2),

      [breakpoints.up('sm')]: {
        position: 'relative',
        top: 'auto',
        left: 'auto',
      },
      [breakpoints.up('md')]: {
        marginRight: spacing(0.5),
        display: 'inline-block',
      },
    },
    description: {
      [breakpoints.up('sm')]: {
        maxWidth: '20rem',
        margin: 'auto',
      },
    },
    link: {
      padding: spacing(2),

      [breakpoints.up('lg')]: {
        marginTop: spacing(4),
      },
    },
  }),
);

export default useStyles;
