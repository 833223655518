import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '../../styles';

export const pxCaptchaStyles = {
  challenge: {
    view: {
      borderColor: colors.purpleNormal,
      borderWidth: 4,
      borderRadius: 6,
      textColor: colors.purpleNormal,
      fillColor: colors.purpleLight,
      height: 56,
    },
  },
};

export const useStyles = makeStyles<Theme>(
  ({ zIndex, shape, spacing, customComponents }) => ({
    pxOverlay: {
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: zIndex.modal,
      display: 'flex',
    },
    pxDialog: {
      minWidth: '25rem',
      maxWidth: '30rem',
      minHeight: '15.625rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      backgroundColor:
        customComponents.perimeterXCaptchaModal.pxDialog.backgroundColor,
      padding: spacing(0, 4),
      textAlign: 'center',
    },
    rounded: {
      borderRadius: shape.borderRadius * 3,
    },
    pxCaptcha: {
      minHeight: '5rem',
    },
  }),
);
