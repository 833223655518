import { AppContextType } from '@/context';

export const checkValidityOfCorridor = ({
  sendCountry,
  receiveCountry,
  showForCorridors,
}: Pick<AppContextType, 'sendCountry' | 'receiveCountry'> & {
  showForCorridors: string[];
}): boolean =>
  receiveCountry?.countryCode
    ? showForCorridors.includes(
        `${sendCountry?.countryCode?.toLowerCase?.()}-${receiveCountry?.countryCode?.toLowerCase?.()}`,
      )
    : showForCorridors
        .map(corridor => corridor.split('-')[0])
        .includes(sendCountry?.countryCode?.toLowerCase?.() ?? '');
