import { Box, Typography } from '@mui/material';
import React, { useContext, useRef } from 'react';

import { PAYOUT_METHOD_DROPDOWN_NAME } from '@/components/calculator/core/core.constants';
import {
  setDataLayerForDropdownOpen,
  setDataLayerForDropdownSelection,
} from '@/components/calculator/core/core.utils';

import { PayoutMethodSelectOption } from '../../components/payout-method-select-option/payout-method-select-option.component';
import { Select } from '../../components/select';
import { CalculatorContext } from '../../context';
import { usePayoutMethods } from './payout-method.hooks';
import {
  PayoutMethodProps,
  PayoutMethodSelectOptionProps,
} from './payout-method.types';
import {
  filterPayoutMethodAlerts,
  getPayoutMethodSelectOptions,
} from './payout-method.utils';

export const PayoutMethod: React.FC<PayoutMethodProps> = ({
  isFetching,
  isLite,
  payoutMethodsLabel,
  contentfulPayoutMethodsList,
  phReceiveBankTransferMessage,
}) => {
  const containerRef = useRef(null);
  const { state, dispatch } = useContext(CalculatorContext);

  const { payoutMethodsResult, payoutMethodAlertsResult } = usePayoutMethods();

  const filteredPayoutMethodAlerts =
    state.payoutMethods &&
    state.payoutMethodAlerts &&
    filterPayoutMethodAlerts(
      state.payoutMethodAlerts,
      state.payoutMethods,
      state.sendCountry,
      state.receiveCountry,
    );

  const onPayoutMethodChange = (newPayoutMethodId: string) => {
    if (state.selectedPayoutMethodId !== newPayoutMethodId) {
      dispatch({
        type: 'SET_SELECTED_PAYOUT_METHOD_ID',
        payload: { selectedPayoutMethodId: newPayoutMethodId },
      });

      dispatch({
        type: 'CREATE_CALCULATION_PENDING',
      });

      setDataLayerForDropdownSelection(
        newPayoutMethodId,
        PAYOUT_METHOD_DROPDOWN_NAME,
        isLite,
      );
    }
  };

  const payoutMethodPlaceholder = '-';

  const payoutMethodOptions = getPayoutMethodSelectOptions(
    state.payoutMethods,
    contentfulPayoutMethodsList,
  );

  return payoutMethodOptions?.length && state.selectedPayoutMethodId ? (
    <div ref={containerRef}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        data-testid="payout-method-container"
        mb={1}
      >
        <Box flex={1}>
          <Typography variant="body1" component="span" id="payout-method-label">
            {payoutMethodsLabel}
          </Typography>
        </Box>

        <Box flex={1} display="flex" justifyContent="end">
          <Select<PayoutMethodSelectOptionProps>
            containerRef={containerRef}
            aria-labelledby="payout-method-label"
            selectFieldContainerId="payout-method-select"
            selectOptionName="payout-method"
            title={payoutMethodsLabel || undefined}
            options={payoutMethodOptions}
            disabled={
              isFetching ||
              state.isLoading ||
              payoutMethodsResult.loading ||
              payoutMethodAlertsResult.loading
            }
            placeholder={payoutMethodPlaceholder}
            value={state.selectedPayoutMethodId}
            onSelect={onPayoutMethodChange}
            optionRenderer={option => {
              return (
                <PayoutMethodSelectOption
                  label={option.label}
                  value={option.value}
                  description={option.description}
                  transferTimeTooltip={option.transferTimeTooltip}
                  payoutMethodAlerts={filteredPayoutMethodAlerts}
                  sendCountry={state.receiveCountry}
                  bankTransferWarning={phReceiveBankTransferMessage}
                />
              );
            }}
            onInputClick={() =>
              setDataLayerForDropdownOpen(PAYOUT_METHOD_DROPDOWN_NAME, isLite)
            }
          />
        </Box>
      </Box>
    </div>
  ) : null;
};
