import { contentfulSchema } from '@wr/web-shared';
import { Container } from '@wr/web-ui';
import React, { useEffect } from 'react';

import useStyles from './one-trust-cookies-policy.styles';

export const OneTrustCookiesPolicy: React.FC<
  Pick<contentfulSchema.ExternalScript, 'id'>
> = ({ id }) => {
  const classes = useStyles();

  useEffect(() => {
    if (id && window && window.OneTrust) {
      window.OneTrust.initializeCookiePolicyHtml();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    return null;
  }

  return (
    <section className={classes.container}>
      <Container>
        <div id={id} />
      </Container>
    </section>
  );
};
