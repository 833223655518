import { ApolloClient, bffSchema } from '@wr/web-shared';

import { logger } from '@/utils';

export const getSendCountries = async ({
  bffClient,
}: {
  bffClient: ApolloClient;
}): Promise<bffSchema.GetSendCountriesQuery> => {
  try {
    const { data } = await bffClient.query<bffSchema.GetSendCountriesQuery>({
      query: bffSchema.GetSendCountries,
    });

    return data;
  } catch (error) {
    logger.error(error, `GetSendCountries query request failed`);

    const { data } = await bffClient.query<bffSchema.GetSendCountriesQuery>({
      query: bffSchema.GetSendCountries,
      fetchPolicy: 'cache-only',
    });

    return data;
  }
};
