import React from 'react';

import { Container } from '../container';
import { useStyles } from './not-found.styles';
import { NotFoundProps } from './not-found.types';

export const NotFound = <Button extends { url?: string | null }, RichText>({
  message,
  buttons,
  containerProps,
  LinkComponent,
  RichTextComponent,
}: NotFoundProps<Button, RichText>): React.ReactElement => {
  const classes = useStyles();

  return (
    <section className={classes.container} {...containerProps}>
      <Container>
        <div className={classes.content}>
          <RichTextComponent {...message} />
          <div className={classes.buttons}>
            {buttons.map(button => (
              <LinkComponent key={button.url} {...button} />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};
