import CloseIcon from '@mui/icons-material/Close';
import { Drawer } from '@mui/material';
import React from 'react';

import useStyles from './dropdown-mobile.styles';
import { DropdownMobileProps } from './dropdown-mobile.types';

export const DropdownMobile: React.FC<DropdownMobileProps> = ({
  id,
  isOpen,
  children,
  onClose,
  showCloseIcon = true,
}) => {
  const classes = useStyles();

  return (
    <Drawer
      onClose={onClose}
      PaperProps={{ id }}
      anchor="bottom"
      open={isOpen}
      classes={{
        paper: classes.drawer,
        paperAnchorBottom: classes.paperAnchorBottom,
      }}
    >
      {showCloseIcon && (
        <CloseIcon className={classes.closeButton} onClick={onClose} />
      )}
      {children}
    </Drawer>
  );
};
