import { bffSchema } from '@wr/web-shared';
import { createContext } from 'react';

import { CalculatorState } from '../types';
import { CalculatorContextType } from './types';

export const calculatorInitialState: CalculatorState = {
  sendCountry: null,
  receiveCountry: null,
  receiveCountries: [],
  sendFromValue: '100.00',
  sendToValue: '100.00',
  feeValue: undefined,
  totalToPay: undefined,
  lastAction: null,
  calculationType: bffSchema.CalculationType.Send,
  isLoading: false,
  isPerimeterXSolved: false,
  errors: [],
  payoutMethods: [],
  payoutMethodAlerts: [],
  correspondentId: undefined,
  mobileNumber: undefined,
  isPhoneNumberValid: undefined,
  topUpUpDenominationId: undefined,
  selectedPayoutMethodId: null,
};

export const CalculatorContext = createContext<CalculatorContextType>({
  state: calculatorInitialState,
  dispatch: () => {},
});
CalculatorContext.displayName = 'CalculatorContext';
