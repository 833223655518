import { CALCULATOR_CONSTANTS } from '@wr/web-ui';

export const getInputValue = (value: string): string | null => {
  const isValueEndsWithSingleDot =
    value.split('.').length - 1 === 1 && value.endsWith('.');

  if (CALCULATOR_CONSTANTS.TWO_DIGIT_REGEX.test(value)) {
    return value;
  }

  if (isValueEndsWithSingleDot) {
    const replacedValue = value.replace(/^0+/, '');
    if (replacedValue.length === 1 && replacedValue === '.') {
      return '0.';
    }
    return replacedValue;
  }

  return null;
};
