import { BLOCKS } from '@contentful/rich-text-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { contentfulSchema } from '@wr/web-shared';
import { FullWidthBanner as UIFullWidthBanner, ImageProps } from '@wr/web-ui';
import React from 'react';

import { useImageByBreakpoint } from '@/hooks';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Button, ButtonProps } from '../button';
import {
  RichTextContentRenderer,
  RichTextProps,
} from '../rich-text-content-renderer';
import { useStyles } from './full-width-banner.styles';

export const FullWidthBanner: React.FC<contentfulSchema.FullWidthBannerFragment> = ({
  sys,
  __typename,
  name,
  backgroundImage,
  backgroundImageMobile,
  backgroundColor,
  secondBackgroundColor,
  image,
  text,
  link,
  textColor,
  textAlign,
  borderColor,
  secondBorderColor,
  contentWidth,
}) => {
  const classes = useStyles();

  const renderBackgroundImage = useImageByBreakpoint({
    mobile: backgroundImageMobile as ImageProps,
    desktop: backgroundImage as ImageProps,
  });

  return (
    <UIFullWidthBanner<ButtonProps, RichTextProps>
      name={name || undefined}
      backgroundImage={
        renderBackgroundImage
          ? {
              ...renderBackgroundImage,
              fill: true,
              style: {
                objectFit: 'cover',
                objectPosition: 'center',
              },
            }
          : null
      }
      backgroundColor={backgroundColor || null}
      secondBackgroundColor={secondBackgroundColor || null}
      image={image || null}
      text={
        text?.json
          ? {
              ...text,
              options: {
                [BLOCKS.HEADING_1]: {
                  variant: 'h3',
                  className: classes.heading,
                },
                [BLOCKS.HEADING_2]: {
                  variant: 'h3',
                  className: classes.heading,
                },
                [BLOCKS.PARAGRAPH]: {
                  variant: 'body2',
                },
              },
            }
          : null
      }
      link={
        link
          ? {
              ...link,
              className: !link.variant ? classes.link : undefined,
              label: '',
              children: !link.variant ? (
                <>
                  {link.label}
                  <ArrowForwardIcon />
                </>
              ) : (
                link.label
              ),
            }
          : null
      }
      textColor={textColor || null}
      textAlign={(textAlign as React.CSSProperties['textAlign']) || null}
      borderColor={borderColor || null}
      secondBorderColor={secondBorderColor || null}
      contentWidth={contentWidth || null}
      LinkComponent={Button}
      RichTextComponent={RichTextContentRenderer}
      containerProps={buildContentfulSidekickAttributes(
        sys.id,
        __typename,
        name,
      )}
    />
  );
};
