import dynamic from 'next/dynamic';
import React from 'react';

import { LineChartProps } from './line-chart.types';

const DynamicLineChart = dynamic(() => import('./line-chart.component'), {
  ssr: false,
});

export const LineChart: React.FC<LineChartProps> = props => {
  return <DynamicLineChart {...props} />;
};
