import { getSendCountryCodeFromLocale } from '@wr/web-ui';

import { SelectOption } from '../select';
import { RegionDropdownProps } from './region-dropdown.types';

export function getRegionDropdownOptions(
  locale: RegionDropdownProps['locale'],
  locales: RegionDropdownProps['locales'],
): (SelectOption & { secondaryLabel?: string })[] {
  const occurencyBySendCountry = locales?.reduce<Record<string, number>>(
    (acc, region) => {
      const sendCountry = getSendCountryCodeFromLocale(region);
      acc[sendCountry] = (acc[sendCountry] ?? 0) + 1;
      return acc;
    },
    {},
  );

  const countryNames = new Intl.DisplayNames([locale], { type: 'region' });

  return locales
    ?.filter(item => {
      // Remove any locales where the locale is the same as the region e.g. pt-pt
      const languageAndRegionArray = item.split('-');
      if (languageAndRegionArray?.length === 1) return true;

      const language = languageAndRegionArray[0];
      const region = languageAndRegionArray[1];

      return language !== region;
    })
    ?.map(region => ({
      value: region,
      label:
        countryNames.of(
          (getSendCountryCodeFromLocale(region) || region).toUpperCase(),
        ) || '',
      secondaryLabel:
        occurencyBySendCountry[getSendCountryCodeFromLocale(region)] > 1 &&
        getSendCountryCodeFromLocale(region)
          ? region.split('-')[0]
          : undefined,
    }))
    .sort((a, b) => {
      if (!a?.label) {
        return 1;
      }
      if (!b?.label) {
        return -1;
      }
      if (a?.label === b?.label) {
        return 0;
      }
      return a.label.localeCompare(b.label);
    });
}

export const getLanguageNames = (locale: string) =>
  new Intl.DisplayNames([locale], {
    type: 'language',
  });
