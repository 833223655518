import { useLazyQuery } from '@apollo/client';
import {
  ANALYTICS_PAGE_TYPE,
  bffSchema,
  contentfulSchema,
} from '@wr/web-shared';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import { getInitialSelectedPayoutMethod } from '@/components/calculator/src/composition/payout-method/payout-method.utils';
import { CalculatorContext } from '@/components/calculator/src/context';
import { CalculatorState } from '@/components/calculator/src/types';
import { AppContext } from '@/context';
import { contentfulClient } from '@/utils';

export const usePayoutMethods = () => {
  const router = useRouter();
  const { analyticsPageType } = useContext(AppContext);
  const { state, dispatch } = useContext(CalculatorContext);

  const [
    getPayoutMethodAlerts,
    payoutMethodAlertsResult,
  ] = useLazyQuery<contentfulSchema.PayoutMethodAlertsQuery>(
    contentfulSchema.PayoutMethodAlerts,
    {
      client: contentfulClient,
      onCompleted: result => {
        dispatch({
          type: 'GET_PAYOUT_METHODS_ALERTS_SUCCESS',
          payload: {
            payoutMethodAlerts:
              (result?.payoutMethodAlertCollection
                ?.items as CalculatorState['payoutMethodAlerts']) || [],
          },
        });
      },
    },
  );

  const [
    getPayoutMethods,
    payoutMethodsResult,
  ] = useLazyQuery<bffSchema.PayoutMethodsQuery>(bffSchema.PayoutMethods, {
    variables: {
      sendCountry: state.sendCountry?.countryCode,
      sendCurrency: state.sendCountry?.currency,
      receiveCountry: state.receiveCountry?.countryCode,
      receiveCurrency: state.receiveCountry?.currency,
    },
    onCompleted: result => {
      dispatch({
        type: 'GET_PAYOUT_METHODS_SUCCESS',
        payload: {
          payoutMethods: result.payOutMethods,
        },
      });

      const newPayoutMethodId = getInitialSelectedPayoutMethod(
        state.selectedPayoutMethodId,
        result.payOutMethods,
      );
      if (newPayoutMethodId !== state.selectedPayoutMethodId) {
        dispatch({
          type: 'SET_SELECTED_PAYOUT_METHOD_ID',
          payload: { selectedPayoutMethodId: newPayoutMethodId },
        });
      }
      dispatch({
        type: 'CREATE_CALCULATION_PENDING',
      });

      getPayoutMethodAlerts({
        variables: {
          locale: router.locale,
          sendCountryCode: state.sendCountry?.countryCode?.toLowerCase?.(),
          receiveCountryCode: state.receiveCountry?.countryCode?.toLowerCase?.(),
        },
      });
    },
    onError: () => {
      dispatch({ type: 'GET_PAYOUT_METHODS_ERROR' });
    },
  });

  useEffect(() => {
    if (
      (state.lastAction === 'GET_CORRIDORS_BY_SEND_COUNTRY_SUCCESS' ||
        (state.lastAction === 'SET_RECEIVE_COUNTRY' &&
          analyticsPageType !== ANALYTICS_PAGE_TYPE.Country)) &&
      state.sendCountry?.countryCode &&
      state.sendCountry?.currency &&
      state.receiveCountry?.countryCode &&
      state.receiveCountry?.currency
    ) {
      getPayoutMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction]);

  return { payoutMethodsResult, payoutMethodAlertsResult };
};
