import {
  getCountryFromCookie,
  getCountryToCookie,
  getOptimizelyEndUserId,
} from '@wr/web-shared';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AppContext } from '@/context';
import { getStickyBanners } from '@/services';
import { PREVIEW_BANNER_DESCRIPTION } from '@/test-helpers/data-interfaces-mocks';

import { StickyBannerContentProps } from './sticky-banner.types';

export const useStickyBanners = () => {
  const router = useRouter();

  const {
    analyticsPageType,
    calculatorSendCountry,
    receiveCountry,
  } = useContext(AppContext);

  const [banners, setBanners] = useState<StickyBannerContentProps[]>([]);

  const fetchStickyBanners = useCallback(
    async (sendCountryCode?: string, receiveCountryCode?: string) => {
      if (sendCountryCode && receiveCountryCode) {
        const bannerList = await getStickyBanners({
          locale: router?.locale ?? '',
          preview: router.isPreview,
          resolvedUrl: router.asPath,
          analyticsPageType: analyticsPageType,
          query: router.query,
          sendCountryCode:
            (router?.query?.selectfrom as string) ?? sendCountryCode,
          receiveCountryCode:
            (router?.query?.selectto as string) ?? receiveCountryCode,
          cookies: {
            optimizelyEndUserId: getOptimizelyEndUserId(),
            selectFrom: getCountryFromCookie(),
            selectTo: getCountryToCookie(),
          },
        });
        setBanners(bannerList);
      }
    },
    [
      analyticsPageType,
      router.asPath,
      router.isPreview,
      router?.locale,
      router.query,
    ],
  );

  const stickyBanners = useMemo(() => {
    if (!router.isPreview) {
      return banners;
    } else {
      const previewBanner = {
        __typename: 'StickyBanner',
        sys: { id: 'preview' },
        name: 'preview_banner',
        backgroundColor: 'red',
        textColor: 'black',
        description: PREVIEW_BANNER_DESCRIPTION,
      } as StickyBannerContentProps;
      return [previewBanner, ...banners];
    }
  }, [banners, router.isPreview]);

  /**
   * Fetch Banners on Mount Only
   */
  useEffect(() => {
    if (calculatorSendCountry?.countryCode && receiveCountry?.countryCode) {
      fetchStickyBanners(
        calculatorSendCountry.countryCode,
        receiveCountry.countryCode,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    stickyBanners,
    fetchStickyBanners,
  };
};
