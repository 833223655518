import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Container as UIContainer } from '../container';
import { Image } from '../image';
import { useStyles } from './comparison-table.styles';
import {
  ComparisonTableProps,
  isComparisonTableCell,
} from './comparison-table.types';

export function ComparisonTable<RichText>({
  name,
  content,
  providersLabel,
  caption,
  rows,
  columns,
  footer,
  RichTextComponent,
  containerProps,
}: ComparisonTableProps<RichText>) {
  const classes = useStyles();

  return (
    <section id={name} {...containerProps} className={classes.section}>
      <UIContainer>
        {content && <RichTextComponent {...content} />}
        <div className={classes.tableWrapper}>
          <Table
            aria-label="caption table"
            classes={{
              root: classes.table,
            }}
          >
            <Typography variant="subtitle1" component="caption">
              <b>{caption}</b>
            </Typography>
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root: classes.tableCell,
                  }}
                >
                  <Typography component="p" variant="h6">
                    {providersLabel}
                  </Typography>
                </TableCell>
                {columns.map(column => {
                  return (
                    <TableCell
                      classes={{
                        root: classes.tableCell,
                      }}
                      key={column.name}
                      align="right"
                    >
                      <Typography component="p" variant="h6">
                        {column.name}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                if (row) {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        classes={{
                          root: classes.tableCell,
                        }}
                        component="th"
                        scope="row"
                      >
                        {row?.image && <Image {...row.image} />}
                      </TableCell>
                      {columns.map(({ payoutMethodId }) => {
                        const cell = row?.[payoutMethodId];

                        if (isComparisonTableCell(cell)) {
                          return (
                            <TableCell
                              classes={{
                                root: classes.tableCell,
                              }}
                              key={row.name + payoutMethodId}
                              align="right"
                            >
                              <Typography variant="body2">
                                {cell.amount}
                              </Typography>
                              <Typography variant="body1">
                                {cell.fee}
                              </Typography>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            classes={{
                              root: classes.tableCell,
                            }}
                            key={row.name + payoutMethodId + 'icon'}
                            align="right"
                          >
                            <ClearRoundedIcon
                              color="error"
                              className={classes.clearIcon}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  classes={{
                    root: classes.footerTableCell,
                  }}
                >
                  <Typography variant="body1" color="textPrimary">
                    {footer}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </UIContainer>
    </section>
  );
}
