import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { HeaderProps } from './header.types';
import { isDarkTheme } from './header.utils';

export const useStyles = makeStyles<
  Theme,
  Pick<HeaderProps<unknown, unknown>, 'headerVariant' | 'submenuItems'>
>(
  theme => ({
    headerWrapper: ({ headerVariant, submenuItems }) => ({
      background: isDarkTheme(headerVariant)
        ? `${theme.customComponents.header.headerWrapper.dark.backgroundColor} !important`
        : `${theme.customComponents.header.headerWrapper.backgroundColor} !important`,
      overflow: 'hidden',
      justifyContent: 'center',
      boxShadow: 'none',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(submenuItems?.length ? 0 : 2),
      top: 'initial',
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),

      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(submenuItems?.length ? 0 : 3),
      },
    }),
    headerContainer: {
      maxWidth: theme.spacing(175),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing(175),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: `${theme.spacing(175)} !important`,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    leftSide: {
      lineHeight: 0,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    divider: {
      backgroundColor: theme.customComponents.header.divider.backgroundColor,
      alignSelf: 'stretch',
      height: 'auto',
      width: 1,
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      flex: 1,
    },
    navUl: {
      padding: 0,
      margin: 0,
      display: 'flex',
      marginLeft: 'auto',
      gap: 20,
    },
    navLi: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      width: 'auto',
      padding: theme.spacing(0.5),
    },
    navLink: ({ headerVariant }) => ({
      'color': isDarkTheme(headerVariant)
        ? theme.customComponents.header?.navLink?.dark?.color
        : theme.customComponents.header?.navLink?.color,
      'textDecoration': theme.customComponents.header?.navLink?.textDecoration,
      'fontSize': theme.customComponents.header?.navLink?.fontSize,

      '&.MuiButton-contained': {
        color: theme.customComponents.header?.navLink?.dark?.color,
      },

      '&:hover': {
        color: theme.customComponents.header?.navLink?.hover.color,
        textDecoration:
          theme.customComponents.header?.navLink?.hover.textDecoration,
        backgroundColor:
          theme.customComponents.header?.navLink?.hover.backgroundColor,
      },
    }),
    hamburgerMenuLabel: {
      display: 'none',

      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        display: 'initial',
      },
    },
    flag: {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: theme.spacing(3),
    },
    regionDivider: {
      height: 'auto',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      backgroundColor: theme.customComponents.search?.option?.borderTopColor,
      color: theme.customComponents.search?.option?.borderTopColor,
    },
    regionLangText: {
      color: theme.customComponents.header?.regionLangText?.color,
      fontSize: '0.875rem',
      padding: theme.spacing(2, 2, 1, 2),
    },
    mobileFlag: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'UIHeaderStyles' },
);
