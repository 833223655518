import { useEffect } from 'react';

import { PERIMETERX_SUCCESS_EVENT_NAME } from './perimeter-x.constants';
import { UsePerimeterXProps } from './perimeter-x.types';

export const usePerimeterX = ({ onSuccess }: UsePerimeterXProps) => {
  useEffect(() => {
    const onSuccessfulPerimeterX = (): void => {
      // TODO: Figure out the intended use-case
      // `isPerimeterXSolved` doesn't do anything at the moment
      onSuccess?.();
    };

    window.addEventListener(
      PERIMETERX_SUCCESS_EVENT_NAME,
      onSuccessfulPerimeterX,
    );

    return () => {
      window.removeEventListener(
        PERIMETERX_SUCCESS_EVENT_NAME,
        onSuccessfulPerimeterX,
      );
    };
  }, [onSuccess]);
};
