import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles<Theme>(
  ({ breakpoints, spacing, palette }) => ({
    heading: {
      [breakpoints.up('md')]: {
        textAlign: 'center',
      },

      [breakpoints.up('md')]: {
        textAlign: 'left',
        fontSize: '3.25rem', // 52px
        lineHeight: 1.07692307692, // 56px
      },
      [breakpoints.up('lg')]: {
        textAlign: 'left',
        fontSize: '3.5rem', // 56px
        lineHeight: 1.07142857143, // 60px
      },
    },
    textCenter: {
      [breakpoints.up('sm')]: {
        textAlign: 'center',
      },

      [breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
    subheading: {
      [breakpoints.up('lg')]: {
        fontSize: '1.75rem', // 28px
        lineHeight: 1.14285714286, // 32px
      },
    },
    heading6: {
      'fontWeight': 700,

      '& a': {
        color: palette.primary.contrastText,
      },
    },
    fullWidthHeading: {
      marginBottom: spacing(1),
    },
    fullWidthSubHeading: {
      marginBottom: spacing(1),

      [breakpoints.up('lg')]: {
        marginBottom: spacing(2),
      },
    },
  }),
);
