import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  backgroundWrapper: {
    position: 'relative',
  },
  main: {
    position: 'relative',
    overflow: 'hidden',
  },
  iconImage: {
    width: '3rem',
    height: 'auto',
    display: 'block',
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      width: '3.5rem',
    },
  },
  container: {
    'textAlign': 'center',
    'paddingTop': theme.spacing(13),
    'paddingBottom': theme.spacing(5.625),

    '& h1': {
      fontSize: '1.375rem',
      marginTop: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        fontSize: '2.25rem',
      },
    },

    '& h3': {
      fontSize: '1.125rem',
      fontWeight: 500,
      marginTop: theme.spacing(5.5),

      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2.375),
      },
    },

    [theme.breakpoints.up('md')]: {
      height: '30rem',
      paddingTop: theme.spacing(22),
      paddingBottom: 0,
    },
  },
  button: {
    marginTop: theme.spacing(3.5),

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4.25),
    },
  },
  hintText: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem',
    fontWeight: 700,
    marginTop: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      marginTop: theme.spacing(9.5),
    },
  },
}));
