import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  flag: {
    marginRight: theme.spacing(2.5),
    height: '1.5rem',
    width: '1.5rem',
  },
}));

export default useStyles;
