import { ImageProps } from '../image';

export const isComparisonTableCell = (
  cell: unknown,
): cell is ComparisonTableCell =>
  !!cell &&
  !!(cell as ComparisonTableCell)?.amount &&
  !!(cell as ComparisonTableCell)?.fee;

export interface ComparisonTableCell {
  amount?: string | null;
  fee?: string | null;
}

export interface ComparisonTableProps<RichText> {
  name?: string;
  content: RichText | null;
  providersLabel: string | null;
  columns: {
    payoutMethodId: string;
    name: string;
  }[];
  rows: (
    | {
        name?: string;
        image?: ImageProps;
        [key: string]:
          | string
          | ImageProps
          | ComparisonTableCell
          | undefined
          | null;
      }
    | undefined
  )[];
  RichTextComponent: React.FC<RichText>;
  caption: string | null;
  footer: string | null;
  containerProps?: Record<string, unknown>;
}

export interface ComparisonTableStyles {
  table: {
    borderColor: string;
  };
  tableCell: {
    borderColor: string;
  };
  tableCaption: {
    color: string;
    backgroundColor: string;
  };
}
