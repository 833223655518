import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Button, ClickAwayListener, Popper } from '@mui/material';
import * as React from 'react';

import { QrCode } from '../qr-code';
import { useStyles } from './app-download-dropdown.styles';
import { AppDownloadDropdownProps } from './app-download-dropdown.types';

const QR_CODE_ID = 'app-download-qr-code';

export const AppDownloadDropdown: React.FC<AppDownloadDropdownProps> = ({
  title,
  url,
  description,
  headerVariant,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = !!anchorEl;
  const id = isOpen ? 'app-download-dropdown-open' : undefined;
  const classes = useStyles({ headerVariant });
  const { qrCodeContainer, qrCodeDescription } = classes;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const iconTransform = {
    transform: `rotate(${isOpen ? 180 : 0}deg)`,
  };

  return (
    <div data-testid="app-download-dropdown">
      <Button
        data-testid="app-download-btn"
        className={classes.appDownloadBtn}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        endIcon={<ArrowDropDown style={iconTransform}></ArrowDropDown>}
      >
        {title}
      </Button>
      <Popper id={id} open={isOpen} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <QrCode
              url={url}
              description={description}
              classes={{
                qrCodeContainer,
                qrCodeDescription,
              }}
              elementId={QR_CODE_ID}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};
