import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  review: {
    padding: theme.spacing(3),
    height: 'auto',
    width: '15.5rem',
    border: `1px solid ${theme.customComponents.review.review.borderColor}`,

    [theme.breakpoints.up('lg')]: {
      width: '16.875rem',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20.125rem',
    },
  },
  rounded: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  avatarIcon: {
    width: '1rem',
    height: '1rem',
  },
  reviewer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  reviewerData: {
    marginLeft: theme.spacing(1),
  },
  reviewerName: {
    fontWeight: 700,
  },
  comment: {
    marginTop: theme.spacing(2),
    color: theme.customComponents.review.comment.color,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  countryFlag: {
    width: '1.25rem',
    display: 'inline-block',
    marginRight: theme.spacing(1),
    height: 'auto',
  },
  ratingIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
}));
