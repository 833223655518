import { GTMDataLayer, PageProps } from '@/types';

import { NO_TRANSFERS } from './gtm-page-load.constants';

export const getLastTransferDate = (
  accountDetails: PageProps['accountDetails'],
): string => {
  const { transactionsCount, lastTransactionDate } =
    accountDetails?.statistics || {};

  // Previously we were getting lastTransactionDate from bff in unix format (1589977830),
  // but then it suddenly changed (without notifying us) to ISO (2022-10-17T16:17:18.000Z) causing app crashing.
  // To avoid such cases we handle both formats
  return transactionsCount && lastTransactionDate
    ? new Date(
        !isNaN(Number(lastTransactionDate))
          ? Number(lastTransactionDate) * 1000
          : lastTransactionDate,
      )
        .toISOString()
        .substring(0, 10)
    : NO_TRANSFERS;
};

export const getAnalyticsPageName = (
  analyticsPageName?: PageProps['analyticsPageName'],
  destinationCountry?: GTMDataLayer['destinationCountry'],
): string => {
  if (destinationCountry && !analyticsPageName?.includes(destinationCountry)) {
    return analyticsPageName + destinationCountry;
  }
  return analyticsPageName || '';
};
