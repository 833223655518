import {
  Breadcrumbs as MuiBreadcrumbs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC } from 'react';

import { useIntlMessages } from '@/hooks';
import { interpolate } from '@/utils';

import { Anchor } from '../anchor';
import { BreadcrumbsProps } from './breadcrumbs.types';

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  color,
  linkColor,
  className,
}) => {
  const isMDUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const intlMessages = useIntlMessages();

  if (!breadcrumbs || !breadcrumbs.length) {
    return null;
  }

  return (
    <MuiBreadcrumbs
      data-testid={'breadcrumbs-container'}
      maxItems={isMDUp ? undefined : 2}
      className={className}
      color={color}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        const breadcrumbName = interpolate(breadcrumb?.name, intlMessages);
        // undefined slug means it's last breadcrumb and should render text only
        // null slug means it's homepage
        return index < breadcrumbs.length - 1 ? (
          <Anchor
            color={linkColor as string}
            data-testid={`${breadcrumbName}-breadcrumb`}
            variant="body2"
            underline="none"
            key={breadcrumb?.name}
            url={'/' + (breadcrumb?.slug ?? '')}
          >
            {breadcrumbName}
          </Anchor>
        ) : (
          <Typography
            key={breadcrumb?.name}
            variant="body2"
            color={color}
            data-testid={`${breadcrumbName}-breadcrumb`}
          >
            {breadcrumbName}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};
