import { ImageProps } from '@wr/web-ui';

import { RichTextProps } from '@/components/rich-text-content-renderer';

export const testSVGImage: ImageProps = {
  title: 'svg test image title',
  alt: 'svg test image alt',
  width: 70,
  height: 70,
  url: '/icons/tick.svg',
};

export const mockCountriesByCode = {
  DE: { name: 'Germany', slug: 'germany' },
  ES: { name: 'Spain', slug: 'spain' },
  FR: { name: 'France', slug: 'france' },
  GB: { name: 'United Kingdom', slug: 'united-kingdom' },
  PH: { name: 'Philippines', slug: 'philippines' },
  PL: { name: 'Poland', slug: 'poland' },
  US: { name: 'United States', slug: 'united-states' },
};

export const mockContentfulCountries = [
  {
    name: 'Poland',
    slug: 'poland',
    code: 'pl',
    prepositionFrom: 'from Poland',
  },
  {
    name: 'Singapore',
    slug: 'singapore',
    code: 'sg',
  },
  {
    name: 'Kenya',
    slug: 'kenya',
    code: 'ke',
    prepositionFrom: 'from Kenya',
    prepositionTo: 'to Kenya',
  },
  {
    name: 'United Kingdom',
    slug: 'united-kingdom',
    code: 'gb',
    article: 'the',
    prepositionFrom: 'from the United Kingdom',
    prepositionTo: 'to the United Kingdom',
  },
  {
    name: 'Australia',
    slug: 'australia',
    code: 'au',
    prepositionFrom: 'from Australia',
    prepositionTo: 'to Australia',
  },
  {
    name: 'United States',
    slug: 'united-states',
    code: 'us',
    article: 'the',
    prepositionFrom: 'from the United States',
    prepositionTo: 'to the United States',
    prepositionIn: 'in the United States',
  },
  {
    name: 'Malaysia',
    slug: 'malaysia',
    code: 'my',
  },
  {
    name: 'Madagascar',
    slug: 'madagascar',
    code: 'mg',
  },
  {
    name: 'New Zealand',
    slug: 'new-zealand',
    code: 'nz',
  },
  {
    name: 'Montserrat',
    slug: 'montserrat',
    code: 'ms',
    prepositionFrom: 'Montserrat',
    prepositionTo: 'Montserrat',
  },
  {
    name: 'Philippines',
    slug: 'philippines',
    code: 'ph',
    article: 'the',
    prepositionFrom: 'from the Philippines',
    prepositionTo: 'to the Philippines',
    prepositionIn: 'in the Philippines',
  },
  {
    name: 'Ghana',
    slug: 'ghana',
    code: 'gh',
  },
  {
    name: 'Ivory Coast',
    slug: 'ivory-coast',
    code: 'ci',
  },
  {
    name: 'Canada',
    slug: 'canada',
    code: 'ca',
    prepositionFrom: 'from Canada',
    prepositionTo: 'to Canada',
  },
];

export const mockRichTextContent = {
  json: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [{ type: 'bold' }],
            value: 'This bold text in a paragraph',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
} as RichTextProps;

export const PREVIEW_BANNER_DESCRIPTION = {
  __typename: 'StickyBannerDescription',
  json: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [{ type: 'bold' }],
            value: 'This is page is a preview. ',
            nodeType: 'text',
          },
          {
            data: {
              uri: process.env.WEB_CMS_URI + '/api/contentful/exit-preview',
            },
            content: [
              {
                data: {},
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                value: 'Click here',
                nodeType: 'text',
              },
            ],
            nodeType: 'hyperlink',
          },
          {
            data: {},
            marks: [{ type: 'bold' }],
            value: ' to exit preview mode.',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
} as RichTextProps;
