import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

const useStyles = (props: { totalCostPerChildLabel: string }) =>
  makeStyles<Theme>(({ spacing, breakpoints, palette, shape }) => {
    const { totalCostPerChildLabel } = props;
    const innerCircle = totalCostPerChildLabel
      ? {
          '&:after': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: '20',
            transform: 'translateX(-50%) translateY(-50%)',
            width: 'var(--circleRatio)',
            height: 'var(--circleRatio)',
            content: '""',
            backgroundColor: colors.purpleNormal,
            borderRadius: '50%',
          },
        }
      : {};
    return {
      section: {
        overflow: 'hidden',
      },
      tooltipWrapper: {
        padding: 0,
        borderRadius: shape.borderRadius * 4,
        opacity: 1,
      },
      arrowWrapper: {
        display: 'none',
      },
      countryFlag: {
        'fontSize': '3.5rem',
        'width': '3.5rem',
        'height': '3.5rem',
        'position': 'relative',
        'marginRight': spacing(1),
        '& img': {
          position: 'absolute',
          top: '-200%',
          left: '-200%',
          width: '500%',
          maxWidth: '9999px',
          display: 'block',
          pointerEvents: 'none',
        },
      },
      flexCenterStart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      flexTextCenter: {
        display: 'flex',
        textAlign: 'center',
      },
      flexStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
      flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      countryWrapper: {
        padding: spacing(1.5),
        display: 'flex',
        flexDirection: 'column',
      },
      countryContainer: {
        display: 'flex',
        paddingBottom: spacing(1),
        justifyContent: 'space-between',
      },
      tooltip: {
        backgroundColor: palette.common.white,
        border: `1px solid ${colors.snow} 1rem`,
        boxShadow: `0 -9px 24px 0 ${alpha(colors.resolutionBlue, 0.1)}`,
        fontSize: '0.875rem',
        fontWeight: 300,
        lineHeight: 1.5,
        color: palette.text.primary,
        width: '300px',
        maxWidth: '80vw',
        borderRadius: '1rem',
        zIndex: 40,
        [breakpoints.down('sm')]: {
          transform: 'none',
          maxWidth: 'calc(80vw - 4rem)',
          fontSize: '0.5rem',
        },
      },
      tooltipHeader: {
        backgroundColor: colors.creamNormal,
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
      },
      tooltipFormatting: {
        padding: spacing(1.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      tooltipHeaderCountryFlag: {
        'marginRight': spacing(1),
        'width': '1.8rem',
        'height': '1.8rem',
        '& img': {
          top: '-470%',
        },
      },
      tooltipValue: {
        fontSize: '.8rem',
        [breakpoints.down('sm')]: {
          fontSize: '.7rem',
        },
      },
      interactiveMapContainer: {
        paddingTop: spacing(4),
        [breakpoints.up('md')]: {
          paddingTop: spacing(8),
        },
      },
      mapImageWrapper: {
        marginTop: spacing(4),
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
        [breakpoints.up('md')]: {
          marginTop: spacing(6),
        },
      },
      mapImageContainer: {
        position: 'relative',
      },
      sectionContentHeader: {
        color: colors.purpleDark,
        fontSize: '1.125rem',
        lineHeight: 2.11,
        fontWeight: 500,
        [breakpoints.up('md')]: {
          lineHeight: 1.07,
          fontSize: '1.75rem',
        },
      },
      pt2: {
        paddingTop: spacing(2),
      },
      headingAndMessage: {
        textAlign: 'center',
        maxWidth: '900px',
        margin: 'auto',
      },
      totalCostPerChild: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      },
      totalCostPerChildLbl: {
        display: 'flex',
        paddingTop: spacing(2),
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      marker: {
        display: 'inline-block',
        width: '23px',
        height: '23px',
        borderRadius: '50%',
        verticalAlign: 'middle',
        marginRight: spacing(1),
        flexShrink: 0,
      },
      mapItem: {
        transform: 'translateX(-50%) translateY(-50%)',
      },
      mapCircle: {
        'position': 'relative',
        'border': '2px solid transparent',
        'borderRadius': '50%',
        'width': 'calc(var(--perHousehold) / 200 * 0.03 * 1200px)',
        'height': 'calc(var(--perHousehold) / 200 * 0.03 * 1200px)',
        [breakpoints.down('sm')]: {
          width: 'calc(var(--perHousehold) / 200 * 0.03 * 600px)',
          height: 'calc(var(--perHousehold) / 200 * 0.03 * 600px)',
        },
        '&:hover': {
          borderColor: colors.white,
        },
        '&:before': {
          position: 'relative',
          zIndex: 10,
          content: '""',
          display: 'block',
          paddingBottom: '100%',
          backgroundColor: colors.purpleDark,
          borderRadius: '50%',
        },
        ...innerCircle,
      },
      mapCircleCountry: {
        position: 'absolute',
        backgroundColor: colors.white,
        top: '50%',
        transform: 'translateY(-50%)',
        right: '100%',
        marginRight: '-0.2em',
        padding: '0em .5em',
        boxShadow: '0px 0px 10px rgba(0,0,0,.1)',
      },
      bgPerChild: {
        backgroundColor: colors.purpleNormal,
      },
      bgPerHousehold: {
        backgroundColor: colors.purpleDark,
      },
      clPerChild: {
        color: colors.purpleNormal,
      },
      clPerHousehold: {
        color: colors.purpleDark,
      },
      clIncome: {
        color: colors.greenLight,
      },
    };
  });

export default useStyles;
