import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  wrapper: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.modal,
  },
}));
