import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  messageHeading: {
    paddingBottom: spacing(3),
    [breakpoints.up('md')]: {
      fontSize: '3.5rem',
    },
  },
  messageSubheading: {
    paddingBottom: spacing(4),
    fontWeight: 700,
  },
  contentParagraph: {
    fontSize: '1.25rem',
  },
}));
