const LANGUAGE_NAMES = {
  'da': 'Dansk',
  'da-dk': 'Dansk',
  'de': 'Deutsch',
  'en': 'English',
  'en-us': 'English (US)',
  'es': 'Español',
  'fr': 'Français',
  'nl': 'Nederlands',
  'it': 'Italiano',
  'pt': 'Portuguese',
  'pt-ao': 'Portuguese (AO)',
  'pt-br': 'Portuguese (BR)',
  'pt-gw': 'Portuguese (GW)',
  'pt-mz': 'Portuguese (MZ)',
  'pt-st': 'Portuguese (ST)',
  'pt-pt': 'Portuguese (PT)',
} as const;

import { DEFAULT_LANGUAGE } from '../constants';

export const pageLanguageMapping: { [key: string]: string } = {
  en: 'english',
  fr: 'french',
  es: 'spanish',
  da: 'danish',
  nl: 'dutch',
  de: 'german',
};

export type LanguageCode = keyof typeof LANGUAGE_NAMES;
type LanguageName = typeof LANGUAGE_NAMES[LanguageCode];

export const getLanguageNameAndCode = (
  locale: LanguageCode,
): {
  label: LanguageName;
  value: LanguageCode;
} => {
  if (!LANGUAGE_NAMES[locale]) {
    throw new Error(`Invalid or unsupported locale: ${locale})`);
  }

  return {
    value: locale,
    label: LANGUAGE_NAMES[locale],
  };
};

/**
 * Helper function that transforms sub-locales into their parent locale
 * @param locale string which is the locale in the URL that a user is visiting
 * @param localeSet array of locales and their keys
 * @returns the locale key from the set that matches the locale in the URL or the default locale 'en'
 */
export const deriveLanguageFromLocale = (
  locale: string | null | undefined,
  localeSet: { [key: string]: string } | null | undefined,
) => {
  if (!locale || !localeSet) return DEFAULT_LANGUAGE;

  const localeKeys = Object.keys(localeSet);
  for (const key of localeKeys) {
    if (locale === key || locale.includes(`${key}-`)) {
      return key;
    }
  }

  return DEFAULT_LANGUAGE;
};
