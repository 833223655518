import { Box } from '@mui/material';
import React from 'react';

import { usePerimeterX } from './perimeterx.hooks';
import useStyles from './perimeterx.styles';
import { IPerimeterXProps } from './perimeterx.types';

// ssr part of perimeterX is in packages/cms/plugins/custom-gatsby-plugin-perimeterx/gatsby-ssr.js
const PerimeterX: React.FC<IPerimeterXProps> = ({ captchaPxLabel }) => {
  const classes = useStyles();

  usePerimeterX();

  return (
    <Box
      id="pxElement"
      data-testid="pxElement"
      className={classes.pxElement}
      hidden
      borderRadius="inherit"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      position="absolute"
      left={0}
      top={0}
      height="100%"
      padding={1.5}
    >
      <h2>{captchaPxLabel}</h2>
    </Box>
  );
};

export default PerimeterX;
