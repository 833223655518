import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

import { Button } from '../button';
import { CountryFlag } from '../country-flag-image';
import { CountryRow } from '../country-row';
import useStyles from './country-search.styles';
import { CountryOption, CountrySearchProps } from './country-search.types';
import { filterOptions } from './country-search.utils';

export function CountrySearch<RichText>({
  name,
  countries,
  onSubmit,
  RichTextComponent,
  description,
  buttonText,
  placeholder,
}: CountrySearchProps<RichText>): React.ReactElement {
  const classes = useStyles();
  const [value, setValue] = useState<CountryOption | null>(null);

  const handleOnChange = (
    _: React.BaseSyntheticEvent,
    newValue: CountryOption | null,
  ) => {
    setValue(newValue);
  };

  const handleClear = () => {
    setValue(null);
  };

  return (
    <section id={name}>
      {description && RichTextComponent && (
        <RichTextComponent {...description} />
      )}
      <Autocomplete
        id={`${name}-search-input`}
        data-testid={`${name}-search-input`}
        options={countries}
        classes={{
          option: classes.option,
        }}
        openOnFocus
        fullWidth
        disablePortal
        value={value}
        onChange={handleOnChange}
        filterOptions={filterOptions}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              'data-testid': `${name}-text-input`,
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.inputBase,
                input: classes.input,
              },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  {value ? (
                    <CountryFlag type="circle" code={value.countryCode} />
                  ) : (
                    <SearchIcon
                      style={{ fontSize: 31 }}
                      color="secondary"
                      data-testid={`${name}-search-icon`}
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: value ? (
                <InputAdornment position="end">
                  <ClearIcon
                    onClick={handleClear}
                    classes={{
                      root: classes.icon,
                    }}
                    color="secondary"
                    data-testid={`${name}-clear-icon`}
                  />
                </InputAdornment>
              ) : null,
            }}
            variant="standard"
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={`${option.countryCode}-${option.currency}`}>
            <CountryRow
              countryCode={option.countryCode.toLowerCase()}
              currencyCode={option.currency.toUpperCase()}
              countryName={option.name}
            />
          </li>
        )}
        getOptionLabel={option => option.name}
      />
      <Button
        disabled={!value}
        variant="contained"
        color="primary"
        classes={{
          root: classes.button,
        }}
        onClick={(e: React.SyntheticEvent) =>
          onSubmit(value as CountryOption, e)
        }
      >
        {buttonText}
      </Button>
    </section>
  );
}
