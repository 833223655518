import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from '@wr/web-ui';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  currencyCode: {
    color: colors.purpleNormal,
    textDecorationLine: 'underline',
    marginTop: spacing(0.5),
    fontWeight: 700,
  },
}));
