import { Box, Typography } from '@mui/material';
import { interpolate } from '@wr/web-shared/src/utils/interpolate';
import React from 'react';

import { useStyles } from './exchange-rate.styles';
import { CalculatorExchangeRateProps } from './exchange-rate.types';

export const CalculatorExchangeRate: React.FC<CalculatorExchangeRateProps> = ({
  isLoading,
  exchangeRate,
  sendCurrencyCode,
  receiveCurrencyCode,
  promoLabel,
  blendedRateLabel,
}) => {
  const classes = useStyles();

  const blendedRate = interpolate(blendedRateLabel, {
    blendedRate: `<span style="font-weight: bold;">${(
      exchangeRate?.conversionRate || 0
    ).toString()}</span>`,
    currency: sendCurrencyCode,
  });

  return (
    <div className={classes.exchangeRateContainer}>
      <Box className={classes.contentWrapper} data-testid="exchange-rate">
        {!isLoading && exchangeRate?.conversionRate ? (
          <div className={classes.exchangeRateAnimationContainer}>
            {promoLabel && exchangeRate.crossedOutRate ? (
              <Box className={classes.specialRateChip}>
                <Typography className={classes.specialRateText}>
                  {promoLabel}
                </Typography>
              </Box>
            ) : null}

            <Box className={classes.contentContainer}>
              <Typography className={classes.exchangeRateText}>
                {`1 ${sendCurrencyCode} = `}
              </Typography>
              <Typography className={classes.exchangeRateText}>
                {`${exchangeRate.conversionRate} ${receiveCurrencyCode}`}
              </Typography>
            </Box>
            {exchangeRate.crossedOutRate ? (
              <Typography
                className={classes.blendedRate}
                dangerouslySetInnerHTML={{
                  __html: blendedRate,
                }}
              />
            ) : null}
          </div>
        ) : null}
      </Box>
    </div>
  );
};
