import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { TextSectionProps } from './text-section.types';

export const useStyles = makeStyles<Theme, Partial<TextSectionProps<unknown>>>(
  theme => ({
    textSection: ({ backgroundColor, isOrderReversed }) => ({
      display: 'flex',
      flexDirection: theme.customComponents.textSection.flexDirection,
      ...(backgroundColor ? { backgroundColor } : {}),

      [theme.breakpoints.up('md')]: {
        flexDirection: isOrderReversed ? 'row-reverse' : 'row',
      },
    }),
    textSectionColumn: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flex: 1,
        height:
          theme.customComponents.textSection.textSectionColumn.breakpointsUpMd
            .height,
        alignItems:
          theme.customComponents.textSection.textSectionColumn.breakpointsUpMd
            .alignItems,
      },
    },
    textSectionImage: {
      position: 'relative',
      height:
        theme.customComponents.textSection.textSectionImage.breakpointsSm
          .height,

      [theme.breakpoints.up('sm')]: {
        height: '20rem',
      },
      [theme.breakpoints.up('md')]: {
        height:
          theme.customComponents.textSection.textSectionImage.breakpointsMdUp
            .height,
      },
    },
    textSectionContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      textAlign:
        theme.customComponents.textSection.textSectionContent.textAlign,

      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        textAlign: 'initial',
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
      [theme.breakpoints.up('xl')]: {
        paddingTop: ({ image }) => (image ? undefined : theme.spacing(7.5)),
        paddingBottom: ({ image }) => (image ? undefined : theme.spacing(7.5)),
      },
    },
  }),
);
